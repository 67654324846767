import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Certification } from 'src/app/core/models/certification';
import { decrypt } from '../../configs/encryption';
import { CertificationService } from '../certification.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  certification = new Certification();
  certificationId = this.route.snapshot.params.id;
  loading = false;

  constructor(
    private certificationService: CertificationService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    public location: Location,
    private confirmation: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    const decryptedId = Number(decrypt(this.certificationId));
    this.get(decryptedId);
  }

  get(id: number) {
    this.certificationService.getById(id)
      .then(response => {
        this.certification = response;
      }).catch(erro => this.errorHandler.handle(erro));
  }

  confirm(certification: any) {
    const status = !certification.estadoPedidoCertificacao;
    const action = status ? 'Certificar' : 'Não Certificar';
    this.confirmation.confirm({
      message: `${action} o pedido?`,
      accept: () => {
        this.valid(certification);
      }
    });
  }

  valid(certification: any): void {
    this.loading = true;

    const newStatus = !certification.estadoPedidoCertificacao;
    const decryptedId = Number(decrypt(this.certificationId));
    this.certification.idPedidoCertificacao = decryptedId;
    this.certificationService.valid(certification.idPedidoCertificacao, newStatus)
      .then(() => {
        this.get(decryptedId);
        const action = newStatus ? 'autorizado' : 'não autorizado';
        certification.estadoPedidoCertificacao = newStatus;
        this.messageService.add({
          severity: 'success',
          detail: `Pedido de certificação ${action} com sucesso!`
        })
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }
}

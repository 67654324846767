import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Certification } from 'src/app/core/models/certification';
import { Emission } from 'src/app/core/models/emission';
import { CertificationEmitterService } from '../certification-emitter.service';

interface CreateCertificationOnlineResultDTO {
  descTipoCertificacao: string;
  dtCertificacao: string;
  dtCriaPedidoCertificacao: string;
  estadoPedidoCertificacao: boolean;
  idPedidoCertificacao: number;
  nifContribuinte: string;
  nomePessoa: string;
  numeroCertificacao: string;
  descPedidoCertificacao: string;
  idCertificacao: number;
  idPedidoEmissaoFatura: number;
  nifSolicitante: string;

  autorizacaoEmissaoList: {
    anoAutorizacaoEmissao: number;
    descTipoDocumentoEmissao: string;
    numAutorizacaoEmissao: string;
    numSerieDocumento: string;
    quantidadeDocumentosSolicitados: number;
    siglaTipoDocumentoEmissao: string;
  }[];
}

@Component({
  selector: 'app-create-for-portal',
  templateUrl: './create-for-portal.component.html',
  styleUrls: ['./create-for-portal.component.scss'],
})
export class CreateForPortalComponent implements OnInit {
  loading = false;
  display = false;
  acceptTerms: any;
  certification = new Certification();
  emission = new Emission();
  msgs2: Message[];
  displayMessage = false;

  certificationResult: CreateCertificationOnlineResultDTO;
  alreadyCertified = false;

  constructor(
    private certificationService: CertificationEmitterService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  addErrorMessage() {
    this.msgs2 = [
      {
        severity: 'info',
        summary: 'Contribuinte já certificado',
        detail:
          'Já possui certificação para emitir séries no portal. Reveja os dados abaixo',
      },
    ];
  }

  addMessages() {
    this.msgs2 = [
      {
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Pedido de certificação efectuado com sucesso.',
      },
    ];
  }

  createForPortal() {
    this.loading = true;
    delete this.certification.pedidoCertificacaoSoftware;

    this.certificationService
      .createForPortal(this.certification)
      .then((response) => {
        if (response.status === 200) {
          this.messageService.add({
            key: 'lg',
            severity: 'info',
            detail:
              'Já recebemos pedido de certificação para este contribuinte! Verifica os detalhes na lista de pedidos.',
            life: 15000,
          });
        } else {
          this.certificationResult = response.body;
          this.display = true;
          this.addMessages();
        }
      })
      .catch((error) => {
        if (error.status === 409) {
          this.alreadyCertified = true;
          this.certificationResult = error.error;
          this.addErrorMessage();
          this.displayMessage = true;
          this.display = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => (this.loading = false));
  }

  clearMessages() {
    this.msgs2 = [];
  }
}

import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Person } from '../core/models/person';
import { AppHttp } from '../auth/app-http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  API_URL: string;

  constructor(
    private http: AppHttp,
  ) {
    this.API_URL = `${environment.apiUrl}/pessoas`;
  }

  update(person: Person): Promise<Person> {
    return this.http.put<Person>(`${this.API_URL}/${person.idPessoa}`, person)
      .toPromise()
      .then(response => {
        const person = response as Person;
        this.convertFields([person]);
        return person;
      })
  }

  private convertFields(people: Person[]) {
    people.forEach(person => {
      person.dtCriaPessoa = moment(person.dtCriaPessoa, 'YYYY-MM-DD').toDate();
      person.dtAlterPessoa = moment(person.dtAlterPessoa, 'YYYY-MM-DD').toDate();
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Authorization } from 'src/app/core/models/authorization';
import { AuthorizationFilter, AuthorizationService } from '../authorization.service';

@Component({
  selector: 'app-authorization-read-total-serie-for-emitter',
  templateUrl: './authorization-read-total-serie-for-emitter.component.html',
  styleUrls: ['./authorization-read-total-serie-for-emitter.component.scss']
})
export class AuthorizationReadTotalSerieForEmitterComponent implements OnInit {
  loading = true;
  authorizations: Authorization[];
  total = 0;
  filter = new AuthorizationFilter;

  constructor(
    private authorizationService: AuthorizationService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.filter.page = page;

    this.authorizationService.readTotalSerieForeachTaxpayer(this.filter)
      .then((response) => {
        this.total = response.total;
        this.authorizations = response.body;
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.read(page);
  }

}

import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { Title } from '@angular/platform-browser';
import { CertificationTypographyService } from '../certification-typography.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  loading = false;
  uploadedFile: any = [];

  constructor(
    private certificationTypographyService: CertificationTypographyService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private auth: AuthService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Pedido de certificação para tipografias');
  }

  onSelectFile(evt: any) {
    this.uploadedFile.push(evt);
  }

  onUpload(event, fileUpload) {
    this.loading = true;
    var pdfFile;
    const formData = new FormData();

    for (let file of event.files) {
      pdfFile = file;
      formData.append('files', pdfFile);
    }

    var jsonObject = {
      // idContribuinte: this.auth.jwtPayload.idCont,
      // idUsuarioCria: this.auth.jwtPayload.idUsuario,
      // idUsuarioAlter: this.auth.jwtPayload.idUsuario
    };

    formData.append('jsondata', JSON.stringify(jsonObject));
    this.certificationTypographyService
      .create(formData)
      .then((response) => {
        if (response.status === 200) {
          this.messageService.add({
            key: 'lg',
            severity: 'error',
            detail:
              'Já solicitou o pedido de certificação! Por favor, verifique a sua lista de pedidos.',
            life: 30000,
          });
        } else {
          this.messageService.add({
            key: 'lg',
            severity: 'success',
            detail:
              ' Pedido enviado com sucesso. Receberá um e-mail de confirmação assim que o pedido for confirmado.',
            life: 20000,
          });
        }
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }
}

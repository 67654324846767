<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tipografia">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR SÉRIES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="tiposDocumento" name="idTipoDocumentoEmissao"
                    [showClear]="true" [(ngModel)]="filter.idTipoDocumentoEmissao" placeholder="Tipo Documento">
                  </p-dropdown>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="serieNumber" [(ngModel)]="filter.serieNumber">
                  <label for="float-input">Série (ex.FR0000120)</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="description" [(ngModel)]="filter.description">
                  <label for="float-input">Descrição</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoDe" [(ngModel)]="filter.dataInicio"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data inicial</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoAte" [(ngModel)]="filter.dataFim"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data final</label>
                </span>
              </div>
            </div>

            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="years" name="year" [showClear]="true"
                    [(ngModel)]="filter.year" placeholder="Ano da série">
                  </p-dropdown>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="emitterNif" [(ngModel)]="filter.emitterNif">
                  <label for="float-input">NIF do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-4">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="emitterName" [(ngModel)]="filter.emitterName">
                  <label for="float-input">Nome do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="emissionDocuments"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" dataKey="idPedidoEmissaoDocumento" editMode="row" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} séries solicitadas.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'10%'}">Documento</th>
              <th [style]="{width:'6%'}">Série</th>
              <th [style]="{width:'5%'}">Última Solicitação</th>
              <th [style]="{width:'6%'}">Data</th>
              <th [style]="{width:'12%'}">Descrição</th>
              <th [style]="{width:'22%'}">Emitente</th>
              <th [style]="{width:'6%'}">Requerente</th>
              <th [style]="{width:'6%'}">Solicitante</th>
              <th [style]="{width:'5%'}">Status</th>
              <th [style]="{width:'2.5%'}"></th>
              <th [style]="{width:'2.5%'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">Tipo de Documento</span>
                {{
                rowData.tipoDocumento
                }}
              </td>

              <td>
                <span class="p-column-title">Série</span>
                {{
                rowData.siglaSerie +''+
                rowData.serie +''+
                rowData.ano
                }}
              </td>

              <td>
                <span class="p-column-title">Última Solicitação</span>
                {{ rowData.totalSolicitado }}
              </td>

              <td>
                <span class="p-column-title">Data</span>
                {{rowData.data | date:'dd/MM/yyyy'}}
              </td>

              <td>
                <span class="p-column-title">Descrição</span>
                {{rowData.descricao}}
              </td>

              <td>
                <span class="p-column-title">Emitente</span>
                {{rowData.nifEmissor+' - '+rowData.nomeEmissor}}
              </td>

              <td>
                <span class="p-column-title">Requerente</span>
                <span [pTooltip]="rowData.nomeRequerente" tooltipPosition="left">
                  {{rowData.nifRequerente}}
                </span>
              </td>

              <td>
                <span class="p-column-title">NIF Solicitante</span>
                {{rowData.nifSolicitante}}
              </td>

              <td>
                <span class="p-column-title">Status</span>
                {{rowData.statusSerie}}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button mdbBtn block="true" color="info" size="sm" pTooltip="Mais detalhes" tooltipPosition="left"
                  (click)="showDialogDetails(rowData);" [style]="{padding: '4px 0'}">
                  <i class="fa fa-search"></i>
                </button>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <a mdbBtn block="true" color="warning" size="sm" pTooltip="Editar" tooltipPosition="left"
                  [routerLink]="['/typography/emission-document/get', rowData.idPedidoEmissaoDocumento]"
                  [style]="{padding: '4px 0'}">
                  <i class="fa fa-edit"></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Atualizar descrição" [(visible)]="displayEdit" [modal]="true" [responsive]="true"
  [style]="{width: '30vw', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <form #updateDescriptionForm="ngForm" (ngSubmit)="updateDescription()">

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Descrição</label>
        <input pInputText type="text" name="descPedidoEmissaoDocumento"
          [(ngModel)]="emissionDocument.descPedidoEmissaoDocumento">
      </div>
    </div>

    <div class="p-col text-right">
      <hr>
      <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayEdit=false" [disabled]="loading">
        CANCELAR
      </button>
      <button type="submit" mdbBtn color="primary" mdbWavesEffect [disabled]="!updateDescriptionForm.valid || loading">
        SALVAR
      </button>
    </div>

    <div class="p-col">
      <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    </div>
  </form>
</p-dialog>

<p-dialog header="Detalhes" [(visible)]="displayDetails" [modal]="true" [responsive]="true"
  [style]="{width: '40vw', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <div class="table-responsive-sm">
    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row"> <strong>Tipo Documento </strong></th>
          <td> {{ emissionDocumentView.tipoDocumento }} </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Nº de Série </strong></th>
          <td>
            {{
            emissionDocumentView.siglaSerie +''+
            emissionDocumentView.serie +''+
            emissionDocumentView.ano
            }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Nº de Autorização </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.numAutorizacaoEmissao }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Data de Autorização </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.data | date:'dd/MM/yyyy' }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Descrição </strong></th>
          <td colspan="2"> {{ emissionDocumentView.descricao }} </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Total Solicitado </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.totalJaSolicitado }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Última Solicitação </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.totalSolicitado }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Máscara </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.mascara }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Emitente </strong></th>
          <td colspan="2">
            {{emissionDocumentView.nifEmissor+' - '+emissionDocumentView.nomeEmissor}}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Requerente </strong></th>
          <td colspan="2">
            {{emissionDocumentView.nifRequerente+' - '+emissionDocumentView.nomeRequerente}}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Tipo de Certificação </strong></th>
          <td colspan="2">
            {{emissionDocumentView.tipoCertificacao}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="p-col text-right">
    <hr>
    <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayDetails=false" [disabled]="loading">
      FECHAR
    </button>
  </div>
</p-dialog>

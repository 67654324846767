<form autocomplete="off" (ngSubmit)="read()">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12 p-md-2">
        <span class="p-float-label">
          <input pInputText class="p-inputtext-sm" name="nifApplicant" [(ngModel)]="filter.nifApplicant">
          <label for="float-input">NIF Solicitante</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-2">
        <span class="p-float-label">
          <input pInputText class="p-inputtext-sm" name="nifExecutor" [(ngModel)]="filter.nifExecutor">
          <label for="float-input">NIF Executor</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-1">
        <span class="p-float-label">
          <p-dropdown inputId="status" class="p-inputtext-sm" [autoDisplayFirst]="false" [showClear]="true"
            [options]="statuses" name="status" [(ngModel)]="filter.status" optionLabel="name">
          </p-dropdown>
          <label for="status">Status</label>
        </span>
      </div>

      <div class="p-field p-col-2">
        <span class="p-float-label">
          <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
            dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
          </p-calendar>
          <label for="float-input">Data registo</label>
        </span>
      </div>

      <div class="p-field p-col-2">
        <span class="p-float-label">
          <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
            dateFormat="dd/mm/yy" name="answeredAt" [(ngModel)]="filter.answeredAt">
          </p-calendar>
          <label for="float-input">Data registo</label>
        </span>
      </div>

      <div class="p-field p-col">
        <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
          Filtrar
        </button>
      </div>

    </div>
  </div>
</form>

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
  [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
  (onLazyLoad)="onChangePage($event)">
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">
      Total de {{ total | number:'1.0-0'}} ficheiros recolhidos.
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th [style]="{width: '35%'}">Ficheiro</th>
      <th class="reason-column">Motivo</th>
      <th [style]="{width: '6%'}">Dt Registo</th>
      <th [style]="{width: '6%'}">Dt Resposta</th>
      <th [style]="{width: '6%'}">Solicitante</th>
      <th [style]="{width: '6%'}">Estado</th>
      <th [style]="{width:'5%'}"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <span class="p-column-title">Ficheiro</span>
        {{ rowData.tbArquivoDocumentoGerado.nomeArquivoDocumentoGerado }}
      </td>

      <td class="reason-column">
        <span class="p-column-title">Motivo</span>
        <span [innerHTML]="rowData.descMotivo"></span>
      </td>

      <td>
        <span class="p-column-title">Dt Registo</span>
        {{ rowData.dtCria | date:'dd/MM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title">Dt Resposta</span>
        {{ rowData.dataResposta | date:'MMM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title">Solicitante</span>
        {{ rowData.nifSolicitante }}
      </td>

      <td>
        <span class="p-column-title">Status</span>
        <div class="badges">
          <span [class]="['p-tag', rowData.statusColor]">
            {{ rowData.status }}
          </span>
        </div>
      </td>

      <td [style]="{padding: '1px 4px'}">
        <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações" tooltipPosition="left"
          class="btn-block" [style]="{padding: '4px 0'}" (click)="showDialog(rowData)">
          <i class="fa fa-search"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Mais Detalhes" [(visible)]="display" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <form #formDelete="ngForm">
    <div class="p-field">
      <label for="descMotivo">Motivo da anulação</label>
      <p-editor name="descMotivo" [(ngModel)]="request.descMotivo" [style]="{'height':'120px'}" [readonly]="true">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
          </span>
        </ng-template>
      </p-editor>
    </div>

    <div class="p-col-12 text-right">
      <hr>
      <button type="button" mdbBtn size="md" color="light" (click)="display=false">
        FECHAR
      </button>
    </div>

    <div class="p-col-12">
      <p-progressBar *ngIf="loadingDelete" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
    </div>
  </form>
</p-dialog>

import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  @Input() userId: number;
  loading = false;
  email: string;

  constructor(
    private profileService: ProfileService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  changeEmail(): void {
    this.loading = true;

    this.profileService.changeEmail({
      userId: this.userId,
      email: this.email
    })
      .then((result) => {
        this.messageService.add({
          severity: 'success',
          detail: 'Foi enviado uma mensagem de confirmação para o seu novo e-mail. Por favor, aceder e seguir as instruções nela contida.',
          life: 20000,
        })
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => this.loading = false);
  }
}

<mdb-card>
  <mdb-card-header class="mdb-card-header text-center p-pt-2">
    <div class="p-grid">
      <div class="p-col-5 p-d-flex p-jc-end">
        <mdb-icon fas icon="chevron-circle-left" size="2x" (click)="arrowLeft()"></mdb-icon>
      </div>
      <div class="p-col-2 p-d-flex p-jc-center">
        <h4>{{now | date:"y" }}</h4>
      </div>
      <div class="p-col-5 p-d-flex p-jc-start">
        <mdb-icon fas icon="chevron-circle-right" size="2x" (click)="arrowRight()"></mdb-icon>
      </div>
    </div>
  </mdb-card-header>

  <mdb-card-body>
    <app-statistic-emitter-detail-short>
    </app-statistic-emitter-detail-short>
  </mdb-card-body>
</mdb-card>

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { ControlDocumentFilter, ControlDocumentService } from './../control-document.service';
import { AuthorizationService } from 'src/app/authorization/authorization.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  loading = true;
  controls = [];
  series = [];
  total = 0;
  filter = new ControlDocumentFilter;

  constructor(
    private service: ControlDocumentService,
    private authorizationService: AuthorizationService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {

  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.controls = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  dropdownSeries(nif: string) {
    this.authorizationService.dropdownByNif(nif)
      .then((response) => this.series = response)
      .catch((error) => this.errorHandler.handle(error));
  }

}

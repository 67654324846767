<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/document/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off">
          <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">NIF do Emitente</label>
              <input pInputText type="text" name="document.tbContribuinteNifEmissor"
                [(ngModel)]="document.tbContribuinteNifEmissor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label class="p-text-bold">Nome do Emitente</label>
              <input pInputText type="text" name="document.tbPessoaNomeEmissor"
                [(ngModel)]="document.tbPessoaNomeEmissor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">NIF do Adquirente</label>
              <input pInputText name="nifConsumidor" [(ngModel)]="document.tbContribuinteNifConsumidor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label class="p-text-bold">Nome do Adquirente</label>
              <input pInputText name="nomePessoa" [(ngModel)]="document.tbPessoaNomeConsumidor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Tipo de Documento*</label>
              <input pInputText type="text" name="tipoFactura" [(ngModel)]="document.tbTipoDocumentoDescricao" disabled>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Número da Documento</label>
              <input pInputText type="text" name="numDocumento" [(ngModel)]="document.numDocumento" disabled>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Data de Emissão</label>
              <p-calendar name="dtEmissaoDocumento" [readonlyInput]="true" dateFormat="dd/mm/yy"
                [(ngModel)]="document.dtEmissaoDocumento" disabled></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Tipo comunicação</label>
              <input pInputText type="text" name="origem" [(ngModel)]="document.origem" disabled>
            </div>

            <div class="p-col-12 document-items">
              <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
                [value]="document.tbItensDocumentoGerados">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Base Tributável</th>
                    <th>Tipo Imposto</th>
                    <th>Taxa</th>
                    <th>Cod. Isenção</th>
                    <th>Imposto</th>
                    <th>Valor Total</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>
                      <span class="p-column-title">Base Tributável</span>
                      {{ item.valorBaseTributavelItens | number:'0.1-6':'pt' }}
                    </td>

                    <td>
                      <span class="p-column-title">Tipo Imposto</span>
                      {{ item.tbTipoImpostoSigla }}
                    </td>

                    <td>
                      <span class="p-column-title">Taxa</span>
                      {{ item.tbTaxaAplicavelDescricao }}
                    </td>

                    <td>
                      <span class="p-column-title">Cod. Isenção</span>
                      {{ item.tbMotivoIsencaoCodigo }}
                    </td>

                    <td>
                      <span class="p-column-title">imposto</span>
                      {{ item.valorImposto | number:'0.1-6':'pt' }}
                    </td>

                    <td>
                      <span class="p-column-title">Valor Total</span>
                      {{ item.totalLinhaAposImpostoItens | number:'0.1-2':'pt' }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Base Tributável</label>
              <input pInputText name="baseTributavel" [(ngModel)]="document.totalValorBaseTributavel" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Imposto</label>
              <input pInputText name="imposto" [(ngModel)]="document.totalImposto" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Valor Total</label>
              <input pInputText name="valorTotal" [(ngModel)]="document.totalValor" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="text-danger p-text-bold">
                Valor Anulado
              </label>
              <input pInputText name="canceledAmount" [(ngModel)]="document.canceledAmount" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>

            <div class="p-col-12 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/document/read">
                VOLTAR
              </button>

              <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="displayCreditAndDebitNotes()">
                NOTAS DE CRÉDITO E DÉBITO
                <span class="badge bg-danger ms-2" *ngIf="creditAndDebitNotesItems.length > 0">
                  {{creditAndDebitNotesItems.length}}
                </span>
              </button>
            </div>

          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-sidebar [(visible)]="creditAndDebitNotesIsOpen" position="right" [baseZIndex]="10000" [style]="{width: '80vw'}">
  <h4>Notas de Crédito e Débito</h4>

  <app-get-credit-and-debit-items-by-invoice [creditAndDebitNotesItems]="creditAndDebitNotesItems">
  </app-get-credit-and-debit-items-by-invoice>
</p-sidebar>

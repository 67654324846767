import { MessageService, ConfirmationService } from 'primeng/api';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { RoutinesService } from './../routines.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-total-emission-document',
  templateUrl: './update-total-emission-document.component.html',
  styleUrls: ['./update-total-emission-document.component.scss']
})
export class UpdateTotalEmissionDocumentComponent implements OnInit {
  loading: boolean = false;
  routine: string = 'Atualizar o total de documentos adicionados a série';

  constructor(
    private routinesService: RoutinesService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
  }

  run() {
    this.loading = true;
    this.routinesService.updateTotalEmissionDocument()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  confirm() {
    this.confirmationService.confirm({
      message: `${this.routine}?`,
      accept: () => {
        this.run();
      }
    });
  }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Certification } from '../../core/models/certification';
import { CertificationEmitterService } from '../certification-emitter.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  certification = new Certification();

  constructor(
    private certificationEmitterService: CertificationEmitterService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    public location: Location
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    this.get(id);
  }

  get(id: number) {
    this.certificationEmitterService.getById(id)
      .then((response) => {
        this.certification = response;
      }).catch(erro => this.errorHandler.handle(erro));
  }
}

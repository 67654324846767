import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from './../../environments/environment';

export class AuthorizationUtilFilter {
  page = 0;
  rows = 10;
  nifEmissor: string;
  numSerieDocumento: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationUtilService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}`;
  }

  check(filter: AuthorizationUtilFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    return this.http.get<any>(`${this.API_URL}/pedidoEmissaoDocumento/util`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <any[]>response.content,
          total: response.totalElements
        }
      });
  }
}

<button mdbBtn type="button" color="primary" mdbWavesEffect (click)="exportReport()">
  <mdb-icon fas icon="file-pdf"></mdb-icon>
  EXPORTAR RELATÓRIO
</button>

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="data.series"
  [loading]="loading" [responsive]="true" [paginator]="true" [rows]="10" [lazy]="true">
  <ng-template pTemplate="header">
    <tr>
      <th>Série</th>
      <th>Data de solicitação</th>
      <th>Último mês comunicado</th>
      <th>Tem comunicação</th>
      <th [style]="{width: '10em'}"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <span class="p-column-title">Série</span>
        {{ rowData.seriesSolicitada }}
      </td>

      <td>
        <span class="p-column-title">Data de solicitação</span>
        {{ rowData.seriesSolicitadaData | date:'dd/MM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title">Último mês comunicado</span>
        {{ rowData.ultimoMesComunicado }}
      </td>

      <td>
        <span class="p-column-title">Último mês comunicado</span>
        {{ rowData.ultimoMesComunicado ? 'SIM' : 'NÃO' }}
      </td>

      <td [style]="{padding: '1px 4px'}">
        <button *ngIf="rowData.mesesAtraso.length; else elseBlock" 
          type="button" mdbBtn color="danger" size="sm" tooltipPosition="left"  
          pTooltip="Ver meses em atraso"
          class="btn-block" [style]="{padding: '4px 0'}" (click)="showDialog(rowData)">
          {{ rowData.mesesAtraso.length }} meses em atraso
        </button>
        <ng-template #elseBlock>
          <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left"  
            pTooltip="Sem meses em atraso"
            class="btn-block" [style]="{padding: '4px 0'}" disabled>
            Sem meses em atraso
        </button>
        </ng-template>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Meses em atraso" [(visible)]="display" [style]="{width: '15vw'}" [baseZIndex]="10000" modal="true">

  <ul class="list-group list-group-flush">
    <li class="list-group-item" *ngFor="let month of monthsOverdue"> {{month.label}} </li>
  </ul>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="display=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

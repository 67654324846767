import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { EditorModule } from 'primeng/editor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { OccurrenceEmitterRouter } from './occurrence-emitter-router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadComponent } from './read/read.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { NewComponent } from './new/new.component';
import { ShowComponent } from './show/show.component';

@NgModule({
  declarations: [
    ReadComponent,
    NewComponent,
    ShowComponent
  ],
  imports: [
    CommonModule,
    OccurrenceEmitterRouter,
    FormsModule,
    MDBBootstrapModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    ProgressBarModule,
    EditorModule,
    CalendarModule,
    DialogModule
  ]
})
export class OccurrenceEmitterModule { }

import { TaxPayer } from './../../core/models/taxpayer';
import { ThirdPartyEmitterService } from './../../third-party-emitter/third-party-emitter.service';
import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { encrypt } from '../../configs/encryption';
import {
  FileDocumentFilter,
  FileDocumentService,
} from '../file-document.service';
import { FileDocumentEmitterService } from 'src/app/file-document-emitter/file-document-emitter.service';

@Component({
  selector: 'app-read-for-third-party',
  templateUrl: './read-for-third-party.component.html',
  styleUrls: ['./read-for-third-party.component.scss'],
})
export class ReadForThirdPartyComponent implements OnInit {
  locale = new Locale();
  fileDocuments = [];
  loading = true;
  total = 0;
  filter = new FileDocumentFilter();
  ACTIVITY_ID = 3;

  statusFiles = [
    { code: true, name: 'ATIVO' },
    { code: false, name: 'INATIVO' },
  ];
  emitters = [];

  fileDocument = new FileDocument();
  displayCancelFile: boolean = false;
  loadingCancelFile: boolean = false;

  statusAnalises = [
    { code: 'POR_ANALISAR', name: 'POR ANALISAR' },
    { code: 'ANALISE_PRIMEIRA_FASE', name: 'ANALISE 1ª FASE' },
    { code: 'ANALISE_SEGUNDA_FASE', name: 'ANALISE 2ª FASE' },
    { code: 'ANALISE_TERCEIRA_FASE', name: 'ANALISE 3ª FASE' },
    { code: 'REJEITADO', name: 'REJEITADO' },
    { code: 'CONCLUIDO', name: 'CONCLUÍDO' },
    { code: 'PROCESSANDO', name: 'PROCESSANDO' },
  ];

  constructor(
    private service: FileDocumentService,
    private fileDocumentEmitterService: FileDocumentEmitterService,
    private thirdPartyEmitterService: ThirdPartyEmitterService,
    private errorHandler: ErrorHandlerService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.thirdPartyEmitterService.getEmittersByActivity(this.ACTIVITY_ID).then(
      (emitters: TaxPayer[]) =>
        (this.emitters = emitters.map((el: TaxPayer) => ({
          value: el.tbPessoa.nifPessoa,
          label: `${el.tbPessoa.nifPessoa} - ${el.tbPessoa.nomePessoa}`,
        })))
    );
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service
      .readForThirdParty(this.filter)
      .then((response) => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  showCancelFile(fileDocument: FileDocument) {
    this.fileDocument = fileDocument;
    this.displayCancelFile = true;
  }

  getFile(file: FileDocument) {
    this.service
      .getFile(file)
      .then((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() =>
        this.message.add({
          key: 'lg',
          severity: 'error',
          life: 20000,
          detail: 'Não foi possível transferir o ficheiro! Tente mais tarde.',
        })
      );
  }

  encrypt(info: string) {
    return encrypt(info);
  }

  cancelFile() {
    this.loadingCancelFile = true;
    this.fileDocumentEmitterService
      .cancelFile(this.fileDocument.idArquivoDocumentoGerado)
      .then(() => {
        this.read();
        this.displayCancelFile = false;

        this.message.add({
          severity: 'success',
          detail: 'A comunicação do ficheiro foi cancelada',
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => {
        this.loadingCancelFile = false;
      });
  }
}

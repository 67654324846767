<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RECOLHER DOCUMENTO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/emitter/file-document/create">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-upload red-text"></i></div>
        <h4 class="title"> Carregamento de Ficheiro </h4>
        <p class="description">
          Permite carregar um ficheiro contendo informações das facturas a serem recolhidas.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/emitter/document/create">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-invoice blue-text"></i></div>
        <h4 class="title"> Recolher Por Unidade </h4>
        <p class="description">
          Permite comunicar os documentos mediante o preenchimento de um formulário.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/emitter/authorization/read-control">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-invoice orange-text"></i></div>
        <h4 class="title"> Declaração de não Facturação </h4>
        <p class="description">
          Permite declarar que não faturou durante um determinado mês.
        </p>
      </div>
    </a>
  </div>

</div>

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CONTRIBUINTES VINCULADOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header text-right">
        <div class="p-d-flex p-ai-top p-jc-between">
          <button type="button" mdbBtn size="md" color="light" mdbWavesEffect (click)="visibleSidebarFilter=true">
            <mdb-icon fas icon="filter"></mdb-icon>
            Filtrar
          </button>
        </div>
      </mdb-card-header>
      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="taxpayers"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} contribuintes vinculados.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '30%'}">NIF - Nome</th>
              <th [style]="{width: '25%'}">Gama de NIF</th>
              <th [style]="{width: '25%'}">Endereço</th>
              <th>Dt Registo</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Nome</span>
                {{ rowData.nifContribuinte }} -
                {{ rowData.tbPessoa.nomePessoa }}
              </td>

              <td>
                <span class="p-column-title">Gama de NIF</span>
                {{ rowData.tbGamaNif.descGamaNif }}
              </td>

              <td>
                <span class="p-column-title">Endereço</span>
                {{ rowData.tbPessoa.enderecoPessoa }}
              </td>

              <td>
                <span class="p-column-title">Dt Registo</span>
                {{ rowData.dtCriaContribuinte | date:'dd/MM/yyyy' }}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                  [routerLink]="['/taxpayer/read', rowData.idContribuinte]">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<!-- FILTERS -->
<p-sidebar [(visible)]="visibleSidebarFilter" position="right" [baseZIndex]="10000" [style]="{width: '450px'}">
  <h2 style="font-weight:normal">Filtros</h2>

  <form #formFilter="ngForm" autocomplete="off" (ngSubmit)="read()">
    <div class="p-fluid">

      <div class="p-grid p-col-12 p-pb-2">
        <div class="p-col-6">
          <button type="button" mdbBtn color="red" size="md" block="true" (click)="resetFormFilter(formFilter)">
            Limpar
          </button>
        </div>

        <div class="p-col-6">
          <button type="submit" mdbBtn color="info" size="md" block="true">
            Filtrar
          </button>
        </div>
        <hr>
      </div>

      <p-scrollPanel [style]="{width: '100%', height: '80vh'}">
        <div class="p-field">
          <span class="p-float-label">
            <input pInputText class="p-inputtext-sm" name="personName" [(ngModel)]="filter.personName">
            <label for="float-input">Nome</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input class="p-inputtext-sm" pInputText name="nifConsumidor" [(ngModel)]="filter.nif">
            <label for="float-input">NIF</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown inputId="rangeNif" class="p-inputtext-sm" [autoDisplayFirst]="false" [options]="ranges"
              [showClear]="true" name="gammaNif" [(ngModel)]="filter.gammaNif" optionLabel="name"></p-dropdown>
            <label for="rangeNif">Gama NIF</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown inputId="category" class="p-inputtext-sm" [autoDisplayFirst]="false" [showClear]="true"
              [options]="categories" name="category" [(ngModel)]="filter.category" optionLabel="name">
            </p-dropdown>
            <label for="category">Categoria</label>
          </span>
        </div>

        <div class="p-grid p-field">
          <div class="p-field p-col-6">
            <span class="p-float-label">
              <p-calendar class="p-inputtext-sm" selectionMode="range" [readonlyInput]="true" inputId="range"
                [locale]="locale.pt" dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
              </p-calendar>
              <label for="float-input">Data registo</label>
            </span>
          </div>

          <div class="p-field p-col-6">
            <span class="p-float-label">
              <p-calendar class="p-inputtext-sm" name="createdAt" [(ngModel)]="filter.createdAt" dateFormat="dd/mm/yy"
                [locale]="locale.pt" [panelStyle]="{right: '10px'}">
              </p-calendar>
              <label for="float-input">Data Final</label>
            </span>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </form>
</p-sidebar>

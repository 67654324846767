import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-account-blocking-info',
  templateUrl: './account-blocking-info.component.html',
  styleUrls: ['./account-blocking-info.component.scss']
})
export class AccountBlockingInfoComponent implements OnInit {
  @Input() display: boolean = false;
  @Output() onCloseDialog: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.onCloseDialog.emit();
  }
}

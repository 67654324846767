import { Component, Input, OnInit } from '@angular/core';
import { Authorization } from 'src/app/core/models/authorization';

@Component({
  selector: 'app-grid-serie-transported',
  templateUrl: './grid-serie-transported.component.html',
  styleUrls: ['./grid-serie-transported.component.scss']
})
export class GridSerieTransportedComponent implements OnInit {
  @Input() transportedSeries: Authorization[];

  constructor() { }

  ngOnInit(): void {
  }

}

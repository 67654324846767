import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Notification } from './../../core/models/notification';
import { NotificationFilter, NotificationService } from './../notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  locale = new Locale();
  total = 0;
  filter = new NotificationFilter();
  notification = new Notification();
  notifications: Notification[];
  notificationId: number;

  isRegularized: boolean = false;

  constructor(
    private service: NotificationService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.notificationId = this.route.snapshot.params.id;
    this.get(this.notificationId);
    this.read();
  }

  read(page = 0) {
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.notifications = response.body;
      })
      .catch(error => this.errorHandler.handle(error));
  }

  get(id) {
    this.service.get(id)
      .then(response => {
        this.notification = response;

        if (moment(this.notification.dtCriaNotificacao).isSame(this.notification.dtPrazoPegularizacao)) {
          this.isRegularized = true;
        } else {
          this.isRegularized = false;
        }
      });
  }

  getNotification(notification: Notification) {
    this.router.navigate(['/notifications/read', notification.idNotificacao]);
    this.notificationId = notification.idNotificacao;
    this.get(notification.idNotificacao);
  }

}

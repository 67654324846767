<div class="p-grid">
  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/documento">
      <div class="box wow fadeInLeft">
        <div class="icon"><i class="fa fa-file-alt teal-text"></i></div>
        <h4 class="title">Documentos </h4>
        <p class="description">
          Pedidos de séries e comunicação de documentos para operadores económicos e consumidores.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/certification/index">
      <div class="box wow fadeInLeft">
        <div class="icon"><i class="fa fa-clipboard-check light-blue-text"></i></div>
        <h4 class="title"> Certificação</h4>
        <p class="description">
          Pedido e consulta de certificação para tipografias e operadores económicos com softwares de facturação.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" [class.disabled]="!auth.hasPermission('ROLE_MENU_TIPOGRAFIA')"
      [routerLink]="auth.hasPermission('ROLE_MENU_TIPOGRAFIA') ? '/tipografia' : '#'">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-text-height pink-text"></i></div>
        <h4 class="title">Tipografia</h4>
        <p class="description">
          Solicitação e gestão das séries de documentos em blocos de papel requisitadas pelos clientes.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/util">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-tools purple-text"></i></div>
        <h4 class="title">Funcionalidades Úteis</h4>
        <p class="description">
          Emissão de facturas no portal, contribuintes autorizados e outras funcionalidades.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/agente" *ngIf="auth.hasPermission('ROLE_MENU_AGENTE')">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-building red-text"></i></div>
        <h4 class="title"> Agente Fiscal </h4>
        <p class="description">
          Área de gestão das comunicações de documentos emitidos e solicitações enviadas.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/admin" *ngIf="auth.hasPermission('ROLE_MENU_ADMIN')">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog stylish-color-dark-text"></i></div>
        <h4 class="title"> Administrador </h4>
        <p class="description">
          Área de gestão de utilizadores, perfiz, segurança e outras parametrizações do sistema.
        </p>
      </div>
    </a>
  </div>
</div>

<!-- <p-dialog header="NOTA DE BLOQUEIO DE CONTAS DE UTILIZADORES NO PORTAL E-FACTURA" [(visible)]="display"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true">

  <div class="alert alert-warning" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    ATENÇÃO
  </div>

  <div class="block-note alert alert-light" role="alert">
    <p>
      Havendo necessidade de validação das contas no sistema e-Factura de São Tomé e Príncipe imposta pela Agência
      Nacional de Protecção de Dados Pessoais, vem a Direcção dos Impostos informar a todos os contribuintes registados
      no sistema e-factura que deverão dirigir-se à Direcção dos Impostos, no prazo de 30 dias a contar da presente
      data, a fim de confirmar o seu registo. Findo o prazo acima referido sem que se mostre confirmado o registo, as
      respectivas contas serão automaticamente bloqueadas.
    </p>

    <br>

    <p>
      O procedimento para a confirmação do registo será o seguinte:
    </p>

    <ul>
      <li>
        <p class="subtitle">
          <strong>
            Pessoas individuais com NIF da gama 109, 114 ou 135
          </strong>
        </p>

        <p>
          Deverão dirigir-se ao guiché do atendimento da Direcção dos Impostos, acompanhados do seu NIF e do seu
          documento de identificação (BI ou Passaporte);
        </p>
      </li>

      <li>
        <p class="subtitle">
          <strong>
            Todas as outras gamas do NIF:
          </strong>
        </p>

        <p>
          A pessoa indigitada pela entidade deverá dirigir-se ao guiché do atendimento da Direcção dos Impostos
          fazendo-se acompanhar de uma declaração emitida por aquela entidade, devidamente assinada e carimbada,
          outorgando autorização à pessoa, devidamente identificada no documento, dando os poderes para junto à Direcção
          solicitar a confirmação da conta.
        </p>

        <p>
          A pessoa deverá fazer-se acompanhar do NIF da empresa em causa, do seu NIF pessoal e de um documento de
          identificação (BI ou Passaporte).
        </p>
      </li>
    </ul>

    <p>
      <strong>Nota:</strong>
      Todas as contas novas no sistema passarão pelo mesmo procedimento. O termo “gama” corresponde aos três
      primeiros algarismos que compõem o NIF.
    </p>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="info" mdbWavesEffect (click)="display=false">
      TOMEI CONHECIMENTO
    </button>
  </ng-template>
</p-dialog> -->

<!-- <p-dialog header="SELECIONAR SÉRIES A SEREM TRANSPORTADAS" [(visible)]="displaySerieToTransport"
  [style]="{width: '60vw'}" [modal]="true">

  <div class="alert alert-warning" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Lista das séries do ano 2021 que ainda não foram transportadas para 2022. Escolha as que continuará a utilizar e
    clique em "TRANSPORTAR" para gerar novas séries para o ano 2022.
  </div>

  <div>
    <app-grid-serie-to-transport [emissionsDocuments]="emissionsDocuments"
      (getSelectedEmissionsDocuments)="getSelectedEmissionsDocuments($event)"></app-grid-serie-to-transport>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displaySerieToTransport=false">
      FECHAR
    </button>

    <button type="button" mdbBtn color="info" mdbWavesEffect (click)="confirm()"
      [disabled]="!selectedEmissionsDocuments.length">
      TRANSPORTAR
    </button>

    <div class="p-col-12">
      <p-progressBar *ngIf="loadingSerieToTransport" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
    </div>
  </ng-template>
</p-dialog> -->

<!--
<p-dialog header="SÉRIES TRANSPORTADAS" [(visible)]="displaySerieTransported" [style]="{width: '60vw'}" [modal]="true">

  <div class="alert alert-success" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Séries transportadas com sucesso. Veja as novas séries geradas na lista abaixo.
    Para mais informações acessa a sua <a routerLink="/emitter/emission-document/read"> área de gestão de séries</a>
  </div>

  <div>
    <app-grid-serie-transported [transportedSeries]="transportedSeries"></app-grid-serie-transported>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displaySerieTransported=false">
      FECHAR
    </button>

    <button type="button" mdbBtn color="info" mdbWavesEffect routerLink="/emitter/emission-document/read">
      GERIR MINHAS SÉRIES
    </button>
  </ng-template>
</p-dialog> -->

<p-dialog header="TRANSPORTAR SÉRIE" [(visible)]="displaySerieToTransport" [style]="{width: '60vw'}" [modal]="true">
  <div class="alert alert-light" role="alert">
    <p>
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Caro contribuinte. Já pode transportar as suas séries para o ano 2022. Para as séries de softwares autónomos
      utilize uma das funcionalidades abaixo
    </p>

    <br>

    <p>
      <mdb-icon far icon="circle"></mdb-icon>
      Para séries utilizadas na emissão de facturas diretamente no portal o processo é automática e não requer
      qualquer intervenção do utilizador
    </p>

    <p>
      <mdb-icon far icon="circle"></mdb-icon>
      Para séries adquiridas nas gráficas o processo é feito nas gráficas.
    </p>

    <p>
      <mdb-icon far icon="circle"></mdb-icon>
      Para séries utilizadas em softwares de faturação autónomos siga um dos procedimentos abaixo.
    </p>
  </div>

  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-6">
      <a class="a-box" routerLink="/emitter/emission-document/transport">
        <div class="box wow fadeInLeft">
          <div class="icon"><i class="fa fa-file-alt teal-text"></i></div>
          <h4 class="title">Transportar séries de forma manual </h4>
          <p class="description">
            Permite transportar qualquer série emitida nos anos anteriores ao 2022.
          </p>
        </div>
      </a>
    </div>

    <div class="p-col-12 p-md-6 p-lg-6">
      <a class="a-box" routerLink="/emitter/emission-document/transport/list">
        <div class="box wow fadeInLeft">
          <div class="icon"><i class="fa fa-clipboard-check light-blue-text"></i></div>
          <h4 class="title"> Transportar séries de forma automática</h4>
          <p class="description">
            Permite selecionar e transportar várias séries todas ou um grupo de séries do ano 2021 para 2022.
          </p>
        </div>
      </a>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displaySerieToTransport=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { RoutinesService } from './../routines.service';

@Component({
  selector: 'app-revalidate-file-second-phase',
  templateUrl: './revalidate-file-second-phase.component.html',
  styleUrls: ['./revalidate-file-second-phase.component.scss']
})
export class RevalidateFileSecondPhaseComponent implements OnInit {
  loading: boolean = false;
  display: boolean = false;

  routine: string = 'Revalidar 2ª fase de análise de ficheiro'

  constructor(
    private routinesService: RoutinesService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
  }

  run() {
    this.loading = true;
    this.routinesService.reValidateFileSecondPhase()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  confirm() {
    this.confirmationService.confirm({
      message: `${this.routine}?`,
      accept: () => {
        this.run();
      }
    });
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIROS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-info" role="alert">
      <p>
        <mdb-icon fas icon="info-circle"></mdb-icon>

        Com o objectivo de facilitar a inclusão de todos os operadores económicos no processo e-factura, inclusive
        aqueles que ainda não dispõem, ou ainda não reuniram condições ao nível informático para a emissão e comunicação
        electrónica das facturas, criou-se a área destinada a terceiros onde é possível um terceiro (por exemplo: um
        contabilista inscrito na OCTOCA e autorizado pelo operador económico), efectuar operações como: emitir e
        comunicação facturas, solicitar novas séries, dentre outros, em nome do operador económico.
      </p>
    </div>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/third-party/index">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-medical cyan-text"></i></div>
        <h4 class="title"> Prestador de Serviços </h4>
        <p class="description">
          Seja um prestador de serviços do e-factura e ajude empresas e comerciantes a se manterem regularizados.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/consumer/third-party/index">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog orange-text"></i></div>
        <h4 class="title"> Operador Económico </h4>
        <p class="description">
          Encontre profissionais que podem lhe auxiliar no cumprimento dos seus compromissos do e-factura e manter-se
          regularizado.
        </p>
      </div>
    </a>
  </div>
</div>

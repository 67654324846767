<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PERFIL
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col">
    <mdb-card>
      <mdb-card-body>
        <p-tabView>
          <p-tabPanel header="INFORMAÇÕES GERAIS">
            <form #updateForm="ngForm"
                  [formGroup]="form"
                  (ngSubmit)="update()">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-9">
                  <label>Nome</label>
                  <input type="text"
                         pInputText
                         name="nomePessoa"
                         formControlName="name">
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>NIF</label>
                  <input type="text"
                         pInputText
                         name="nif"
                         formControlName="nif"
                  >
                </div>

                <div class="p-field p-col-12 p-md-9">
                  <label>E-mail</label>
                  <input type="text"
                         pInputText
                         name="mail"
                         formControlName="mail"
                  >
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Telefone</label>
                  <input type="text"
                         pInputText
                         name="phone"
                         formControlName="phone"
                  >
                </div>

                <div class="p-field p-col-12 p-md-9">
                  <label>Endereço</label>
                  <input type="text"
                         pInputText
                         name="address"
                         formControlName="address">
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Data de Registo</label>
                  <p-calendar
                    name="dataCriaUsuario"
                    formControlName="createdAt"
                    dateFormat="dd/mm/yy"
                  >
                  </p-calendar>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label>Perfil</label>
                  <input type="text"
                         pInputText
                         name="profile"
                         formControlName="profile">
                </div>

                <div class="p-field p-col-12 text-right">
                  <hr>
                  <button type="button" mdbBtn color="light" mdbWavesEffect (click)="location.back()">
                    VOLTAR
                  </button>

                  <!--<button mdbBtn type="submit" color="success" mdbWavesEffect-->
                  <!--        [disabled]="!updateForm.valid">SALVAR-->
                  <!--</button>-->
                </div>

                <div class="p-field p-col-12">
                  <p-progressBar *ngIf="loadingProfile" mode="indeterminate" [style]="{'height': '3px'}">
                  </p-progressBar>
                </div>
              </div>
            </form>
          </p-tabPanel>

          <!--<p-tabPanel header="ALTERAR SENHA">-->
          <!--  <form autocomplete="off" #f="ngForm" (ngSubmit)="validOldPassword()">-->
          <!--    <div class="p-fluid p-formgrid p-grid">-->

          <!--      <div class="p-field p-col-12">-->
          <!--        <label>Senha actual</label>-->
          <!--        <div class="form-line">-->
          <!--          <div class="p-inputgroup">-->
          <!--            <input pPassword type="password" name="currentPassword" [(ngModel)]="currentPassword"-->
          <!--                   #curPass="ngModel" [feedback]="false" [showPassword]="showPassword" required/>-->
          <!--            <span class="p-inputgroup-addon" (click)="changeShowPassword()">-->
          <!--              <mdb-icon fas icon="eye" *ngIf="!showPassword"></mdb-icon>-->
          <!--              <mdb-icon fas icon="eye-slash" *ngIf="showPassword"></mdb-icon>-->
          <!--            </span>-->
          <!--          </div>-->

          <!--          <app-message [control]="curPass" error="required" text="Por favor informe a sua senha actual">-->
          <!--          </app-message>-->
          <!--        </div>-->
          <!--      </div>-->

          <!--      <div class="p-field p-col-12">-->
          <!--        <label>Nova senha</label>-->
          <!--        <div class="form-line">-->
          <!--          <div class="p-inputgroup">-->
          <!--            <input pPassword type="password" name="newPassword" [(ngModel)]="newPassword" #newPass="ngModel"-->
          <!--                   promptLabel="Por favor insira uma nova senha" weakLabel="Fraca" mediumLabel="Média"-->
          <!--                   strongLabel="Forte" required minlength="8" [showPassword]="showNewPassword"/>-->
          <!--            <span class="p-inputgroup-addon" (click)="changeShowNewPassword()">-->
          <!--              <mdb-icon fas icon="eye" *ngIf="!showNewPassword"></mdb-icon>-->
          <!--              <mdb-icon fas icon="eye-slash" *ngIf="showNewPassword"></mdb-icon>-->
          <!--            </span>-->
          <!--          </div>-->

          <!--          <app-message [control]="newPass" error="required" text="Por favor informe a nova senha">-->
          <!--          </app-message>-->
          <!--          <app-message [control]="newPass" error="minlength"-->
          <!--                       text=" Mínimo de {{newPass.errors?.minlength?.requiredLength}} caracteres">-->
          <!--          </app-message>-->
          <!--        </div>-->
          <!--      </div>-->

          <!--      <div class="p-field p-col-12">-->
          <!--        <label>Confirmar nova senha</label>-->
          <!--        <div class="form-line">-->
          <!--          <div class="p-inputgroup">-->
          <!--            <input pPassword type="password" name="senhaUsuarioConfirmacao" required-->
          <!--                   [(ngModel)]="user.senhaUsuarioConfirmacao" #senhaUsuarioConfirmacao="ngModel"-->
          <!--                   [showPassword]="showConfirmNewPassword" [feedback]="false">-->
          <!--            <span class="p-inputgroup-addon" (click)="changeShowConfirmNewPassword()">-->
          <!--              <mdb-icon fas icon="eye" *ngIf="!showConfirmNewPassword"></mdb-icon>-->
          <!--              <mdb-icon fas icon="eye-slash" *ngIf="showConfirmNewPassword"></mdb-icon>-->
          <!--            </span>-->
          <!--          </div>-->

          <!--          <div class="ui-message ui-message-error"-->
          <!--               *ngIf="senhaUsuarioConfirmacao.dirty && senhaUsuarioConfirmacao.value !== newPass.value">-->
          <!--            Senha e Confirmar senha precisam ser iguais-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </div>-->

          <!--      <div class="p-col-12 text-right">-->
          <!--        <div class="button-demo">-->
          <!--          <button class="btn btn-success waves-effect" type="submit"-->
          <!--                  [disabled]="!f.valid || senhaUsuarioConfirmacao.value !== newPass.value || loading">ALTERAR-->
          <!--          </button>-->
          <!--        </div>-->
          <!--      </div>-->

          <!--      <div class="p-col-12">-->
          <!--        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '6px'}">-->
          <!--        </p-progressBar>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </form>-->
          <!--</p-tabPanel>-->

          <!--<p-tabPanel header="ALTERAR E-MAIL">-->
          <!--  <app-change-email [userId]="user.idUsuario"></app-change-email>-->
          <!--</p-tabPanel>-->

        </p-tabView>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AppHttp } from './../auth/app-http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutinesService {
  EMISSION_DOCUMENT_URL: string;
  ROUTINES_URL: string;

  constructor(private http: AppHttp) {
    this.EMISSION_DOCUMENT_URL = `${environment.apiUrl}/pedidoEmissaoDocumento`;
    this.ROUTINES_URL = `${environment.apiUrl}/rotinas`;
  }

  fixDocumentByMask() {
    return this.http.put(`${this.EMISSION_DOCUMENT_URL}/corrigirNumeroFaturas`, null)
      .toPromise();
  }

  sendMailConfirmationFile() {
    return this.http.post(`${environment.apiUrl}/send/mailConfirmacaoFicheiro`, null)
      .toPromise();
  }

  sendMailCommunicationWithProblem() {
    return this.http.post(`${environment.apiUrl}/send/mailComunicacaoDocumentosProblema`, null)
      .toPromise();
  }

  sendMailFixMask() {
    return this.http.post(`${environment.apiUrl}/send/mailCorrecaoMascara`, null)
      .toPromise();
  }

  validFirstPhase() {
    return this.http.get(`${this.ROUTINES_URL}/validarFicheiroPrimeiraFase`)
      .toPromise();
  }

  validSecondPhase() {
    return this.http.get(`${this.ROUTINES_URL}/validarFicheiroSegundaFase`)
      .toPromise();
  }

  validThirdPhase() {
    return this.http.get(`${this.ROUTINES_URL}/validarFicheiroTerceiraFase`)
      .toPromise();
  }

  sendNotifications() {
    return this.http.post(`${environment.apiUrl}/send/mailNotificacao`, null)
      .toPromise();
  }

  communicateDocumentOnline() {
    return this.http.get(`${this.ROUTINES_URL}/geradoOnlineParaGerado`)
      .toPromise();
  }

  getDuplicateDocumentAndSetItAsEditable() {
    return this.http.get(`${this.ROUTINES_URL}/pegarDocumentoGeradoDuplicadoESetarComoEditavel`)
      .toPromise();
  }

  transportSerieOnline() {
    return this.http.get(`${this.ROUTINES_URL}/passarSeriesParaProximoAno`)
      .toPromise();
  }

  updateDocumentValueToBeCanceled() {
    return this.http.get(`${this.ROUTINES_URL}/atualizarValorDisponivelAnularDocumentoGerado`)
      .toPromise();
  }

  loadFileThirdFaseTempByPeriod(period: Date) {
    return this.http.get(`${this.ROUTINES_URL}/carregarFicheirosTercFaseTodosOsTiposPorListIdPassadoParamentro`)
      .toPromise();
  }

  fixPeriodPassingFirstDateOnFile() {
    return this.http.get(`${this.ROUTINES_URL}/corrigirPeriodoFaturacaoApartirPrimeiraDataFicheiro`)
      .toPromise();
  }

  fixPeriodPassingStandardDay() {
    return this.http.get(`${this.ROUTINES_URL}/corrigirPeriodoFaturacaoPassandoDiaPadrao`)
      .toPromise();
  }

  reValidateFileFirstPhase() {
    return this.http.get(`${this.ROUTINES_URL}/reValidarFicheiroPrimeiraFase`)
      .toPromise();
  }

  reValidateFileSecondPhase() {
    return this.http.get(`${this.ROUTINES_URL}/reValidarFicheiroSegundaFase`)
      .toPromise();
  }

  updateTotalEmissionDocument() {
    return this.http.get(`${this.ROUTINES_URL}/atualizarQtJaComunicadoPedidoEmissaoDocumento`)
      .toPromise();
  }

  insertEmissionDocumentHistories() {
    return this.http.get(`${this.ROUTINES_URL}/inserirHistoricoDePedidoEmissaoDocumento`)
      .toPromise();
  }
}

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadReportByFileComponent } from './read-report-by-file/read-report-by-file.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    ReadReportByFileComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,

    ProgressSpinnerModule
  ],
  exports: [
    ReadReportByFileComponent
  ]
})
export class FileDocumentAnalysisReportModule { }

import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { TaxpayerService } from '../taxpayer.service';
import { jsPDF } from "jspdf";
import * as moment from "moment/moment";

@Component({
  selector: 'app-get-situation',
  templateUrl: './get-situation.component.html',
  styleUrls: ['./get-situation.component.scss']
})
export class GetSituationComponent implements OnInit {
  @Input() taxpayerId: number;

  loading = true;
  display = false;
  monthsOverdue = [];

  data = {
    series: [{
      mesesAtraso: [],
      monthsOverdue: [],
      seriesSolicitada: null,
      seriesSolicitadaData: null,
      ultimoMesComunicado: null,
    }],
    nif: null,
    nomeContribuinte: null,
    registoPortal: null,
    registoPortalData: null,
  };

  constructor(
    private taxpayerService: TaxpayerService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.taxpayerService.getSituation(this.taxpayerId)
      .then(response => {
        const series = response.grandesContribuintesDtoInfos.map(value => {
          value.seriesSolicitadaData = moment(value.seriesSolicitadaData, 'YYYY-MM-DD').toDate();
          return value;
        }).map((grand) => ({
          ...grand,
          monthsOverdue: grand.mesesAtraso.map(month => ({
            number: month,
            label: new Date(2021, (month - 1), 10).toLocaleString('default', { month: 'long' })
          }))
        }));

        this.data = response;
        this.data.series = series;
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  showDialog(data: any) {
    this.monthsOverdue = data.monthsOverdue;
    this.display = true;
  }

  exportReport() {
    const report = new jsPDF();

    report.setFontSize(16);
    report.text('SITUAÇÃO DE CONTRIBUINTES NO E-FACTURA', 10, 10);

    report.setFontSize(14);
    report.text('Grandes Contribuintes', 10, 20);

    // report.text("This is centred text.", 105, 80, null, null, "center");
    // report.text("And a little bit more underneath it.", 105, 90, null, null, "center");

    report.setFontSize(10);
    report.text('CONTRIBUINTE: CUNHA SOARES STP, LIMITADA', 10, 40);
    report.text('NIF: 517558206', 10, 50);
    report.text('DATA DE REGISTO: 10/02/2020', 10, 60);
    report.text('SÉRIES ADQUIRIDAS NAS GRÁFICAS: INSERIR EM TABELA', 10, 70);
    report.text('RESUMO DE COMUNICAÇÃO', 10, 80);
    report.save('report.pdf');
  }
}

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { AuthorizationUtilFilter, AuthorizationUtilService } from '../authorization-util.service';
import { ErrorHandlerService } from './../../core/error-handler.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {
  loading = true;
  total = 0;
  emissionDocuments = [];
  filter = new AuthorizationUtilFilter();

  constructor(
    private service: AuthorizationUtilService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.check(this.filter)
      .then(response => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

}

<form autocomplete="off" (ngSubmit)="read()">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-2">
        <span class="p-float-label">
          <input pInputText class="p-inputtext-sm" name="emitterNif" [(ngModel)]="filter.emitterNif">
          <label for="float-input">NIF do Emitente</label>
        </span>
      </div>

      <div class="p-field p-col-4">
        <span class="p-float-label">
          <input pInputText class="p-inputtext-sm" name="fileName" [(ngModel)]="filter.fileName">
          <label for="float-input">Descrição do ficheiro</label>
        </span>
      </div>

      <div class="p-field p-col-2">
        <span class="p-float-label">
          <p-calendar name="startDate" [(ngModel)]="filter.startDate" view="month" dateFormat="mm/yy"
            [yearNavigator]="true" yearRange="2019:2021" [locale]="locale.pt">
          </p-calendar>
          <label for="float-input">Período de facturação De</label>
        </span>
      </div>

      <div class="p-field p-col-2">
        <span class="p-float-label">
          <p-calendar name="endDate" [(ngModel)]="filter.endDate" view="month" dateFormat="mm/yy" [yearNavigator]="true"
            yearRange="2019:2021" [locale]="locale.pt">
          </p-calendar>
          <label for="float-input">Período de facturação Até</label>
        </span>
      </div>

      <div class="p-field p-col">
        <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
          Filtrar
        </button>
      </div>
    </div>
  </div>
</form>

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
  [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
  (onLazyLoad)="onChangePage($event)">
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">
      Total de {{ total | number:'1.0-0'}} ficheiros.
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th [style]="{width: '3rem'}">ID</th>
      <th [style]="{width: '38%'}">Ficheiro</th>
      <th [style]="{width: '6%'}">Período</th>
      <th [style]="{width: '5%'}">Qtd FT</th>
      <th [style]="{width: '5%'}">Qtd NC</th>
      <th [style]="{width: '5%'}">Qtd ND</th>
      <th [style]="{width: '5%'}">Tt BT FT</th>
      <th [style]="{width: '5%'}">Tt BT NC</th>
      <th [style]="{width: '5%'}">Tt BT ND</th>
      <th [style]="{width: '5%'}">Tt ISC FT</th>
      <th [style]="{width: '5%'}">Tt ISC NC</th>
      <th [style]="{width: '5.5%'}">Tt ISC ND</th>
      <th [style]="{width: '5%'}">Tt Linhas</th>
      <th [style]="{width: '5%'}">Tt Val FT</th>
      <th [style]="{width: '5%'}">Tt Val NC</th>
      <th [style]="{width: '5.5%'}">Tt Val ND</th>
      <th [style]="{width:'3em'}"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <span class="p-column-title">ID</span>
        {{ rowData.id }}
      </td>

      <td>
        <span class="p-column-title">Ficheiro</span>
        {{ rowData.nomeFicheiro }}
      </td>

      <td>
        <span class="p-column-title">Período</span>
        {{ rowData.dtPeriodoFaturacao | date:'MMM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title">Qtd FT</span>
        <div *ngIf="rowData.quantidadeFatura; else elseQtdFT">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseQtdFT>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Qtd NC</span>
        <div *ngIf="rowData.quantidadeNotaCredito; else elseQtdNC">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseQtdNC>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Qtd ND</span>
        <div *ngIf="rowData.quantidadeNotaDebito; else elseQtdND">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseQtdND>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt BT FT</span>
        <div *ngIf="rowData.totalBaseTributavelFactura; else elseTtBtFT">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtBtFT>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt BT NC</span>
        <div *ngIf="rowData.totalBaseTributavelNotaCredito; else elseTtBtNC">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtBtNC>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt BT ND</span>
        <div *ngIf="rowData.totalBaseTributavelNotaDebito; else elseTtBtND">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtBtND>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt ISC FT</span>
        <div *ngIf="rowData.totalImpostoFactura; else elseTtIscFT">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtIscFT>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt ISC NC</span>
        <div *ngIf="rowData.totalImpostoNotaCredito; else elseTtIscNC">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtIscNC>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt ISC ND</span>
        <div *ngIf="rowData.totalImpostoNotaDebito; else elseTtIscND">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtIscND>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt Linhas</span>
        <div *ngIf="rowData.totalLinha; else elseTtLinhas">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtLinhas>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt Val FT</span>
        <div *ngIf="rowData.totalValorFactura; else elseTtValFT">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtValFT>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt Val NC</span>
        <div *ngIf="rowData.totalValorNotaCredito; else elseTtValNC">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtValNC>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Tt Val ND</span>
        <div *ngIf="rowData.totalValorNotaDebito; else elseTtValND">
          <mdb-icon fas icon="check" class="mr-2 teal-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>
        <ng-template #elseTtValND>
          <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        </ng-template>
      </td>

      <td [style]="{padding: '1px 4px'}">
        <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações" tooltipPosition="left"
          class="btn-block" [style]="{padding: '4px 0'}"
          [routerLink]="['/admin/file-document/read', rowData.idFicheiro]">
          <i class="fa fa-search"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="row clearfix">

  <div class="title text-center">
    <h2 class="h2-responsive">Nova Senha</h2>
  </div>

  <mdb-card class="card-login">
    <mdb-card-body>
      <form autocomplete="off" #form="ngForm" (ngSubmit)="save(form)">
        <div class="p-fluid p-formgrid p-grid">

          <!--
            <div class="ui-g-12 ui-fluid">
                <label>NIF</label>
                <div class="form-line">
                    <input pInputText class="form-control" name="nifUsuario" placeholder="Seu NIF"
                        [(ngModel)]="user.nifUsuario" required>
                </div>
            </div>
          -->

          <div class="p-field p-col-12">
            <label>Código de Recuperação</label>
            <input pInputText class="form-control" name="senhaUsuarioRecuperacao" autofocus
              [(ngModel)]="user.senhaUsuarioRecuperacao" required #senhaUsuarioRecuperacao="ngModel"
              placeholder="Código recebido por e-mail">
          </div>

          <div class="p-field p-col-12">
            <label>Nova Senha</label>
            <div class="form-line">
              <input pPassword type="password" class="form-control" name="senhaUsuario" [(ngModel)]="user.senhaUsuario"
                #senhaUsuario="ngModel" strongLabel="Forte" required minlength="8" mediumLabel="Média"
                promptLabel="Por favor insira uma nova senha" weakLabel="Fraca" />

              <app-message [control]="senhaUsuario" error="required" text="Por favor informe uma senha">
              </app-message>

              <app-message [control]="senhaUsuario" error="minlength"
                text=" Mínimo de {{senhaUsuario.errors?.minlength?.requiredLength}} caracteres">
              </app-message>
            </div>
          </div>

          <div class="p-field p-col-12">
            <label>Confirmar Nova Senha</label>
            <div class="form-line">
              <input pInputText type="password" class="form-control" name="senhaUsuarioConfirmacao" required
                [(ngModel)]="user.senhaUsuarioConfirmacao" #senhaUsuarioConfirmacao="ngModel"
                placeholder="Confirme a nova senha">

              <div class="ui-message ui-message-error"
                *ngIf="senhaUsuarioConfirmacao.dirty && senhaUsuarioConfirmacao.value !== senhaUsuario.value">
                Senha e Confirmar senha precisam ser iguais
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <button type="submit" mdbBtn color="pink" block="true" class="my-4" size="sm"
              [disabled]="!form.valid || loading">
              CONFIRMAR
            </button>
          </div>

          <div class="p-col-12 text-center">
            <a routerLink="/login">Cancelar tudo e voltar à tela de autenticação.</a>
          </div>

          <div class="p-col-12 ui-fluid">
            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
          </div>
        </div>

      </form>
    </mdb-card-body>
  </mdb-card>

</div>

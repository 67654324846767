import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationReadTotalSerieForEmitterComponent } from './authorization-read-total-serie-for-emitter/authorization-read-total-serie-for-emitter.component';

const routes: Routes = [
  {
    path: 'total-serie-by-emitter',
    component: AuthorizationReadTotalSerieForEmitterComponent,
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthorizationRouter { }

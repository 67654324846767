import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import {
  CertificationEmitterFilter,
  CertificationEmitterService
} from './../../certification-emitter/certification-emitter.service';
import { ErrorHandlerService } from './../../core/error-handler.service';

@Component({
  selector: 'app-get-certification',
  templateUrl: './get-certification.component.html',
  styleUrls: ['./get-certification.component.scss']
})
export class GetCertificationComponent implements OnInit {
  loading = true;
  certifications = [];
  totalRecords = 0;
  filtro = new CertificationEmitterFilter();

  constructor(
    private certificationService: CertificationEmitterService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void { }

  read(page = 0) {
    this.loading = true;
    this.filtro.page = page;
    this.certificationService.read(this.filtro)
      .then(result => {
        this.totalRecords = result.total;
        this.certifications = result.content;
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }
}

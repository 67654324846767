<header id="header">
  <div class="container">
    <div id="logo" class="pull-left">
      <!-- <img src="/assets/img/luto.jpeg" alt="Luto"> -->
      <h1>
        <a routerLink="/index" class="scrollto">e<span>Factura</span></a>
      </h1>
    </div>

    <nav id="nav-menu-container">
      <ul class="nav-menu">
        <li routerLinkActive="menu-active">
          <a routerLink="/index">Início</a>
        </li>
        <li routerLinkActive="menu-active" *ngIf="isLoggedIn">
          <a routerLink="/adquirente">Adquirente</a>
        </li>
        <li routerLinkActive="menu-active" *ngIf="isLoggedIn">
          <a routerLink="/emitente">Emitente</a>
        </li>
        <li routerLinkActive="menu-active" *ngIf="isLoggedIn">
          <a routerLink="/emitter/certification/index">Certificação</a>
        </li>
        <li routerLinkActive="menu-active" *ngIf="auth.hasPermission('ROLE_MENU_AGENTE')">
          <a routerLink="/agente">Agente Fiscal</a>
        </li>
        <li routerLinkActive="menu-active" *ngIf="auth.hasPermission('ROLE_MENU_ADMIN')">
          <a routerLink="/admin"> Administrador </a>
        </li>
        <li routerLinkActive="menu-active" *ngIf="auth.hasPermission('ROLE_MENU_AGENTE') || auth.hasPermission('ROLE_ACCESS_STATE_APP')">
          <a [href]="efaturaStateLink" target="_blank"> Estado </a>
        </li>
        <li routerLinkActive="menu-active">
          <a routerLink="/contacto">Contacto</a>
        </li>

        <!-- <li class="dropdown"><a href="#"><span>Úteis</span> <i class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a href="#">Drop Down 1</a></li>
            <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
              <ul>
                <li><a href="#">Deep Drop Down 1</a></li>
                <li><a href="#">Deep Drop Down 2</a></li>
                <li><a href="#">Deep Drop Down 3</a></li>
                <li><a href="#">Deep Drop Down 4</a></li>
                <li><a href="#">Deep Drop Down 5</a></li>
              </ul>
            </li>
            <li><a href="#">Drop Down 2</a></li>
            <li><a href="#">Drop Down 3</a></li>
            <li><a href="#">Drop Down 4</a></li>
          </ul>
        </li>
        <li routerLinkActive="menu-active"><a routerLink="/tutorial">Procedimentos Importantes</a></li> -->
        <!-- <li routerLinkActive="menu-active"><a routerLink="/emitter/occurrence">Ocorrências</a></li> -->
      </ul>
    </nav>
  </div>
</header>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMIN
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR FICHEIROS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="ui-g-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body">
        <p-tabView>
          <p-tabPanel header="FICHEIROS">
            <form autocomplete="off" (ngSubmit)="read()">
              <div class="p-col-12">
                <div class="p-fluid p-formgrid p-grid">

                  <div class="p-field p-col-12 p-md-1">
                    <span class="p-float-label">
                      <input pInputText class="p-inputtext-sm" name="emitterNif" [(ngModel)]="filter.emitterNif">
                      <label for="float-input">NIF Emitente</label>
                    </span>
                  </div>

                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                      <input pInputText class="p-inputtext-sm" name="fileName" [(ngModel)]="filter.fileName">
                      <label for="float-input">Descrição do ficheiro</label>
                    </span>
                  </div>

                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                      <p-dropdown inputId="statusAnalise" class="p-inputtext-sm" [autoDisplayFirst]="false"
                        [showClear]="true" [options]="statusAnalises" name="statusAnalise"
                        [(ngModel)]="filter.statusAnalise" optionLabel="name"></p-dropdown>
                      <label for="statusAnalise">Fase análise</label>
                    </span>
                  </div>

                  <div class="p-field p-col-12 p-md-1">
                    <span class="p-float-label">
                      <p-dropdown inputId="statusFile" class="p-inputtext-sm" [autoDisplayFirst]="false"
                        [showClear]="true" [options]="statusFiles" name="statusFile" [(ngModel)]="filter.statusFile"
                        optionLabel="name">
                      </p-dropdown>
                      <label for="statusFile">Status</label>
                    </span>
                  </div>

                  <div class="p-field p-col-2">
                    <span class="p-float-label">
                      <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                        view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2019:2022" name="period"
                        [(ngModel)]="filter.period">
                      </p-calendar>
                      <label for="float-input">Período facturação</label>
                    </span>
                  </div>

                  <div class="p-field p-col-2">
                    <span class="p-float-label">
                      <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                        dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
                      </p-calendar>
                      <label for="float-input">Data registo</label>
                    </span>
                  </div>

                  <div class="p-field p-col">
                    <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                      Filtrar
                    </button>
                  </div>

                </div>
              </div>
            </form>

            <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
              [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
              [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
              <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                  Total de {{ total | number:'1.0-0'}} ficheiros recolhidos.
                </div>
              </ng-template>

              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width:'4em'}">ID</th>
                  <th [style]="{width: '25%'}">Requerente</th>
                  <th [style]="{width: '25%'}">Ficheiro</th>
                  <th [style]="{width: '6%'}">Dt Registo</th>
                  <th [style]="{width: '6%'}">Período</th>
                  <th [style]="{width: '5%'}">1ª análise</th>
                  <th [style]="{width: '5%'}">2ª análise</th>
                  <th [style]="{width: '5%'}">3ª análise</th>
                  <th [style]="{width: '7%'}">Análise</th>
                  <th [style]="{width: '3%'}">Status</th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td>
                    <span class="p-column-title">ID</span>
                    {{ rowData.idArquivoDocumentoGerado }}
                  </td>

                  <td>
                    <span class="p-column-title">Requerente</span>
                    {{ rowData.tbContribuinte.tbPessoa.nifPessoa+' - '+rowData.tbContribuinte.tbPessoa.nomePessoa }}
                  </td>

                  <td>
                    <span class="p-column-title">Ficheiro</span>
                    {{ rowData.nomeArquivoDocumentoGerado }}
                  </td>

                  <td>
                    <span class="p-column-title">Dt Registo</span>
                    {{ rowData.dtCria | date:'dd/MM/yyyy' }}
                  </td>

                  <td>
                    <span class="p-column-title">Período</span>
                    {{ rowData.dtPeriodoFaturacao | date:'dd/MM/yyyy' }}
                  </td>

                  <td>
                    <span class="p-column-title">1ª análise</span>
                    <div *ngIf="rowData.step1 === null; else elseNull">
                      <mdb-icon fas icon="sync" class="mr-2 gray-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull>
                      <div *ngIf="rowData.step1; else elseBlock">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>

                      <ng-template #elseBlock>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>

                  <td>
                    <span class="p-column-title">2ª análise</span>
                    <div *ngIf="rowData.step2 === null; else elseNull2">
                      <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull2>
                      <div *ngIf="rowData.step2; else elseBlock2">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>
                      <ng-template #elseBlock2>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>

                  <td>
                    <span class="p-column-title">3ª análise</span>

                    <div *ngIf="rowData.statusAnaliseArquivo === 'EM_ESPERA'; else elseWaiting_father">
                      <div *ngIf="(rowData.step3 && rowData.statusAnaliseArquivo === 'EM_ESPERA'); else elseWaiting">
                        <mdb-icon fas icon="sync" class="mr-2 text-warning" size="lg" aria-hidden="true"> </mdb-icon>
                      </div>

                      <ng-template #elseWaiting>
                        <div *ngIf="rowData.step3 === null; else elseNull3">
                          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true"> </mdb-icon>
                        </div>

                        <ng-template #elseNull3>
                          <div *ngIf="rowData.step3; else elseBlock3">
                            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                            </mdb-icon>
                          </div>

                          <ng-template #elseBlock3>
                            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </div>

                    <ng-template #elseWaiting_father>
                      <div
                        *ngIf="(rowData.step3 && rowData.statusAnaliseArquivo === 'PROCESSANDO'); else elseProcessing">
                        <mdb-icon fas icon="sync" class="mr-2 blue-text" size="lg" aria-hidden="true"> </mdb-icon>
                      </div>

                      <ng-template #elseProcessing>
                        <div *ngIf="rowData.step3 === null; else elseNull3">
                          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true"> </mdb-icon>
                        </div>

                        <ng-template #elseNull3>
                          <div *ngIf="rowData.step3; else elseBlock3">
                            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                            </mdb-icon>
                          </div>

                          <ng-template #elseBlock3>
                            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </td>

                  <td>
                    <span class="p-column-title">Analise</span>
                    <div class="badges">
                      <span [class]="['p-tag', rowData.fileAnaliseColor]">
                        {{ rowData.fileAnalise }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <span class="p-column-title">Status</span>
                    <a (click)="confirm(rowData)">
                      <mdb-icon fas icon="circle" [class]="rowData.statusColor" pTooltip="Click para alterar o status"
                        tooltipPosition="top"></mdb-icon>
                      <!-- {{ rowData.status ? ' Ativo' : ' Inativo' }} -->
                    </a>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações"
                      tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                      [routerLink]="['/admin/file-document/read', encrypt(rowData.idArquivoDocumentoGerado)]">
                      <i class="fa fa-search"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="primary" size="sm" tooltipPosition="left"
                      pTooltip="Alterar o período de facturação" class="btn-block" [style]="{padding: '4px 0'}"
                      (click)="showChangePeriodDialog(rowData)"
                      [disabled]="rowData.statusAnaliseArquivo === 'PROCESSANDO' || rowData.statusAnaliseArquivo === 'CONCLUIDO'">
                      <i class="fa fa-calendar-alt"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left"
                      pTooltip="Transferir o ficheiro" class="btn-block" [style]="{padding: '4px 0'}"
                      (click)="getFile(rowData)">
                      <i class="fa fa-download"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="warning" size="sm" tooltipPosition="left"
                      pTooltip="Copiar para documentoGerado" class="btn-block" [style]="{padding: '4px 0'}"
                      (click)="copyToDocumentTable(rowData)"
                      [disabled]="rowData.statusAnaliseArquivo !== 'PROCESSANDO'">
                      <i class="fa fa-gears"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          <p-tabPanel header="RELATÓRIO DE ANÁLISE">
            <app-read-file-document-detail></app-read-file-document-detail>
          </p-tabPanel>
        </p-tabView>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Alterar Período de Facturação" [(visible)]="displayPeriod" [style]="{width: '30vw'}"
  [baseZIndex]="10000" [contentStyle]="{'overflow':'visible'}">
  <form #formPeriod="ngForm" autocomplete="off" (ngSubmit)="updatePeriod()">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12">
        <label>Período de Facturação<b class="font-bold col-pink">*</b></label>
        <p-calendar name="dtPeriodoFaturacao" [(ngModel)]="fileDocument.dtPeriodoFaturacao" view="month"
          dateFormat="mm/yy" [yearNavigator]="true" yearRange="2019:2021" [locale]="locale.pt" [minDate]="minDate"
          [maxDate]="maxDate" placeholder="Informe o período de facturação" required>
        </p-calendar>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayPeriod=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="info" mdbWavesEffect [disabled]="!formPeriod.valid || loadingChangePeriod">
          ENVIAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingChangePeriod" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<p-dialog [showHeader]="false" [(visible)]="loadingCopyToDocumentTable" [style]="{width: '8vw'}" [baseZIndex]="10000"
  [contentStyle]="{'overflow':'visible'}" [modal]="true">

  <p-progressSpinner></p-progressSpinner>

</p-dialog>

<form autocomplete="off" (ngSubmit)="read()">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-2">
        <span class="p-float-label">
          <input class="p-inputtext-sm" pInputText name="nifConsumidor" [(ngModel)]="filter.nifConsumidor">
          <label for="float-input">NIF Adquirente</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-2">
        <span class="p-float-label">
          <input class="p-inputtext-sm" pInputText name="numDocumento" [(ngModel)]="filter.numDocumento">
          <label for="float-input">Número Documento</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-2">
        <span class="p-float-label">
          <input class="p-inputtext-sm" pInputText name="numSerieDocumento" [(ngModel)]="filter.numSerieDocumento">
          <label for="float-input">Número Série</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-1">
        <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
          Filtrar
        </button>
      </div>
    </div>
  </div>
</form>

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="documents"
  [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
  (onLazyLoad)="onChangePage($event)">
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">
      Total de {{ total | number:'1.0-0'}} documentos recolhidos.
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th [style]="{width: '10%'}">Tipo Documento</th>
      <th [style]="{width: '10%'}">Nº Documento</th>
      <th [style]="{width: '45%'}">Nome Adquirente</th>
      <th [style]="{width: '7%'}">Data Emissão</th>
      <th [style]="{width: '8%'}">Base Tributável</th>
      <th [style]="{width: '8%'}">ISC</th>
      <th [style]="{width: '8%'}">Valor Total</th>
      <th [style]="{width: '3%'}"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <span class="p-column-title">Tipo Documento</span>
        {{rowData.tbTipoDocumentoDescricao}}
      </td>

      <td>
        <span class="p-column-title">Nº Documento</span>
        {{rowData.numDocumento}}
      </td>

      <td>
        <span class="p-column-title">Nome Adquirente</span>
        {{ rowData.tbContribuinteNifConsumidor + ' - ' + rowData.tbPessoaNomeConsumidor }}
      </td>

      <td>
        <span class="p-column-title">Data Emissão</span>
        {{ rowData.dtEmissaoDocumento | date:'dd/MM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title"> Base Tributável</span>
        <strong>{{ rowData.totalValorBaseTributavel | currency:'STN' }}</strong>
      </td>

      <td>
        <span class="p-column-title"> ISC </span>
        <strong>{{ rowData.totalImposto | currency:'STN' }}</strong>
      </td>

      <td>
        <span class="p-column-title"> Valor Total </span>
        <strong>{{ rowData.totalValor | currency:'STN' }}</strong>
      </td>

      <td [style]="{padding: '1px 4px'}">
        <button type="button" mdbBtn color="info" size="sm" block="true" pTooltip="Ver mais informaões"
          tooltipPosition="left" [style]="{padding: '4px 0'}"
          [routerLink]="['/document/read', rowData.idDocumentoGerado]">
          <mdb-icon fas icon="search"></mdb-icon>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

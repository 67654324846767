import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { FileDocumentCancellationRequest } from '../core/models/file-document-cancellation-request';
import { FileDocument } from './../core/models/file-document';

export class FileDocumentFilter {
  page = 0;
  rows = 15;

  fileName: string;
  emitterNif: string;

  statusFile: boolean = true;
  statusAnalise: any;
  period: Date[];
  createdAt: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class FileDocumentService {
  API_URL: string;

  constructor(
    private http: AppHttp,
  ) {
    this.API_URL = `${environment.apiUrl}/arquivoDocumentoGerado`;
  }

  async read(filter: FileDocumentFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif.toString());
    }

    if (filter.fileName) {
      params = params.append('nomeArquivoDocumentoGerado', filter.fileName.toString());
    }

    if (filter.statusAnalise) {
      params = params.append('statusAnalise', filter.statusAnalise.code.toString());
    }

    if (filter.statusFile) {
      params = params.append('status', filter.statusFile.toString());
    }

    if (filter.createdAt) {
      if (filter.createdAt[0])
        params = params.append('dtCriaDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (filter.createdAt[1])
        params = params.append('dtCriaAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }

    if (filter.period) {
      if (!!filter.period[0]) {
        filter.period[0].setDate(5);
        params = params.append('dtPeriodoFaturacaoDe', moment(filter.period[0]).format('YYYY-MM-DD'));
      }
      if (!!filter.period[1]) {
        filter.period[1].setDate(5);
        params = params.append('dtPeriodoFaturacaoAte', moment(filter.period[1]).format('YYYY-MM-DD'))
      }
    }

    const response = await this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise();
    this.convertFields(response.content);
    return {
      body: response.content,
      total: response.totalElements
    };
  }

  async readForThirdParty(filter: FileDocumentFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif.toString());
    }

    if (filter.fileName) {
      params = params.append('nomeArquivoDocumentoGerado', filter.fileName.toString());
    }

    if (filter.statusAnalise) {
      params = params.append('statusAnalise', filter.statusAnalise.code.toString());
    }

    if (filter.statusFile) {
      params = params.append('status', filter.statusFile.toString());
    }

    if (filter.createdAt) {
      if (filter.createdAt[0])
        params = params.append('dtCriaDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (filter.createdAt[1])
        params = params.append('dtCriaAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }

    if (filter.period) {
      if (!!filter.period[0]) {
        filter.period[0].setDate(5);
        params = params.append('dtPeriodoFaturacaoDe', moment(filter.period[0]).format('YYYY-MM-DD'));
      }
      if (!!filter.period[1]) {
        filter.period[1].setDate(5);
        params = params.append('dtPeriodoFaturacaoAte', moment(filter.period[1]).format('YYYY-MM-DD'))
      }
    }

    const response = await this.http.get<any>(`${this.API_URL}/terceiro`, { params })
      .toPromise();
    this.convertFields(response.content);
    return {
      body: response.content,
      total: response.totalElements
    };
  }

  async get(id: number): Promise<FileDocument> {
    const response = await this.http.get<FileDocument>(`${this.API_URL}/${id}`)
      .toPromise();
    const fileDocument = response as FileDocument;
    this.convertFields([fileDocument]);
    return fileDocument;
  }

  async getForThirdParty(id: number): Promise<FileDocument> {
    const response = await this.http.get<FileDocument>(`${this.API_URL}/terceiro/${id}`)
      .toPromise();
    const fileDocument = response as FileDocument;
    this.convertFields([fileDocument]);
    return fileDocument;
  }

  getFile(file: FileDocument) {
    let params = new HttpParams();
    params = params.append('fileName', file.nomeArquivoDocumentoGerado);

    return this.http.get<Blob>(`${this.API_URL}/download`,
      { params, responseType: 'blob' })
      .toPromise();
  }

  private convertFields(documents: FileDocument[]) {
    for (const document of documents) {
      if (document.dtPeriodoFaturacao) {
        document.dtPeriodoFaturacao = moment(document.dtPeriodoFaturacao, 'YYYY-MM-DD').toDate();

        document.formattedPeriod = moment(document.dtPeriodoFaturacao).format('MMMM/YYYY');
      }

      if (document.dtCria)
        document.dtCria = moment(document.dtCria, 'YYYY-MM-DD').toDate();

      switch (document.statusAnaliseArquivo) {
        case 'POR_ANALISAR':
          document.fileAnalise = 'Por analisar';
          document.fileAnaliseColor = 'p-tag-secondary';
          break;
        case 'ANALISE_PRIMEIRA_FASE':
          document.fileAnalise = '1ª Fase';
          document.fileAnaliseColor = 'p-tag-info';
          break;
        case 'ANALISE_SEGUNDA_FASE':
          document.fileAnalise = '2ª Fase';
          document.fileAnaliseColor = 'p-tag-info';
          break;
        case 'ANALISE_TERCEIRA_FASE':
          document.fileAnalise = '3ª Fase';
          document.fileAnaliseColor = 'p-tag-info';
          break;
        case 'REJEITADO':
          document.fileAnalise = 'Rejeitado';
          document.fileAnaliseColor = 'p-tag-danger';
          break;
        case 'PROCESSANDO':
          document.fileAnalise = 'Processando';
          document.fileAnaliseColor = 'p-tag-primary';
          break;
        case 'CONCLUIDO':
          document.fileAnalise = 'Concluído';
          document.fileAnaliseColor = 'p-tag-success';
          break;
        case 'EM_ESPERA':
          document.fileAnalise = 'Em Espera';
          document.fileAnaliseColor = 'p-tag-warning';
          break;
        default:
          document.fileAnalise = 'Por analisar';
          document.fileAnaliseColor = 'p-tag-secondary';
          break;
      }

      if (document.statusAnaliseArquivo === 'REJEITADO') {
        if (document.analisePrim && !document.analiseSegu && !document.analiseTerc) {
          document.step1 = false;
          document.step2 = null;
          document.step3 = null;
        } else if (document.analisePrim && document.analiseSegu && !document.analiseTerc) {
          document.step1 = true;
          document.step2 = false;
          document.step3 = null;
        } else if (document.analisePrim && document.analiseSegu && document.analiseTerc) {
          document.step1 = true;
          document.step2 = true;
          document.step3 = false;
        }
      } else if (document.statusAnaliseArquivo === 'ANALISE_PRIMEIRA_FASE') {
        document.step1 = true;
        document.step2 = null;
        document.step3 = null;
      } else if (document.statusAnaliseArquivo === 'EM_ESPERA') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      } else if (document.statusAnaliseArquivo === 'ANALISE_SEGUNDA_FASE') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = null;
      } else if (document.statusAnaliseArquivo === 'ANALISE_TERCEIRA_FASE') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      }
      else if (document.statusAnaliseArquivo === 'PROCESSANDO') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      } else if (document.statusAnaliseArquivo === 'CONCLUIDO') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      }

      document.status
        ? document.statusColor = 'text-success'
        : document.statusColor = 'text-danger';
    }
  }
}

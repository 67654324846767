<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR SÉRIES DOS EMITENTES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifEmissor" [(ngModel)]="filter.nifEmissor">
                  <label for="float-input">NIF do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="numSerieDocumento"
                    [(ngModel)]="filter.numSerieDocumento">
                  <label for="float-input">Série (ex. FT0000120)</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="emissionDocuments"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [totalRecords]="total" [lazy]="true"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '15%'}">Documento</th>
              <th [style]="{width: '30%'}">Emitente</th>
              <th>NIF</th>
              <th>Série</th>
              <th>Status</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">Tipo de Documento</span>
                {{ rowData.tipoDocumento }}
              </td>

              <td>
                <span class="p-column-title">Emitente</span>
                {{ rowData.nomeEmissor }}
              </td>

              <td>
                <span class="p-column-title">NIF</span>
                {{ rowData.nifEmissor }}
              </td>

              <td>
                <span class="p-column-title">Série</span>
                {{
                rowData.siglaSerie +
                rowData.serie +
                rowData.ano
                }}
              </td>

              <td>
                <span class="p-column-title">Status</span>
                {{rowData.status ? 'Autorizado' : 'Não Autorizado'}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/document/new">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RECOLHER DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          POR UNIDADE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Esta funcionalidade permite aos adquirentes comunicarem as suas facturas e
      outros documentos equivalentes que não foram comunicados pelos seus
      emitentes.
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" [formGroup]="documentForm" (ngSubmit)="save()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">NIF do Adquirente </label>
              <input
                pInputText
                name="nifConsumidor"
                formControlName="nifConsumidor"
                (input)="onInputNif($event)"
              />
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label class="p-text-bold">Nome do Adquirente</label>
              <input
                pInputText
                name="nomeConsumidor"
                formControlName="nomeConsumidor"
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold"
                >Tipo Documento <b class="font-bold pink-text">*</b></label
              >
              <p-dropdown
                [options]="typesDocument"
                name="tbTipoDocumentoEmissao"
                formControlName="tbTipoDocumentoEmissao"
                placeholder="Selecione"
                (onChange)="dropdownSeriesByTypeDocument($event.value)"
              >
              </p-dropdown>
            </div>

            <div
              class="p-field p-col-12 p-md-3"
              formGroupName="tbAutorizacaoEmissao"
            >
              <label class="p-text-bold"
                >Número de Série <b class="font-bold pink-text">*</b></label
              >
              <p-dropdown
                [options]="series"
                placeholder="Selecione"
                name="idAutorizacaoEmissao"
                [filter]="true"
                formControlName="idAutorizacaoEmissao"
                required
              >
              </p-dropdown>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold"
                >Número Sequencial <b class="font-bold pink-text">*</b></label
              >
              <p-inputNumber
                [min]="1"
                [max]="99999999999999999999"
                name="numDocumento"
                formControlName="numDocumento"
              >
              </p-inputNumber>

              <app-message
                [control]="documentForm.get('numDocumento')"
                error="required"
                text="Informe o número  do documento"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold"
                >Data de Emissão <b class="font-bold pink-text">*</b></label
              >
              <p-calendar
                dateFormat="dd/mm/yy"
                name="dtEmissaoDocumento"
                formControlName="dtEmissaoDocumento"
                placeholder="dd/mm/aaaa"
                (onSelect)="showTaxSigla($event)"
              >
              </p-calendar>

              <app-message
                [control]="documentForm.get('dtEmissaoDocumento')"
                error="required"
                text="Informe a data de emissão"
              >
              </app-message>
            </div>

            <div class="p-col-12">
              <hr />

              <div formArrayName="tbItensDocumentoGerados">
                <div class="p-grid p-mt-2">
                  <label
                    class="p-col-fixed p-text-bold"
                    [ngStyle]="{ width: '250px' }"
                    *ngIf="displayFieldForCreditNote"
                  >
                    Documento a anular<b class="red-text">*</b>
                  </label>

                  <label
                    class="p-col-fixed p-text-bold"
                    [ngStyle]="{ width: '450px' }"
                  >
                    Descrição (opcional)
                  </label>

                  <label class="p-col p-text-bold">
                    Subtotal<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Taxa ({{ siglaTipoImpostoToShow }})<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Código i/s<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    {{ siglaTipoImpostoToShow }}<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Total<b class="red-text">*</b>
                  </label>

                  <label class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                  </label>
                </div>

                <div *ngFor="let item of items.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="p-grid">
                      <div
                        class="p-field p-col-fixed"
                        formGroupName="tbDocumentoOrigems"
                        *ngIf="displayFieldForCreditNote"
                        [ngStyle]="{ width: '250px' }"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="tbDocumentoOrigems"
                          (focus)="showDisplayOrigin(i)"
                          formControlName="numDocumentoOrigem"
                          placeholder="Documento origem"
                        />
                      </div>

                      <div
                        class="p-field p-col-fixed"
                        [ngStyle]="{ width: '450px' }"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descItens"
                          formControlName="descItens"
                          maxlength="100"
                          placeholder="Descrição (máximo 100 caracteres)"
                        />
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber
                          mode="decimal"
                          [minFractionDigits]="2"
                          name="valorBaseTributavelItens"
                          formControlName="valorBaseTributavelItens"
                          class="p-inputtext-sm"
                          placeholder="Montante do item"
                          (onBlur)="calcItemTax(i)"
                        >
                        </p-inputNumber>

                        <app-message
                          [control]="
                            items.at(i).get('valorBaseTributavelItens')
                          "
                          error="required"
                          text="Informe o montante"
                        >
                        </app-message>
                      </div>

                      <div
                        class="p-field p-col"
                        formGroupName="tbTaxaAplicavel"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descTaxaAplicavel"
                          formControlName="descTaxaAplicavel"
                          placeholder="Taxas"
                          [attr.disabled]="!showReceiptFields() ? 'disabled' : null"
                          (focus)="showTaxRate(i)"
                        />
                      </div>

                      <div
                        class="p-field p-col"
                        formGroupName="tbMotivoIsencao"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="tbMotivoIsencao"
                          formControlName="codigoMotivoIsencao"
                          placeholder="Motivo isenção/sujeição"
                          (focus)="showDisplayExemption(i)"
                          [attr.disabled]="!showReceiptFields() ? 'disabled' : null"
                        />
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber
                          class="p-inputtext-sm"
                          mode="decimal"
                          [minFractionDigits]="2"
                          name="valorImposto"
                          formControlName="valorImposto"
                          placeholder="Imposto"
                        >
                        </p-inputNumber>
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber
                          class="p-inputtext-sm"
                          mode="decimal"
                          [minFractionDigits]="2"
                          name="valorItens"
                          formControlName="valorItens"
                          placeholder="Total do item"
                        >
                        </p-inputNumber>
                      </div>

                      <div class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                        <button
                          type="button"
                          mdbBtn
                          color="danger"
                          size="sm"
                          pTooltip="Remover item"
                          tooltipPosition="left"
                          (click)="removeItem(i)"
                        >
                          <mdb-icon fas icon="trash"></mdb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                mdbBtn
                color="info"
                size="sm"
                pTooltip="Adicionar linha"
                tooltipPosition="right"
                (click)="addItem()"
                [style]="{ margin: '5px 0 15px 0' }"
              >
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold"
                >Montante <b class="font-bold pink-text">*</b></label
              >
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="2"
                name="totalValorBaseTributavel"
                formControlName="totalValorBaseTributavel"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold"
                >{{ siglaTipoImpostoToShow }}
                <b class="font-bold pink-text">*</b></label
              >
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="2"
                name="totalImposto"
                formControlName="totalImposto"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold"
                >Total <b class="font-bold pink-text">*</b></label
              >
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="2"
                name="totalValor"
                formControlName="totalValor"
              >
              </p-inputNumber>
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/emitter/document/new"
              >
                VOLTAR
              </button>

              <button
                type="submit"
                mdbBtn
                color="success"
                mdbWavesEffect
                [disabled]="documentForm.invalid || loading"
              >
                SALVAR
              </button>
            </div>

            <div class="p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Selecione a taxa"
  modal="true"
  [(visible)]="displayTaxRate"
  [style]="{ width: '50vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="taxRates"
    selectionMode="single"
    [(selection)]="selectedTaxRate"
    (onRowSelect)="onTaxRateSelect($event)"
    [rows]="10"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{ width: '60%' }">Imposto</th>
        <th [style]="{ width: '20%' }">Sigla</th>
        <th [style]="{ width: '20%' }">Taxa</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.descricaoTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.siglaTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Taxa</span>
          {{ rowData.descTaxaAplicavel }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayTaxRate = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o documento de origem"
  [(visible)]="displayOrigin"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <form autocomplete="off" (ngSubmit)="readOriginsByEmitter()">
    <div class="p-col-12">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-5">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="numDocumento"
              [(ngModel)]="filter.numDocumento"
            />
            <label for="float-input">Número Documento</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-2">
          <button
            type="submit"
            mdbBtn
            color="pink"
            size="sm"
            class="btn-block btn-filter"
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  </form>

  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="documentsOrigin"
    selectionMode="single"
    [(selection)]="selectedOrigin"
    (onRowSelect)="onOriginSelect($event)"
    [paginator]="true"
    [rows]="filter.minRows"
    [totalRecords]="totalDocumentsOrigin"
    [lazy]="true"
    (onLazyLoad)="onChangePage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Número Documento</th>
        <th>Data</th>
        <th>Montante</th>
        <th>{{ siglaTipoImpostoToShow }}</th>
        <th>Total</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>{{ rowData.numDocumento }}</td>
        <td>{{ rowData.dtEmissaoDocumento | date : "dd/MM/yyyy" }}</td>
        <td>{{ rowData.totalValorBaseTributavel }}</td>
        <td>{{ rowData.totalImposto }}</td>
        <td>{{ rowData.totalValor }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayOrigin = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o código de isenção/sujeição"
  [(visible)]="displayExemption"
  [style]="{ width: '70vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="exemptionReasons"
    selectionMode="single"
    [(selection)]="selectedExemptionReason"
    (onRowSelect)="onExemptionSelect($event)"
    [paginator]="true"
    [rows]="5"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Código</th>
        <th [style]="{ width: '40%' }">Descrição</th>
        <th [style]="{ width: '40%' }">Artigo</th>
        <th>Situação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Código</span>
          {{ rowData.codigoMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Descrição</span>
          {{ rowData.desMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Artigo</span>
          {{ rowData.artigo }}
        </td>
        <td>
          <span class="p-column-title">Situação</span>
          {{ rowData.isento ? "Isenção" : "Sujeição" }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayExemption = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR CERTIFICAÇÃO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifEmitter" [(ngModel)]="filter.nifEmitter">
                  <label for="float-input">NIF do Requerente</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="initialCertificationDate"
                    [(ngModel)]="filter.initialCertificationDate" dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="finalCertificationDate"
                    [(ngModel)]="filter.finalCertificationDate" dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="certifications"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="totalRecords"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ totalRecords | number:'1.0-0'}} pedidos de certificação.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>NIF</th>
              <th [style]="{width:'30%'}">Tipo de Certificação</th>
              <th>Data do Pedido</th>
              <th>Nº de certificação</th>
              <th>Estado</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">NIF</span>
                {{rowData.tbContribuinte.nifContribuinte}}
              </td>
              <td>
                <span class="p-column-title">Tipo de Certificação</span>
                {{ rowData.tbTipoCertificacao.descTipoCertificacao }}
              </td>
              <td>
                <span class="p-column-title">Data de Certificação</span>
                {{rowData.dtCriaPedidoCertificacao | date:'dd/MM/yyyy'}}
              </td>
              <td>
                <span class="p-column-title">Nº de Certificação</span>
                {{ rowData.numeroCertificacao }}
              </td>
              <td>
                <span class="p-column-title">Estado</span>
                {{rowData.estadoPedidoCertificacao ? 'Certificado' : 'Não certificado'}}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                  [routerLink]="['/agent/certification/read', encrypt(rowData.idPedidoCertificacao)]">
                  <i class="fa fa-search"></i>
                </button>
              </td>

            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

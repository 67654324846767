import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-get-credit-and-debit-items-by-invoice',
  templateUrl: './get-credit-and-debit-items-by-invoice.component.html',
  styleUrls: ['./get-credit-and-debit-items-by-invoice.component.scss']
})
export class GetCreditAndDebitItemsByInvoiceComponent implements OnInit {
  @Input() creditAndDebitNotesItems: any;

  constructor() { }

  ngOnInit(): void {
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CreateTaxRateComponent } from './create-tax-rate/create-tax-rate.component';
import { TaxRateRoutingModule } from './tax-rate-routing.module';
import { TaxRateComponent } from './tax-rate.component';
import { UpdateTaxRateComponent } from './update-tax-rate/update-tax-rate.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChangeStatusTaxRateComponent } from './change-status-tax-rate/change-status-tax-rate.component';

@NgModule({
  declarations: [
    TaxRateComponent,
    CreateTaxRateComponent,
    UpdateTaxRateComponent,
    ChangeStatusTaxRateComponent,
  ],
  imports: [
    CommonModule,
    TaxRateRoutingModule,

    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModule,

    TableModule,
    TooltipModule,
    ProgressBarModule,
    DropdownModule,
    ProgressBarModule,
    CalendarModule,
    DialogModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
  ],
})
export class TaxRateModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { FileDocumentCancellationRequest } from 'src/app/core/models/file-document-cancellation-request';
import { FileDocumentCancellationRequestService } from 'src/app/file-document-cancellation-request/file-document-cancellation-request.service';
import { decrypt } from '../../configs/encryption';
import { FileDocumentService } from '../file-document.service';

@Component({
  selector: 'app-get-for-third-party',
  templateUrl: './get-for-third-party.component.html',
  styleUrls: ['./get-for-third-party.component.scss'],
})
export class GetForThirdPartyComponent implements OnInit {
  locale = new Locale();
  fileDocument = new FileDocument();

  display: boolean = false;
  loadingRequestCancellation: boolean = false;
  request = new FileDocumentCancellationRequest();

  constructor(
    private service: FileDocumentService,
    private fileDocumentCancellationRequestService: FileDocumentCancellationRequestService,
    private route: ActivatedRoute,
    private message: MessageService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    const userId = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(userId));
    this.get(decryptedId);
  }

  showDialog() {
    this.display = true;
  }

  get(id: number) {
    this.service
      .getForThirdParty(id)
      .then((response) => (this.fileDocument = response))
      .catch((error) => this.errorHandler.handle(error));
  }

  getFile(file: FileDocument) {
    this.service
      .getFile(file)
      .then((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() =>
        this.message.add({
          key: 'lg',
          severity: 'error',
          life: 20000,
          detail: 'Não foi possível transferir o ficheiro! Tente mais tarde.',
        })
      );
  }

  requestCancellation() {
    this.loadingRequestCancellation = true;
    this.request.tbArquivoDocumentoGerado.idArquivoDocumentoGerado =
      this.fileDocument.idArquivoDocumentoGerado;

    delete this.request.tbArquivoDocumentoGerado.step1;
    delete this.request.tbArquivoDocumentoGerado.step2;
    delete this.request.tbArquivoDocumentoGerado.step3;
    delete this.request.tbArquivoDocumentoGerado.tbContribuinte;

    this.fileDocumentCancellationRequestService
      .create(this.request)
      .then(() => {
        this.message.add({
          severity: 'success',
          detail:
            'Solicitação enviada! Receberá uma notificação assim que for processada',
        });
        this.display = false;
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => (this.loadingRequestCancellation = false));
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <!-- <mdb-breadcrumb-item routerLink="/emitter/emission/new">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PEDIDO DE SÉRIES
        </mdb-breadcrumb-item> -->
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PARA SOFTWARE AUTÓNOMO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Esta funcionalidade permite a solicitação de séries de documentos à serem
      emitidos.
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form
          autocomplete="off"
          [formGroup]="emissionForm"
          (ngSubmit)="save(emissionForm)"
        >
          <div class="p-fluid p-formgrid p-grid">
            
              <div class="p-col-12">
                <div class="p-field-checkbox">
                  <p-checkbox name="btPedidoEmissaoProximoAno" formControlName="btPedidoEmissaoProximoAno" binary="true"
                  inputId="binary"></p-checkbox>
                  <label for="binary" class="pink-text" [style]="{marginTop: '10px'}">
                    <strong> SÉRIES PARA O PRÓXIMO ANO</strong>
                  </label>
                </div>
              </div>
           

            <div class="p-col-12">
              <div formArrayName="pedidoEmissaoDocumentos">
                <div
                  class="p-mb-3"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.5)"
                >
                  <label
                    class="p-col p-md-2 p-text-bold p-text-left"
                    style="padding: 0 0 !important"
                  >
                    Tipo documento
                  </label>
                  <label
                    class="p-col p-md-1 p-text-bold p-text-left"
                    style="padding: 0 0 !important"
                    placeholder="Ex. 1000"
                  >
                    Quantidade
                  </label>
                  <label
                    class="p-col p-md-1 p-text-bold p-text-left"
                    style="padding: 0 0 !important"
                  >
                    Máscara
                  </label>
                  <label
                    class="p-col p-md-3 p-text-bold p-text-left"
                    style="padding: 0 0 !important"
                  >
                    Descrição (Opcional)
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left"> </label>
                </div>

                <div *ngFor="let item of items.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="p-grid">
                      <div
                        class="p-field p-col-12 p-md-2"
                        formGroupName="tbTipoDocumentoEmissao"
                      >
                        <p-dropdown
                          class="p-inputtext-sm"
                          name="tbTipoDocumentoEmissao"
                          [options]="typesDocument"
                          placeholder="Selecione"
                          formControlName="idTipoDocumentoEmissao"
                        >
                        </p-dropdown>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="qtDocumentosSolicitado"
                          formControlName="qtDocumentosSolicitado"
                          (input)="setTotalEmissionDocuments($event, i)"
                        />
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="mascara"
                          formControlName="mascara"
                          placeholder="Ex. 6 dígitos: 000001"
                        />
                      </div>

                      <div class="p-field p-col-12 p-md-3">
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descPedidoEmissaoDocumento"
                          formControlName="descPedidoEmissaoDocumento"
                        />
                      </div>

                      <div class="p-field p-col-12 d-flex p-md-3">
                        <div class="field-checkbox d-flex align-items-center">
                          <p-checkbox
                            name="localTributacao"
                            formControlName="localTributacao"
                            [binary]="true"
                            inputId="binary"
                          ></p-checkbox>
                          <label>Solicitação de série para Princípe</label>
                        </div>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <button
                          type="button"
                          mdbBtn
                          color="danger"
                          size="sm"
                          pTooltip="Remover linha"
                          tooltipPosition="left"
                          (click)="removeItem(i)"
                        >
                          <mdb-icon fas icon="times"></mdb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                mdbBtn
                color="info"
                size="sm"
                pTooltip="Adicionar linha"
                tooltipPosition="right"
                (click)="addItem()"
                [style]="{ margin: '5px 0 15px 0' }"
              >
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr />
            </div>

            <div class="p-field p-col-12">
              <label>Observações</label>
              <textarea
                pInputTextarea
                name="descPedidoEmissaoFatura"
                rows="2"
                formControlName="descPedidoEmissaoFatura"
              ></textarea>
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/emitente"
              >
                VOLTAR
              </button>

              <button
                type="submit"
                color="success"
                mdbBtn
                mdbWavesEffect
                [disabled]="emissionForm.invalid || loading"
              >
                SALVAR
              </button>
            </div>

            <div class="p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  [(visible)]="display"
  [style]="{ width: '60vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
  modal="true"
>
  <div class="p-grid">
    <div class="col-md-12 text-center p-mt-3">
      <mdb-icon
        class="icon-message-lg text-success text-center"
        fas
        icon="check-circle"
      ></mdb-icon>
      <p class="bq-title text-center p-mt-2">
        O seu pedido foi processado com sucesso!
      </p>
      <hr />
    </div>

    <table mdbTable borderless="true" small="true">
      <tbody>
        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Nome do Emitente</strong>
          </th>
          <td class="text-left">
            {{ emission?.nomeEmissor }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>NIF do Emitente</strong>
          </th>
          <td class="text-left">
            {{ emission?.nifEmissor }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Número de certificação</strong>
          </th>
          <td class="text-left">
            {{ emission.numeroCertificacao }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Data de certificação</strong>
          </th>
          <td class="text-left">
            {{
              emission.dtCertificacao | date : "dd/MM/yyyy"
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <p-table
      [style]="{ width: '100%' }"
      styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
      [value]="emission.pedidoEmissaoDocumentos"
    >
      <ng-template pTemplate="header">
        <tr class="bg-red">
          <th>Série</th>
          <th>Qtd Documentos</th>
          <th>Nº Autorização</th>
          <th>Máscara</th>
          <th>Descrição</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>
            <span class="p-column-title">Série</span>
            {{
              rowData.siglaTipoDocumentoEmissao +
                "" +
                rowData.numSerieDocumento +
                "" +
                rowData.anoAutorizacaoEmissao
            }}
          </td>

          <td>
            <span class="p-column-title">Qtd documentos</span>
            {{ rowData.qtDocumentosSolicitado }}
          </td>

          <td>
            <span class="p-column-title">Nº Autorização</span>
            {{ rowData.numAutorizacaoEmissao }}
          </td>

          <td>
            <span class="p-column-title">Máscara</span>
            {{ rowData.mascara }}
          </td>

          <td>
            <span class="p-column-title">Descrição</span>
            {{ rowData.descPedidoEmissaoDocumento }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <ng-template pTemplate="footer">
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="display = false"
    >
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayBlockedSerieMessage"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
>
  <div class="alert alert-danger" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Sujeito passivo bloqueado para solicitação de novas séries. Deve comunicar
    as faturas em falta!
    <p class="p-mt-2">Nome: {{ blockedTaxpayerInfo?.nomePessoa }}</p>
    <p>NIF: {{ blockedTaxpayerInfo?.nifContribuinte }}</p>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBlockedSerieMessage = false"
      label="Ok"
      styleClass="p-button-text"
    >
    </p-button>
  </ng-template>
</p-dialog>

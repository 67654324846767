import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/auth/app-http';

export class AuthorizationEmitterFilter {
  page = 0;
  rows = 20;

  serieNumber: string;
  typeDocumentId: string;
  authorizationId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationEmitterService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/autorizacaoEmissao`;
  }

  readControl(filter: AuthorizationEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.authorizationId)
      params = params.append('tbAutorizacaoEmissaoId', filter.authorizationId);

    if (filter.typeDocumentId)
      params = params.append('tbTipoDocumentoId', filter.typeDocumentId);

    return this.http.get<any>(`${this.API_URL}/controlo/porEmissor`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <any[]>response.content,
          total: response.totalElements
        }
      });
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { TaxRateService } from '../tax-rate.service';

@Component({
  selector: 'app-change-status-tax-rate',
  templateUrl: './change-status-tax-rate.component.html',
  styleUrls: ['./change-status-tax-rate.component.scss'],
})
export class ChangeStatusTaxRateComponent implements OnInit {
  @Output('updateList') updateList = new EventEmitter<void>();
  @Input() taxRateId: number;
  @Input() status: boolean;

  constructor(
    private taxRateService: TaxRateService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {}

  changeStatus(): void {
    this.taxRateService
      .changeStatus(this.taxRateId, this.status)
      .then(() => this.updateList.emit())
      .catch((err) => this.errorHandler.handle(err));
  }
}

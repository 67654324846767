import { OccurrenceService } from './../../occurrence/occurrence.service';
import { Occurrence } from './../../core/models/occurrence';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { OccurrenceEmitterFilter, OccurrenceEmitterService } from './../occurrence-emitter.service';
import { Component, OnInit } from '@angular/core';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';

export enum statuses {
  PENDENTE = 'PENDENTE',
  EM_ANALISE = 'EM_ANALISE',
  FECHADO = 'FECHADO'
};

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  loading: boolean = true;

  locale = new Locale();
  totalRecords = 0;
  filter = new OccurrenceEmitterFilter();
  occurrences = [];

  constructor(
    private occurrenceEmitterService: OccurrenceEmitterService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;

    this.occurrenceEmitterService.read(this.filter)
      .then((result) => {
        this.totalRecords = result.total;
        this.occurrences = result.content;
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ReadComponent } from './read/read.component';
import { ReportRoutinesAnalysisFileRouter } from './report-routines-analysis-file-route';

@NgModule({
  declarations: [ReadComponent],
  imports: [
    CommonModule,
    ReportRoutinesAnalysisFileRouter,

    FormsModule,
    MDBBootstrapModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    DropdownModule,
  ]
})
export class ReportRoutinesAnalysisFileModule { }

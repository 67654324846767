<p-dialog
  header="Detalhes do Documento"
  [(visible)]="display"
  [responsive]="true"
  [style]="{ width: '50vw' }"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <kendo-pdf-export #pdf paperSize="A4" margin="0.4cm">
    <div class="invoice-box">
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="2">
            <table class="tb-two-rows">
              <tr>
                <td class="title">
                  <span>
                    <strong>
                      {{ document.get("nomeEmissor").value | titlecase }}
                    </strong>
                  </span>
                  <br />
                  <span>
                    <strong>NIF: </strong>
                    {{ document.get("nifEmissor").value }}
                  </span>
                  <br />
                  <span>
                    <strong> Endereço: </strong>
                  </span>
                  <br />
                  <span><strong>Telefone: </strong> 9811015</span> <br />
                </td>

                <td class="number">
                  <span
                    ><strong>
                      {{
                        document.get("tbTipoDocumentoEmissao").value
                          ? document.get("tbTipoDocumentoEmissao").value
                              .descTipoDocumentoEmissao
                          : ""
                      }}
                    </strong></span
                  >
                  <br />
                  <span>
                    <strong>
                      Número:
                      {{
                        document.get("tbTipoDocumentoEmissao").value
                          ? document.get("tbAutorizacaoEmissao").value
                              .siglaDocAutorizacaoEmissao +
                            document.get("tbAutorizacaoEmissao").value
                              .numSerieDocumento +
                            document.get("tbAutorizacaoEmissao").value
                              .anoAutorizacaoEmissao +
                            document.get("numDocumentoOnline").value
                          : ""
                      }}
                    </strong> </span
                  ><br />
                  <span>
                    <strong>Data: </strong>
                    {{
                      document.get("dtEmissaoDocumentoOnline").value
                        | date : "dd/MM/yyyy"
                    }}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="consumer">
          <td colspan="2">
            <table class="tb-two-rows">
              <tr>
                <td>
                  <strong>Adquirente: </strong> <br />
                  {{ document.get("nomeConsumidorOnline").value | titlecase }}
                </td>
                <td>
                  <strong>NIF: </strong> <br />
                  {{ document.get("nifConsumidorOnline").value }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <div class="invoice-items">
        <table class="tb-items table table-sm table-striped">
          <thead>
            <tr>
              <th>Qth</th>
              <th>Descrição</th>
              <th>V. Unit.</th>
              <th>Sub Total</th>
              <th>{{ showTaxSigla }}</th>
              <th>V.Total</th>
            </tr>
          </thead>

          <tbody>
            <tr class="item" *ngFor="let item of items.controls; let i = index">
              <td>{{ item.get("quantItens").value }}</td>
              <td [style]="{ textAlign: 'left' }">
                {{ item.descItens }}
              </td>
              <td>{{ item.get("valorUnitItens").value }}</td>
              <td>{{ item.get("valorBaseTributavelItens").value }}</td>
              <td>{{ item.get("valorImposto").value }}</td>
              <td>{{ item.get("totalLinhaAposImpostoItens").value }}</td>
            </tr>
          </tbody>
        </table>

        <table
          class="tb-items-total tb-two-rows"
          cellpadding="0"
          cellspacing="0"
        >
          <tr>
            <td></td>
            <td>
              Subtotal:
              <strong>
                {{ document.get("totalValorBaseTributavelOnline").value }}
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              Total ISC:
              <strong>
                {{ document.get("totalImpostoOnline").value }}
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              Total:
              <strong>
                {{ document.get("totalValorOnline").value }}
              </strong>
            </td>
          </tr>
        </table>
      </div>

      <div class="invoice-obs">
        <table cellpadding="0" cellspacing="0">
          <tr>
            <td>
              {{ document.get("observacaoDocumentoGeradoOnline").value }}
            </td>
          </tr>
        </table>
      </div>

      <div class="invoice-footer">
        <span>Número de certificado: 897894456465456</span>
        <br />
        <span>
          Número de Autorização:
          {{
            document.get("tbAutorizacaoEmissao").value
              ? document.get("tbAutorizacaoEmissao").value.numAutorizacaoEmissao
              : ""
          }}
        </span>
      </div>
    </div>
  </kendo-pdf-export>

  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="display = false"
      label="FECHAR"
    ></button>
  </p-footer>
</p-dialog>

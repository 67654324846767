<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tipografia">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PEDIDO DE SÉRIE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-warning" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      <p>
        Esta funcionalidade permite as tipografias solicitarem novos números de
        séries aos seus clientes.
      </p>
      <p>
        Para os emitentes que desejam incrementar novas folhas à uma série
        existente, devem
        <a routerLink="/typography/emission-document/increment"
          >solicitar novas folhas</a
        >
        e não solicitar uma nova série.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" [formGroup]="emissionForm" (ngSubmit)="save()">
          <div class="p-fluid p-formgrid p-grid">
            <!--
              <div class="p-col-12">
              <div class="p-field-checkbox">
                <p-checkbox name="btPedidoEmissaoProximoAno" formControlName="btPedidoEmissaoProximoAno" binary="true"
                inputId="binary"></p-checkbox>
                <label for="binary" class="pink-text" [style]="{marginTop: '10px'}">
                  <strong> SÉRIE PARA O PRÓXIMO ANO</strong>
                </label>
              </div>
              <hr>
            </div>
          --></div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2" formGroupName="tbContribuinte">
              <label>NIF do emitente <strong class="red-text">*</strong></label>
              <input
                pInputText
                type="text"
                name="nifContribuinte"
                formControlName="nifContribuinte"
                placeholder="Quem vai emitir os documentos"
                (input)="onInputNif($event)"
              />

              <div *ngIf="!validNif" class="ui-message ui-message-error">
                NIF inválido
              </div>
            </div>

            <div class="p-field p-col-12 p-md-5" formGroupName="tbContribuinte">
              <label>Nome do emitente<strong class="red-text">*</strong></label>
              <input
                pInputText
                type="text"
                name="nomeContribuinte"
                formControlName="nomeContribuinte"
              />
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
              <label
                >NIF do solicitante<strong class="red-text">*</strong></label
              >
              <input
                pInputText
                type="text"
                name="nifSolicitante"
                formControlName="nifSolicitante"
                placeholder="Quem fez a solicitação presencialmente"
                (input)="onInputNifSol($event)"
              />

              <div *ngIf="!validNifSol" class="ui-message ui-message-error">
                NIF inválido
              </div>
            </div>

            <div class="p-field p-col-12 p-md-5">
              <label
                >Nome do solicitante<strong class="red-text">*</strong></label
              >
              <input
                pInputText
                type="text"
                name="nomeSolicitante"
                formControlName="nomeSolicitante"
              />
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12">
              <hr />
              <div formArrayName="pedidoEmissaoDocumentos">
                <div
                  class="p-mb-3"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.5)"
                >
                  <label
                    class="p-col p-md-2 p-text-bold p-text-left item-label"
                  >
                    Tipo documento
                    <strong class="red-text">*</strong>
                  </label>
                  <label
                    class="p-col p-md-1 p-text-bold p-text-left item-label"
                  >
                    Quantidade
                    <strong class="red-text">*</strong>
                  </label>
                  <label
                    class="p-col p-md-3 p-text-bold p-text-left item-label"
                  >
                    Descrição
                  </label>
                  <label
                    class="p-col p-md-1 p-text-bold p-text-left item-label"
                  >
                  </label>
                </div>

                <div *ngFor="let item of items.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="p-grid">
                      <div
                        class="p-field p-col-12 p-md-2"
                        formGroupName="tbTipoDocumentoEmissao"
                      >
                        <p-dropdown
                          class="p-inputtext-sm"
                          name="tbTipoDocumentoEmissao"
                          [options]="typesDocument"
                          placeholder="Selecione"
                          formControlName="idTipoDocumentoEmissao"
                        >
                        </p-dropdown>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="qtDocumentosSolicitado"
                          formControlName="qtDocumentosSolicitado"
                        />
                      </div>

                      <div class="p-field p-col-12 p-md-3">
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descPedidoEmissaoDocumento"
                          formControlName="descPedidoEmissaoDocumento"
                        />
                      </div>

                      <div class="p-field p-col-12 d-flex p-md-3">
                        <div class="field-checkbox d-flex align-items-center">
                          <p-checkbox
                            name="localTributacao"
                            formControlName="localTributacao"
                            [binary]="true"
                            inputId="binary"
                          ></p-checkbox>
                          <label>Solicitação de série para Princípe</label>
                        </div>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <button
                          type="button"
                          mdbBtn
                          color="danger"
                          size="sm"
                          pTooltip="Remover item"
                          tooltipPosition="left"
                          (click)="removeItem(i)"
                        >
                          <mdb-icon fas icon="times"></mdb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                mdbBtn
                color="info"
                size="sm"
                pTooltip="Adicionar linha"
                tooltipPosition="right"
                (click)="addItem()"
                [style]="{ margin: '5px 0 15px 0' }"
              >
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr />
            </div>

            <div class="p-field p-col-12">
              <label>Observações</label>
              <textarea
                pInputTextarea
                name="descPedidoEmissaoFatura"
                rows="2"
                formControlName="descPedidoEmissaoFatura"
              ></textarea>
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/tipografia"
              >
                VOLTAR
              </button>

              <button
                type="submit"
                color="success"
                mdbBtn
                mdbWavesEffect
                [disabled]="emissionForm.invalid || loading"
              >
                SALVAR
              </button>
            </div>

            <div class="p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  [(visible)]="display"
  [style]="{ width: '60vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
  modal="true"
>
  <div class="p-grid">
    <div class="col-md-12 text-center p-mt-3">
      <mdb-icon
        class="icon-message-lg text-success text-center"
        fas
        icon="check-circle"
      ></mdb-icon>
      <p class="bq-title text-center p-mt-2">
        O seu pedido foi processado com sucesso!
      </p>
      <hr />
    </div>

    <div class="p-col-12">
      <div class="alert alert-warning" role="alert">
        <p>
          As informações de identificação do emitente na tabela abaixo devem
          constar obrigatoriamente nas folhas a serem impressas.
        </p>

        <p>
          Caso o emitente deseje alterar as mesmas informações, o mesmo deve
          dirigir-se à Direcção dos Impostos.
        </p>
      </div>
    </div>

    <table mdbTable borderless="true" small="true">
      <tbody>
        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Nome do Emitente</strong>
          </th>
          <td class="text-left">
            {{ emission.tbContribuinte.tbPessoa.nomePessoa }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>NIF do Emitente</strong>
          </th>
          <td class="text-left">
            {{ emission.tbContribuinte.nifContribuinte }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Telefone</strong>
          </th>
          <td class="text-left">
            {{ emission.tbContribuinte.tbPessoa.telefonePessoa }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Endereço</strong>
          </th>
          <td class="text-left">
            {{ emission.tbContribuinte.tbPessoa.enderecoPessoa }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Número de certificação</strong>
          </th>
          <td class="text-left">
            {{ emission.tbPedidoCertificacao.numeroCertificacao }}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{ width: '20%' }">
            <strong>Data de certificação</strong>
          </th>
          <td class="text-left">
            {{
              emission.tbPedidoCertificacao.dtCertificacao | date : "dd/MM/yyyy"
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="col-md-12">
      <hr />
    </div>

    <p-table
      styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
      [value]="emission.pedidoEmissaoDocumentos"
    >
      <ng-template pTemplate="header">
        <tr class="bg-red">
          <th>Série</th>
          <th>Documentos Solicitados</th>
          <th>Nº Autorização</th>
          <th>Máscara</th>
          <th>Descrição</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>
            <span class="p-column-title">Série</span>
            {{
              rowData.tbAutorizacaoEmissao.siglaDocAutorizacaoEmissao +
                "" +
                rowData.tbAutorizacaoEmissao.numSerieDocumento +
                "" +
                rowData.tbAutorizacaoEmissao.anoAutorizacaoEmissao
            }}
          </td>

          <td>
            <span class="p-column-title">Documentos Solicitados</span>
            {{ rowData.qtDocumentosSolicitado }}
          </td>

          <td>
            <span class="p-column-title">Nº Autorização</span>
            {{ rowData.tbAutorizacaoEmissao.numAutorizacaoEmissao }}
          </td>

          <td>
            <span class="p-column-title">Máscara</span>
            {{ rowData.mascara }}
          </td>

          <td>
            <span class="p-column-title">Descrição</span>
            {{ rowData.descPedidoEmissaoDocumento }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <ng-template pTemplate="footer">
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="display = false"
    >
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayBlockedSerieMessage"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
>
  <div class="alert alert-danger" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Sujeito passivo bloqueado para solicitação de novas séries. Deve comunicar
    as faturas em falta!
    <p class="p-mt-2">Nome: {{ blockedTaxpayerInfo?.nomePessoa }}</p>
    <p>NIF: {{ blockedTaxpayerInfo?.nifContribuinte }}</p>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBlockedSerieMessage = false"
      label="Ok"
      styleClass="p-button-text"
    >
    </p-button>
  </ng-template>
</p-dialog>

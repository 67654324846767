import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import {
  Certification,
  CreateCertificationSoftwareDTO,
} from './../core/models/certification';
import { environment } from 'src/environments/environment';

export class CertificationEmitterFilter {
  page = 0;
  rows = 15;
  nifEmitter: string;
  initialCertificationDate: Date;
  finalCertificationDate: Date;
}

@Injectable({
  providedIn: 'root',
})
export class CertificationEmitterService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/pedidosCertificacao`;
  }

  create(data: CreateCertificationSoftwareDTO): Promise<any> {
    return this.http
      .post(`${this.API_URL}/empresa`, data, { observe: 'response' })
      .toPromise();
  }

  createForPortal(certification: any): Promise<any> {
    return this.http
      .post<any>(`${this.API_URL}/online`, certification, {
        observe: 'response',
      })
      .toPromise();
  }

  read(filter: CertificationEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    return this.http
      .get<any>(`${this.API_URL}/porEmissor`, { params })
      .toPromise()
      .then((response) => {
        return {
          content: response.content,
          total: response.totalElements,
        };
      });
  }

  getById(id: number): Promise<Certification> {
    return this.http
      .get(`${this.API_URL}/${id}`)
      .toPromise()
      .then((response) => {
        const certification = response as Certification;
        CertificationEmitterService.convertData([certification]);
        return certification;
      });
  }

  private static convertData(certifications: Certification[]) {
    for (const certification of certifications) {
      certification.dtCriaPedidoCertificacao = moment(
        certification.dtCriaPedidoCertificacao,
        'YYYY-MM-DD'
      ).toDate();

      if (certification.dtCertificacao) {
        certification.dtCertificacao = moment(
          certification.dtCertificacao,
          'YYYY-MM-DD'
        ).toDate();
      }
    }
  }
}

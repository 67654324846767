<p-table [value]="transportedSeries">
  <ng-template pTemplate="header">
    <tr>
      <th>Tipo documento</th>
      <th>Série</th>
      <th>Nº de Autorização</th>
      <th>Data de emissão</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{rowData.siglaDocAutorizacaoEmissao}}</td>
      <td>{{rowData.siglaDocAutorizacaoEmissao+rowData.numSerieDocumento+rowData.anoAutorizacaoEmissao}}</td>
      <td>{{rowData.numAutorizacaoEmissao}}</td>
      <td>{{rowData.dtCria | date:'dd/MM/yyyy'}}</td>
    </tr>
  </ng-template>
</p-table>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recorver-password3',
  templateUrl: './recorver-password3.component.html',
  styleUrls: ['./recorver-password3.component.scss']
})
export class RecorverPassword3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

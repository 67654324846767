import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from '../shared/shared.module';
import { GetComponent } from './get/get.component';
import { ReadComponent } from './read/read.component';
import { UserAdminRouter } from './user-admin-router';
import { ValidateAccountGetComponent } from './validate-account-get/validate-account-get.component';
import { ValidateAccountComponent } from './validate-account/validate-account.component';

@NgModule({
  declarations: [
    ReadComponent,
    GetComponent,
    ValidateAccountComponent,
    ValidateAccountGetComponent
  ],
  imports: [
    CommonModule,
    UserAdminRouter,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ProgressBarModule,
    MessageModule,
    MessagesModule,
    ToggleButtonModule,
    PasswordModule,
    DropdownModule,
    DialogModule,
    PDFExportModule
  ]
})
export class UserAdminModule { }

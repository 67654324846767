import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocumentDetail } from 'src/app/core/models/file-document-detail';
import { decrypt } from '../../configs/encryption';
import { FileDocumentDetailService } from '../file-document-detail.service';

@Component({
  selector: 'app-get-file-document-detail',
  templateUrl: './get-file-document-detail.component.html',
  styleUrls: ['./get-file-document-detail.component.scss']
})
export class GetFileDocumentDetailComponent implements OnInit {
  locale = new Locale();
  fileDocument = new FileDocumentDetail();

  constructor(
    private service: FileDocumentDetailService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    const userId = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(userId));
    this.get(decryptedId);
  }

  get(id: number) {
    this.service.getByFileDocument(id)
      .then(response => {
        this.fileDocument = response;
      })
      .catch(error => {
        if (error.status !== 404) {
          this.errorHandler.handle(error);
        }
      });
  }
}

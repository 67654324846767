import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { DocumentoItem } from './../core/models/document-item';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentItemService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/itensDocumentoGerado`;
  }

  async readByDocument(documentNumber: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('numeroOrigem', documentNumber);

    return this.http.get(`${this.API_URL}/byOrigem`, { params })
      .toPromise();
  }
}

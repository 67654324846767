import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CertificationUtilRouter } from './certification-util-router';
import { ReadTypographyComponent } from './read-typography/read-typography.component';

@NgModule({
  declarations: [ReadTypographyComponent],
  imports: [
    CommonModule,
    CertificationUtilRouter,

    FormsModule,
    MDBBootstrapModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    ProgressBarModule,
    DropdownModule,
    InputSwitchModule,
    ScrollPanelModule,
  ]
})
export class CertificationUtilModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { CertificationEmitterRouter } from './certification-emitter-router';
import { CreateForPortalComponent } from './create-for-portal/create-for-portal.component';
import { CreateComponent } from './create/create.component';
import { GetComponent } from './get/get.component';
import { IndexComponent } from './index/index.component';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [
    ReadComponent,
    IndexComponent,
    GetComponent,
    CreateComponent,
    CreateForPortalComponent,
  ],
  imports: [
    CommonModule,
    CertificationEmitterRouter,

    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    SidebarModule,
    InputSwitchModule,
    ScrollPanelModule,
    DialogModule,
    MessageModule,
    MessagesModule,
  ],
  exports: [],
})
export class CertificationEmitterModule {}

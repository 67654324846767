<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TOTAL DOCUMENTOS COMUNICADOS POR SÉRIE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="emitterNif" [(ngModel)]="filter.emitterNif"
                    (input)="dropdownSeries($event.target.value)">
                  <label>NIF do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="series" name="serieNumber"
                    [(ngModel)]="filter.serieNumber" (onChange)="read()">
                  </p-dropdown>
                  <label>Número de Série</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="period" [(ngModel)]="filter.period" view="month"
                    dateFormat="mm/yy" [yearNavigator]="true" yearRange="2019:2020" [locale]="locale.pt"
                    placeholder="Período Faturação">
                  </p-calendar>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table [value]="controls" [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows"
          [lazy]="true" [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '40%'}">Emitente</th>
              <th>NIF</th>
              <th>Série</th>
              <th>Período</th>
              <th>Documentos Comunicados</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Emitente</span>
                {{ rowData.nomePessoa }}
              </td>

              <td>
                <span class="p-column-title">NIF</span>
                {{ rowData.nifContribuinte }}
              </td>

              <td>
                <span class="p-column-title">Série</span>
                {{
                rowData.siglaDocAutorizacaoEmissao +
                rowData.numSerieDocumento +
                rowData.anoAutorizacaoEmissao
                }}
              </td>

              <td>
                <span class="p-column-title">Período</span>
                {{ rowData.periodo | date:'yyyy - MMMM' }}
              </td>

              <td>
                <span class="p-column-title">Período</span>
                {{ rowData.totalDocumentogerado }}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" block="true" pTooltip="Ver mais informaões"
                  tooltipPosition="left" [style]="{padding: '4px 0'}" [routerLink]="['/controls-document', rowData.id]">
                  <mdb-icon fas icon="search"></mdb-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

import { DocumentTempView } from './../core/models/document-temp';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';

export class DocumentTempFilter {
  page = 0;
  rows = 15;

  nifEmissor: string;
  nifConsumidor: string;
  numDocumento: string;
  numSerieDocumento: string;
  dtEmissaoDocumentoDe: Date;
  dtEmissaoDocumentoAte: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentTempService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGeradoTemp`;
  }

  read(filter: DocumentTempFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <DocumentTempView[]>response.content,
          total: response.totalElements
        }
      });
  }

  readByFile(fileId: number, filter: DocumentTempFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.API_URL}/ficheiro/${fileId}`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <DocumentTempView[]>response.content,
          total: response.totalElements
        }
      });
  }

  get(id): Promise<DocumentTempView> {
    return this.http.get<DocumentTempView>(`${this.API_URL}/geral/${id}`)
      .toPromise()
      .then(response => {
        const document = response as DocumentTempView;
        this.convertFields([document]);
        return document;
      })
  }

  private convertFields(documents: DocumentTempView[]) {
    for (const document of documents) {
      document.dtAlter = moment(document.dtAlter,
        'YYYY-MM-DD').toDate();

      document.dtCria = moment(document.dtCria,
        'YYYY-MM-DD').toDate();

      if (document.dtEmissaoDocumento) {
        document.dtEmissaoDocumento = moment(document.dtEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
    }
  }
}

<div class="p-grid">
  <div class="title text-center">
    <h2 class="h2-responsive">Solicitar Nova Senha</h2>
  </div>

  <mdb-card class="card-login">
    <mdb-card-body>
      <form autocomplete="off" #form="ngForm" (ngSubmit)="request(form)">
        <div class="p-fluid p-formgrid p-grid">

          <div class="p-field p-col-12">
            <label>NIF</label>
            <div class="form-line">
              <input pInputText class="form-control" name="nifUsuario" placeholder="Seu NIF"
                [(ngModel)]="user.nifUsuario" required>
            </div>
          </div>

          <div class="p-field p-col-12">
            <label>E-mail</label>
            <input pInputText type="email" class="form-control" name="emailUsuario" placeholder="Seu e-mail" autofocus
              [(ngModel)]="user.emailUsuario" required>
          </div>

          <div class="p-col-12">
            <button type="submit" mdbBtn color="pink" block="true" class="my-4" size="sm"
              [disabled]="!form.valid || loading">
              SOLICITAR
            </button>
          </div>

          <div class="p-col-12 text-center">
            <a routerLink="/login">Voltar</a>
          </div>

          <div class="p-col-12">
            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
          </div>
        </div>

      </form>
    </mdb-card-body>
  </mdb-card>
</div>

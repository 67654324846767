<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/certification/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CERTIFICAÇÃO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body">
        <p-table
          styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
          [value]="certifications"
          [loading]="loading"
          [paginator]="true"
          [rows]="filtro.rows"
          [lazy]="true"
          [totalRecords]="totalRecords"
          (onLazyLoad)="onChangePage($event)"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>NIF</th>
              <th [style]="{ width: '50%' }">Tipo de Certificação</th>
              <th>Data do Pedido</th>
              <th>Nº de certificação</th>
              <th>Estado</th>
              <th [style]="{ width: '3em' }"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">NIF</span>
                {{ rowData.nifContribuinte }}
              </td>

              <td>
                <span class="p-column-title">Tipo de Certificação</span>
                {{ rowData.descTipoCertificacao }}
              </td>

              <td>
                <span class="p-column-title">Data de Certificação</span>
                {{ rowData.dtCriaPedidoCertificacao | date : "dd/MM/yyyy" }}
              </td>

              <td>
                <span class="p-column-title">Nº de Certificação</span>
                {{ rowData.numeroCertificacao }}
              </td>

              <td>
                <span class="p-column-title">Estado</span>
                {{
                  rowData.estadoPedidoCertificacao
                    ? "Certificado"
                    : "Não certificado"
                }}
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <button
                  type="button"
                  mdbBtn
                  color="info"
                  size="sm"
                  pTooltip="Ver mais informaões"
                  tooltipPosition="left"
                  class="btn-block"
                  [style]="{ padding: '4px 0' }"
                  [routerLink]="[
                    '/emitter/certification/read',
                    rowData.idPedidoCertificacao
                  ]"
                >
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

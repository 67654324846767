<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/adquirente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADQUIRENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/consumer/document-consumer/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS REGISTADOS PELO ADQUIRENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-d-flex justify-content-end">
              <p-tag [severity]="getDocumentStatus()" [value]="getDocumentStatusLabel()"></p-tag>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>NIF do Emitente</label>
              <input pInputText type="text" name="nifEmissor" [(ngModel)]="document.nifEmissor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label>Nome do Emitente</label>
              <input pInputText type="text" name="nomeEmissor" [(ngModel)]="document.nomeEmissor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Número da Documento</label>
              <input pInputText type="text" name="numDocumento" [(ngModel)]="document.numDocumento" disabled>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Emissão</label>
              <p-calendar name="dtEmissaoDocumento" [readonlyInput]="true" dateFormat="dd/mm/yy"
                [(ngModel)]="document.dtEmissaoDocumento" disabled></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Registo</label>
              <p-calendar name="dtCria" [readonlyInput]="true" dateFormat="dd/mm/yy"
                [(ngModel)]="document.dtCria" disabled></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Alteração</label>
              <p-calendar name="dtAlter" [readonlyInput]="true" dateFormat="dd/mm/yy"
                [(ngModel)]="document.dtAlter" disabled></p-calendar>
            </div>

            <div class="p-field p p-col-12 p-md-4">
              <label>Montante</label>
              <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2"
                name="totalValorBaseTributavel" [(ngModel)]="document.totalValorBaseTributavel" disabled>
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>IVA</label>
              <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2" name="totalImposto"
              [(ngModel)]="document.totalImposto" disabled>
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Valor Total</label>
              <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2" name="totalValor"
              [(ngModel)]="document.totalValor" disabled>
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12">
              <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="files" [responsive]="true" [lazy]="true" >
                <ng-template pTemplate="header">
                  <tr>
                    <th [style]="{width: '90%'}">Ficheiros</th>
                    <th [style]="{width: '3em'}"></th>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>
                      <span class="p-column-title">Ficheiro</span>
                      <span>{{ rowData.nomeArquivoDocumentoGerado }}</span>
                    </td>

                    <td [style]="{padding: '1px 4px'}">
                      <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left"
                        pTooltip="Transferir o ficheiro" class="btn-block" [style]="{padding: '4px'}"
                        (click)="downloadFile(rowData)">
                        <i class="fa fa-download"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div class="p-col-12 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/consumer/document-consumer/read">
                VOLTAR
              </button>
            </div>

          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

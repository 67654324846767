<div class="row clearfix">
  <mdb-card class="card-login">
    <mdb-card-body>
      <blockquote class="blockquote bq-success">
        <div class="col-md-12 text-center">
          <mdb-icon class="icon-message-lg text-success" fas icon="check-circle"></mdb-icon>
        </div>

        <p class="bq-title text-center">Utilizador cadastrado com sucesso</p>

        <div>
          <div class="block-note alert alert-light" role="alert">
            <p>
              Havendo necessidade de confirmação de que é de facto o titular do cartão de contribuinte utilizado para o
              registo desta conta de utilizador, deverá dirigir-se a Direcção dos Impostos para a efetivação dos mesmo.
            </p>

            <p>
              O procedimento para a confirmação do registo será o seguinte:
            </p>

            <ul>
              <li>
                <p class="subtitle">
                  <strong>
                    Pessoas individuais com NIF da gama 109, 114 ou 135
                  </strong>
                </p>

                <p>
                  Deverão dirigir-se ao guiché do atendimento da Direcção dos Impostos, acompanhados do seu NIF e do seu
                  documento de identificação (BI ou Passaporte);
                </p>
              </li>

              <li>
                <p class="subtitle">
                  <strong>
                    Todas as outras gamas do NIF:
                  </strong>
                </p>

                <p>
                  A pessoa indigitada pela entidade deverá dirigir-se ao guiché do atendimento da Direcção dos Impostos
                  fazendo-se acompanhar de uma declaração emitida por aquela entidade, devidamente assinada e carimbada,
                  outorgando autorização à pessoa, devidamente identificada no documento, dando os poderes para junto à
                  Direcção solicitar a confirmação da conta.
                </p>

                <p>
                  A pessoa deverá fazer-se acompanhar do NIF da empresa em causa, do seu NIF pessoal e de um documento
                  de identificação (BI ou Passaporte).
                </p>
              </li>
            </ul>

            <p>
              <strong>Nota:</strong>
              O termo “gama” corresponde aos três primeiros algarismos que compõem o NIF.
            </p>
          </div>
        </div>

        <hr>

        <footer class="blockquote-footer mb-3 text-center">
          <a routerLink="/login">
            Voltar ao seu perfil
          </a>
        </footer>
      </blockquote>
    </mdb-card-body>
  </mdb-card>
</div>

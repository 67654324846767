import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { encrypt } from '../../configs/encryption';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { DocumentEmitterFilter, DocumentEmitterService } from './../document-emitter.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  loading = true;
  documents = [];
  total = 0;
  filter = new DocumentEmitterFilter();

  constructor(
    private service: DocumentEmitterService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.documents = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  encrypt(info: string) {
    return encrypt(info)
  }
}

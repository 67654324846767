import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder } from '@angular/forms';
import { Mathematic } from 'src/app/configs/models/math';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import {DocumentConsumerRegistration, SimpleDocumentConsumerRegistrationForm, SimpleDocumentItemConsumerRegistrationForm } from 'src/app/core/models/document-consumer-registration';
import { ExemptionReason } from 'src/app/core/models/exemption-reason';
import { TaxRate } from 'src/app/core/models/tax-rate';
import { ExemptionReasonService } from 'src/app/exemption-reason/exemption-reason.service';
import { buildCurrentDate } from 'src/app/shared/date';
import { TaxRateService } from 'src/app/tax-rate/tax-rate.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {

  @Input() showModal = true;
  @Output() onClose = new EventEmitter<void>();
  @Output() onAccept = new EventEmitter<DocumentConsumerRegistration>();
  @Output() onRefuse = new EventEmitter<DocumentConsumerRegistration>();
  displayExemption = false;
  @Input() documentForm =  new SimpleDocumentConsumerRegistrationForm(this.fb).builder();
  @Input() canValidate = false;
  
  exemptionReasons: ExemptionReason[];
  currentDocumentDate = buildCurrentDate(new Date());
  
  documentItemForm = new SimpleDocumentItemConsumerRegistrationForm(this.fb);

  constructor(
    private fb: UntypedFormBuilder,
    private exemptionReasonService: ExemptionReasonService,
    private taxRateService: TaxRateService,
    private errorHandler: ErrorHandlerService,
  ) { }

   ngAfterViewInit(): void {
    this.getTaxRates();
   }

  toggleModal() {
    this.onClose.emit();
  }

  refuseDocument() {
    this.onRefuse.emit(this.documentForm.getRawValue());
  }
  
  acceptDocument() {
    this.onAccept.emit(this.documentForm.getRawValue());
  }

  showDisplayExemption(index) {
    this.itemIndex = index;
    this.displayExemption = true;
  }

  onExemptionSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbMotivoIsencao: {
        idMotivoIsencao: event.data.idMotivoIsencao,
        codigoMotivoIsencao: event.data.codigoMotivoIsencao,
        desMotivoIsencao: event.data.desMotivoIsencao,
      },
    });
    this.displayExemption = false;
  }

  // ITEMS FORM
  itemIndex: number;
  displayTaxRate: boolean = false;
  taxRates: TaxRate[];
  selectedTaxRate: TaxRate;
  selectedExemptionReason: ExemptionReason;
  iscTax = false;

  get items(): FormArray {
    return this.documentForm.get('tbItensDocumentoGerados') as FormArray;
  }

  async getTaxRates() {
    const response = await this.taxRateService.readActives(this.currentDocumentDate);
    this.taxRates = response;
  }

  onTaxRateSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbTaxaAplicavel: {
        idTaxaAplicavel: event.data.idTaxaAplicavel,
        descTaxaAplicavel: event.data.descTaxaAplicavel,
        valorTaxaAplicavel: event.data.valorTaxaAplicavel,
      },
    });
    this.clearTaxesRegimeCode();
    this.getExemptionReasons(event.data);
    this.calcItemTax(this.itemIndex);
    this.displayTaxRate = false;
  }

  clearTaxesRegimeCode(): void {
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  calcItemTax(index: number) {
    const itemAmount = this.items
      .at(index)
      .get('valorBaseTributavelItens').value;
    const itemTaxRate = this.items.at(index).get('tbTaxaAplicavel')
      .value.valorTaxaAplicavel;

    var m = new Mathematic();
    this.items.at(index).patchValue({
      valorImposto: m.round((itemAmount * itemTaxRate) / 100, 2),
    });

    const itemTax = this.items.at(index).get('valorImposto').value;

    this.items.at(index).patchValue({
      valorItens: m.round(itemAmount + itemTax, 2),
    });

    this.calcTax();
  }

  async getExemptionReasons(taxRate: TaxRate) {
    var isento = taxRate.valorTaxaAplicavel === 0 ? true : false;

    const dataFilter = {
      taxId: taxRate.idTaxaAplicavel,
      documentTypeSigla: 'FT',
    };

    try {
      const response =
        await this.exemptionReasonService.getByTaxIdAndDocumentTypeSigla(
          dataFilter
        );
      if (this.iscTax) {
        this.exemptionReasons = response;
      } else {
        this.exemptionReasons = response.filter(
          (e) => e.isento === isento && e.tbTipoImposto.idTipoImposto === 1
        );
      }
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  calcTax() {
    var m = new Mathematic();
    var amount = 0;
    var tax = 0;
    var total = 0;

    for (var i = 0; i < this.items.length; i++) {
      amount += this.items.at(i).get('valorBaseTributavelItens').value;
      tax += this.items.at(i).get('valorImposto').value;
      total += this.items.at(i).get('valorItens').value;
    }
  }

  showTaxRate(index) {
    this.itemIndex = index;
    this.displayTaxRate = true;
  }

  addItem() {
    this.items.push(this.documentItemForm.builder());
  }

  removeItem(i: number) {
    if (this.items.controls.length > 1) {
      this.items.removeAt(i);
      this.calcTax();
    }
  }
}

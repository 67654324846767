<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SISTEMA DE FACTURAÇÃO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/document-online/create">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-plus-circle pink-text"></i></div>
        <h4 class="title"> Nova Factura </h4>
        <p class="description">
          Permite os agentes económicos emitirem as suas facturas e documentos equivalentes os seus clientes à
          partir deste portal.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/document-online/read">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-search text-info"></i></div>
        <h4 class="title"> Verificar
          Facturas </h4>
        <p class="description">
          Permite aos agentes económicos consultarem as facturas e documentos equivalentes que
          emitiu neste portal.
        </p>
      </div>
    </a>
  </div>

</div>

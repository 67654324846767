import { LazyLoadEvent } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { StatisticFilter, StatisticService } from '../statistic.service';

@Component({
  selector: 'app-statistic-ranking',
  templateUrl: './statistic-ranking.component.html',
  styleUrls: ['./statistic-ranking.component.scss']
})
export class StatisticRankingComponent implements OnInit {
  loading: boolean = false;
  ranking = [];
  filter = new StatisticFilter();
  total = 0;

  now = new Date();
  startDate = new Date(this.now.getFullYear(), 0, 1);
  endDate = new Date(this.now.getFullYear(), 11, 31);

  constructor(
    private statisticService: StatisticService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.read();
  }

  read(page: number = 0) {
    this.loading = true;
    this.filter.page = page;
    this.filter.period = [this.startDate, this.endDate];

    this.statisticService.ranking(this.filter)
      .then((result) => {
        this.total = result.totalElements;
        this.ranking = result.content;
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  arrowLeft() {
    this.prevDate();
    this.read();
  }

  arrowRight() {
    this.nextDate();
    this.read();
  }

  prevDate(): void {
    this.now = new Date(this.now.getFullYear() - 1, 11);

    this.startDate = new Date(this.now.getFullYear(), 0, 1);
    this.endDate = new Date(this.now.getFullYear(), 11, 31);
  }

  nextDate(): void {
    this.now = new Date(this.now.getFullYear() + 1, 0);

    this.startDate = new Date(this.now.getFullYear(), 0, 1);
    this.endDate = new Date(this.now.getFullYear(), 11, 31);
  }
}

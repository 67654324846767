import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { DocumentConsumer } from '../core/models/document-consumer';
import { AppHttp } from './../auth/app-http';
import { DocumentConsumerRegistration } from '../core/models/document-consumer-registration';

export class DocumentConsumerRegFilter {
  page = 0;
  rows = 15;

  nifEmissor: string;
  nomeEmissor: string;
  nifConsumidor: string;
  nomeConsumidor: string;
  numDocumento: string;
  numSerieDocumento: string;
  dtEmissaoDocumentoDe: Date;
  dtEmissaoDocumentoAte: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentConsumerRegService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGeradoConsumidor`;
  }

  create(document: DocumentConsumer): Promise<DocumentConsumer> {
    return this.http.post<DocumentConsumer>(`${this.API_URL}`, document)
      .toPromise();
  }

  read(filter: DocumentConsumerRegFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    if (filter.nomeEmissor)
      params = params.append('nomeEmissor', filter.nomeEmissor);

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.nomeConsumidor)
      params = params.append('nomeConsumidor', filter.nomeConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise()
      .then((response) => {
        return {
          body: <Document[]>response.content,
          total: response.totalElements
        }
      })
  }

  get(id): Promise<DocumentConsumerRegistration> {
    return this.http.get<DocumentConsumerRegistration>(`${this.API_URL}/${id}`)
      .toPromise()
      .then((response) => {
        const documentView = response as DocumentConsumerRegistration;
        this.convertFields([documentView]);
        return documentView;
      });
  }

  getFile(id): Promise<any> {
    return this.http.get<any>(`${this.API_URL}/arquivo/${id}`)
      .toPromise()
      .then((response) => {
        return response;
      });
  }

  verify(document: DocumentConsumerRegistration): Promise<any> {
    return this.http.get<any>(`${this.API_URL}/verificar?numero=${document.numero}&serie=${document.serie}&idDocumentoGeradoConsumidor=${document.idDocumentoGeradoConsumidor}`)
      .toPromise();
  }

  validate(document: DocumentConsumerRegistration, status: boolean): Promise<any> {
    const validationContent: any = {
      idDocumentoGeradoConsumidor: document.idDocumentoGeradoConsumidor,
      desDocumentoGeradoConsumidor: document.desDocumentoGeradoConsumidor,
      status
    };

    if (status) {
      validationContent.cdMotivoIsencao = document.tbMotivoIsencao.codigoMotivoIsencao;
    }

    return this.http.put<any>(`${this.API_URL}/validar`, validationContent)
      .toPromise();
  }

  private convertFields(documentsView: DocumentConsumerRegistration[]) {
    documentsView.forEach(documentView => {
      if (documentView.dtCria) {
        documentView.dtCria = moment(documentView.dtCria, 'YYYY-MM-DD').toDate();
      }

      if (documentView.dtAlter) {
        documentView.dtAlter = moment(documentView.dtAlter, 'YYYY-MM-DD').toDate();
      }

      if (documentView.dtEmissaoDocumento) {
        documentView.dtEmissaoDocumento = new Date(documentView.dtEmissaoDocumento);
      }
    });
  }
}

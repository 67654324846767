import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CountToModule } from 'angular-count-to';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { LayoutModule } from './../layout/layout.module';
import { StatisticDetailShortComponent } from './statistic-detail-short/statistic-detail-short.component';
import { StatisticRankingShortComponent } from './statistic-ranking-short/statistic-ranking-short.component';
import { StatisticRankingComponent } from './statistic-ranking/statistic-ranking.component';
import { StatisticReadMonthComponent } from './statistic-read-month/statistic-read-month.component';
import { StatisticReadYearComponent } from './statistic-read-year/statistic-read-year.component';
import { StatisticRouter } from './statistic-router';
@NgModule({
  declarations: [
    StatisticReadMonthComponent,
    StatisticReadYearComponent,
    StatisticDetailShortComponent,
    StatisticRankingComponent,
    StatisticRankingShortComponent,
  ],
  imports: [
    CommonModule,
    StatisticRouter,
    FormsModule,
    LayoutModule,
    MDBBootstrapModule,
    CountToModule,
    AccordionModule,
    CalendarModule,
    TableModule,
    ButtonModule
  ],
  exports: [
    StatisticReadMonthComponent,
    StatisticReadYearComponent,
    StatisticRankingComponent,
    StatisticRankingShortComponent
  ]
})
export class StatisticModule { }

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';

@Injectable({
  providedIn: 'root',
})
export class TypeTaxService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/tipoImposto`;
  }

  read(): Promise<any> {
    return this.http.get<any>(this.API_URL).toPromise();
  }

  readWithTaxRate(): Promise<any> {
    return this.http.get(`${this.API_URL}/comTaxasAplicavel`).toPromise();
  }

  dropdown() {
    return this.readWithTaxRate().then((response) =>
      response.map((item) => ({
        label: item.siglaTipoImposto,
        items: item.tbTaxasAplicavel.map((t) => ({
          label: t.descTaxaAplicavel,
          value: t.idTaxaAplicavel,
        })),
      }))
    );
  }

  dropdownCreate() {
    return this.readWithTaxRate().then((response) =>
      response.map((item) => ({
        label: item.siglaTipoImposto,
        items: item.tbTaxasAplicavel.map((t) => ({
          label: t.descTaxaAplicavel,
          value: {
            idTaxaAplicavel: t.idTaxaAplicavel,
            descTaxaAplicavel: t.descTaxaAplicavel,
            valorTaxaAplicavel: t.valorTaxaAplicavel,
          },
        })),
      }))
    );
  }
}

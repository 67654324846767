import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../core/error-handler.service';
import { TaxpayerFilter } from '../taxpayer/taxpayer.service';
import { TypeTaxService } from '../type-tax/type-tax.service';
import { TaxRateService } from './tax-rate.service';

@Component({
  selector: 'app-tax-rate',
  templateUrl: './tax-rate.component.html',
  styleUrls: ['./tax-rate.component.scss'],
})
export class TaxRateComponent implements OnInit {
  filter = new TaxpayerFilter();
  visibleSideBarFilter = false;
  loading = false;
  taxRates = [];
  taxTypes = [];
  totalRecords = 0;

  constructor(
    private taxRateService: TaxRateService,
    private typeTaxService: TypeTaxService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.read();
    this.dropdownTaxTypes();
  }

  dropdownTaxTypes() {
    this.typeTaxService.read().then((response) => {
      this.taxTypes = response.map((item) => ({
        label: `${item.descricaoTipoImposto} (${item.siglaTipoImposto})`,
        value: item.idTipoImposto,
      }));
    });
  }

  read() {
    this.loading = true;
    this.taxRateService
      .read()
      .then((response) => {
        this.taxRates = response;
        this.totalRecords = this.taxRates.length;
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => (this.loading = false));
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { ControlDocument, ControlDocumentMonth } from './../core/models/control-document';

export class ControlDocumentFilter {
  page = 0;
  rows = 15;

  emitterNif: string;
  serieNumber: string;
  period: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ControlDocumentService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/controloComunicacao`;
  }

  async read(filter: ControlDocumentFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif)
      params = params.append('nifContribuinte', filter.emitterNif);

    if (filter.serieNumber)
      params = params.append('idAutorizacaoEmissao', filter.serieNumber);

    if (filter.period)
      params = params.append('periodo', moment(filter.period).format('YYYY-MM-DD'));

    const response = await this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise();
    return {
      body: <ControlDocument[]>response.content,
      total: response.totalElements
    };
  }

  async create(controlDocument: ControlDocument): Promise<ControlDocument> {
    delete controlDocument.dtCria;
    delete controlDocument.dtAlter;
    delete controlDocument.criaNifUsuario;
    delete controlDocument.alterNifUsuario;

    const response = await this.http.post<ControlDocument>(this.API_URL, controlDocument)
      .toPromise();
    const controlDocument_1 = response as ControlDocument;
    this.convertFields([controlDocument_1]);
    return controlDocument_1;
  }

  async createMany(controlDocument: ControlDocumentMonth): Promise<ControlDocumentMonth> {
    const response = await this.http.post<ControlDocumentMonth>(this.API_URL, controlDocument)
      .toPromise();
    const controlDocument_1 = response as ControlDocumentMonth;
    this.convertFieldsMonths([controlDocument_1]);
    return controlDocument_1;
  }

  private convertFieldsMonths(documents: ControlDocumentMonth[]) {
    for (const document of documents) {
      if (document.dataCertificacao) {
        document.dataCertificacao = moment(
          document.dataCertificacao, 'YYYY-MM-DD'
        ).toDate();
      }

      if (document.jan) {
        if (document.qunt1 > 0) {
          document.jan = true;
        } else document.jan = false;
      } else document.jan = null;

      if (document.feb) {
        if (document.qunt2 > 0) {
          document.feb = true;
        } else document.feb = false;
      } else document.feb = null;

      if (document.mar) {
        if (document.qunt3 > 0) {
          document.mar = true;
        } else document.mar = false;
      } else document.mar = null;

      if (document.apr) {
        if (document.qunt4 > 0) {
          document.apr = true;
        } else document.apr = false;
      } else document.apr = null;

      if (document.may) {
        if (document.qunt5 > 0) {
          document.may = true;
        } else document.may = false;
      } else document.may = null;

      if (document.june) {
        if (document.qunt6 > 0) {
          document.june = true;
        } else document.june = false;
      } else document.june = null;

      if (document.july) {
        if (document.qunt7 > 0) {
          document.july = true;
        } else document.july = false;
      } else document.july = null;

      if (document.aug) {
        if (document.qunt8 > 0) {
          document.aug = true;
        } else document.aug = false;
      } else document.aug = null;

      if (document.sept) {
        if (document.qunt9 > 0) {
          document.sept = true;
        } else document.sept = false;
      } else document.sept = null;

      if (document.oct) {
        if (document.qunt10 > 0) {
          document.oct = true;
        } else document.oct = false;
      } else document.oct = null;

      if (document.nov) {
        if (document.qunt11 > 0) {
          document.nov = true;
        } else document.nov = false;
      } else document.nov = null;

      if (document.dec) {
        if (document.qunt12 > 0) {
          document.dec = true;
        } else document.dec = false;
      } else document.dec = null;
    }
  }

  private convertFields(documents: ControlDocument[]) {
    for (const document of documents) {
      if (document.dtAlter) {
        document.dtAlter = moment(document.dtAlter,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtCria) {
        document.dtCria = moment(document.dtCria,
          'YYYY-MM-DD').toDate();
      }

      if (document.periodo) {
        document.periodo = moment(document.periodo,
          'YYYY-MM-DD').toDate();
      }
    }
  }
}

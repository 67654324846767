import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { GetFileDocumentDetailComponent } from './get-file-document-detail/get-file-document-detail.component';
import { ReadFileDocumentDetailComponent } from './read-file-document-detail/read-file-document-detail.component';

@NgModule({
  declarations: [
    ReadFileDocumentDetailComponent,
    GetFileDocumentDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    FormsModule,
    MDBBootstrapModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    FileUploadModule,
    DialogModule,
    OverlayPanelModule,
    InputSwitchModule,
    TabViewModule
  ],
  exports: [
    GetFileDocumentDetailComponent,
    ReadFileDocumentDetailComponent
  ]
})
export class FileDocumentDetailModule { }

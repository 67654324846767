<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PEDIDO DE SÉRIES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/emission/create">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-medical red-text"></i></div>
        <h4 class="title"> Para Softwares Autónomos </h4>
        <p class="description">
          Na área do adquirente ou consumidor poderá verificar e registar as suas facturas e consultar
          alertas pendentes, bem como aceder às outras funcionalidades.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/emission/create-portal">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-medical blue-text"></i></div>
        <h4 class="title"> Para o Portal e-Factura </h4>
        <p class="description">
          Na área do emitente poderá consultar facturas, enviar facturas emitidas através do cadastro ou
          pelo carregamento de arquivo e verificar alertas pendentes.
        </p>
      </div>
    </a>
  </div>
</div>

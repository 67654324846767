import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { FileDocumentDetail } from '../core/models/file-document-detail';

export class FileDocumentDetailFilter {
  page = 0;
  rows = 15;

  fileName: string;
  emitterNif: string;
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class FileDocumentDetailService {
  API_URL_DETAIL: string;

  constructor(private http: AppHttp,) {
    this.API_URL_DETAIL = `${environment.apiUrl}/arquivoDocumentoGeradoDetalhe`;
  }

  read(filter: FileDocumentDetailFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif.toString());
    }

    if (filter.fileName) {
      params = params.append('nomeArquivo', filter.fileName.toString());
    }

    if (filter.startDate) {
      params = params.append('dtPeriodoFaturacaoDe', moment(filter.startDate).format('YYYY-MM-DD'));
    }

    if (filter.endDate) {
      params = params.append('dtPeriodoFaturacaoAte', moment(filter.endDate).format('YYYY-MM-DD'));
    }

    return this.http.get<any>(`${this.API_URL_DETAIL}`, { params })
      .toPromise()
      .then(response => {
        return {
          body: response.content,
          total: response.totalElements
        };
      });
  }

  getByFileDocument(id): Promise<any> {
    return this.http.get<any>(`${this.API_URL_DETAIL}/ficheiro/${id}`)
      .toPromise()
      .then(response => {
        const fileDocumentDetail = response as FileDocumentDetail;
        this.convertFields([fileDocumentDetail]);
        return fileDocumentDetail;
      })
  }

  private convertFields(fileDocumentDetails: FileDocumentDetail[]) {
    for (const fileDocumentDetail of fileDocumentDetails) {
      fileDocumentDetail.amountDocumentsFound =
        fileDocumentDetail.quantidadeFaturaAntes +
        fileDocumentDetail.quantidadeNotaCreditoAntes +
        fileDocumentDetail.quantidadeNotaDebitoAntes;

      fileDocumentDetail.amountDocuments =
        fileDocumentDetail.quantidadeFaturaDepois +
        fileDocumentDetail.quantidadeNotaCreditoDepois +
        fileDocumentDetail.quantidadeNotaDebitoDepois;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { EmissionDocumentTypographyFilter, EmissionDocumentTypographyService } from '../emission-document-typography.service';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { EmissionDocumentView } from './../../core/models/emission-document';
import { EmissionDocumentService } from './../../emission-document/emission-document.service';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { EmissionDocumentSaved } from './../increment/increment.component';

@Component({
  selector: 'app-change-emitter',
  templateUrl: './change-emitter.component.html',
  styleUrls: ['./change-emitter.component.scss']
})
export class ChangeEmitterComponent implements OnInit {
  loading: boolean = false;

  newEmitterNif: string;
  newEmitterName: string;
  validNif: boolean = true;

  emissionDocument = new EmissionDocumentView();
  filter = new EmissionDocumentTypographyFilter();
  total = 0;
  edSaved = new EmissionDocumentSaved();

  constructor(
    private service: EmissionDocumentTypographyService,
    private emissionDocumentService: EmissionDocumentService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private taxpayerService: TaxpayerService
  ) { }

  ngOnInit(): void {
  }

  onValidNif(event) {
    const nif = event.target.value;
    this.taxpayerService.validNif(nif)
      .then(result => {
        const { isValid, name } = result;
        this.validNif = isValid;
        this.newEmitterName = name;
      })
  }

  getSerieDetail() {
    this.service.readIncrement(this.filter)
      .then(response => {
        if (response.body.length) {
          const [emissionDocument] = response.body;
          this.emissionDocument = emissionDocument;
        } else {
          this.messageService.add({
            severity: 'error',
            detail: 'Serie inválida ou não foi adquirida numa tipografia.',
          })
        }
      })
      .catch(error => this.errorHandler.handle(error))
  }

  fixEmitter(form: NgForm): void {
    this.loading = true;

    const data = {
      emitterNif: this.newEmitterNif,
      emissionDocumentId: this.emissionDocument.idPedidoEmissaoDocumento
    }

    this.emissionDocumentService.changeEmitter(data)
      .then(() => {
        form.reset();
        this.messageService.add({
          severity: 'success',
          detail: 'Informação atualizada com sucesso!'
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }
}

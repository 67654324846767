import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { FileDocument } from '../core/models/file-document';

export class FileDocumentEmitterFilter {
  page = 0;
  rows = 15;
  fileName: string;

  createdAt: Date[];
  period: Date[];
}

@Injectable({
  providedIn: 'root',
})
export class FileDocumentEmitterService {
  API_URL: string;

  constructor(
    private http: AppHttp,
    private httpClient: HttpClient,
    private auth: AuthService
  ) {
    this.API_URL = `${environment.apiUrl}/arquivoDocumentoGerado`;
  }

  async read(filter: FileDocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.fileName) {
      params = params.append(
        'nomeArquivoDocumentoGerado',
        filter.fileName.toString()
      );
    }

    if (filter.createdAt) {
      if (filter.createdAt[0])
        params = params.append(
          'dtCriaDe',
          moment(filter.createdAt[0]).format('YYYY-MM-DD')
        );
      if (filter.createdAt[1])
        params = params.append(
          'dtCriaAte',
          moment(filter.createdAt[1]).format('YYYY-MM-DD')
        );
    }

    if (filter.period) {
      if (!!filter.period[0]) {
        filter.period[0].setDate(5);
        params = params.append(
          'dtPeriodoFaturacaoDe',
          moment(filter.period[0]).format('YYYY-MM-DD')
        );
      }
      if (!!filter.period[1]) {
        filter.period[1].setDate(5);
        params = params.append(
          'dtPeriodoFaturacaoAte',
          moment(filter.period[1]).format('YYYY-MM-DD')
        );
      }
    }

    const response = await this.http
      .get<any>(`${this.API_URL}/porEmissor`, { params })
      .toPromise();
    this.convertFields(response.content);
    return {
      body: <FileDocument[]>response.content,
      total: response.totalElements,
    };
  }

  async get(id: number): Promise<FileDocument> {
    const response = await this.http
      .get<FileDocument>(`${this.API_URL}/${id}`)
      .toPromise();
    const fileDocument = response as FileDocument;
    this.convertFields([fileDocument]);
    return fileDocument;
  }

  async pesquisarPorEmissor(): Promise<any> {
    const response = await this.http
      .get<any>(`${this.API_URL}/porEmissor`)
      .toPromise();
    this.convertFields(response.content);
    return {
      content: response.content,
    };
  }

  async updatePeriod(fileDocument: FileDocument) {
    const response = await this.http
      .put<any>(
        `${this.API_URL}/periodoFaturacao/${fileDocument.idArquivoDocumentoGerado}`,
        { dtPeriodoFaturacao: fileDocument.dtPeriodoFaturacao }
      )
      .toPromise();
    const fileDocumentEdited = response as FileDocument;
    this.convertFields([fileDocumentEdited]);
    return {
      fileDocumentEdited,
    };
  }

  async enviarFicheiro(
    formData: any,
    { emitterNif, dtPeriodoFaturacao: period }
  ) {
    let params = new HttpParams();
    params = params.append(
      'dtPeriodoFaturacao',
      moment(period).format('YYYY-MM-DD')
    );

    // params = params.append('idContribuinte', this.auth.jwtPayload.idCont);

    if (emitterNif) params = params.append('nifEmitente', emitterNif);

    const response = await this.httpClient
      .post<any>(`${this.API_URL}/uploadFiles`, formData, { params })
      .toPromise();
    return response;
  }

  async enviarFicheiroTerceiro(formData: any, { emitterNif, period }) {
    let params = new HttpParams();
    params = params.append(
      'dtPeriodoFaturacao',
      moment(period).format('YYYY-MM-DD')
    );

    // params = params.append('idContribuinte', this.auth.jwtPayload.idCont);

    if (emitterNif) params = params.append('nifEmitente', emitterNif);

    const response = await this.httpClient
      .post<any>(`${this.API_URL}/terceiro/uploadFiles`, formData, { params })
      .toPromise();
    return response;
  }

  async fixFile(formData: any) {
    const response = await this.httpClient
      .post<any>(`${this.API_URL}/updateUploadFiles`, formData)
      .toPromise();
    return response;
  }

  async verificarFicheiro(formData: any, { dtPeriodoFaturacao: period }) {
    let params = new HttpParams();
    params = params.append(
      'dtPeriodoFaturacao',
      moment(period).format('YYYY-MM-DD')
    );

    const response = await this.httpClient
      .post<any>(`${this.API_URL}/validarFicheiro`, formData, { params })
      .toPromise();
    return response;
  }

  async verificarFicheiroTerceiro(formData: any, { emitterNif, period }) {
    let params = new HttpParams();
    params = params.append(
      'dtPeriodoFaturacao',
      moment(period).format('YYYY-MM-DD')
    );

    if (emitterNif) params = params.append('nifEmitente', emitterNif);

    const response = await this.httpClient
      .post<any>(`${this.API_URL}/terceiro/validarFicheiro`, formData, {
        params,
      })
      .toPromise();
    return response;
  }

  async pesquisarFixeiroCadastradosNoMomentoPorConsumidor(
    nifConsumidor: string
  ) {
    const response = await this.http
      .get<any>(`${this.API_URL}/porConsumidor`)
      .toPromise();
    return response.content;
  }

  async getFile(file: FileDocument) {
    let params = new HttpParams();
    params = params.append('fileName', file.nomeArquivoDocumentoGerado);

    return this.http
      .get<Blob>(`${this.API_URL}/download`, { params, responseType: 'blob' })
      .toPromise();
  }

  async getConsumerFile(file: FileDocument) {
    let params = new HttpParams();
    params = params.append('fileName', file.nomeArquivoDocumentoGerado);

    return this.http
      .get<Blob>(`${environment.apiUrl}/documentoGeradoConsumidor/arquivo/download`, { params, responseType: 'blob' })
      .toPromise();
  }

  async cancelFile(id: number): Promise<void> {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    await this.http
      .put<any>(`${this.API_URL}/${id}/status`, false, { headers })
      .toPromise();
    return null;
  }

  private convertFields(documents: FileDocument[]) {
    for (const document of documents) {
      if (document.dtPeriodoFaturacao) {
        document.dtPeriodoFaturacao = moment(
          document.dtPeriodoFaturacao,
          'YYYY-MM-DD'
        ).toDate();

        document.formattedPeriod = moment(document.dtPeriodoFaturacao).format(
          'MMMM/YYYY'
        );
      }

      if (document.dtCria)
        document.dtCria = moment(document.dtCria, 'YYYY-MM-DD').toDate();

      switch (document.statusAnaliseArquivo) {
        case 'POR_ANALISAR':
          document.fileAnalise = 'Por analisar';
          document.fileAnaliseColor = 'p-tag-secondary';
          break;
        case 'ANALISE_PRIMEIRA_FASE':
          document.fileAnalise = '1ª Fase';
          document.fileAnaliseColor = 'p-tag-info';
          break;
        case 'ANALISE_SEGUNDA_FASE':
          document.fileAnalise = '2ª Fase';
          document.fileAnaliseColor = 'p-tag-info';
          break;
        case 'ANALISE_TERCEIRA_FASE':
          document.fileAnalise = '3ª Fase';
          document.fileAnaliseColor = 'p-tag-info';
          break;
        case 'REJEITADO':
          document.fileAnalise = 'Rejeitado';
          document.fileAnaliseColor = 'p-tag-danger';
          break;
        case 'PROCESSANDO':
          document.fileAnalise = 'Processando';
          document.fileAnaliseColor = 'p-tag-primary';
          break;
        case 'CONCLUIDO':
          document.fileAnalise = 'Concluído';
          document.fileAnaliseColor = 'p-tag-success';
          break;
        case 'EM_ESPERA':
          document.fileAnalise = 'Em Espera';
          document.fileAnaliseColor = 'p-tag-warning';
          break;
        default:
          document.fileAnalise = 'Por analisar';
          document.fileAnaliseColor = 'p-tag-secondary';
          break;
      }

      if (document.statusAnaliseArquivo === 'REJEITADO') {
        if (
          document.analisePrim &&
          !document.analiseSegu &&
          !document.analiseTerc
        ) {
          document.step1 = false;
          document.step2 = null;
          document.step3 = null;
        } else if (
          document.analisePrim &&
          document.analiseSegu &&
          !document.analiseTerc
        ) {
          document.step1 = true;
          document.step2 = false;
          document.step3 = null;
        } else if (
          document.analisePrim &&
          document.analiseSegu &&
          document.analiseTerc
        ) {
          document.step1 = true;
          document.step2 = true;
          document.step3 = false;
        }
      } else if (document.statusAnaliseArquivo === 'ANALISE_PRIMEIRA_FASE') {
        document.step1 = true;
        document.step2 = null;
        document.step3 = null;
      } else if (document.statusAnaliseArquivo === 'EM_ESPERA') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      } else if (document.statusAnaliseArquivo === 'ANALISE_SEGUNDA_FASE') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = null;
      } else if (document.statusAnaliseArquivo === 'ANALISE_TERCEIRA_FASE') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      } else if (document.statusAnaliseArquivo === 'PROCESSANDO') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      } else if (document.statusAnaliseArquivo === 'CONCLUIDO') {
        document.step1 = true;
        document.step2 = true;
        document.step3 = true;
      }

      document.status
        ? (document.statusColor = 'text-success')
        : (document.statusColor = 'text-danger');
    }
  }
}

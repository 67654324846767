<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMINISTRADOR
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <p class="p-col-12">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <p-button
          label="Solicitação de anulação de ficheiro"
          icon="pi pi-inbox"
          styleClass="p-button-warning"
          [badge]="totalCancellationRequest"
          badgeClass="p-badge-danger"
          routerLink="/file-document-cancellation-request/read"
        ></p-button>
      </div>
    </p-toolbar>
  </p>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_CERTIFICACAO')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_DOCUMENTO_GERADO')
          ? '/user-admin/read'
          : '#'
      "
    >
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users gray-text"></i></div>
        <h4 class="title">Utilizadores</h4>
        <p class="description">
          Permite consultar pedidos de certificação de tipografias e softwares
          de facturação.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
          ? '/routines/fix-document-by-mask'
          : '#'
      "
    >
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-list-ol gray-text"></i></div>
        <h4 class="title">Rotinas</h4>
        <p class="description">Executa as rotinas do sistema manualmente</p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
          ? '/mail/send'
          : '#'
      "
    >
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-paper-plane gray-text"></i></div>
        <h4 class="title">Enviar E-mail</h4>
        <p class="description">Permite o envio de e-mail de forma manual</p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
          ? '/admin/file-document/read'
          : '#'
      "
    >
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file gray-text"></i></div>
        <h4 class="title">Gerir Ficheiros</h4>
        <p class="description">
          Permite gerir ficheiros comunicados pelos contribuintes.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
          ? '/document-temp/read'
          : '#'
      "
    >
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-alt gray-text"></i></div>
        <h4 class="title">Gerir Documentos Temporários</h4>
        <p class="description">
          Permite gerir os documentos temporários enviados via ficheiros.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
          ? '/report-routines-analysis-file/read'
          : '#'
      "
    >
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-clipboard-list gray-text"></i></div>
        <h4 class="title">Relatório das Rotinas de Análise de Ficheiro</h4>
        <p class="description">
          Permite acompanhar o processamento dos ficheiros.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a
      class="a-box"
      [class.disabled]="
        !auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
      "
      [routerLink]="
        auth.hasPermission('ROLE_PESQUISAR_GERAL_PEDIDO_EMISSAO_FATURA')
          ? '/database-manager/read'
          : '#'
      "
    >
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-database gray-text"></i></div>
        <h4 class="title">Gerir Base de Dados</h4>
        <p class="description">Permite gerir base de dados.</p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4 p-lg-4">
    <a class="a-box" routerLink="/admin/tax-rate">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-business-time gray-text"></i></div>
        <h4 class="title">Gerir Impostos e Taxas</h4>
        <p class="description">Permite gerir Impostos e Taxas.</p>
      </div>
    </a>
  </div>
</div>

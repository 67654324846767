import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { ReadForThirdComponent } from './read-for-third/read-for-third.component';
import { ReadComponent } from './read/read.component';
import { TransportSerieFastComponent } from './transport-serie-fast/transport-serie-fast.component';
import { TransportSerieManuallyComponent } from './transport-serie-manually/transport-serie-manually.component';

const routes: Routes = [
  {
    path: 'transport',
    component: TransportSerieManuallyComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },

  {
    path: 'transport/list',
    component: TransportSerieFastComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },

  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },

  {
    path: 'read-for-third-party',
    component: ReadForThirdComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmissionDocumentEmitterRouter { }

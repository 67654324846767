import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { GetComponent } from './get/get.component';
import { ReadComponent } from './read/read.component';

const routes: Routes = [
  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read/:id',
    component: GetComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class DocumentConsumerRegRouter { }

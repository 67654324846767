import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { FileDocumentCancellationRequest } from './../core/models/file-document-cancellation-request';

export class FileDocumentCancellationRequestFilter {
  page = 0;
  rows = 15;

  createdAt: Date[];
  answeredAt: Date[];

  nifApplicant: string;
  nifExecutor: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileDocumentCancellationRequestService {
  API_URL: string;

  constructor(
    private http: AppHttp,
  ) {
    this.API_URL = `${environment.apiUrl}/arquivoDocumentoGerado`;
  }

  async read(filter: FileDocumentCancellationRequestFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.createdAt) {
      if (filter.createdAt[0])
        params = params.append('dtCriaDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (filter.createdAt[1])
        params = params.append('dtCriaAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }

    if (filter.answeredAt) {
      if (filter.answeredAt[0])
        params = params.append('dataRespostaDe', moment(filter.answeredAt[0]).format('YYYY-MM-DD'));
      if (filter.answeredAt[1])
        params = params.append('dataRespostaAte', moment(filter.answeredAt[1]).format('YYYY-MM-DD'));
    }

    if (filter.nifApplicant) {
      params = params.append('nifSolicitante', filter.nifApplicant);
    }
    if (filter.nifExecutor) {
      params = params.append('nifExecutor', filter.nifExecutor);
    }
    if (filter.status) {
      params = params.append('tbSolicitacaoEstado', filter.status);
    }

    const response = await this.http.get<any>(`${this.API_URL}/listSolicitacao`, { params })
      .toPromise();

    this.convertFields(response.content);

    return {
      body: response.content,
      total: response.totalElements
    }
  }

  async readByEmitter(filter: FileDocumentCancellationRequestFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.createdAt) {
      if (filter.createdAt[0])
        params = params.append('dtCriaDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (filter.createdAt[1])
        params = params.append('dtCriaAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }
    if (filter.answeredAt) {
      if (filter.answeredAt[0])
        params = params.append('dataRespostaDe', moment(filter.answeredAt[0]).format('YYYY-MM-DD'));
      if (filter.answeredAt[1])
        params = params.append('dataRespostaAte', moment(filter.answeredAt[1]).format('YYYY-MM-DD'));
    }

    if (filter.nifApplicant) {
      params = params.append('nifSolicitante', filter.nifApplicant);
    }
    if (filter.status) {
      params = params.append('tbSolicitacaoEstado', filter.status);
    }

    const response = await this.http.get<any>(`${this.API_URL}/pesquisarSolicitacaoPorEmissor`, { params })
      .toPromise();

    this.convertFields(response.content);

    return {
      body: response.content,
      total: response.totalElements
    }
  }

  async create(request: FileDocumentCancellationRequest)
    : Promise<FileDocumentCancellationRequest> {
    delete request.statusColor;

    const response = await this.http.post(`${this.API_URL}/solicitarAnulacao`, request)
      .toPromise();

    const req = response as FileDocumentCancellationRequest;
    this.convertFields([req]);
    return req;
  }

  async delete(request: FileDocumentCancellationRequest) {
    delete request.status;
    delete request.statusColor;
    return this.http.post(`${this.API_URL}/deleteFileById`, request)
      .toPromise();
  }

  private convertFields(requests: FileDocumentCancellationRequest[]) {
    for (const request of requests) {
      if (request.dataResposta)
        request.dataResposta = moment(request.dataResposta, 'YYYY-MM-DD').toDate();

      if (request.dtCria)
        request.dtCria = moment(request.dtCria, 'YYYY-MM-DD').toDate();

      if (request.dtAlter)
        request.dtAlter = moment(request.dtAlter, 'YYYY-MM-DD').toDate();

      switch (request.tbSolicitacaoEstado) {
        case 'PENDENTE':
          request.status = 'Pendente';
          request.statusColor = 'p-tag-warning';
          break;
        case 'FECHADO':
          request.status = 'Fechado';
          request.statusColor = 'p-tag-success';
          break;
        case 'EM_ANALISE':
          request.status = 'Em Análise';
          request.statusColor = 'p-tag-default';
          break;
        default:
          request.status = 'Pendente';
          request.statusColor = 'p-tag-warning';
          break;
      }
    }
  }
}

<div *ngIf="loading; else elseBlock" class="text-center">
  <p-progressSpinner></p-progressSpinner>
</div>

<ng-template #elseBlock>
  <div *ngIf="!fileDocumentAnalysis.length" class="text-center p-pt-4">
    <h6>Nenhuma informação encontrada referente a análise do ficheiro.</h6>
  </div>

  <ul class="list-group">
    <li class="list-group-item list-group-item-action flex-column align-items-start"
      *ngFor="let item of fileDocumentAnalysis; index as i">
      <div class="d-flex w-100">
        <small class="text-muted"> {{item.dtRelatorioAnaliseFicheiro}} </small>
      </div>
      <div [innerHTML]="item.relatorioAnaliseFicheiro"> </div>
      <small class="text-muted">
        {{item.geradoNotificacao ? 'Notificação gerada' : 'Notificação não gerada'}}
      </small>
    </li>
  </ul>
</ng-template>

export class Person {
  idPessoa: number;
  btStatus: boolean;
  enderecoPessoa: string;
  identidade: string;
  nifPessoa: string;
  nomePessoa: string;
  sobreNomePessoa: string;
  telefonePessoa: string;

  dtAlterPessoa: Date;
  dtCriaPessoa: Date;
}

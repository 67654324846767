<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/certification/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CERTIFICAÇÃO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PARA SOFTWARE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form
          #certificacaoSoftwareForm="ngForm"
          [formGroup]="form"
          (ngSubmit)="adicionarCertificacaoSoftware()"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-8">
              <label>Nome do Software</label>
              <input
                pInputText
                type="text"
                name="nomeSoftware"
                formControlName="nomeSoftware"
              />
              <app-message
                [control]="form.get('nomeSoftware')"
                error="required"
                text="Nome do software é obrigatório"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Versão do Software</label>
              <input
                pInputText
                type="text"
                name="versaoSoftware"
                formControlName="versaoSoftware"
              />
              <app-message
                [control]="form.get('versaoSoftware')"
                error="required"
                text="A versão é obrigatória"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label>Fornecedor</label>
              <input
                pInputText
                name="fornecedorSoftware"
                formControlName="fornecedorSoftware"
              />
              <app-message
                [control]="form.get('fornecedorSoftware')"
                error="required"
                text="O fornecedor é obrigatório"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Idioma</label>
              <p-dropdown
                [options]="idiomas"
                placeholder="Selecione"
                name="idiomaSoftware"
                formControlName="idiomaSoftware"
              >
              </p-dropdown>
              <app-message
                [control]="form.get('idiomaSoftware')"
                error="required"
                text="O idioma é obrigatório"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                (click)="location.back()"
              >
                VOLTAR
              </button>

              <button
                mdbBtn
                type="submit"
                color="success"
                mdbWavesEffect
                [disabled]="loading"
              >
                ENVIAR
              </button>
            </div>

            <div class="p-field p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Detalhes"
  [(visible)]="displayMessage"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '60vw', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <p-messages [(value)]="msgs2" [enableService]="false"></p-messages>

  <div class="table-responsive-sm">
    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row"><strong>Tipo de certificação </strong></th>
          <td>{{ certificationResult?.descTipoCertificacao }}</td>
        </tr>

        <tr>
          <th scope="row"><strong>Número de certificação </strong></th>
          <td>{{ certificationResult?.numeroCertificacao }}</td>
        </tr>

        <tr>
          <th scope="row"><strong>Data de Certificação </strong></th>
          <td colspan="2">{{ certificationResult?.dtCertificacao }}</td>
        </tr>

        <!-- <tr>
          <th scope="row"><strong>Nome do software </strong></th>
          <td>{{ certificationResult.nomeSoftware }}</td>
        </tr>

        <tr>
          <th scope="row"><strong>Fornecedor do software </strong></th>
          <td>
            {{ certificationResult.fornecedorSoftware }}
          </td>
        </tr> -->
        <!--
        <tr>
          <th scope="row"><strong>Versão do software </strong></th>
          <td>{{ certificationResult.pedidoCertificacaoSoftware.versaoSoftware }}</td>
        </tr>

        <tr>
          <th scope="row"><strong>Idioma do software </strong></th>
          <td>{{ certificationResult.pedidoCertificacaoSoftware.idiomaSoftware }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>

  <div class="p-col text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
      (click)="displayMessage = false"
      [disabled]="loading"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-warning" role="alert">
      <p>
        <span class="text-danger">
          <mdb-icon fas icon="info-circle"></mdb-icon>
          <strong> IMPORTANTE!</strong>
        </span>
      </p>

      <p>
        <strong>1.</strong> Antes de solicitar novas séries verifique se de facto o contribuinte pretende uma NOVA série
        ou novos documentos (folhas) para uma série existente.
      </p>

      <p>
        -> Caso o contribuinte pretende uma nova série, solicite a mesma na funcionalidade "Pedir Novas Séries".
      </p>

      <p>
        -> Caso o contribuinte pretende incrementar novos documentos à uma série existente, solicite os mesmos
        na funcionalidade "Incrementar Documentos à Série", informando sempre a quantidade solicitar.
      </p>

      <p class="p-mt-2">
        <strong>2.</strong> As impressões dos documentos devem obrigatoriamente corresponder ao total solicitado.
        <br>
        Ex. Se o total já solicitado for 500 e o contribuinte solicitou mais 100 documentos, as folhas à serem impressas
        devem respeitar a continuação da sequência anterior, partindo de 501 à 600.
      </p>

      <p class="p-mt-2">
        <strong>3.</strong> Para cada solicitação já efetuada verificar se o total de folhas já impressas para uma
        determinada série corresponde ao total já solicitado na plataforma, caso não seja, deve-se efetuar a correção do
        mesmo até o final do ano 2020.
      </p>
    </div>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" (click)="display=true" href="javascript:;">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-medical pink-text"></i></div>
        <h4 class="title"> Pedir Novas Séries </h4>
        <p class="description">
          Permite adquirir novas séries de documentos para os seus clientes.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/typography/emission-document/read">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-list-alt pink-text"></i></div>
        <h4 class="title"> Consultar Pedidos de Séries </h4>
        <p class="description">
          Permite verificar das séries adquiridas para os seus clientes.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/typography/emission-document/increment">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-medical pink-text"></i></div>
        <h4 class="title"> Incrementar Documentos à Série </h4>
        <p class="description">
          <!-- <b class="text-danger"> Funcionalidade em manutenção! </b> -->
          Permite adicionar novas folhas de documentos à uma série existente.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/typography/emission-document/transport">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-export pink-text"></i></div>
        <h4 class="title"> Transportar séries. </h4>
        <p class="description">
          Permite transportar séries para o novo económico.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-4">
    <a class="a-box" routerLink="/typography/emission-document/change-emitter">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-user-circle pink-text"></i></div>
        <h4 class="title"> Corrigir proprietários das séries </h4>
        <p class="description">
          Permite corrigir problemas relacionados com trocas de séries entre contribuintes.
        </p>
      </div>
    </a>
  </div>

  <!-- <div class="p-col-12 p-md-6 p-lg-4">
    <a class="a-box" routerLink="/emitter/third-party/index">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog pink-text"></i></div>
        <h4 class="title"> Terceiros </h4>
        <p class="description">
          Permite ao utilizador gerir as suas informações de terceiros e operar em nome de outrém.
        </p>
      </div>
    </a>
  </div> -->
</div>

<p-dialog [(visible)]="display" [style]="{width: '55vw', height: 'auto'}" [baseZIndex]="10000"
  [contentStyle]="{'overflow':'visible'}" modal="true">

  <div class="p-grid">
    <div class="p-col-12">
      <div class="alert alert-warning" role="alert">
        <mdb-icon fas icon="info-circle"></mdb-icon>
        Por favor confirme a sua operação.
      </div>
    </div>

    <div class="p-col-12 p-md-6">
      <a class="a-box" routerLink="/typography/emission/create" href="javascript:;">
        <div class="box wow fadeInLeft" data-wow-delay="0.2s">
          <div class="icon"><i class="fa fa-file-medical pink-text"></i></div>
          <h4 class="title"> Pedir Nova Série </h4>
          <p class="description">
            Permite adquirir novas séries de documentos para os seus clientes.
          </p>
        </div>
      </a>
    </div>

    <div class="p-col-12 p-md-6">
      <a class="a-box" routerLink="/typography/emission-document/increment">
        <div class="box wow fadeInRight" data-wow-delay="0.2s">
          <div class="icon"> <i class="fa fa-file-medical pink-text"></i></div>
          <h4 class="title"> Incrementar documentos à série </h4>
          <p class="description">
            <!-- <b class="text-danger"> Funcionalidade em manutenção! </b> -->
            Permite adicionar novas folhas de documentos à uma série existente.
          </p>
        </div>
      </a>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <hr>
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="display=false">
      CANCELAR
    </button>
  </div>

</p-dialog>

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { CertificationUtilFilter, CertificationUtilService } from './../certification-util.service';

@Component({
  selector: 'app-read-typography',
  templateUrl: './read-typography.component.html',
  styleUrls: ['./read-typography.component.scss']
})
export class ReadTypographyComponent implements OnInit {
  certifications = [];
  loading = true;
  total = 0;
  filter = new CertificationUtilFilter();

  constructor(
    private service: CertificationUtilService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.readCertified(this.filter)
      .then(response => {
        this.total = response.total;
        this.certifications = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

}

import { TaxPayer } from 'src/app/core/models/taxpayer';
import { ThirdPartyEmitterService } from './../../third-party-emitter/third-party-emitter.service';
import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { Authorization } from 'src/app/core/models/authorization';
import { EmissionDocument, EmissionDocumentView } from 'src/app/core/models/emission-document';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { TypeDocumentService } from './../../type-document/type-document.service';
import { EmissionDocumentEmitterFilter, EmissionDocumentEmitterService } from './../emission-document-emitter.service';

@Component({
  selector: 'app-read-for-third',
  templateUrl: './read-for-third.component.html',
  styleUrls: ['./read-for-third.component.scss']
})
export class ReadForThirdComponent implements OnInit {
  loading = true;
  loadingIncrement = false;
  visibleSidebarFilter = false;

  total = 0;
  filter = new EmissionDocumentEmitterFilter();
  locale = new Locale();
  typesDocument = [];

  emissionDocument = new EmissionDocument();
  emissionDocumentView = new EmissionDocumentView();
  authorization = new Authorization();

  years = [
    { name: '2022', code: '2022' },
    { name: '2021', code: '2021' },
    { name: '2020', code: '2020' },
    { name: '2019', code: '2019' },
  ];

  emissionDocuments = [];
  displayEdit: boolean = false;
  displayDetails: boolean = false;
  displayIncrement: boolean = false;

  serieToShow: string;

  ACTIVITY_ID = 2;
  emitters = [];

  constructor(
    private service: EmissionDocumentEmitterService,
    private tipoDocumentoService: TypeDocumentService,
    private thirdPartyEmitterService: ThirdPartyEmitterService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.readTypeDocument();
    this.readEmitters();
  }

  readEmitters() {
    this.thirdPartyEmitterService.getEmittersByActivity(this.ACTIVITY_ID)
      .then((emitters: TaxPayer[]) =>
        this.emitters = emitters.map((el: TaxPayer) => ({
          value: el.tbPessoa.nifPessoa,
          label: `${el.tbPessoa.nifPessoa} - ${el.tbPessoa.nomePessoa}`,
        })))
  }

  async readTypeDocument() {
    try {
      const response = await this.tipoDocumentoService.read();
      this.typesDocument = response.map(t => ({
        name: t.descTipoDocumentoEmissao, code: t.idTipoDocumentoEmissao
      }));
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.readForThird(this.filter)
      .then(response => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  update() {
    this.loading = true;
    this.service.update(this.emissionDocument)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Informações alteradas!'
        });
        this.read();
        this.displayEdit = false;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  showDialogDetails(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;
    this.displayDetails = true;
  }

  resetFormFilter(form) {
    form.reset();
  }
}

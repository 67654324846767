import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { decrypt } from '../../configs/encryption';
import { Locale } from './../../configs/models/locale';
import { DocumentView } from './../../core/models/document';
import { DocumentConsumerService } from './../document-consumer.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  document = new DocumentView();
  locale = new Locale();

  constructor(
    private service: DocumentConsumerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));
    this.get(decryptedId);
  }

  get(id: number) {
    this.service.get(id)
      .then((response) => this.document = response);
  }
}

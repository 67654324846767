import { Mail } from './../core/models/mail';
import { AppHttp } from 'src/app/auth/app-http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/send`;
  }

  sendByNifList(mail: Mail): Promise<any> {
    const { recipients: nif, subject: assunto, body: corpoEmail } = mail;
    return this.http.post(`${this.API_URL}/mailPersonalizadoParaListaNif`, { nif, assunto, corpoEmail })
      .toPromise();
  }

  send(mail: Mail): Promise<any> {
    const { subject: assunto, body: corpoEmail } = mail;
    return this.http.post(`${this.API_URL}/mailPersonalizadoParaTodos`, { assunto, corpoEmail })
      .toPromise();
  }
}

<div class="row clearfix">

  <div class="title text-center">
    <h2 class="h2-responsive">Novo Utilizador</h2>
  </div>

  <mdb-card class="card-login">
    <mdb-card-body>
      <form autocomplete="off" #form="ngForm" (ngSubmit)="create()">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label>NIF</label>
            <input pInputText type="text" class="form-control" name="nifUsuario" minlength="9" #nifUsuario="ngModel"
              placeholder="Seu número de contribuinte" [(ngModel)]="user.nifUsuario" required autofocus>

            <app-message [control]="nifUsuario" error="required" text="O NIF é obrigatório"></app-message>
            <app-message [control]="nifUsuario" error="minlength"
              text=" Mínimo de {{nifUsuario.errors?.minlength?.requiredLength}} caracteres">
            </app-message>
          </div>

          <div class="p-field p-col-12">
            <label>E-mail</label>
            <input pInputText type="email" class="form-control" name="emailUsuario" placeholder="Seu e-mail"
              [(ngModel)]="user.emailUsuario" #emailUsuario="ngModel" required email>
            <app-message [control]="emailUsuario" error="required" text="O e-mail é obrigatório">
            </app-message>
          </div>

          <div class="p-field p-col-12">
            <label>Confirmar E-mail</label>
            <input pInputText type="email" name="emailUsuarioConfirmacao" placeholder="Seu e-mail" ngModel
              #emailUsuarioConfirmacao="ngModel" required>

            <div class="ui-message ui-message-error"
              *ngIf="emailUsuarioConfirmacao.dirty && emailUsuarioConfirmacao.value !== emailUsuario.value">
              Os e-mails não correspondem.
            </div>
          </div>

          <div class="p-col-12">
            <button type="submit" mdbBtn color="pink" block="true" class="my-4" size="sm"
              [disabled]="!form.valid || loading">
              REGISTAR
            </button>
          </div>

          <div class="p-col-12 text-center">
            <a routerLink="/login">Já tem conta?</a>
          </div>

          <div class="p-col-12">
            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
          </div>
        </div>
      </form>
    </mdb-card-body>
  </mdb-card>
</div>

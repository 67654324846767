import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AlertComponent } from './alert/alert.component';
import { GetComponent } from './get/get.component';
import { NotificationUserRouter } from './notification-user-router';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [
    ReadComponent,
    GetComponent,
    AlertComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NotificationUserRouter,
    MDBBootstrapModule,
    OverlayPanelModule,
    ScrollPanelModule,
    SelectButtonModule,
    DropdownModule,
    InputSwitchModule
  ],
  exports: [
    AlertComponent
  ]
})
export class NotificationUserModule { }

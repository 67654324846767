<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIAS CERTIFICADAS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="certifications"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="header">
            <tr>
              <th>Nome</th>
              <th>NIF</th>
              <th>Nº Certificação</th>
              <th>Data Certificação</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Nome</span>
                {{rowData.tbContribuinte.tbPessoa.nomePessoa}}
              </td>

              <td>
                <span class="p-column-title">NIF</span>
                {{ rowData.tbContribuinte.nifContribuinte }}
              </td>

              <td>
                <span class="p-column-title"> Nº de Certificação </span>
                {{ rowData.numeroCertificacao }}
              </td>

              <td>
                <span class="p-column-title">Data Certificação</span>
                {{rowData.dtCertificacao | date:'dd/MM/yyyy'}}
              </td>

            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

<div class="row clearfix">
  <mdb-card class="card-login">
    <mdb-card-body>
      <blockquote class="blockquote bq-success text-center">

        <div class="col-md-12 text-center">
          <mdb-icon class="icon-message-lg text-success" fas icon="check-circle"></mdb-icon>
        </div>

        <p>Senha restabelecida com sucesso. Use a sua nova senha para aceder ao sistema.</p>

        <hr>

        <footer class="blockquote-footer mb-3"><a routerLink="/login">Aceder ao sistema</a></footer>
      </blockquote>
    </mdb-card-body>
  </mdb-card>
</div>

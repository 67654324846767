import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { Invoice, InvoiceView } from './../core/models/invoice-system';

export class DocumentOnlineFilter {
  page = 0;
  rows = 15;

  numDocumentoOnline: string;
  numSerieDocumentoOnline: string;
  nifEmissorOnline: string;
  nifConsumidorOnline: string;
  dtEmissaoDocumentoOnlineDe: Date;
  dtEmissaoDocumentoOnlineAte: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentOnlineService {
  API_URL: string;
  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGeradoOnline`;
  }

  async read(filter: DocumentOnlineFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.numDocumentoOnline)
      params = params.append('numDocumentoOnline', filter.numDocumentoOnline);

    if (filter.numSerieDocumentoOnline)
      params = params.append('numSerieDocumentoOnline', filter.numSerieDocumentoOnline);

    if (filter.nifEmissorOnline)
      params = params.append('tbContribuinteNifEmissor', filter.nifEmissorOnline);

    if (filter.nifConsumidorOnline)
      params = params.append('tbContribuinteNifConsumidor', filter.nifConsumidorOnline);

    if (filter.dtEmissaoDocumentoOnlineDe)
      params = params.append('dtEmissaoDocumentoOnlineDe', moment(filter.dtEmissaoDocumentoOnlineDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoOnlineAte)
      params = params.append('dtEmissaoDocumentoOnlineAte', moment(filter.dtEmissaoDocumentoOnlineAte).format('YYYY-MM-DD'));

    const response = await this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise();
    return {
      body: <Invoice[]>response.content,
      total: response.totalElements
    };
  }

  async get(id: number): Promise<InvoiceView> {
    const response = await this.http.get(`${this.API_URL}/${id}`)
      .toPromise();
    const invoice = response as InvoiceView;
    return invoice;
  }
}

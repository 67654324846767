import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { decrypt } from '../../configs/encryption';
import { FileDocumentAnalysisReportService } from '../file-document-analysis-report.service';
import { buildCurrentDateWithHour } from 'src/app/shared/date';

@Component({
  selector: 'app-read-report-by-file',
  templateUrl: './read-report-by-file.component.html',
  styleUrls: ['./read-report-by-file.component.scss']
})
export class ReadReportByFileComponent implements OnInit {
  fileDocumentAnalysis = [];
  loading: boolean = true;

  constructor(
    private service: FileDocumentAnalysisReportService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    const fileDocumentId: number = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(fileDocumentId));
    this.readByFileDocument(decryptedId);
  }

  readByFileDocument(fileDocumentId: number) {
    this.loading = true;
    this.service.readByFIleDocument(fileDocumentId)
      .then((response) => {
        response.forEach((responseData) => {
          responseData.dtRelatorioAnaliseFicheiro  = buildCurrentDateWithHour(new Date(responseData.dtRelatorioAnaliseFicheiro));
        });
        this.fileDocumentAnalysis = response;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR SÉRIES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header text-right">
        <button type="button" mdbBtn size="md" color="light" mdbWavesEffect (click)="visibleSidebarFilter=true">
          <mdb-icon fas icon="search"></mdb-icon>
          Filtrar
        </button>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="emissionDocuments"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" dataKey="idPedidoEmissaoDocumento" editMode="row" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} séries solicitadas.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'10%'}">Documento</th>
              <th [style]="{width:'6%'}">Série</th>
              <th [style]="{width:'6%'}">Data</th>
              <th [style]="{width:'20%'}">Requerente</th>
              <th [style]="{width:'20%'}">Emitente</th>
              <th [style]="{width:'20%'}">Tipo de Certificação</th>
              <th [style]="{width:'10%'}">Estado</th>
              <th [style]="{width: '4%'}"></th>
              <th [style]="{ width: '4%' }"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">Tipo de Documento</span>
                {{
                rowData.tipoDocumento
                }}
              </td>
              <td>
                <span class="p-column-title">Série</span>
                {{
                rowData.siglaSerie +''+
                rowData.serie +''+
                rowData.ano
                }}
              </td>

              <td>
                <span class="p-column-title">Data</span>
                {{rowData.data | date:'dd/MM/yyyy'}}
              </td>

              <td>
                <span class="p-column-title">Requerente</span>
                {{rowData.nifRequerente+' - '+rowData.nomeRequerente}}
              </td>

              <td>
                <span class="p-column-title">Emitente</span>
                {{rowData.nifEmissor+' - '+rowData.nomeEmissor}}
              </td>

              <td>
                <span class="p-column-title">Tipo de Certificação</span>
                {{rowData.tipoCertificacao}}
              </td>
              <td>
                <span class="p-column-title">Estado</span>
                <a (click)="unblockSerieConfirm(rowData)">
                  <p-tag [severity]="getDocumentStatus(rowData) ? 'danger' : 'success'" [value]="getDocumentStatusLabel(rowData)"></p-tag>
                </a>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button mdbBtn block="true" color="info" size="sm" pTooltip="Mais detalhes" tooltipPosition="left"
                  (click)="showDialogDetails(rowData);" [style]="{padding: '4px 0'}">
                  <i class="fa fa-search"></i>
                </button>
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <button
                  type="button"
                  mdbBtn
                  block="true"
                  color="warning"
                  size="sm"
                  tooltipPosition="left"
                  pTooltip="Transportar série"
                  (click)="showTransportDialog(rowData)"
                  [disabled]="rowData.transferida || rowData.idTipoCertificacao !== 2 || rowData.ano === 24"
                >
                  <mdb-icon fas icon="arrow-right"></mdb-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Detalhes" [(visible)]="displayDetails" [modal]="true" [responsive]="true"
  [style]="{width: '70vw', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <div class="table-responsive-sm">
    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row"> <strong>Tipo Documento </strong></th>
          <td> {{ emissionDocumentView.tipoDocumento }} </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Nº de Série </strong></th>
          <td>
            {{
            emissionDocumentView.siglaSerie +''+
            emissionDocumentView.serie +''+
            emissionDocumentView.ano
            }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Nº de Autorização </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.numAutorizacaoEmissao }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Data de Autorização </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.data | date:'dd/MM/yyyy' }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Descrição </strong></th>
          <td colspan="2"> {{ emissionDocumentView.descricao }} </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Documentos Solicitados </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.totalJaSolicitado }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Documentos Comunicados </strong></th>
          <td colspan="2">
            <strong class="red-text">EM DESENVOLVIMENTO</strong>
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Última Solicitação </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.totalSolicitado }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Máscara </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.mascara }}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Emitente </strong></th>
          <td colspan="2">
            {{emissionDocumentView.nifEmissor+' - '+emissionDocumentView.nomeEmissor}}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Requerente </strong></th>
          <td colspan="2">
            {{emissionDocumentView.nifRequerente+' - '+emissionDocumentView.nomeRequerente}}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Tipo de Certificação </strong></th>
          <td colspan="2">
            {{emissionDocumentView.tipoCertificacao}}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Gerada Pelo Agente </strong></th>
          <td colspan="2">
            {{emissionDocumentView.nifUsuarioTranferiu}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="p-col text-right">
    <hr>
    <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayDetails=false" [disabled]="loading">
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Transportar para o próximo ano"
  [(visible)]="displayTransportDialog"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '550px', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
  (onHide)="closeTransportDialog(formTransport)"
>
  <form #formTransport="ngForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>Série a Transportar<strong class="red-text">*</strong></label>
        <input
          pInputText
          type="text"
          name="currentSerie"
          [(ngModel)]="transportSerieInput.currentSerie"
          required
          disabled
        />
      </div>

      <div class="p-field p-col-12  p-md-6">
        <label>Transportar para o ano<strong class="red-text">*</strong></label>
        <p-dropdown
          [options]="yearsToTransport"
          name="nextYear"
          [showClear]="true"
          [(ngModel)]="transportSerieInput.nextYear"
          placeholder="Selecione o ano"
          required="required"
        >
        </p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-6">
        <label>Qtd. documentos<strong class="red-text">*</strong></label>
        <input
          pInputText
          type="text"
          name="documentAmount"
          [(ngModel)]="transportSerieInput.documentAmount"
          required
        />
      </div>

      <div class="p-field p-col-12">
        <label>Descrição (Opcional)</label>
        <input
          pInputText
          type="text"
          name="description"
          [(ngModel)]="transportSerieInput.description"
          placeholder="Ex. Série para agência nº 1"
        />
      </div>

      <div class="p-col-12 mt-4"><p-divider></p-divider></div>

      <div class="p-field p-col-12">
        <label>NIF do solicitante<strong class="red-text">*</strong></label>
        <input pInputText type="text" name="requesterNif" [(ngModel)]="transportSerieInput.requesterNif"
          placeholder="Quem fez a solicitação presencialmente" (input)="onInputNifSol($event)" required>

        <div *ngIf="!validNifSol" class="ui-message ui-message-error">
          NIF inválido
        </div>
      </div>

      <div class="p-field p-col-12">
        <label>Nome do solicitante<strong class="red-text">*</strong></label>
        <input pInputText type="text" name="requesterName" [(ngModel)]="transportSerieInput.requesterName" required disabled>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button
      type="button"
      mdbBtn
      size="md"
      color="light"
      (click)="closeTransportDialog(formTransport)"
    >
      CANCELAR
    </button>

    <button
      type="button"
      mdbBtn
      size="md"
      color="primary"
      [disabled]="!formTransport.valid || loading"
      (click)="transport(formTransport)"
    >
      TRANSPORTAR
    </button>

    <div class="p-col-12">
      <p-progressBar
        *ngIf="loadingTransport"
        mode="indeterminate"
        [style]="{ height: '3px' }"
      ></p-progressBar>
    </div>
  </ng-template>
</p-dialog>

<p-sidebar [(visible)]="visibleSidebarFilter" position="right" [baseZIndex]="10000" [style]="{width: '450px'}">
  <h2 style="font-weight:normal">Filtros</h2>
  <form #formFilter="ngForm" autocomplete="off" (ngSubmit)="read()">
    <div class="p-fluid">

      <div class="p-grid p-col-12 p-pb-2">
        <div class="p-col-6">
          <button type="button" mdbBtn color="red" size="md" block="true" (click)="resetFormFilter(formFilter)">
            Limpar
          </button>
        </div>

        <div class="p-col-6">
          <button type="submit" mdbBtn color="info" size="md" block="true">
            Filtrar
          </button>
        </div>
        <hr>
      </div>

      <p-scrollPanel [style]="{width: '100%', height: '80vh'}">
        <div class="p-field">
          <span class="p-float-label">
            <input pInputText class="p-inputtext-sm" name="serieNumber" [(ngModel)]="filter.serieNumber">
            <label for="float-input">Número de Série</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
              dateFormat="dd/mm/yy" [showIcon]="true" name="createdAt" [(ngModel)]="filter.createdAt">
            </p-calendar>
            <label for="float-input">Data solicitação [inicial-final]</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label p-input-icon-right">
            <i class="pi pi-user"></i>
            <input pInputText class="p-inputtext-sm" name="emitterNif" [(ngModel)]="filter.emitterNif">
            <label for="float-input">NIF do Emitente</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label p-input-icon-right">
            <i class="pi pi-user"></i>
            <input pInputText class="p-inputtext-sm" name="applicantNif" [(ngModel)]="filter.applicantNif">
            <label for="float-input">NIF do Requerente</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown class="p-inputtext-sm" [options]="tiposDocumento" name="idTipoDocumentoEmissao"
              [showClear]="true" [(ngModel)]="filter.idTipoDocumentoEmissao" placeholder="Tipo Documento">
            </p-dropdown>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown class="p-inputtext-sm" [options]="typesCertification" name="typeCertificationId"
              [showClear]="true" [(ngModel)]="filter.typeCertificationId" placeholder="Tipo Certificação">
            </p-dropdown>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown class="p-inputtext-sm" [options]="years" name="year" [showClear]="true"
              [(ngModel)]="filter.year" placeholder="Ano da série">
            </p-dropdown>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input pInputText class="p-inputtext-sm" name="description" [(ngModel)]="filter.description">
            <label for="float-input">Descrição</label>
          </span>
        </div>

        <!-- <div class="p-field">
          <span class="p-float-label">
            <input pInputText class="p-inputtext-sm" name="applicantName" [(ngModel)]="filter.applicantName">
            <label for="float-input">Nome do Requerente</label>
          </span>
        </div> -->

        <!-- <div class="p-field">
          <span class="p-float-label">
            <input pInputText class="p-inputtext-sm" name="emitterName" [(ngModel)]="filter.emitterName">
            <label for="float-input">Nome do Emitente</label>
          </span>
        </div> -->
      </p-scrollPanel>
    </div>
  </form>
</p-sidebar>

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { encrypt } from '../../configs/encryption';
import { FileDocumentFilter, FileDocumentService } from '../file-document.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  fileDocuments = [];
  loading = true;
  total = 0;
  filter = new FileDocumentFilter();

  statusFiles = [
    { value: true, label: 'ATIVO' },
    { value: false, label: 'INATIVO' },
  ]

  statusAnalises = [
    { code: 'POR_ANALISAR', name: 'POR ANALISAR' },
    { code: 'ANALISE_PRIMEIRA_FASE', name: 'ANALISE 1ª FASE' },
    { code: 'ANALISE_SEGUNDA_FASE', name: 'ANALISE 2ª FASE' },
    // { code: 'ANALISE_TERCEIRA_FASE', name: 'ANALISE 3ª FASE' },
    { code: 'EM_ESPERA', name: 'EM ESPERA' },
    { code: 'PROCESSANDO', name: 'PROCESSANDO' },
    { code: 'REJEITADO', name: 'REJEITADO' },
    { code: 'CONCLUIDO', name: 'CONCLUÍDO' },
  ]

  constructor(
    private service: FileDocumentService,
    private errorHandler: ErrorHandlerService,
    private message: MessageService
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  getFile(file: FileDocument) {
    this.service.getFile(file)
      .then(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() => this.message.add({
        key: 'lg',
        severity: 'error',
        life: 20000,
        detail: 'Não foi possível transferir o ficheiro! Tente mais tarde.'
      }))
  }

  encrypt(info: string) {
    return encrypt(info)
  }
}

import { TaxPayer } from './../core/models/taxpayer';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { ThirdParty, ThirdPartyView } from './../core/models/third-party';

export class ThirdPartyEmitterFilter {
  page = 0;
  rows = 15;

  nifContriPri: string;
  nifContriTer: string;
  nomeContriPri: string;
  nomeContriTer: string;
  idAtividade: number;
}

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyEmitterService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/contribuinte`;
  }

  async read(filter: ThirdPartyEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.idAtividade)
      params = params.append('idAtividade', filter.idAtividade.toString());

    if (filter.nomeContriTer)
      params = params.append('nomeContriTer', filter.nomeContriTer);

    if (filter.nifContriTer)
      params = params.append('nifContriTer', filter.nifContriTer);

    const response = await this.http
      .get<any>(`${this.API_URL}/atividade/emitenteTer`, { params })
      .toPromise();

    this.convertFields(response.content);

    return {
      body: <ThirdParty[]>response.content,
      total: response.totalElements,
    };
  }

  async create(thirdParty: ThirdParty): Promise<ThirdParty> {
    return this.http
      .post<ThirdParty>(`${this.API_URL}/tomarAtividade`, thirdParty)
      .toPromise();
  }

  async acceptForTaxpayer(
    thirdPartyView: ThirdPartyView
  ): Promise<ThirdPartyView> {
    delete thirdPartyView.labelActivity;
    return this.http
      .put<ThirdPartyView>(`${this.API_URL}/atividade/emitente`, thirdPartyView)
      .toPromise()
      .then((response: ThirdPartyView) => {
        this.convertFields([response]);
        return response;
      });
  }

  async acceptForThirdPart(
    thirdPartyView: ThirdPartyView
  ): Promise<ThirdPartyView> {
    delete thirdPartyView.labelActivity;
    return this.http
      .put<ThirdPartyView>(`${this.API_URL}/atividade/terceiro`, thirdPartyView)
      .toPromise()
      .then((response: ThirdPartyView) => {
        this.convertFields([response]);
        return response;
      });
  }

  async getEmittersByActivity(activityId: number): Promise<TaxPayer[]> {
    let params = new HttpParams();
    params = params.append('idAtividade', activityId.toString());

    return this.http
      .get<TaxPayer[]>(`${this.API_URL}/clientes`, { params })
      .toPromise();
  }

  private convertFields(thirdParties: ThirdPartyView[]) {
    thirdParties.forEach((thirdParty) => {
      thirdParty.dataCria = moment(thirdParty.dataCria, 'YYYY-MM-DD').toDate();

      switch (thirdParty.nomeAtividade) {
        case 'EMITIR_FATURA_ONLINE':
          thirdParty.labelActivity = 'EMITIR FATURAS NO PORTAL E-FACTURA';
          break;

        case 'SOLICITAR_NOVAS_SERIES':
          thirdParty.labelActivity =
            'SOLICITAR NOVAS SERIES PARA SOFTWARE AUTÓNOMO';
          break;

        case 'COMUNICAR_FICHEIRO':
          thirdParty.labelActivity =
            'COMUNICAR POR FICHEIROS OS DOCUMENTOS EMITIDOS';
          break;

        case 'COMUNICAR_UNIDADE':
          thirdParty.labelActivity =
            'COMUNICAR POR UNIDADE OS DOCUMENTOS EMITIDOS';
          break;

        default:
          thirdParty.labelActivity = null;
          break;
      }
    });
  }
}

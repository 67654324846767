<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR FICHEIROS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <p>
        <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true"></mdb-icon>
        - Aguardando análise.
      </p>
      <p>
        <mdb-icon fas icon="sync" class="mr-2 blue-text" size="lg" aria-hidden="true"> </mdb-icon>
        - Análise em processamento.
      </p>
      <p>
        <mdb-icon fas icon="sync" class="mr-2 text-warning" size="lg" aria-hidden="true"> </mdb-icon>
        - O ficheiro aguarda a correção do(s) ficheiro(s) rejeitados dos períodos de facturações anteriores.
      </p>
      <p>
        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
        - O ficheiro foi processado sem êxito durante a análise.
      </p>
      <p>
        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true"></mdb-icon>
        - O ficheiro foi processado com êxito durante a análise.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body">
        <p-tabView>
          <p-tabPanel header="FICHEIROS RECOLHIDOS">
            <form autocomplete="off" (ngSubmit)="read()">
              <div class="p-col-12">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-2">
                    <span class="p-float-label">
                      <input pInputText class="p-inputtext-sm" name="fileName" [(ngModel)]="filter.fileName">
                      <label for="float-input">Descrição do ficheiro</label>
                    </span>
                  </div>

                  <!-- <div class="p-field p-col-2">
                    <span class="p-float-label">
                      <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                        view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2000:2050" name="period"
                        [(ngModel)]="filter.period">
                      </p-calendar>
                      <label for="float-input">Período facturação</label>
                    </span>
                  </div> -->

                  <div class="p-field p-col-1">
                    <span class="p-float-label">
                      <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                        dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
                      </p-calendar>
                      <label for="float-input">Data registo</label>
                    </span>
                  </div>

                  <div class="p-field p-col">
                    <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
              [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
              [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
              <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                  Total de {{ total | number:'1.0-0'}} ficheiros recolhidos.
                </div>
              </ng-template>

              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width: '50%'}">Ficheiro</th>
                  <th [style]="{width: '7%'}">Enviado em</th>
                  <th [style]="{width: '6%'}">Período</th>
                  <th [style]="{width: '5%'}">1ª análise</th>
                  <th [style]="{width: '5%'}">2ª análise</th>
                  <th [style]="{width: '5%'}">3ª análise</th>
                  <th [style]="{width: '6%'}">Status</th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                  <th [style]="{width:'3em'}"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td>
                    <span class="p-column-title">Ficheiro</span>
                    {{ rowData.nomeArquivoDocumentoGerado }}
                  </td>

                  <td>
                    <span class="p-column-title">Dt Registo</span>
                    {{ rowData.dtCria | date:'dd/MM/yyyy' }}
                  </td>

                  <td>
                    <span class="p-column-title">Período</span>
                    {{ rowData.dtPeriodoFaturacao | date:'MMM/yyyy' }}
                  </td>

                  <td>
                    <span class="p-column-title">1ª análise</span>
                    <div *ngIf="rowData.step1 === null; else elseNull">
                      <mdb-icon fas icon="sync" class="mr-2 gray-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull>
                      <div *ngIf="rowData.step1; else elseBlock">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>

                      <ng-template #elseBlock>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>

                  <td>
                    <span class="p-column-title">2ª análise</span>
                    <div *ngIf="rowData.step2 === null; else elseNull2">
                      <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull2>
                      <div *ngIf="rowData.step2; else elseBlock2">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>
                      <ng-template #elseBlock2>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>

                  <td>
                    <span class="p-column-title">3ª análise</span>

                    <div *ngIf="rowData.statusAnaliseArquivo === 'EM_ESPERA'; else elseWaiting_father">
                      <div *ngIf="(rowData.step3 && rowData.statusAnaliseArquivo === 'EM_ESPERA'); else elseWaiting">
                        <mdb-icon fas icon="sync" class="mr-2 text-warning" size="lg" aria-hidden="true"> </mdb-icon>
                      </div>

                      <ng-template #elseWaiting>
                        <div *ngIf="rowData.step3 === null; else elseNull3">
                          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true"> </mdb-icon>
                        </div>

                        <ng-template #elseNull3>
                          <div *ngIf="rowData.step3; else elseBlock3">
                            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                            </mdb-icon>
                          </div>

                          <ng-template #elseBlock3>
                            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </div>

                    <ng-template #elseWaiting_father>
                      <div
                        *ngIf="(rowData.step3 && rowData.statusAnaliseArquivo === 'PROCESSANDO'); else elseProcessing">
                        <mdb-icon fas icon="sync" class="mr-2 blue-text" size="lg" aria-hidden="true"> </mdb-icon>
                      </div>

                      <ng-template #elseProcessing>
                        <div *ngIf="rowData.step3 === null; else elseNull3">
                          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true"> </mdb-icon>
                        </div>

                        <ng-template #elseNull3>
                          <div *ngIf="rowData.step3; else elseBlock3">
                            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                            </mdb-icon>
                          </div>

                          <ng-template #elseBlock3>
                            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </td>

                  <td>
                    <span class="p-column-title">Status</span>
                    <div class="badges">
                      <span [class]="['p-tag', rowData.fileAnaliseColor]">
                        {{ rowData.fileAnalise }}
                      </span>
                    </div>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações"
                      tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                      [routerLink]="['/emitter/file-document/read', encrypt(rowData.idArquivoDocumentoGerado)]">
                      <i class="fa fa-search"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="orange" size="sm" tooltipPosition="left"
                      [disabled]="rowData.statusAnaliseArquivo === 'PROCESSANDO' || rowData.statusAnaliseArquivo === 'CONCLUIDO'"
                      pTooltip="Substituir ficheiro" class="btn-block" [style]="{padding: '4px 0'}"
                      (click)="showChangeFileDialog(rowData)">
                      <i class="fa fa-exchange-alt"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="primary" size="sm" tooltipPosition="left"
                      pTooltip="Alterar o período de facturação" class="btn-block" [style]="{padding: '4px 0'}"
                      [disabled]="rowData.statusAnaliseArquivo === 'PROCESSANDO' || rowData.statusAnaliseArquivo === 'CONCLUIDO'"
                      (click)="showChangePeriodDialog(rowData)">
                      <i class="fa fa-calendar-alt"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left"
                      pTooltip="Transferir o ficheiro" class="btn-block" [style]="{padding: '4px 0'}"
                      (click)="getFile(rowData)">
                      <i class="fa fa-download"></i>
                    </button>
                  </td>

                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="red" size="sm" tooltipPosition="left"
                      pTooltip="Cancelar a comunicação do ficheiro" class="btn-block" [style]="{padding: '4px 0'}"
                      [disabled]="rowData.statusAnaliseArquivo === 'PROCESSANDO' || rowData.statusAnaliseArquivo === 'CONCLUIDO' || rowData.statusAnaliseArquivo === 'EM_ESPERA'"
                      (click)="showCancelFile(rowData)">
                      <i class="fa fa-times-circle"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          <p-tabPanel header="SOLICITAÇÕES DE ANULAÇÃO DE FICHEIROS PROCESSADOS">
            <app-read-cancellation-request-by-emitter></app-read-cancellation-request-by-emitter>
          </p-tabPanel>
        </p-tabView>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Alterar Período de Facturação" [(visible)]="displayPeriod" [style]="{width: '30vw'}"
  [baseZIndex]="10000" [contentStyle]="{'overflow':'visible'}">
  <form #formPeriod="ngForm" autocomplete="off" (ngSubmit)="updatePeriod()">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12">
        <label>Período de Facturação<b class="font-bold col-pink">*</b></label>
        <p-calendar name="dtPeriodoFaturacao" [(ngModel)]="fileDocument.dtPeriodoFaturacao" view="month"
          dateFormat="mm/yy" [yearNavigator]="true" yearRange="2019:2021" [locale]="locale.pt" [minDate]="minDate"
          [maxDate]="maxDate" placeholder="Informe o período de facturação" required>
        </p-calendar>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayPeriod=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="info" mdbWavesEffect [disabled]="!formPeriod.valid || loadingChangePeriod">
          ENVIAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingChangePeriod" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<p-dialog header="Substituir o ficheiro" [(visible)]="displayFile" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <form #formFixFile="ngForm" autocomplete="off" (ngSubmit)="fixFile()">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12">
        <p-fileUpload name="file" accept=".xls, .xlsx, .csv, .json" maxFileSize="100000000" customUpload="true"
          chooseLabel="Adicionar" cancelLabel="Cancelar" (onSelect)="onSelectFileEdit($event.files)" #fileUpload
          [showUploadButton]="false">
          <ng-template pTemplate="content">
          </ng-template>
        </p-fileUpload>
      </div>

      <div class="p-col-12">
        <div class="alert bg-white" role="alert" style="border: thin solid #cecece;" *ngIf="showVerifiedFile">

          <h5>Resumo da Verificação</h5>

          <p style="color: #000000;">
            <strong>Nome do ficheiro:</strong> {{ fileCommunicationError.nomeFile }}
          </p>

          <p style="color: #000000;">
            <strong>Linhas processadas:</strong>
            {{ fileCommunicationError.totalLinhas }}
          </p>

          <p style="color: #000000;">
            <strong>Erros encontrados:</strong>
            {{ fileCommunicationError.erros.length }}
          </p>

          <ul class="">
            <li class="col-pink" *ngFor="let erro of fileCommunicationError.erros; index as i">
              {{erro}}
            </li>
          </ul>
        </div>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayFile=false">
          CANCELAR
        </button>

        <button type="button" mdbBtn color="info" mdbWavesEffect (click)="verificarFicheiro()"
          pTooltip="Verificar o ficheiro antes de enviar" tooltipPosition="top" [disabled]="!uploadedFileEdit">
          VERIFICAR
        </button>

        <button type="submit" mdbBtn color="success" mdbWavesEffect pTooltip="Substituir o ficheiro"
          tooltipPosition="top" [disabled]="!uploadedFileEdit">
          SUBSTITUIR
        </button>
      </div>

      <div class="p-col-12">
        <p-progressBar *ngIf="loadingChangeFile" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<p-dialog header="Anular ficheiro" [(visible)]="displayCancelFile" [style]="{width: '45vw'}" [baseZIndex]="10000"
  [contentStyle]="{'overflow':'visible'}">
  <form #formCancelFile="ngForm" autocomplete="off" (ngSubmit)="cancelFile()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="alert alert-warning p-col" role="alert">
        <p> Esta operação é irreversível e deixará de ter acesso ao ficheiro.</p>
        <p> Tem certeza que quer prosseguir? </p>
      </div>

      <table mdbTable [style]="{width:'100%'}">
        <tr>
          <th>Nome:</th>
          <td> {{fileDocument.nomeArquivoDocumentoGerado}} </td>
        </tr>
        <tr>
          <th>Período:</th>
          <td> {{fileDocument.dtPeriodoFaturacao | date:'MMMM/yyyy'}} </td>
        </tr>
        <tr>
          <th>Status:</th>
          <td> {{fileDocument.statusAnaliseArquivo}} </td>
        </tr>
      </table>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayCancelFile=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="success" mdbWavesEffect
          [disabled]="!formCancelFile.valid || loadingCancelFile">
          CONFIRMAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingCancelFile" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<p-dialog
  header="Aviso"
  [(visible)]="showModal"
  [modal]="true"
  [style]="{ width: '60vw' }"
  [draggable]="false"
  [closable]="false"
  [resizable]="false">
  <div class="relative p-6 flex-auto">
    <ng-container *ngIf="genericError">
      <p-messages severity="error" [closable]="false">
        <ng-template pTemplate>
          De momento não é possível verificar o seu enquadramento de regime de IVA
        </ng-template>
        
      </p-messages>
    </ng-container>
    <ng-container *ngIf="!genericError">
      <p-messages severity="warn" [closable]="false">
        <ng-template pTemplate>
          O Contribuinte não se encontra registado no SIT IVA ou ainda não tem o enquadramento validado. <br>
          Deverá aceder ao portal Fazenda Non para efetuar o devido enquadramento mediante a declaração de actividade do IVA.<br>
          De lembrar que para realizar a declaração de actividade deverá primeiramente aceder a Caixa de Correio Eletrónico Fiscal.
        </ng-template>
      </p-messages>
    </ng-container>
  </div>
  <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
    
  </div>
  
  <div class="p-col text-right">
    <hr />
    <div class="d-flex justify-content-between" *ngIf="!genericError">
      <a routerLink="/emitter/document-online">
        <button
        type="button"
        mdbBtn
        color="light"
        size="md"
        mdbWavesEffect
        >
        Voltar
        </button>
      </a>
      <a target="_blank" [attr.href]="fazendaNonElectronicBoxUrl" [style.margin-right]="'10px'">
        <button
        type="button"
        mdbBtn
        color="teal"
        size="md"
        mdbWavesEffect
        >
        Aceder a Caixa de Correio Eletrónico Fiscal
        </button>
      </a>
      <a target="_blank" [attr.href]="fazendaNonActivityUrl">
        <button
        type="button"
        mdbBtn
        color="teal"
        size="md"
        mdbWavesEffect
        >
        Realizar Declaração de Actividade
        </button>
      </a>
    </div>
    <button
      *ngIf="genericError"
      type="button"
      mdbBtn
      size="md"
      color="light"
      mdbWavesEffect
      routerLink="/emitter/document-online"
    >
      FECHAR
    </button>
  </div>
</p-dialog>
import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocumentCancellationRequest } from 'src/app/core/models/file-document-cancellation-request';
import { encrypt } from '../../configs/encryption';
import { FileDocumentCancellationRequestFilter, FileDocumentCancellationRequestService } from '../file-document-cancellation-request.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  display: boolean = false;
  fileDocuments = [];
  loading: boolean = true;
  loadingDelete: boolean = false;
  total = 0;
  filter = new FileDocumentCancellationRequestFilter();
  request = new FileDocumentCancellationRequest();

  statuses = [
    { value: 'PENDENTE', label: 'PENDENTE' },
    { value: 'FECHADO', label: 'FECHADO' },
  ]

  constructor(
    private service: FileDocumentCancellationRequestService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  showDialog(request: FileDocumentCancellationRequest) {
    this.request = request;
    this.display = true;
  }

  encrypt(info: string) {
    return encrypt(info)
  }

  delete() {
    this.loadingDelete = true;
    this.service.delete(this.request)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Solicitação enviada com sucesso!'
        });
        this.display = false;
        this.read();
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => this.loadingDelete = false);
  }
}

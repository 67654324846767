<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMIN
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          IMPOSTOS E TAXAS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <app-create-tax-rate
          (updateList)="read()"
          [taxTypes]="taxTypes"
          *ngIf="taxTypes.length"
        ></app-create-tax-rate>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table
          styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
          [value]="taxRates"
          [loading]="loading"
          [paginator]="true"
          [rows]="filter.rows"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ totalRecords | number : "1.0-0" }} registos
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{ width: '40%' }">Imposto</th>
              <th [style]="{ width: '10%' }">Sigla</th>
              <th [style]="{ width: '10%' }">Taxa</th>
              <th [style]="{ width: '10%' }">Valor</th>
              <th [style]="{ width: '20%' }">Registado em</th>
              <th [style]="{ width: '7%' }">Status</th>
              <th [style]="{ width: '3%' }"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Imposto</span>
                {{ rowData.tbTipoImposto.descricaoTipoImposto }}
              </td>
              <td>
                <span class="p-column-title">Imposto</span>
                {{ rowData.tbTipoImposto.siglaTipoImposto }}
              </td>
              <td>
                <span class="p-column-title">Descrição</span>
                {{ rowData.descTaxaAplicavel }}
              </td>
              <td>
                <span class="p-column-title">Valor</span>
                {{ rowData.valorTaxaAplicavel }}
              </td>
              <td>
                <span class="p-column-title">Registado em</span>
                {{ rowData.dtCriaTaxaAplicavel | date }}
              </td>
              <td>
                <span class="p-column-title">Status</span>
                <app-change-status-tax-rate
                  [taxRateId]="rowData.idTaxaAplicavel"
                  [status]="rowData.status"
                ></app-change-status-tax-rate>
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <app-update-tax-rate
                  [taxRate]="rowData"
                  [taxTypes]="taxTypes"
                  (updateList)="read()"
                  *ngIf="taxTypes.length"
                ></app-update-tax-rate>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DialogModule } from 'primeng/dialog';
import { IndexComponent } from './index/index.component';
import { TutorialRouter } from './tutorial-router';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    TutorialRouter,

    MDBBootstrapModule,
    DialogModule
  ]
})
export class TutorialModule { }

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/certification/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CERTIFICAÇÃO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PARA TIPOGRAFIA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      <p> 1. Use a funcionalidade abaixo para submeter os documentos necessários.</p>
      <p> 2. O único formato permitido para os arquivos é o PDF. </p>
      <p>
        3. Os nomes dos arquivos a serem anexados deve corresponder ao documento equivalente. Ex:
        Declaracao_Situacao_de_Falencia_ou_insolvencia.
      </p>
      <p> 4. Receberá uma notificação por e-mail assim que o seu pedido for confirmado. </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form id="fileUploadForm" #certificacaoAnexoForm="ngForm" enctype="multipart/form-data">
          <div class="p-col-12">
            <p-fileUpload name="file[]" multiple="multiple" accept=".pdf" maxFileSize="80000000" customUpload="true"
              (uploadHandler)="onUpload($event, fileUpload)" #fileUpload chooseLabel="Adicionar" uploadLabel="Enviar"
              cancelLabel="Cancelar" fileLimit="4" invalidFileSizeMessageDetail="O tamanho máximo do ficheiro é 8MB"
              invalidFileLimitMessageDetail="Máximo 4 arquivos">
            </p-fileUpload>
          </div>

          <div class="p-col-12">
            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
            </p-progressBar>
          </div>

          <div class="p-field p-col-12">
            <hr>
            <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/emitter/certification/index">
              VOLTAR
            </button>
          </div>

        </form>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

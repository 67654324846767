<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/document/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS RECOLHIDOS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off">
          <div class="p-fluid p-formgrid p-grid">
            <div *ngIf="document.status" class="p-col-12 p-d-flex justify-content-end">
              <p-tag [severity]="getDocumentStatus()" [value]="getDocumentStatusLabel()"></p-tag>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">NIF do Emitente</label>
              <input pInputText type="text" name="document.nifFornecedor"
                [(ngModel)]="document.nifFornecedor" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-8">
              <label class="p-text-bold">Nome do Emitente</label>
              <input pInputText type="text" name="document.nomeFornecedor"
                [(ngModel)]="document.nomeFornecedor" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">NIF do Adquirente</label>
              <input pInputText name="nifConsumidor" [(ngModel)]="document.nifConsumidor" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-8">
              <label class="p-text-bold">Nome do Adquirente</label>
              <input pInputText name="nomeConsumidor" [(ngModel)]="document.nomeConsumidor" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Tipo de Documento*</label>
              <input pInputText type="text" name="descTipoFatura" [(ngModel)]="document.descTipoFatura" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Número da Documento</label>
              <input pInputText type="text" name="numero" [(ngModel)]="document.numero" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Data de Emissão</label>
              <p-calendar name="dataEmissao" [readonlyInput]="true" dateFormat="dd/mm/yy"
                [(ngModel)]="document.dataEmissao" disabled></p-calendar>
            </div>
  
            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Tipo comunicação</label>
              <input pInputText type="text" name="origem" [(ngModel)]="document.origem" disabled>
            </div>
  
            <div class="p-col-12 document-items">
              <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
                [value]="document.tbItensDocumentoGerados">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Base Tributável</th>
                    <th>Descrição</th>
                    <th>Taxa</th>
                    <th>Cod. Isenção</th>
                    <th>Imposto</th>
                    <th>Valor Total</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>
                      <span class="p-column-title">Base Tributável</span>
                      {{ item.valorBaseTributavelItens | number:'0.1-6':'pt' }}
                    </td>
  
                    <td>
                      <span class="p-column-title">Descrição</span>
                      {{ item.descItens }}
                    </td>
  
                    <td>
                      <span class="p-column-title">Taxa</span>
                      {{ item.descTaxaAplicavel }}
                    </td>
  
                    <td>
                      <span class="p-column-title">Cod. Isenção</span>
                      {{ item.codigoMotivoIsencao }}
                    </td>
  
                    <td>
                      <span class="p-column-title">imposto</span>
                      {{ item.valorImposto | number:'0.1-6':'pt' }}
                    </td>
  
                    <td>
                      <span class="p-column-title">Valor Total</span>
                      {{ item.valorItens | number:'0.1-2':'pt' }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
  
            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Base Tributável</label>
              <input pInputText name="baseTributavel" [(ngModel)]="document.baseTributavel" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Imposto</label>
              <input pInputText name="imposto" [(ngModel)]="document.imposto" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Valor Total</label>
              <input pInputText name="total" [(ngModel)]="document.total" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>
  
            <div class="p-field p-col-12 p-md-4">
              <label class="text-danger p-text-bold">
                Valor Anulado
              </label>
              <input pInputText name="canceledAmount" [(ngModel)]="document.canceledAmount" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" disabled>
            </div>
  
            <div class="p-col-12 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/document/read">
                VOLTAR
              </button>
            </div>
  
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { DocumentModule } from '../document/document.module';
import { FileDocumentDetailModule } from '../file-document-detail/file-document-detail.module';
import { LayoutModule } from '../layout/layout.module';
import { FileDocumentAnalysisReportModule } from './../file-document-analysis-report/file-document-analysis-report.module';
import { FileDocumentCancellationRequestRouter } from './file-document-cancellation-request.router';
import { GetComponent } from './get/get.component';
import { ReadCancellationRequestByEmitterComponent } from './read-cancellation-request-by-emitter/read-cancellation-request-by-emitter.component';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [GetComponent, ReadComponent, ReadCancellationRequestByEmitterComponent],
  imports: [
    CommonModule,
    FileDocumentCancellationRequestRouter,
    FormsModule,
    MDBBootstrapModule,

    DocumentModule,
    FileDocumentDetailModule,
    FileDocumentAnalysisReportModule,

    LayoutModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    FileUploadModule,
    DialogModule,
    OverlayPanelModule,
    TabViewModule,
    DropdownModule,
    EditorModule
  ],
  exports: [
    ReadCancellationRequestByEmitterComponent
  ]
})
export class FileDocumentCancellationRequestModule { }

import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {AuthService} from 'src/app/auth/auth.service';
import {ErrorHandlerService} from 'src/app/core/error-handler.service';
import {Person} from 'src/app/core/models/person';
import {User} from 'src/app/core/models/user';
import {UserService} from '../user.service';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  loading: boolean = false;
  loadingProfile: boolean = false;
  showPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmNewPassword: boolean = false;

  user = new User();
  person = new Person();

  currentPassword: string;
  newPassword: string;

  form: FormGroup;

  constructor(
    private userService: UserService,
    private auth: AuthService,
    private fb: FormBuilder,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    public location: Location
  ) {
    this.createForm()
  }

  ngOnInit(): void {
    this.getLoggedUser()
  }

  createForm() {
    this.form = this.fb.group({
      name: [{value: null, disabled: true}],
      nif: [{value: null, disabled: true}],
      mail: [{value: null, disabled: true}],
      phone: [{value: null, disabled: true}],
      address: [{value: null, disabled: true}],
      profile: [{value: null, disabled: true}],
      createdAt: [{value: null, disabled: true}]
    })
  }

  getLoggedUser() {
    const loggedUser = this.auth.getLoggedUser();

    this.form.patchValue({
      name: loggedUser.given_name,
      nif: loggedUser.preferred_username,
      mail: loggedUser.email,
    })
  }

  changeShowPassword(): boolean {
    this.showPassword = !this.showPassword;
    return this.showPassword;
  }

  changeShowNewPassword(): boolean {
    this.showNewPassword = !this.showNewPassword;
    return this.showNewPassword;
  }

  changeShowConfirmNewPassword(): boolean {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
    return this.showConfirmNewPassword;
  }


  update() {
    this.person = this.user.tbContribuinte.tbPessoa;
    this.person.enderecoPessoa = this.person.enderecoPessoa.toUpperCase();
    this.userService.update(this.user).then((response) => {
      this.user = response;
      this.messageService.add({
        severity: 'success',
        detail: 'Informações alteradas com sucesso.',
      });
    });
  }

  validOldPassword() {
    this.loading = true;
    this.user.senhaUsuario = this.newPassword;

    const data = {
      username: this.user.nifUsuario,
      password: this.currentPassword,
    };

    this.userService
      .validOldPassword(data)
      .then(() => {
        this.changePassword();
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  changePassword() {
    this.userService
      .changePassword(this.user)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Senha alterada com sucesso!',
          life: 25000,
        });
      })
      .catch((error) => this.errorHandler.handle(error));
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SITUAÇÃO DOS GRANDES CONTRIBUINTES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header text-center p-p-2">
        <mdb-icon fas icon="chevron-circle-left" size="2x" (click)="arrowLeft()" class="arrow p-mr-2"
          [class.disabled]="disableArrowLeft()"> </mdb-icon>

        <mdb-icon fas icon="chevron-circle-right" size="2x" (click)="arrowRight()" class="arrow"
          [class.disabled]="disableArrowRight()"> </mdb-icon>
      </mdb-card-header>

      <mdb-card-body>
        <div class="p-col-12 text-right">
          <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect (click)="displayFilter=true">
            <mdb-icon fas icon="search"></mdb-icon>
          </button>

          <button mdbBtn type="button" color="primary" size="sm" mdbWavesEffect (click)="exportReport()">
            <mdb-icon fas icon="file-pdf"></mdb-icon>
            EXPORTAR
          </button>
        </div>

        <table mdbTable striped="true" small="true">
          <tbody>
            <tr>
              <th scope="row" [style]="{width: '17%'}"> <strong>Contribuinte </strong></th>
              <td> {{ data.name }} </td>
            </tr>

            <tr>
              <th scope="row"> <strong>NIF </strong></th>
              <td> {{ data.nif }} </td>
            </tr>

            <tr>
              <th scope="row"> <strong>Cadastrado </strong></th>
              <td colspan="2">
                {{ data.isRegistered ? 'SIM' : 'NÃO' }}
              </td>
            </tr>

            <tr>
              <th scope="row"> <strong>Data de registo </strong></th>
              <td colspan="2">
                {{ data.registeredAt | date:'dd/MM/yyyy':'pt' }}
              </td>
            </tr>
          </tbody>
        </table>

        <hr>
        <p class="h6">Comunicação Por Série</p>

        <p-accordion>
          <ng-template ngFor let-serie [ngForOf]="data.series" let-i="index">
            <p-accordionTab [header]="serie.serie">
              <table mdbTable striped="true" small="true">
                <tbody>
                  <tr>
                    <th scope="row" [style]="{width: '17%'}"> <strong>Série: </strong></th>
                    <td> {{ serie.serie }} </td>
                  </tr>

                  <tr>
                    <th scope="row"> <strong>Data solicitação: </strong></th>
                    <td> {{ serie.date | date:'dd/MMM/yyyy' }} </td>
                  </tr>

                  <tr>
                    <th scope="row"> <strong>Último mês comunicado: </strong></th>
                    <td> {{ serie.lastMonthCommunicated || '--NUNCA COMUNICOU--' }} </td>
                  </tr>
                </tbody>
              </table>

              <hr>
              <p class="h6">Meses em atraso</p>
              <ul class="list-group list-group-horizontal-sm">
                <li class="list-group-item" *ngFor="let month of serie.monthsOverdue">
                  {{month.label}}
                </li>
              </ul>
            </p-accordionTab>
          </ng-template>
        </p-accordion>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Pesquisar por NIF" [(visible)]="displayFilter" [modal]="true" [responsive]="true"
  [style]="{width: '40vw', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <form #formFilter="ngForm" autocomplete="off" (ngSubmit)="filter()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>NIF</label>
        <input pInputText class="p-inputtext-sm" name="nif" [(ngModel)]="nif">
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="displayFilter=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="success" size="sm" mdbWavesEffect
          [disabled]="!formFilter.valid || loadingFilter">
          VALIDAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingFilter" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>
    </div>
  </form>
</p-dialog>

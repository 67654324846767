import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { DocumentItemModule } from './../document-item/document-item.module';
import { SharedModule } from './../shared/shared.module';
import { CreateComponent } from './create/create.component';
import { DocumentEmitterRouter } from './document-emitter-router';
import { GetComponent } from './get/get.component';
import { NewComponent } from './new/new.component';
import { ReadComponent } from './read/read.component';
import { GetCreditAndDebitItemsByInvoiceComponent } from './components/get-credit-and-debit-items-by-invoice/get-credit-and-debit-items-by-invoice.component';
import { TabViewModule } from 'primeng/tabview';
import { ReadStateComponent } from './read-state/read-state.component';
import { GetStateComponent } from './get-state/get-state.component';

@NgModule({
  declarations: [
    GetComponent,
    ReadComponent,
    CreateComponent,
    NewComponent,
    GetCreditAndDebitItemsByInvoiceComponent,
    ReadStateComponent,
    GetStateComponent
  ],
  imports: [
    CommonModule,
    DocumentEmitterRouter,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    SharedModule,
    DocumentItemModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    FileUploadModule,
    DialogModule,
    OverlayPanelModule,
    DropdownModule,
    InputMaskModule,
    CurrencyMaskModule,
    InputNumberModule,
    SidebarModule,
    TabViewModule
  ],
  exports: [
    GetCreditAndDebitItemsByInvoiceComponent,
  ]
})
export class DocumentEmitterModule { }

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/emission/new">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PEDIDO DE SÉRIES
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PARA O PORTAL E-FACTURA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Esta funcionalidade permite aos adquirentes comunicarem as suas facturas e outros documentos
      equivalentes que não foram comunicados pelos seus emitentes.
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" [formGroup]="emissionForm" (ngSubmit)="save()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12">
              <div formArrayName="pedidoEmissaoDocumentos">
                <div class="p-mb-3" style="border-bottom: 1px solid rgba(0, 0, 0, 0.5)">
                  <label class="p-col p-md-2 p-text-bold p-text-left item-label"> Tipo documento </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left item-label"> Quantidade </label>
                  <label class="p-col p-md-4 p-text-bold p-text-left item-label"> Descrição </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left item-label"> </label>
                </div>

                <div *ngFor="let item of items.controls; let i=index">
                  <div [formGroupName]="i">
                    <div class="p-grid">

                      <div class="p-field p-col-12 p-md-2" formGroupName="tbTipoDocumentoEmissao">
                        <p-dropdown class="p-inputtext-sm" name="tbTipoDocumentoEmissao" [options]="typesDocument"
                          placeholder="Selecione" formControlName="idTipoDocumentoEmissao">
                        </p-dropdown>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <input pInputText class="p-inputtext-sm" name="qtDocumentosSolicitado"
                          formControlName="qtDocumentosSolicitado">
                      </div>

                      <div class="p-field p-col-12 p-md-4">
                        <input pInputText class="p-inputtext-sm" name="descPedidoEmissaoDocumento"
                          formControlName="descPedidoEmissaoDocumento">
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <button type="button" mdbBtn color="danger" size="sm" pTooltip="Remover linha"
                          tooltipPosition="left" (click)="removeItem(i)">
                          <mdb-icon fas icon="times"></mdb-icon>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <button type="button" mdbBtn color="info" size="sm" pTooltip="Adicionar linha" tooltipPosition="right"
                (click)="addItem()" [style]="{margin: '5px 0 15px 0'}">
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr>
            </div>

            <div class="p-field p-col-12">
              <label>Observações</label>
              <textarea pInputTextarea name="descPedidoEmissaoFatura" rows="2"
                formControlName="descPedidoEmissaoFatura"></textarea>
            </div>

            <div class="p-col-12 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/emitter/emission/new">
                VOLTAR
              </button>

              <button type="submit" color="success" mdbBtn mdbWavesEffect [disabled]="emissionForm.invalid || loading">
                SALVAR
              </button>
            </div>

            <div class="p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
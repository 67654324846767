<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIROS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/consumer/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIRIZAR ATIVIDADES
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VINCULAR PRESTADOR DE SERVIÇOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form #formThirdParty="ngForm" enctype="multipart/form-data" (ngSubmit)="create(formThirdParty)">
          <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12 p-md-3">
              <label>NIF do terceiro</label>
              <input pInputText type="text" name="nifContribuinte" [(ngModel)]="thirdParty.nifContribuinte"
                #nifContribuinte="ngModel" required (input)="onInputNif($event)">

              <app-message [control]="nifContribuinte" error="required" text="Informe o NIF do terceiro.">
              </app-message>
              <span class="ui-message-error" *ngIf="!isNifValid && nifContribuinte.touched">
                NIF inválido ou inexistente.
              </span>
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome do terceiro</label>
              <input pInputText type="text" name="thirdName" [(ngModel)]="thirdName" required disabled
                placeholder="Informe o NIF do terceiro para visualizar o seu respetivo nome">
            </div>

            <p-table [value]="activities" [(selection)]="selectedActivities" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>SELECIONE A(S) ATIVIDADE(S) A AUTORIZAR</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td>{{rowData.label}}</td>
                </tr>
              </ng-template>
            </p-table>

            <div class="p-field p-col-12 text-right">
              <button type="button" mdbBtn color="light" mdbWavesEffect
                routerLink="/consumer/third-party/index">VOLTAR</button>

              <button mdbBtn type="submit" color="success" mdbWavesEffect
                [disabled]="!formThirdParty.valid || loading || !selectedActivities.length">
                ENVIAR
              </button>
            </div>

            <div class="p-field p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util/help-center">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CENTRAL DE AJUDA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SOLICITAÇÕES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <button type="button" mdbBtn size="md" color="primary" mdbWavesEffect routerLink="/emitter/occurrence/new">
          <mdb-icon fas icon="plus"></mdb-icon>
          NOVA SOLICITAÇÃO
        </button>

        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-10">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-3">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="subject" [(ngModel)]="filter.subject">
                  <label for="float-input">Assunto</label>
                </span>
              </div>

              <div class="p-field p-col-2">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                    dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
                  </p-calendar>
                  <label for="float-input">Data solicitação</label>
                </span>
              </div>

              <div class="p-field p-col-2">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                    dateFormat="dd/mm/yy" name="answeredAt" [(ngModel)]="filter.answeredAt">
                  </p-calendar>
                  <label for="float-input">Data resposta</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="occurrences"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="totalRecords"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ totalRecords | number:'1.0-0'}} pedidos de certificação.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'50%'}">Assunto</th>
              <th>Enviado em</th>
              <th>Respondido em</th>
              <th>Estado</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Assunto</span>
                {{ rowData.assunto}}
              </td>
              <td>
                <span class="p-column-title">Enviado em</span>
                {{ rowData.dataCria | date:'medium' }}
              </td>
              <td>
                <span class="p-column-title">Respondido em</span>
                {{ rowData.dataResposta | date:'medium' }}
              </td>
              <td>
                <span class="p-column-title">Estado</span>
                <div class="badges">
                  <span [class]="['p-tag', rowData.statusColor]">
                    {{ rowData.status }}
                  </span>
                </div>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais detalhes" tooltipPosition="left"
                  class="btn-block" [style]="{padding: '4px 0'}"
                  [routerLink]="['/emitter/occurrence/show', rowData.id]">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<!--
<p-dialog [header]="isEditing ? 'DETALHES DA SOLICITAÇÃO' : 'NOVA SOLICITAÇÃO'" [(visible)]="displayModal"
  [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <form #occurrenceForm="ngForm" (ngSubmit)="save()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>Assunto</label>
        <input pInputText name="assunto" [(ngModel)]="occurrence.assunto" placeholder="Informe o assunto da solicitação"
          required>
      </div>

      <div class="p-field p-col-12">
        <label>Mensagem</label>
        <p-editor name="detalhe" [(ngModel)]="occurrence.detalhe" [style]="{'height':'150px'}"></p-editor>
      </div>

      <div class="p-field p-col-12">
        <label>Resposta</label>
        <p-editor name="resposta" [(ngModel)]="occurrence.resposta" [readonly]="true" [style]="{'height':'150px'}">
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
            </span>
          </ng-template>
        </p-editor>
      </div>

      <ul class="list-group list-group-horizontal" *ngIf="isEditing">
        <li class="list-group-item"><strong>Estado: </strong> {{occurrence.status}} </li>
        <li class="list-group-item"><strong>Enviada em: </strong> {{occurrence.dataCria | date:'medium'}} </li>
        <li class="list-group-item"><strong>Respondida em: </strong> {{occurrence.dataResposta | date:'medium'}} </li>
      </ul>

      <div class="p-field p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayModal=false">
          FECHAR
        </button>

        <button mdbBtn type="submit" color="success" mdbWavesEffect *ngIf="!isEditing"
          [disabled]="!occurrenceForm.valid || loadingSave">
          ENVIAR
        </button>

        <button mdbBtn type="submit" color="success" mdbWavesEffect *ngIf="isEditing"
          [disabled]="!occurrenceForm.valid || loadingSave || occurrence.tbSolicitacaoEstado !== 'PENDENTE'">
          SALVAR
        </button>
      </div>

      <div class="p-field p-col-12">
        <p-progressBar *ngIf="loadingSave" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>
    </div>
  </form>
</p-dialog> -->

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DocumentConsumer } from 'src/app/core/models/document-consumer';
import { DocumentConsumerRegService } from '../document-consumer-reg.service';
import { DocumentConsumerRegistration, DocumentConsumerRegistrationForm, SimpleDocumentConsumerRegistrationForm } from 'src/app/core/models/document-consumer-registration';
import { UntypedFormBuilder } from '@angular/forms';
import { ExemptionReason } from 'src/app/core/models/exemption-reason';
import { ExemptionReasonService } from 'src/app/exemption-reason/exemption-reason.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { FileDocumentEmitterService } from 'src/app/file-document-emitter/file-document-emitter.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  locale = new Locale();
  document = new DocumentConsumerRegistration();
  documentForm =  new DocumentConsumerRegistrationForm(this.fb).builder();
  validationDocumentForm = new SimpleDocumentConsumerRegistrationForm(this.fb).builder();
  canValidate = false;
  loading = false;
  displayExemption = false;
  exemptionReasons: ExemptionReason[];
  showValidationModal = false;
  files = [];

  constructor(
    private documentConsumerRegService: DocumentConsumerRegService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private exemptionReasonService: ExemptionReasonService,
    private fileService: FileDocumentEmitterService
  ) { }

  async ngOnInit(): Promise<void> {
    const id: number = this.route.snapshot.params.id;
    await this.getExemptionReasons();
    this.get(id);
    this.getFile(id);
  }

  toggleValidationModal(value: boolean): void {
    this.showValidationModal = value;
  }

  onExemptionSelect(event) {
    this.documentForm.get('tbMotivoIsencao').patchValue({
      idMotivoIsencao: event.data.idMotivoIsencao,
      codigoMotivoIsencao: event.data.codigoMotivoIsencao,
      desMotivoIsencao: event.data.desMotivoIsencao,
    });
    this.displayExemption = false;
  }

  showDisplayExemption() {
    this.displayExemption = true;
  }

  async getExemptionReasons() {
    try {
      const response: ExemptionReason[] =
        await this.exemptionReasonService.getAll();
      this.exemptionReasons = response.filter((reason) => reason.tbTipoImposto.idTipoImposto === 1);
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  getFile(id: number) {
    this.documentConsumerRegService.getFile(id)
      .then((response) => {
        this.files = response;
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  downloadFile(file: FileDocument) {
    this.fileService.getConsumerFile(file)
      .then(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
      });
  }

  get(id: number) {
    this.documentConsumerRegService.get(id)
      .then((response) => {
        this.document = response;
        this.documentForm.patchValue({
          ...response,
          serie: this.document.serie || (this.document.numDocumento?.substring(0, 9) || null),
          numero: this.document.numero || (this.document.numero?.substring(9) || null)
        });
        this.validationDocumentForm.patchValue({
          desDocumentoGeradoConsumidor: response.desDocumentoGeradoConsumidor,
          tbMotivoIsencao: this.exemptionReasons.find((reason) => {
            return Number(reason.codigoMotivoIsencao) === Number(response.cdMotivoIsencao);
          })
        });
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  save(document: DocumentConsumerRegistration) {
    this.toggleValidationModal(false);
    this.loading = true;
    document.idDocumentoGeradoConsumidor = this.documentForm.get('idDocumentoGeradoConsumidor').value;
    this.documentConsumerRegService.validate(document, true)
    .then((response) => {
      this.documentForm.patchValue({
        ...response,
      });
      this.messageService.add({
        severity: 'success',
        detail: 'Documento validado com sucesso!',
      });

    })
    .catch((error) => this.errorHandler.handle(error))
    .finally(() => {
      this.loading = false;
    });
  }

  refuse(document) {
    this.toggleValidationModal(false);
    this.loading = true;
    document.idDocumentoGeradoConsumidor = this.documentForm.get('idDocumentoGeradoConsumidor').value;
    this.documentConsumerRegService.validate(document, false)
    .then((response) => {
      this.documentForm.patchValue({
        ...response,
      });
      this.messageService.add({
        severity: 'success',
        detail: 'Documento recusado com sucesso!',
      });

    })
    .catch((error) => this.errorHandler.handle(error))
    .finally(() => {
      this.loading = false;
    });
  }

  verify() {
    this.loading = true;
    this.documentConsumerRegService.verify(this.documentForm.getRawValue())
    .then((response) => {
      this.messageService.add({
        severity: 'success',
        detail: 'Documento verificado com sucesso! Deve proceder com a sua validação.',
      });
      this.canValidate = true;
    })
    .catch((error) => {
      console.log('erro', error);
      if (error.status === 409) {
        this.messageService.add({ 
          severity: 'error', 
          summary: 'Documento já comunicado',
          detail: 'Este documento já foi comunicado pelo seu emitente.',
          life: 10000
        });
        this.validationDocumentForm.get('desDocumentoGeradoConsumidor')
          .patchValue('Este documento já foi comunicado pelo seu emitente/fornecedor. Por favor verificar na lista de documentos comunicados pelo Emitente.');
      } else if (error.status === 400) {
        this.messageService.add({ 
          severity: 'error', 
          summary: 'Dados inválidos',
          detail: 'O número de série informado na referência não é valido.',
          life: 10000
        });
        this.validationDocumentForm.get('desDocumentoGeradoConsumidor')
          .patchValue('O número de série informado na referência não é valido. Por favor reveja os seus dados!');
      } else {
        this.errorHandler.handle(error);
      }
      this.canValidate = false;
    })
    .finally(() => {
      this.loading = false;
      this.toggleValidationModal(true);
    });

  }

  getDocumentStatus() {
    const status = this.documentForm.get('status').value;
    return status ? 'success' : (status === false ? 'danger' : 'warning');
  }

  getDocumentStatusLabel() {
    const status = this.documentForm.get('status').value;
    return status ? 'ACEITE' : (status === false ? 'RECUSADO' : 'PENDENTE');
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthKeycloakService } from 'src/app/auth/services/auth-keycloak.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public registerUrl: string = environment.registerUrl;

  constructor(
    public auth: AuthKeycloakService,
    public errorHandler: ErrorHandlerService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.verifyIfIsLoggedIn();
  }

  get user() {
    return this.auth.getLoggedUser();
  }

  verifyIfIsLoggedIn() {
    this.auth.isLoggedIn().then((result) => (this.isLoggedIn = result));
  }

  logout() {
    this.auth.logout();
  }

  redirectToLogin() {
    this.auth.redirectToLogin();
  }
  
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthValidFirstUser } from '../auth/auth.valid-first-user';
import { AuthGuard } from './../auth/auth.guard';
import { CreateForPortalComponent } from './create-for-portal/create-for-portal.component';
import { CreateComponent } from './create/create.component';
import { GetComponent } from './get/get.component';
import { IndexComponent } from './index/index.component';
import { ReadComponent } from './read/read.component';

const routes: Routes = [
  {
    path: 'index',
    component: IndexComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },

  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },

  {
    path: 'read/:id',
    component: GetComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },

  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },

  {
    path: 'create/portal',
    component: CreateForPortalComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CertificationEmitterRouter { }

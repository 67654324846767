import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { DocumentState, DocumentView } from 'src/app/core/models/document';
import { environment } from 'src/environments/environment';

export class DocumentFilter {
  page = 0;
  rows = 15;

  nifEmissor: string;
  nifConsumidor: string;
  numDocumento: string;
  numSerieDocumento: string;
  dtEmissaoDocumentoDe: Date;
  dtEmissaoDocumentoAte: Date;
  hasTax: any = null;

  minValue: number = null;
  maxValue: number = null;
  typeDocumentId: number;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGerado`;
  }

  getFilterParams(filter: DocumentFilter): HttpParams {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    if (filter.hasTax !== null)
      params = params.append('comImpostoConsumo', filter.hasTax.toString());

    if (filter.minValue !== null) {
      params = params.append('valorTotalDe', filter.minValue.toString());
    }

    if (filter.maxValue !== null) {
      params = params.append('valorTotalAte', filter.maxValue.toString());
    }

    if (filter.typeDocumentId) {
      params = params.append('idTipoDocumento', filter.typeDocumentId.toString());
    }

    return params;
  }

  async read(filter: DocumentFilter): Promise<any> {
    const params: HttpParams = this.getFilterParams(filter);

    const response = await this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise();
    return {
      body: <DocumentView[]>response.content,
      total: response.totalElements
    };
  }

  async readState(filter: DocumentFilter): Promise<any> {
    const params: HttpParams = this.getFilterParams(filter);

    const response = await this.http.get<any>(`${this.API_URL}/estado`, { params })
      .toPromise();
    return {
      body: <DocumentView[]>response.content,
      total: response.totalElements
    };
  }

  async readByFile(fileId: number, filter: DocumentFilter): Promise<any> {
    const params: HttpParams = this.getFilterParams(filter);

    const response = await this.http.get<any>(`${this.API_URL}/ficheiro/${fileId}`, { params })
      .toPromise();
    return {
      body: <DocumentView[]>response.content,
      total: response.totalElements
    };
  }

  async get(id: number): Promise<DocumentView> {
    const response = await this.http.get<DocumentView>(`${this.API_URL}/geral/${id}`)
      .toPromise();
    const document = response as DocumentView;
    this.convertFields([document]);
    return document;
  }

  async getState(id: number): Promise<DocumentState> {
    const response = await this.http.get<DocumentState>(`${this.API_URL}/estado/${id}`)
      .toPromise();
    const document = response as DocumentState;
    this.convertFieldsState([document]);
    return document;
  }

  async exportExcelOrPdf(type: string, filter: DocumentFilter): Promise<Blob> {
    if (type === 'xls') type = 'excel';

    const params: HttpParams = this.getFilterParams(filter);

    params.delete('size');
    params.delete('rows');

    return this.http.get<Blob>(`${this.API_URL}/export/${type}`, {
      responseType: 'blob',
      params
    }).toPromise();
  }

  private convertFields(documents: DocumentView[]) {
    for (const document of documents) {
      document.canceledAmount =
        document.totalValorBaseTributavel - document.valorDisponivelAnular;

      document.dtAlter = moment(document.dtAlter,
        'YYYY-MM-DD').toDate();

      document.dtCria = moment(document.dtCria,
        'YYYY-MM-DD').toDate();

      if (document.dtEmissaoDocumento) {
        document.dtEmissaoDocumento = moment(document.dtEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
    }
  }

  private convertFieldsState(documents: DocumentState[]) {
    for (const document of documents) {
      document.canceledAmount =
        document.baseTributavel - document.disponivelAnular;

      if (document.dtAlter) {
        document.dtAlter = moment(document.dtAlter,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtCria) {
        document.dtCria = moment(document.dtCria,
          'YYYY-MM-DD').toDate();
      }

      if (document.dataEmissao) {
        document.dataEmissao = moment(document.dataEmissao,
          'YYYY-MM-DD').toDate();
      }
    }
  }
}

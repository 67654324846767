import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { EmissionDocumentEmitterService } from 'src/app/emission-document-emitter/emission-document-emitter.service';
import { EmissionDocumentDTO } from 'src/app/emission-document-emitter/types/emission-document';
import { Authorization } from 'src/app/core/models/authorization';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  // loading = false;
  // display: boolean = false;
  displaySerieToTransport: boolean = false;
  displaySerieTransported: boolean = false;
  loadingSerieToTransport: boolean = false;

  emissionsDocuments: EmissionDocumentDTO[] = [];
  selectedEmissionsDocuments: EmissionDocumentDTO[] = [];
  transportedSeries: Authorization[] = [];

  constructor(
    public auth: AuthService,
    private confirmationService: ConfirmationService,
    private service: EmissionDocumentEmitterService,
    private errorHandler: ErrorHandlerService,
  ) {
  }

  ngOnInit(): void {
    // if (!this.auth.jwtPayload.statusAssinatura) this.display = true;

    // const blockNote = sessionStorage.getItem('blockNote');

    // if (!blockNote) {
    //   this.display = true;
    //   sessionStorage.setItem('blockNote', 'true');

    //   return;
    // }

    // this.read();
  }

  confirm() {
    this.confirmationService.confirm({
      message: `
      Deseja transportar as séries selecionadas?
      `,
      accept: () => {
        this.sendSerieToTransportAutomatic();
      },
    });
  }

  read() {
    this.service.readSerieToTransportAutomatic()
      .then((response) => {
        this.emissionsDocuments = response;

        if (this.emissionsDocuments.length) {
          this.displaySerieToTransport = true;
        }
      })
  }

  getSelectedEmissionsDocuments(event: EmissionDocumentDTO[]) {
    this.selectedEmissionsDocuments = event;
  }

  sendSerieToTransportAutomatic() {
    this.loadingSerieToTransport = true;

    this.service.sendSerieToTransportAutomatic(this.selectedEmissionsDocuments)
      .then((result) => {
        this.transportedSeries = result;
        this.displaySerieToTransport = false;
        this.displaySerieTransported = true;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingSerieToTransport = false);
  }
}

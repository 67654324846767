import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full',
  },

  // {
  //   path: '',
  //   loadChildren: () => import('./start/start.module')
  //     .then(m => m.StartModule)
  // },

  {
    path: 'control-document',
    loadChildren: () =>
      import('./control-document/control-document.module').then(
        (m) => m.ControlDocumentModule
      ),
  },

  {
    path: 'notifications',
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationModule
      ),
  },

  {
    path: 'notifications/by-user',
    loadChildren: () =>
      import('./notification-user/notification-user.module').then(
        (m) => m.NotificationUserModule
      ),
  },

  {
    path: 'emitter/emission',
    loadChildren: () =>
      import('./emission-emitter/emission-emitter.module').then(
        (m) => m.EmissionEmitterModule
      ),
  },

  {
    path: 'typography/emission',
    loadChildren: () =>
      import('./emission-typography/emission-typography.module').then(
        (m) => m.EmissionTypographyModule
      ),
  },

  {
    path: 'emission-document',
    loadChildren: () =>
      import('./emission-document/emission-document.module').then(
        (m) => m.EmissionDocumentModule
      ),
  },

  {
    path: 'emitter/emission-document',
    loadChildren: () =>
      import(
        './emission-document-emitter/emission-document-emitter.module'
      ).then((m) => m.EmissionDocumentEmitterModule),
  },

  {
    path: 'typography/emission-document',
    loadChildren: () =>
      import(
        './emission-document-typography/emission-document-typography.module'
      ).then((m) => m.EmissionDocumentTypographyModule),
  },

  {
    path: 'document',
    loadChildren: () =>
      import('./document/document.module').then((m) => m.DocumentModule),
  },

  {
    path: 'emitter/document',
    loadChildren: () =>
      import('./document-emitter/document-emitter.module').then(
        (m) => m.DocumentEmitterModule
      ),
  },

  {
    path: 'consumer/document',
    loadChildren: () =>
      import('./document-consumer/document-consumer.module').then(
        (m) => m.DocumentConsumerModule
      ),
  },

  {
    path: 'file-document',
    loadChildren: () =>
      import('./file-document/file-document.module').then(
        (m) => m.FileDocumentModule
      ),
  },

  {
    path: 'admin/file-document',
    loadChildren: () =>
      import('./file-document-admin/file-document-admin.module').then(
        (m) => m.FileDocumentAdminModule
      ),
  },

  {
    path: 'emitter/file-document',
    loadChildren: () =>
      import('./file-document-emitter/file-document-emitter.module').then(
        (m) => m.FileDocumentEmitterModule
      ),
  },

  {
    path: 'emitter/document-online',
    loadChildren: () =>
      import('./document-online-emitter/document-online-emitter.module').then(
        (m) => m.DocumentOnlineEmitterModule
      ),
  },

  {
    path: 'document-consumer',
    loadChildren: () =>
      import('./document-consumer-reg/document-consumer-reg.module').then(
        (m) => m.DocumentConsumerRegModule
      ),
  },

  {
    path: 'emitter/document-consumer',
    loadChildren: () =>
      import(
        './document-consumer-reg-emitter/document-consumer-reg-emitter.module'
      ).then((m) => m.DocumentConsumerRegEmitterModule),
  },

  {
    path: 'consumer/document-consumer',
    loadChildren: () =>
      import(
        './document-consumer-reg-consumer/document-consumer-reg-consumer.module'
      ).then((m) => m.DocumentConsumerRegConsumerModule),
  },

  {
    path: 'user-admin',
    loadChildren: () =>
      import('./user-admin/user-admin.module').then((m) => m.UserAdminModule),
  },

  {
    path: 'routines',
    loadChildren: () =>
      import('./routines/routines.module').then((m) => m.RoutinesModule),
  },

  {
    path: 'emitter/authorization',
    loadChildren: () =>
      import('./authorization-emitter/authorization-emitter.module').then(
        (m) => m.AuthorizationEmitterModule
      ),
  },

  {
    path: 'util/authorization',
    loadChildren: () =>
      import('./authorization-util/authorization-util.module').then(
        (m) => m.AuthorizationUtilModule
      ),
  },

  {
    path: 'authorization',
    loadChildren: () =>
      import('./authorization/authorization.module').then(
        (m) => m.AuthorizationModule
      ),
  },

  {
    path: 'emitter/certification',
    loadChildren: () =>
      import('./certification-emitter/certification-emitter.module').then(
        (m) => m.CertificationEmitterModule
      ),
  },

  {
    path: 'typography/certification',
    loadChildren: () =>
      import('./certification-typography/certification-typography.module').then(
        (m) => m.CertificationTypographyModule
      ),
  },

  {
    path: 'agent/certification',
    loadChildren: () =>
      import('./certification/certification.module').then(
        (m) => m.CertificationModule
      ),
  },

  {
    path: 'util/certification',
    loadChildren: () =>
      import('./certification-util/certification-util.module').then(
        (m) => m.CertificationUtilModule
      ),
  },

  {
    path: 'tutorial',
    loadChildren: () =>
      import('./tutorial/tutorial.module').then((m) => m.TutorialModule),
  },

  {
    path: 'mail',
    loadChildren: () => import('./mail/mail.module').then((m) => m.MailModule),
  },

  {
    path: 'document-online',
    loadChildren: () =>
      import('./document-online/document-online.module').then(
        (m) => m.DocumentOnlineModule
      ),
  },

  {
    path: 'current-account',
    loadChildren: () =>
      import('./current-account/current-account.module').then(
        (m) => m.CurrentAccountModule
      ),
  },

  {
    path: 'document-temp',
    loadChildren: () =>
      import('./document-temp/document-temp.module').then(
        (m) => m.DocumentTempModule
      ),
  },

  {
    path: 'report-routines-analysis-file',
    loadChildren: () =>
      import(
        './report-routines-analysis-file/report-routines-analysis-file.module'
      ).then((m) => m.ReportRoutinesAnalysisFileModule),
  },

  {
    path: 'agent/taxpayer',
    loadChildren: () =>
      import('./taxpayer/taxpayer.module').then((m) => m.TaxpayerModule),
  },

  {
    path: 'util/help-center',
    loadChildren: () =>
      import('./help-center/help-center.module').then(
        (m) => m.HelpCenterModule
      ),
  },

  {
    path: 'occurrence',
    loadChildren: () =>
      import('./occurrence/occurrence.module').then((m) => m.OccurrenceModule),
  },

  {
    path: 'emitter/occurrence',
    loadChildren: () =>
      import('./occurrence-emitter/occurrence-emitter.module').then(
        (m) => m.OccurrenceEmitterModule
      ),
  },

  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },

  {
    path: 'taxpayer',
    loadChildren: () =>
      import('./taxpayer/taxpayer.module').then((m) => m.TaxpayerModule),
  },

  {
    path: 'statistic',
    loadChildren: () =>
      import('./statistic/statistic.module').then((m) => m.StatisticModule),
  },

  {
    path: 'database-manager',
    loadChildren: () =>
      import('./database-manager/database-manager.module').then(
        (m) => m.DatabaseManagerModule
      ),
  },

  {
    path: 'file-document-cancellation-request',
    loadChildren: () =>
      import(
        './file-document-cancellation-request/file-document-cancellation-request.module'
      ).then((m) => m.FileDocumentCancellationRequestModule),
  },

  {
    path: 'third-party',
    loadChildren: () =>
      import('./third-party/third-party.module').then(
        (m) => m.ThirdPartyModule
      ),
  },

  {
    path: 'emitter/third-party',
    loadChildren: () =>
      import('./third-party-emitter/third-party-emitter.module').then(
        (m) => m.ThirdPartyEmitterModule
      ),
  },

  {
    path: 'consumer/third-party',
    loadChildren: () =>
      import('./third-party-consumer/third-party-consumer.module').then(
        (m) => m.ThirdPartyConsumerModule
      ),
  },
  {
    path: 'admin/tax-rate',
    loadChildren: () =>
      import('./tax-rate/tax-rate.module').then((m) => m.TaxRateModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRouterModule {}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/file-document/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FICHEIROS RECOLHIDOS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label>Remetente</label>
              <input pInputText class="p-inputtext-sm" name="nomePessoa"
                [(ngModel)]="fileDocument.tbContribuinte.tbPessoa.nomePessoa" disabled>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>NIF</label>
              <input pInputText class="p-inputtext-sm" name="nifPessoa"
                [(ngModel)]="fileDocument.tbContribuinte.tbPessoa.nifPessoa" disabled>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Telefone</label>
              <input pInputText class="p-inputtext-sm" name="telefonePessoa"
                [(ngModel)]="fileDocument.tbContribuinte.tbPessoa.telefonePessoa" disabled>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Endereço</label>
              <input pInputText class="p-inputtext-sm" name="enderecoPessoa"
                [(ngModel)]="fileDocument.tbContribuinte.tbPessoa.enderecoPessoa" disabled>
            </div>

            <div class="p-field p-col-12 p-md-6">
              <label>Descrição do Ficheiro</label>
              <input pInputText class="p-inputtext-sm" name="nomeArquivoDocumentoGerado"
                [(ngModel)]="fileDocument.nomeArquivoDocumentoGerado" disabled>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Data de comunicação</label>
              <p-calendar class="p-inputtext-sm" name="dtCria" dateFormat="dd/mm/yy" [locale]="locale.pt"
                [(ngModel)]="fileDocument.dtCria" disabled>
              </p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Período de faturação</label>
              <input pInputText class="p-inputtext-sm" name="formattedPeriod" [(ngModel)]="fileDocument.formattedPeriod"
                disabled>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Status</label>
              <input pInputText class="p-inputtext-sm" name="statusAnaliseArquivo"
                [(ngModel)]="fileDocument.statusAnaliseArquivo" disabled>
            </div>

            <div class="p-col-12">
              <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left"
                pTooltip="Transferir o ficheiro" (click)="getFile(fileDocument)">
                <i class="fa fa-download"></i>
              </button>
            </div>
          </div>
        </form>

        <p-tabView>
          <p-tabPanel header="ANÁLISE">
            <table mdbTable striped="true" small="true">
              <tbody>
                <tr>
                  <th scope="row" [style]="{width: '10%'}"> <strong>1ª análise </strong></th>
                  <td colspan="2">
                    <div *ngIf="fileDocument.step1 === null; else elseNull">
                      <mdb-icon fas icon="sync" class="mr-2 gray-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull>
                      <div *ngIf="fileDocument.step1; else elseBlock">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>
                      <ng-template #elseBlock>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>
                </tr>

                <tr>
                  <th scope="row"> <strong>2ª análise </strong></th>
                  <td colspan="2">
                    <div *ngIf="fileDocument.step2 === null; else elseNull2">
                      <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull2>
                      <div *ngIf="fileDocument.step2; else elseBlock2">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>
                      <ng-template #elseBlock2>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>
                </tr>

                <tr>
                  <th scope="row"> <strong>3ª análise </strong></th>
                  <td colspan="2">
                    <div *ngIf="fileDocument.step3 === null; else elseNull3">
                      <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
                      </mdb-icon>
                    </div>

                    <ng-template #elseNull3>
                      <div *ngIf="fileDocument.step3; else elseBlock3">
                        <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                        </mdb-icon>
                      </div>
                      <ng-template #elseBlock3>
                        <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
                      </ng-template>
                    </ng-template>
                  </td>
                </tr>

                <tr>
                  <th scope="row"> <strong>Status de análise </strong></th>
                  <td colspan="2">
                    {{fileDocument.fileAnalise}}
                  </td>
                </tr>

                <tr>
                  <th scope="row"> <strong>Status do ficheiro </strong></th>
                  <td colspan="2">
                    {{fileDocument.status ? 'Ativo' : 'Anulado'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </p-tabPanel>

          <p-tabPanel header="RELATÓRIO DE ANÁLISE">
            <app-read-report-by-file></app-read-report-by-file>
          </p-tabPanel>

          <p-tabPanel header="RELATÓRIO DE CARREGAMENTO">
            <app-get-file-document-detail></app-get-file-document-detail>
          </p-tabPanel>

          <p-tabPanel header="DOCUMENTOS CARREGADOS">
            <app-read-document-by-file></app-read-document-by-file>
          </p-tabPanel>
        </p-tabView>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

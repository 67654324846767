<form #mailForm="ngForm" (ngSubmit)="send()">
  <div class="p-fluid p-formgrid p-grid">
    <!--
    <div class="p-field p-col-12">
      <label class>Para</label>
      <p-chips name="recipients" [(ngModel)]="mail.recipients" placeholder="NIFs dos destinatários" required>
      </p-chips>
    </div>
    -->

    <div class="p-field p-col-12">
      <label>Assunto</label>
      <input pInputText name="subject" [(ngModel)]="mail.subject" placeholder="Informe o assunto do e-mail">
    </div>

    <div class="p-field p-col-12">
      <label>Mensagem</label>
      <p-editor name="body" [(ngModel)]="mail.body" [style]="{'height':'320px'}"></p-editor>
    </div>

    <div class="p-field p-col-12 text-right">
      <hr>
      <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/admin">VOLTAR</button>

      <button mdbBtn type="submit" color="success" mdbWavesEffect
        [disabled]="!mailForm.valid || loading">ENVIAR</button>
    </div>

    <div class="p-field p-col-12">
      <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
      </p-progressBar>
    </div>
  </div>
</form>

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="certifications"
  [loading]="loading" [paginator]="true" [rows]="filtro.rows" [lazy]="true" [totalRecords]="totalRecords"
  (onLazyLoad)="onChangePage($event)">

  <ng-template pTemplate="header">
    <tr>
      <th [style]="{width:'50%'}">Tipo de Certificação</th>
      <th>Data do Pedido</th>
      <th>Nº de certificação</th>
      <th>Estado</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <span class="p-column-title">Tipo de Certificação</span>
        {{ rowData.tbTipoCertificacao.descTipoCertificacao }}
      </td>

      <td>
        <span class="p-column-title">Data de Certificação</span>
        {{rowData.dtCriaPedidoCertificacao | date:'dd/MM/yyyy'}}
      </td>

      <td>
        <span class="p-column-title">Nº de Certificação</span>
        {{ rowData.numeroCertificacao }}
      </td>

      <td>
        <span class="p-column-title">Estado</span>
        {{rowData.estadoPedidoCertificacao ? 'Certificado' : 'Não certificado'}}
      </td>
    </tr>
  </ng-template>
</p-table>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { GetForThirdPartyComponent } from './get-for-third-party/get-for-third-party.component';
import { GetComponent } from './get/get.component';
import { ReadForThirdPartyComponent } from './read-for-third-party/read-for-third-party.component';
import { ReadComponent } from './read/read.component';

const routes: Routes = [
  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read/:id',
    component: GetComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'read-for-third-party',
    component: ReadForThirdPartyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read-for-third-party/:id',
    component: GetForThirdPartyComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FileDocumentRouter {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { GetGreatTaxpayerSituationComponent } from './get-great-taxpayer-situation/get-great-taxpayer-situation.component';
import { TaxpayerReadNotHaveSerieComponent } from './taxpayer-read-not-have-serie/taxpayer-read-not-have-serie.component';
import { TaxpayerReadWithSerieWithoutDocumentComponent } from './taxpayer-read-with-serie-without-document/taxpayer-read-with-serie-without-document.component';
import { ReadComponent } from './read/read.component';

const routes: Routes = [
  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_GERAL_USUARIO'] }
  },

  {
    path: 'great-taxpayer-situation',
    component: GetGreatTaxpayerSituationComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_GERAL_USUARIO'] }
  },

  {
    path: 'without-series',
    component: TaxpayerReadNotHaveSerieComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_GERAL_USUARIO'] }
  },

  {
    path: 'without-document',
    component: TaxpayerReadWithSerieWithoutDocumentComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_GERAL_USUARIO'] }
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TaxpayerRouter { }

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TRANSPORTAR SÉRIES DE FORMA RÁPIDA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <p>
        <span class="text-info">
          <mdb-icon fas icon="info-circle"></mdb-icon>
        </span>
        Nesta tela o emitente pode transportar todas ou uma lista de séries de forma automática.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <div class="p-col-12">
          <button type="button" mdbBtn color="teal" (click)="confirm()"
            [disabled]="!selectedEmissionsDocuments.length || loadingTransportSerie">
            TRANSPORTAR
          </button>
        </div>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <app-grid-serie-to-transport [emissionsDocuments]="emissionsDocuments"
          (getSelectedEmissionsDocuments)="getSelectedEmissionsDocuments($event)"></app-grid-serie-to-transport>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="SÉRIES TRANSPORTADAS" [(visible)]="displaySerieTransported" [style]="{width: '60vw'}" [modal]="true">
  <div class="alert alert-success" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Séries transportadas com sucesso. Veja as novas séries geradas na lista abaixo.
    Para mais informações acessa a sua <a routerLink="/emitter/emission-document/read"> área de gestão de séries</a>
  </div>

  <div>
    <app-grid-serie-transported [transportedSeries]="transportedSeries"></app-grid-serie-transported>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displaySerieTransported=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayBlockedSerieMessage"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
>
  <div class="alert alert-danger" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Sujeito passivo bloqueado para solicitação de novas séries. Deve comunicar
    as faturas em falta!
    <p class="p-mt-2">Nome: {{ blockedTaxpayerInfo?.nomePessoa }}</p>
    <p>NIF: {{ blockedTaxpayerInfo?.nifContribuinte }}</p>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBlockedSerieMessage = false"
      label="Ok"
      styleClass="p-button-text"
    >
    </p-button>
  </ng-template>
</p-dialog>
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttp } from '../auth/app-http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  API_URL: string;
  USER_API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/perfils/all`;
    this.USER_API_URL = `${environment.apiUrl}/usuarios`;
  }

  dropdown(): Promise<any> {
    return this.http.get<any>(this.API_URL)
      .toPromise();
  }

  changeEmail({ userId, email }): Promise<any> {
    let params = new HttpParams();
    params = params.set('email', email);
    params = params.set('idUsuario', userId);

    return this.http.patch<any>(`${this.USER_API_URL}/alterarEmail`, null, { params })
      .toPromise();
  }

  validChangeEmail(userId: number): Promise<any> {
    let params = new HttpParams();
    params = params.set('idUsuario', userId.toString());

    return this.http.patch<any>(`${this.USER_API_URL}/confirmaAlterarcaoEmail`, null, { params })
      .toPromise();
  }
}

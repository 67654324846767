import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  isLoggedIn: boolean = false;
  efaturaStateLink: string = environment.efaturaStateLink;

  constructor(public auth: AuthService) {}

  ngOnInit(): void {
    this.auth.isLoggedIn().then((result) => (this.isLoggedIn = result));
  }
}

import { OccurrenceService } from './../occurrence.service';
import { Component, OnInit } from '@angular/core';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Occurrence } from './../../core/models/occurrence';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { OccurrenceFilter } from '../occurrence.service';
import * as moment from "moment";

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  loading: boolean = true;
  displayModal: boolean = false;
  locale = new Locale();
  totalRecords = 0;
  filter = new OccurrenceFilter();
  occurrences = [];

  occurrence = new Occurrence();

  constructor(
    private occurrenceEmitterService: OccurrenceService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;

    this.occurrenceEmitterService.read(this.filter)
      .then((result) => {
        this.totalRecords = result.total;
        this.occurrences = result.content.map(value => {
          value.dataCria = moment(value.dataCria, 'YYYY-MM-DD').toDate();
          return value;
        });
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }
}

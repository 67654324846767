<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-6">
    <div class="card">
      <p-tabView>
        <p-tabPanel header="MENSAL">
          <app-statistic-read-month></app-statistic-read-month>
        </p-tabPanel>
        <p-tabPanel header="ANUAL">
          <app-statistic-read-year></app-statistic-read-year>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <div class="p-col-6">
    <!-- <app-statistic-ranking-short></app-statistic-ranking-short> -->
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Notification } from './../../core/models/notification';
import { NotificationUserFilter, NotificationUserService } from './../notification-user.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  total = 0;
  filter = new NotificationUserFilter();
  notifications: Notification[];

  constructor(
    private service: NotificationUserService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.read();
  }

  read(page = 0) {
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.notifications = response.body;
      })
      .catch(error => this.errorHandler.handle(error))
  }

  getNotification(notification: Notification) {
    this.router.navigate(['/notifications/by-user/read', notification.idNotificacao]);
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ChipsModule } from 'primeng/chips';
import { EditorModule } from 'primeng/editor';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from 'src/app/shared/shared.module';
import { MailRouter } from './mail.router';
import { SendComponent } from './send/send.component';
import { ToAllComponent } from './to-all/to-all.component';
import { ToSomeComponent } from './to-some/to-some.component';

@NgModule({
  declarations: [
    SendComponent,
    ToAllComponent,
    ToSomeComponent
  ],
  imports: [
    CommonModule,
    MailRouter,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    ChipsModule,
    EditorModule,
    ProgressBarModule,
    TabViewModule
  ],
  exports: [
    ToAllComponent,
    ToSomeComponent
  ]
})
export class MailModule { }

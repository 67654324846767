<button type="button" mdbBtn color="primary" (click)="showDialog()">
  Adicionar Taxa
</button>

<p-dialog
  header="Novo Endereço"
  [(visible)]="display"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [baseZIndex]="10000"
>
  <form autocomplete="off" #f="ngForm" (ngSubmit)="create()" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="city">Tipo de Imposto</label>
        <p-dropdown
          [options]="taxTypes"
          placeholder="Selecione"
          formControlName="idTipoImposto"
        >
        </p-dropdown>
      </div>

      <div class="p-field p-col-12">
        <label for="district">Valor da Taxa</label>
        <p-inputNumber
          mode="decimal"
          [max]="100000"
          formControlName="valorTaxaAplicavel"
        >
        </p-inputNumber>
      </div>

      <div class="p-field p-col-12">
        <label for="street">Descrição</label>
        <input
          pInputText
          [ngStyle]="{ width: '100%' }"
          formControlName="descTaxaAplicavel"
        />
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-times"
      (click)="display = false"
      label="Cancelar"
      styleClass="p-button-text"
    ></p-button>
    <p-button
      type="submit"
      icon="pi pi-check"
      label="Salvar"
      styleClass="p-button-text"
      [disabled]="form.invalid || loading"
      (click)="f.ngSubmit.emit()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMIN
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SOLICITAÇÕES DE ANULAÇÃO DE FICHEIROS PROCESSADOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="ui-g-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifApplicant" [(ngModel)]="filter.nifApplicant">
                  <label for="float-input">NIF Solicitante</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifExecutor" [(ngModel)]="filter.nifExecutor">
                  <label for="float-input">NIF Executor</label>
                </span>
              </div>

              <div class="p-field status-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <p-dropdown inputId="status" class="p-inputtext-sm" [autoDisplayFirst]="false" [showClear]="true"
                    [options]="statuses" name="status" [(ngModel)]="filter.status">
                  </p-dropdown>
                  <label for="status">Status</label>
                </span>
              </div>

              <div class="p-field date-field p-col-3">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                    dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
                  </p-calendar>
                  <label for="float-input">Data registo [dd/mm/aaaa - dd/mm/aaaa]</label>
                </span>
              </div>

              <div class="p-field date-field p-col-3">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" selectionMode="range" inputId="range" [locale]="locale.pt"
                    dateFormat="dd/mm/yy" name="answeredAt" [(ngModel)]="filter.answeredAt">
                  </p-calendar>
                  <label for="float-input">Data resposta [dd/mm/aaaa - dd/mm/aaaa]</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} ficheiros recolhidos.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '35%'}">Ficheiro</th>
              <th class="reason-column">Motivo</th>
              <th [style]="{width: '6%'}">Dt Registo</th>
              <th [style]="{width: '6%'}">Dt Resposta</th>
              <th [style]="{width: '6%'}">Solicitante</th>
              <th [style]="{width: '6%'}">Executor</th>
              <th [style]="{width: '6%'}">Estado</th>
              <th [style]="{width:'5%'}"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>
                <span class="p-column-title">Ficheiro</span>
                {{ rowData.tbArquivoDocumentoGerado.nomeArquivoDocumentoGerado }}
              </td>

              <td class="reason-column">
                <span class="p-column-title">Motivo</span>
                <span [innerHTML]="rowData.descMotivo"></span>
              </td>

              <td>
                <span class="p-column-title">Dt Registo</span>
                {{ rowData.dtCria | date:'dd/MM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title">Dt Resposta</span>
                {{ rowData.dataResposta | date:'MMM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title">Solicitante</span>
                {{ rowData.nifSolicitante }}
              </td>

              <td>
                <span class="p-column-title">Executor</span>
                {{ rowData.nifExecutor }}
              </td>

              <td>
                <span class="p-column-title">Status</span>
                <div class="badges">
                  <span [class]="['p-tag', rowData.statusColor]">
                    {{ rowData.status }}
                  </span>
                </div>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}" (click)="showDialog(rowData)">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Detalhes da Solicitação" [(visible)]="display" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [modal]="true">
  <form #formDelete="ngForm" (ngSubmit)="delete()">
    <div class="p-field">
      <label for="descMotivo">Motivo da anulação</label>
      <p-editor name="descMotivo" [(ngModel)]="request.descMotivo" [style]="{'height':'120px'}" [readonly]="true">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
          </span>
        </ng-template>
      </p-editor>
    </div>

    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row" [ngStyle]="{width:'150px'}">
            <strong>NIF do Solicitante </strong>
          </th>
          <td colspan="2">
            {{request.nifSolicitante}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>NIF do Técnico </strong>
          </th>
          <td colspan="2">
            {{request.nifExecutor}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Data de Solicitação </strong>
          </th>
          <td colspan="2">
            {{request.dtCria | date}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Data de Resposta </strong>
          </th>
          <td colspan="2">
            {{request.dataResposta | date}}
          </td>
        </tr>

        <tr>
          <th scope="row"> <strong>Status </strong></th>
          <td colspan="2">
            <div class="badges">
              <span [class]="['p-tag', request.statusColor]">
                {{ request.status }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="p-col-12 text-right">
      <hr>
      <button type="button" mdbBtn size="md" color="light" (click)="display=false">
        FECHAR
      </button>

      <button type="submit" mdbBtn size="md" color="red" [disabled]="!formDelete.valid || loadingDelete"
        *ngIf="request.tbSolicitacaoEstado !== 'FECHADO'">
        CONFIRMAR CANCELAMENTO
      </button>
    </div>

    <div class="p-col-12">
      <p-progressBar *ngIf="loadingDelete" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
    </div>
  </form>
</p-dialog>

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { DocumentOnline, DocumentOnlineView } from './../core/models/document-online';
import { buildCurrentDate } from '../shared/date';

export class DocumentOnlineEmitterFilter {
  page: number = 0;
  rows: number = 15;

  typeDocument: any;
  documentNumber: string;
  serieNumber: string;
  consumerNif: string;
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentOnlineEmitterService {
  API_URL: string;
  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGeradoOnline`;
  }

  create(document: any): Promise<DocumentOnline> {
    document.numeroSequencial = Number(document.numDocumentoOnline);

    delete document.numDocumentoOnline;
    delete document.tbTipoDocumentoEmissao;
    delete document.nomeConsumidorOnline;
    delete document.nifEmissor;
    delete document.nomeEmissor;

    delete document.numeroCertificacao;
    delete document.enderecoEmissor;
    delete document.telefoneEmissor;

    /*document.tbItensDocumentosGeradosOnline.forEach(item => {
      delete item.documentosOrigemOnline;
    });*/

    return this.http.post<DocumentOnline>(`${this.API_URL}/online`, (document))
      .toPromise()
      .then(response => {
        const document = response as DocumentOnline;
        this.convertFields([document]);
        return document;
      })
  }

  read(filter: DocumentOnlineEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.typeDocument)
      params = params.append('tipoDocumento', filter.typeDocument.code.toString());

    if (filter.documentNumber)
      params = params.append('numDocumentoOnline', filter.documentNumber);

    if (filter.serieNumber)
      params = params.append('serie', filter.serieNumber);

    if (filter.consumerNif)
      params = params.append('nifConsumidor', filter.consumerNif);

    if (filter.startDate)
      params = params.append('dtFaturacaoDe', moment(filter.startDate).format('YYYY-MM-DD'));

    if (filter.endDate)
      params = params.append('dtFaturacaoAte', moment(filter.endDate).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.API_URL}/porEmissorOnline`, { params })
      .toPromise()
      .then(response => {
        const documentOnlineView = <DocumentOnlineView[]>response.content;
        this.convertFieldsView(documentOnlineView);
        return {
          body: documentOnlineView,
          total: response.totalElements
        }
      });
  }

  async getDTOById(id: number): Promise<DocumentOnlineView> {
    const response = await this.http.get(`${this.API_URL}/porEmissor/dto/${id}`)
      .toPromise();
    const invoice = response as DocumentOnlineView;
    return invoice;
  }

  async getById(id: number): Promise<DocumentOnline> {
    const response = await this.http.get(`${this.API_URL}/porEmissor/${id}`)
      .toPromise();
    const invoice = response as DocumentOnline;
    this.convertFields([invoice]);
    return invoice;
  }

  getLastByAuthorizationId(idAuthorization): Promise<DocumentOnline> {
    return this.http.get<DocumentOnline>(`${this.API_URL}/ultimoPorAno/${idAuthorization}`)
      .toPromise();
  }

  async update(documentOnline: DocumentOnline): Promise<DocumentOnline> {

    const response = await this.http
      .put<DocumentOnline>(`${this.API_URL}/porEmissor`, documentOnline)
      .toPromise();

    const result = response as DocumentOnline;
    this.convertFields([result]);
    return result;
  }

  async cancelDocument(id: number): Promise<DocumentOnline> {
    return this.http
      .put<DocumentOnline>(`${this.API_URL}/anular/${id}`, null)
      .toPromise();
  }

  private convertFields(invoices: DocumentOnline[]) {
    for (const invoice of invoices) {
      invoice.dtAlterOnline = moment(invoice.dtAlterOnline,
        'YYYY-MM-DD').toDate();

      invoice.dtCriaOnline = moment(invoice.dtCriaOnline,
        'YYYY-MM-DD').toDate();

      if (invoice.dtEmissaoDocumentoOnline) {
        invoice.dtEmissaoDocumentoOnline = new Date(buildCurrentDate(invoice.dtEmissaoDocumentoOnline));
      }
    }
  }

  private convertFieldsView(invoices: DocumentOnlineView[]) {
    for (const invoice of invoices) {
      invoice.totalValorAnulado = invoice.totalValorBaseTributavel - invoice.valorDisponivelAnular;

      if (invoice.totalValorAnulado === invoice.totalValorBaseTributavel) {
        invoice.cancellationSeverity = 'text-danger';
      } else if (invoice.totalValorAnulado > 0 && invoice.totalValorAnulado < invoice.totalValorBaseTributavel) {
        invoice.cancellationSeverity = 'text-warning';
      }
    }
  }

  // createCreditNote(document: any): Promise<DocumentOnline> {
  //   document.numeroSequencial = Number(document.numDocumentoOnline);

  //   delete document.numDocumentoOnline;
  //   delete document.tbTipoDocumentoEmissao;
  //   delete document.nomeConsumidorOnline;
  //   delete document.nifEmissor;
  //   delete document.nomeEmissor;

  //   delete document.numeroCertificacao;
  //   delete document.enderecoEmissor;
  //   delete document.telefoneEmissor;

  //   /*document.tbItensDocumentosGeradosOnline.forEach(item => {
  //     delete item.documentosOrigemOnline;
  //   });*/

  //   return this.http.post<DocumentOnline>(`${this.API_URL}/notaCredito`, (document))
  //     .toPromise()
  //     .then(response => {
  //       const document = response as DocumentOnline;
  //       this.convertFields([document]);
  //       return document;
  //     })
  // }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthorizationUtilRouter } from './authorization-util-router';
import { CheckComponent } from './check/check.component';

@NgModule({
  declarations: [CheckComponent],
  imports: [
    CommonModule,
    AuthorizationUtilRouter,

    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ProgressBarModule,
    ToggleButtonModule,
    SharedModule,
    TableModule,
    TooltipModule
  ]
})
export class AuthorizationUtilModule { }

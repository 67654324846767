import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { decrypt } from '../../configs/encryption';
import { DocumentFilter, DocumentService } from '../document.service';

@Component({
  selector: 'app-read-document-by-file',
  templateUrl: './read-document-by-file.component.html',
  styleUrls: ['./read-document-by-file.component.scss']
})
export class ReadDocumentByFileComponent implements OnInit {
  loading = true;
  locale = new Locale();
  documents = [];
  total = 0;
  filter = new DocumentFilter();

  fileId = this.route.snapshot.params.id;

  constructor(
    private service: DocumentService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    const decryptedId = Number(decrypt(this.fileId));
    this.service.readByFile(decryptedId, this.filter)
      .then(response => {
        this.total = response.total;
        this.documents = response.body;
      })
      .catch(error => {
        if (error.status !== 404) {
          this.errorHandler.handle(error);
        }
      })
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }
}

<div class="row clearfix">
  <mdb-card class="card-login">
    <mdb-card-body>
      <div *ngIf="loading; else elseLoading">
        <blockquote class="blockquote bq-info">
          <div class="col-md-12 text-center">
            <div class="spinner-border" role="status">
              <!-- <mdb-icon class="icon-message-lg text-info" fas icon="check-circle"></mdb-icon> -->
            </div>
          </div>
        </blockquote>
      </div>

      <ng-template #elseLoading>
        <div *ngIf="isEmailChanged; else elseBlock">
          <blockquote class="blockquote bq-success">
            <div class="col-md-12 text-center">
              <mdb-icon
                class="icon-message-lg text-success"
                fas
                icon="check-circle"
              ></mdb-icon>
            </div>

            <p class="bq-title text-center">
              O seu e-mail foi alterado com sucesso!
            </p>

            <hr />

            <footer class="blockquote-footer mb-3 text-center">
              <a routerLink="/user/profile"> Aceder ao sistema </a>
            </footer>
          </blockquote>
        </div>
        <ng-template #elseBlock>
          <blockquote class="blockquote bq-danger">
            <div class="col-md-12 text-center">
              <mdb-icon
                class="icon-message-lg text-danger"
                fas
                icon="times-circle"
              ></mdb-icon>
            </div>

            <p class="bq-title text-center">
              Não foi possível atualizar o seu e-mail!
            </p>

            <hr />

            <div>
              <div class="block-note alert alert-light" role="alert">
                <p class="text-center">
                  Tente novamente mais tarde ou entre em contacto com a Direcção
                  dos Impostos.
                </p>
              </div>
            </div>

            <footer class="blockquote-footer mb-3 text-center">
              <a routerLink="/user/profile"> Voltar ao seu perfil </a>
            </footer>
          </blockquote>
        </ng-template>
      </ng-template>
    </mdb-card-body>
  </mdb-card>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppHttp } from 'src/app/auth/app-http';

export class CertificationTypographyFilter {
  page = 0;
  rows = 15;
}

@Injectable({
  providedIn: 'root'
})
export class CertificationTypographyService {
  API_URL: string;

  constructor(
    private httpClient: HttpClient,
    private http: AppHttp
  ) {
    this.API_URL = `${environment.apiUrl}/pedidosCertificacao`;
  }

  create(formData): Promise<any> {
    return this.httpClient.post<Boolean>(`${this.API_URL}/tipografia`, formData,
      { observe: "response" })
      .toPromise();
  }
}

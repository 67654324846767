import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import {
  EmissionDocument,
  EmissionDocumentResult,
} from 'src/app/core/models/emission-document';
import { TaxpayerService } from 'src/app/taxpayer/taxpayer.service';
import { EmissionDocumentView } from './../../core/models/emission-document';
import { TypeDocumentService } from './../../type-document/type-document.service';
import {
  EmissionDocumentTypographyFilter,
  EmissionDocumentTypographyService,
} from './../emission-document-typography.service';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss'],
})
export class TransportComponent implements OnInit {
  loading: boolean = true;
  loadingTransportSerie: boolean = false;
  display: boolean = false;
  displayTransported: boolean = false;

  locale = new Locale();
  filter = new EmissionDocumentTypographyFilter();
  emissionDocument = new EmissionDocument();
  emissionDocumentResult = new EmissionDocumentResult();
  emissionDocumentView = new EmissionDocumentView();

  total = 0;

  tiposDocumento = [];
  emissionDocuments = [];

  years = [
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
  ];

  yearsToTransport = [];
  yearToTransport: string;

  validNifSol: boolean = true;
  nifSolicitante: string;
  nomeSolicitante: string;
  applicantNif: string;

  serieToTransport: string;

  constructor(
    private service: EmissionDocumentTypographyService,
    private authorizationService: AuthorizationService,
    private tipoDocumentoService: TypeDocumentService,
    private taxPayerService: TaxpayerService,
    private errorHandler: ErrorHandlerService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    // this.applicantNif = this.auth.jwtPayload.nif;
    this.readTypeDocument();
    this.getYearsToTransport();
  }

  readTypeDocument() {
    return this.tipoDocumentoService
      .read()
      .then((response) => {
        this.tiposDocumento = response.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service
      .readNotTransported(this.filter)
      .then((response) => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  onInputNifSol(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.nomeSolicitante = response.contriNome;
          this.validNifSol = true;
        })
        .catch(() => {
          this.validNifSol = false;
          this.nomeSolicitante = null;
        });
    } else {
      this.validNifSol = false;
      this.nomeSolicitante = null;
    }
  }

  getYearsToTransport(): void {
    this.service
      .getYearsToTransport()
      .then((results) => {
        const years = results.map((result: any) => ({
          value: result,
          label: result.toString(),
        }));

        this.yearsToTransport = years;
      })
      .catch((err) => this.errorHandler.handle(err));
  }

  transport() {
    this.loadingTransportSerie = true;
    const newEmissionDocument = new EmissionDocument();

    newEmissionDocument.qtDocumentosSolicitado =
      this.emissionDocument.qtDocumentosSolicitado;

    newEmissionDocument.qtTotalDocumentosJaSolicitado =
      newEmissionDocument.qtDocumentosSolicitado;

    newEmissionDocument.mascara = this.emissionDocument.mascara;
    
    newEmissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocument.descPedidoEmissaoDocumento;

    newEmissionDocument.tbTipoDocumentoEmissao =
      this.emissionDocument.tbTipoDocumentoEmissao;
      
    newEmissionDocument.tbTipoDocumentoEmissao =
      this.emissionDocument.tbTipoDocumentoEmissao;

    const data = {
      currentEmissionDocumentId:
        this.emissionDocument.tbAutorizacaoEmissao.idAutorizacaoEmissao,
      requesterNif: this.nifSolicitante,
      applicantNif: this.applicantNif,
      newEmissionDocument: newEmissionDocument,
      yearToTransport: this.yearToTransport,
      isTypography: true,
    };

    this.authorizationService
      .transport(data)
      .then((response) => {
        this.emissionDocumentResult = response;
        this.read();
        this.display = false;
        this.displayTransported = true;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => {
        this.loading = false;
        this.loadingTransportSerie = false;
      });
  }

  showDialog(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;

    this.serieToTransport = `${this.emissionDocumentView.siglaSerie}${this.emissionDocumentView.serie}${this.emissionDocumentView.ano}`;

    this.emissionDocument.idPedidoEmissaoDocumento =
      this.emissionDocumentView.idPedidoEmissaoDocumento;
    this.emissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocumentView.descricao;
    this.emissionDocument.mascara = this.emissionDocumentView.mascara;

    this.emissionDocument.qtDocumentosSolicitado =
      this.emissionDocumentView.totalSolicitado;
    this.emissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocumentView.descricao;
    this.emissionDocument.tbAutorizacaoEmissao.idAutorizacaoEmissao =
      this.emissionDocumentView.idAutorizacaoEmissao;
    this.emissionDocument.tbTipoDocumentoEmissao.idTipoDocumentoEmissao =
      this.emissionDocumentView.idTipoDocumento;

    /**
     * Coleção dinâmica de anos para transportar série
     */
    // this.yearsToTransport = [];
    // const currentYear = 22;
    // let year: number = this.emissionDocumentView.ano + 1;

    // while (year <= currentYear) {
    //   const yearData = {
    //     label: `20${year}`,
    //     value: `20${year}`
    //   }

    //   this.yearsToTransport.push(yearData);

    //   year++;
    // }

    this.display = true;
  }
}

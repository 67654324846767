import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { ThirdParty, ThirdPartyView } from './../core/models/third-party';

export class ThirdPartyFilter {
  page = 0;
  rows = 15;

  nifContriPri: string;
  nifContriTer: string;
  nomeContriPri: string;
  nomeContriTer: string;
  idAtividade: number;
}

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/contribuinte/atribuirAtividade`;
  }

  async read(filter: ThirdPartyFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.idAtividade)
      params = params.append('idAtividade', filter.idAtividade.toString());

    if (filter.nomeContriTer)
      params = params.append('nomeContriTer', filter.nomeContriTer);

    if (filter.nifContriTer)
      params = params.append('nifContriTer', filter.nifContriTer);

    if (filter.nomeContriPri)
      params = params.append('nomeContriPri', filter.nomeContriPri);

    if (filter.nifContriPri)
      params = params.append('nifContriPri', filter.nifContriPri);

    const response = await this.http.get<any>(`${environment.apiUrl}/contribuinte/atividade`, { params })
      .toPromise();

    this.convertFields(response.content);

    return {
      body: <ThirdParty[]>response.content,
      total: response.totalElements
    };
  }

  async acceptForTaxpayer(thirdPartyView: ThirdPartyView): Promise<ThirdPartyView> {
    delete thirdPartyView.labelActivity;
    return this.http.put<ThirdPartyView>(`${environment.apiUrl}/contribuinte/atividade/agente`, thirdPartyView)
      .toPromise()
      .then((response: ThirdPartyView) => {
        this.convertFields([response])
        return response;
      })
  }

  private convertFields(thirdParties: ThirdPartyView[]) {
    thirdParties.forEach(thirdParty => {
      thirdParty.dataCria = moment(thirdParty.dataCria, 'YYYY-MM-DD').toDate();

      switch (thirdParty.nomeAtividade) {
        case 'EMITIR_FATURA_ONLINE':
          thirdParty.labelActivity = 'EMITIR FATURAS NO PORTAL E-FACTURA';
          break;

        case 'SOLICITAR_NOVAS_SERIES':
          thirdParty.labelActivity = 'SOLICITAR NOVAS SERIES PARA SOFTWARE AUTÓNOMO';
          break;

        case 'COMUNICAR_FICHEIRO':
          thirdParty.labelActivity = 'COMUNICAR POR FICHEIROS OS DOCUMENTOS EMITIDOS';
          break;

        case 'COMUNICAR_UNIDADE':
          thirdParty.labelActivity = 'COMUNICAR POR UNIDADE OS DOCUMENTOS EMITIDOS';
          break;

        default:
          thirdParty.labelActivity = null;
      }
    });
  }
}

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/public_api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { TypeDocumentService } from 'src/app/type-document/type-document.service';
import { encrypt } from '../../configs/encryption';
import { DocumentOnlineEmitterFilter, DocumentOnlineEmitterService } from './../document-online-emitter.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  loading = true;
  invoices = [];
  total = 0;
  filter = new DocumentOnlineEmitterFilter();

  typesDocument = [];

  constructor(
    private service: DocumentOnlineEmitterService,
    private tipoDocumentoService: TypeDocumentService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.readTypeDocument();
  }

  readTypeDocument() {
    return this.tipoDocumentoService.read()
      .then(response => {
        this.typesDocument = response.map(t => ({
          name: t.descTipoDocumentoEmissao, code: t.idTipoDocumentoEmissao
        }));
      }).catch(erro => this.errorHandler.handle(erro));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.invoices = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  routerLink(id: number) {
    const encryptedId = encrypt(id.toString());
    this.router.navigate(['/emitter/document-online/read', encryptedId]);
  }
}

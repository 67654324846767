import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { decrypt } from '../../configs/encryption';
import { FileDocumentCancellationRequestService } from '../file-document-cancellation-request.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  locale = new Locale();
  fileDocument = new FileDocument();

  constructor(
    private service: FileDocumentCancellationRequestService,
    private route: ActivatedRoute,
    private message: MessageService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    const userId = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(userId));
    // this.get(decryptedId);
  }

  // get(id: number) {
  //   this.service.get(id)
  //     .then(response => this.fileDocument = response)
  //     .catch(error => this.errorHandler.handle(error));
  // }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/document/new">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RECOLHER DOCUMENTOS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DECLARAR NÃO FACTURAÇÃO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <p>
        <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
        - Click para comunicar que não houve facturação durante um determinado mês.
      </p>
      <p>
        <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
        - Foi comunicado que não houve facturação para o mês em questão.
      </p>
      <p>
        <mdb-icon class="icon-declared" far icon="calendar-check"></mdb-icon>
        - Foram comunicados documentos para o mês em questão.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="typesDocument" name="typeDocumentId" [showClear]="true"
                    [(ngModel)]="filter.typeDocumentId" placeholder="Tipo Documento" (onChange)="dropdownSeries($event.value)">
                  </p-dropdown>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="series" placeholder="Selecione a série"
                    name="serieNumber" [filter]="true" [showClear]="true" [(ngModel)]="filter.authorizationId">
                  </p-dropdown>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>
      <mdb-card-body>
        <!-- <div class="p-col-12">
          <p-button label="Comunicar para as séries selecionadas" icon="pi pi-calendar-plus" styleClass="p-button-sm"
            [disabled]="!selectedAuthorizations.length" (click)="declareMany()">
          </p-button>
        </div> -->

        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="authorizations"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)" [(selection)]="selectedAuthorizations" dataKey="idAutorizacaoEmissao">
          <ng-template pTemplate="header">
            <tr>
              <!-- <th [ngStyle]="{width: '3rem'}">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th> -->
              <th>Série</th>
              <th>Janeiro</th>
              <th>Fevereiro</th>
              <th>Março</th>
              <th>Abril</th>
              <th>Maio</th>
              <th>Junho</th>
              <th>Julho</th>
              <th>Agosto</th>
              <th>Setembro</th>
              <th>Outubro</th>
              <th>Novembro</th>
              <th>Dezembro</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr>
              <!-- <td>
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td> -->

              <td>
                <span class="p-column-title">Documento</span>
                {{ rowData.siglaDocAutorizacaoEmissao+''+rowData.numSerieDocumento+''+rowData.anoAutorizacaoEmissao }}
              </td>

              <td>
                <span class="p-column-title">Janeiro</span>
                <span *ngIf="rowData.jan; else elseBlock1">
                  <span *ngIf="rowData.qunt1 > 0; else elseQunt1">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt1>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>

                <ng-template #elseBlock1>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 1, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 1, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Fevereiro</span>
                <span *ngIf="rowData.feb; else elseBlock2">
                  <span *ngIf="rowData.qunt2 > 0; else elseQunt2">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt2>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>

                <ng-template #elseBlock2>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 2, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 2, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Março</span>
                <span *ngIf="rowData.mar; else elseBlock3">
                  <span *ngIf="rowData.qunt3 > 0; else elseQunt3">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt3>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock3>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 3, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 3, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Abril</span>
                <span *ngIf="rowData.apr; else elseBlock4">
                  <span *ngIf="rowData.qunt4 > 0; else elseQunt4">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt4>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock4>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 4, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 4, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Maio</span>
                <span *ngIf="rowData.may; else elseBlock5">
                  <span *ngIf="rowData.qunt5 > 0; else elseQunt5">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt5>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock5>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 5, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 5, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Junho</span>
                <span *ngIf="rowData.june; else elseBlock6">
                  <span *ngIf="rowData.qunt6 > 0; else elseQunt6">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt6>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock6>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 6, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 6, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Julho</span>
                <span *ngIf="rowData.july; else elseBlock7">
                  <span *ngIf="rowData.qunt7 > 0; else elseQunt7">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt7>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock7>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 7, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 7, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Agosto</span>
                <span *ngIf="rowData.aug; else elseBlock8">
                  <span *ngIf="rowData.qunt8 > 0; else elseQunt8">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt8>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock8>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 8, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 8, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Setembro</span>
                <span *ngIf="rowData.sept; else elseBlock9">
                  <span *ngIf="rowData.qunt9 > 0; else elseQunt9">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt9>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock9>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 9, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 9, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Outubro</span>
                <span *ngIf="rowData.oct; else elseBlock10">
                  <span *ngIf="rowData.qunt10 > 0; else elseQunt10">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt10>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock10>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 10, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 10, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Novembro</span>
                <span *ngIf="rowData.nov; else elseBlock11">
                  <span *ngIf="rowData.qunt11 > 0; else elseQunt11">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt11>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock11>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 11, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 11, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

              <td>
                <span class="p-column-title">Dezembro</span>
                <span *ngIf="rowData.dec; else elseBlock12">
                  <span *ngIf="rowData.qunt12 > 0; else elseQunt12">
                    <mdb-icon class="icon-declared" far icon="calendar-check"
                      pTooltip="Foram comunicados documentos para este mês." tooltipPosition="top"></mdb-icon>
                  </span>
                  <ng-template #elseQunt12>
                    <mdb-icon class="icon-declare" far icon="calendar"></mdb-icon>
                  </ng-template>
                </span>
                <ng-template #elseBlock12>
                  <a href="javascript:;"
                    [class.disabled]="!isCurrentDateBefore({month: 12, year: rowData.anoAutorizacaoEmissao})"
                    (click)="confirmDeclare({month: 12, year: rowData.anoAutorizacaoEmissao}, rowData)">
                    <mdb-icon class="icon-not-declared" far icon="calendar-plus"></mdb-icon>
                  </a>
                </ng-template>
              </td>

            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from './../shared/shared.module';
import { CreateComponent } from './create/create.component';
import { IndexComponent } from './index/index.component';
import { ReadComponent } from './read/read.component';
import { ThirdPartyConsumerRouter } from './third-party-consumer-router';

@NgModule({
  declarations: [
    IndexComponent,
    CreateComponent,
    ReadComponent
  ],
  imports: [
    CommonModule,

    ThirdPartyConsumerRouter,
    SharedModule,

    FormsModule,
    MDBBootstrapModule,
    LayoutModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ProgressBarModule,
    DialogModule,
    SidebarModule,
    ScrollPanelModule,
    DropdownModule,
    InputSwitchModule,
    FileUploadModule
  ]
})
export class ThirdPartyConsumerModule { }

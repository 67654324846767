<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMINISTRADOR
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ENVIAR E-MAIL
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-8">
    <p-tabView>
      <p-tabPanel header="GERAL">
        <app-to-all></app-to-all>
      </p-tabPanel>

      <p-tabPanel header="PARA ALGUNS">
        <app-to-some></app-to-some>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<form autocomplete="off" #formChangeEmail="ngForm" (ngSubmit)="changeEmail()">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
      <label>Novo E-mail</label>
      <input type="text" pInputText name="email" [(ngModel)]="email" placeholder="Inserira novo e-mail">
    </div>

    <div class="p-col-12 text-right">
      <div class="button-demo">
        <button class="btn btn-success waves-effect" type="submit"
          [disabled]="!formChangeEmail.valid || loading">ALTERAR</button>
      </div>
    </div>

    <div class="p-col-12">
      <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
      </p-progressBar>
    </div>
  </div>
</form>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIROS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          OPERADOR ECONÓMICO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-info" role="alert">
      <p>
        <mdb-icon fas icon="info-circle"></mdb-icon>

        Esta área destina-se aos <strong>Operadores Económicos</strong> e permite que os mesmos façam
        gestão das actividades realizadas pelos seus prestadores de serviços do e-Factura.
      </p>
    </div>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/consumer/third-party/read">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog orange-text"></i></div>
        <h4 class="title"> Gerir Prestadores de Serviços </h4>
        <p class="description">
          Permite ao utilizador gerir as suas informações de terceiros e operar em nome de outrém.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/consumer/third-party/create">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog orange-text"></i></div>
        <h4 class="title"> Vincular Novo Prestador de Serviço </h4>
        <p class="description">
          Permite ao utilizador gerir as suas informações de terceiros e operar em nome de outrém.
        </p>
      </div>
    </a>
  </div>
</div>

import { NgForm } from '@angular/forms';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { TaxRateService } from './../../tax-rate/tax-rate.service';
import { ThirdPartyActivity } from './../../core/models/third-party-activity';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { ThirdParty } from 'src/app/core/models/third-party';
import { ThirdPartyConsumerService } from '../third-party-consumer.service';
import { Locale } from './../../configs/models/locale';
import { ThirdPartyActivityService } from './../../third-party-activity/third-party-activity.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  locale = new Locale();
  loading: boolean = false;
  thirdParty = new ThirdParty();
  activities: ThirdPartyActivity[] = [];
  selectedActivities: ThirdPartyActivity[] = [];
  thirdName: string;
  isNifValid: boolean = true;

  constructor(
    private thirdPartyConsumerService: ThirdPartyConsumerService,
    private thirdPartyActivityService: ThirdPartyActivityService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private taxPayerService: TaxpayerService,
  ) { }

  ngOnInit(): void {
    this.readActivities();
  }

  onInputNif(event: any) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService.getByNifInWebService(nif)
        .then((response) => {
          this.thirdName = response.contriNome;
          this.isNifValid = true;
        })
        .catch(() => {
          this.isNifValid = false;
          this.thirdName = null;
        });
    } else {
      this.isNifValid = false;
      this.thirdName = null;
    }
  }

  readActivities(): void {
    this.thirdPartyActivityService.read()
      .then((activities) => this.activities = activities)
      .catch(error => this.errorHandler.handle(error))
  }

  create(form: NgForm): void {
    this.loading = true;

    this.thirdParty.atividades = this.selectedActivities.map((activity) => (
      activity.id
    ))

    this.thirdPartyConsumerService.create(this.thirdParty)
      .then((result) => {
        this.thirdParty = result;
        this.messageService.add({
          severity: 'success',
          detail: 'Informação salva com sucesso!'
        });
        form.reset();
        this.selectedActivities = [];
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }
}

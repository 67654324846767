<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CONTRIBUINTES REGISTADOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifUsuario" [(ngModel)]="filter.nifUsuario">
                  <label for="float-input">NIF</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nomePessoa" [(ngModel)]="filter.nomePessoa">
                  <label for="float-input">Nome</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown inputId="rangeNif" class="p-inputtext-sm" [autoDisplayFirst]="false" [options]="ranges"
                    [showClear]="true" name="range" [(ngModel)]="filter.range" optionLabel="name"></p-dropdown>
                  <label for="rangeNif">Gama de NIF</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown inputId="category" class="p-inputtext-sm" [autoDisplayFirst]="false" [showClear]="true"
                    [options]="categories" name="category" [(ngModel)]="filter.category" optionLabel="name">
                  </p-dropdown>
                  <label for="category">Categoria</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="emailUsuario" [(ngModel)]="filter.emailUsuario">
                  <label for="float-input">E-mail</label>
                </span>
              </div>

              <div class="p-field user-date-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dataCriaUsuarioDe" [(ngModel)]="filter.dataCriaUsuarioDe"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field user-date-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dataCriaUsuarioAte" [(ngModel)]="filter.dataCriaUsuarioAte"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="users"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} contribuintes.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '15%'}">NIF</th>
              <th [style]="{width: '40%'}">Nome</th>
              <th [style]="{width: '25%'}">E-mail</th>
              <th [style]="{width: '10%'}">Dt Registo</th>
              <th [style]="{width: '5%'}">Ativo</th>
              <th [style]="{width: '5%'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">NIF</span>
                {{ rowData.nifUsuario }}
              </td>

              <td>
                <span class="p-column-title">Nome</span>
                {{ rowData.tbContribuinte.tbPessoa.nomePessoa }}
              </td>

              <td>
                <span class="p-column-title">E-mail</span>
                {{ rowData.emailUsuario }}
              </td>

              <td>
                <span class="p-column-title">Dt Registo</span>
                {{ rowData.dataCriaUsuario | date:'dd/MM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title">Ativo</span>
                <mdb-icon fas icon="circle" [class]="rowData.statusColor"></mdb-icon>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informações"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                  [routerLink]="['/user/read', encrypt(rowData.idUsuario)]">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from 'src/app/configs/models/locale';
import { DocumentState } from 'src/app/core/models/document';
import { decrypt } from '../../configs/encryption';
import { DocumentEmitterService } from '../document-emitter.service';

@Component({
  selector: 'app-get-state',
  templateUrl: './get-state.component.html',
  styleUrls: ['./get-state.component.scss']
})
export class GetStateComponent implements OnInit {
  document = new DocumentState();
  locale = new Locale();

  creditAndDebitNotesIsOpen: boolean;
  creditAndDebitNotesItems = [];

  constructor(
    private service: DocumentEmitterService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));
    this.get(decryptedId);
  }

  get(id: number) {
    this.service.getState(id)
      .then((response) => {
        this.document = response
      });
  }

  getDocumentStatus() {
    return  this.document.status === 'ESTADO' ? 'warning' : ( this.document.status === 'ESTADO_ATIVO' ? 'success' : 'danger');
  }

  getDocumentStatusLabel() {
    return  this.document.status === 'ESTADO' ? 'PENDENTE' : ( this.document.status === 'ESTADO_ATIVO' ? 'ACEITE' : 'RECUSADO');
  }
}

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { DocumentConsumer } from './../../core/models/document-consumer';
import { DocumentConsumerRegFilter, DocumentConsumerRegService } from './../document-consumer-reg.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  loading = true;
  documents: DocumentConsumer[];
  total = 0;
  filter = new DocumentConsumerRegFilter();

  constructor(
    private service: DocumentConsumerRegService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.documents = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.read(page);
  }

  getDocumentStatus(data) {
    return data.status ? 'success' : (data.status === false ? 'danger' : 'warning');
  }

  getDocumentStatusLabel(data) {
    return data.status ? 'ACEITE' : (data.status === false ? 'RECUSADO' : 'PENDENTE');
  }

}

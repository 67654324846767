<mdb-card>
  <mdb-card-header class="mdb-card-header text-center p-pt-2">
    <div class="p-grid">
      <div class="p-col-5 p-d-flex p-jc-end">
        <mdb-icon fas icon="chevron-circle-left" size="2x" (click)="arrowLeft()"> </mdb-icon>
      </div>
      <div class="p-col-2 p-d-flex p-jc-center">
        <h4>{{now | date:"y" }}</h4>
      </div>
      <div class="p-col-5 p-d-flex p-jc-start">
        <mdb-icon fas icon="chevron-circle-right" size="2x" (click)="arrowRight()"> </mdb-icon>
      </div>
    </div>
  </mdb-card-header>

  <mdb-card-body>
    <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="ranking"
      [loading]="loading">

      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          Ranking dos {{ filter.rows }} maiores contribuintes
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: '80%'}">Contribuinte</th>
          <th [style]="{width: '20%'}">Total da factura</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>
            <span class="p-column-title">Contribuinte</span>
            {{rowData.tbPessoa.nomePessoa}}
          </td>

          <td>
            <span class="p-column-title">Total das facturas</span>
            <strong>
              {{rowData.estatisticaEmitente.ttValoresFaturasEmitidas | currency:'STN'}}
            </strong>
          </td>
        </tr>
      </ng-template>s
    </p-table>
    <!-- <p-divider></p-divider> -->

    <p-button label="Mostrar lista completa" styleClass="p-button-link" routerLink="/statistic/ranking"></p-button>
  </mdb-card-body>
</mdb-card>

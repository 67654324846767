<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMINISTRADOR
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          UTILIZADORES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText name="nifUsuario" [(ngModel)]="filter.nifUsuario">
                  <label for="float-input">NIF</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText name="nomeUsuario" [(ngModel)]="filter.nomeUsuario">
                  <label for="float-input">Nome</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar name="dataCriaUsuarioDe" [(ngModel)]="filter.dataCriaUsuarioDe" dateFormat="dd/mm/yy"
                    [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar name="dataCriaUsuarioAte" [(ngModel)]="filter.dataCriaUsuarioAte" dateFormat="dd/mm/yy"
                    [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="users"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '4rem'}">ID</th>
              <th>NIF</th>
              <th [style]="{width: '30%'}">Nome</th>
              <th [style]="{width: '25%'}">E-mail</th>
              <th>Perfil</th>
              <th>Dt Registo</th>
              <th>Status</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">ID</span>
                {{ rowData.idUsuario }}
              </td>

              <td>
                <span class="p-column-title">NIF</span>
                {{ rowData.nifUsuario }}
              </td>

              <td>
                <span class="p-column-title">Nome</span>
                {{ rowData.tbContribuinte.tbPessoa.nomePessoa }}
              </td>

              <td>
                <span class="p-column-title">E-mail</span>
                {{ rowData.emailUsuario }}
              </td>

              <td>
                <span class="p-column-title">Perfil</span>
                {{ rowData.tbPerfil.descPerfil }}
              </td>

              <td>
                <span class="p-column-title">Dt Registo</span>
                {{ rowData.dataCriaUsuario | date:'dd/MM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title">Registo Confirmado</span>
                {{ rowData.isFirst ? 'Não' : 'Sim' }}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informaões"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                  [routerLink]="['/user-admin/read', rowData.idUsuario]">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

import { HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { Injectable } from '@angular/core';

export class DatabaseManagerFilter {
  page = 0;
  rows = 10;
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseManagerService {
  API_URL: string;

  constructor(
    private http: AppHttp,
  ) {
    this.API_URL = `${environment.apiUrl}/baseDados`;
  }

  async read(filter: DatabaseManagerFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    const response = await this.http.get<any>(this.API_URL, { params })
      .toPromise();
    return {
      body: response.content,
      total: response.totalElements
    };
  }

  async export(): Promise<any> {
    return this.http.post(this.API_URL, {})
      .toPromise()
  }
}

import { ValidateAccountGetComponent } from './validate-account-get/validate-account-get.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GetComponent } from './get/get.component';
import { ReadComponent } from './read/read.component';
import { ValidateAccountComponent } from './validate-account/validate-account.component';

const routes: Routes = [
  {
    path: 'read',
    component: ReadComponent,
  },
  {
    path: 'read/:id',
    component: GetComponent,
  },
  {
    path: 'validate-account',
    component: ValidateAccountComponent,
  },
  {
    path: 'validate-account/:id',
    component: ValidateAccountGetComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class UserAdminRouter {
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Emission, EmissionForm } from 'src/app/core/models/emission';
import { EmissionDocumentForm } from 'src/app/core/models/emission-document';
import { TypeDocument } from 'src/app/core/models/type-document';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { EmissionEmitterService } from './../../emission-emitter/emission-emitter.service';
import { TypeDocumentService } from './../../type-document/type-document.service';
import { BlockedTaxpayerInfo } from 'src/app/core/models/taxpayer';

class EmissionResponse {
  btPedidoEmissaoProximoAno: boolean;
  descPedidoEmissaoFatura: string;
  dtCertificacao: Date;
  nifEmissor: string;
  nifRequerente: string;
  nomeEmissor: string;
  nomeRequerente: string;
  numeroCertificacao: string;
  pedidoEmissaoDocumentos: [{
    anoAutorizacaoEmissao: number;
    idAutorizacaoEmissao: number;
    idTipoDocumentoEmissao: number;
    mascara: number;
    numAutorizacaoEmissao: string;
    numSerieDocumento: string;
    qtDocumentosSolicitado: number;
    siglaTipoDocumentoEmissao: string;
    statusPedidoEmissao: boolean;
    totalJaSolicitado: number;
  }];
  tipoCertificacao: string;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  loading: boolean = false;
  display: boolean = false;

  emission = new EmissionResponse();

  emissionForm = new EmissionForm(this.fb).builder();
  emissionDocumentForm = new EmissionDocumentForm(this.fb);

  typesDocument: TypeDocument[];

  displayBlockedSerieMessage: boolean = false;

  blockedTaxpayerInfo: BlockedTaxpayerInfo;

  constructor(
    private fb: UntypedFormBuilder,
    private emissionEmitterService: EmissionEmitterService,
    private typeDocumentService: TypeDocumentService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.addItem();

    this.getTypeDocument();
  }

  get items(): UntypedFormArray {
    return this.emissionForm.get('pedidoEmissaoDocumentos') as UntypedFormArray;
  }

  addItem() {
    this.items.push(this.emissionDocumentForm.builder());
  }

  removeItem(i: number) {
    if (this.items.controls.length > 1) {
      this.items.removeAt(i);
    }
  }

  getTypeDocument() {
    return this.typeDocumentService
      .read()
      .then((tiposDocumento) => {
        this.typesDocument = tiposDocumento.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  setTotalEmissionDocuments(event, index: number) {
    this.items.at(index).patchValue({
      qtTotalDocumentosJaSolicitado: event.target.value,
    });
  }

  save(emissionForm: UntypedFormGroup) {
    this.loading = true;
    this.emissionForm.removeControl('mascara');
    this.emissionForm.value.pedidoEmissaoDocumentos.forEach((pedido) => {
      pedido.localTributacao = pedido.localTributacao ? 'P' : 'S';
    });

    this.emissionEmitterService
      .creat(this.emissionForm.value)
      .then((response) => {
        this.emission = response;
        this.display = true;

        emissionForm.reset();
        emissionForm = new EmissionForm(this.fb).builder();

        this.items.controls.forEach((item) => {
          item.patchValue({ mascara: 6 });
        });
      })
      .catch((error) => {
        if (error.status === 406) {
          this.blockedTaxpayerInfo = error.error;
          this.displayBlockedSerieMessage = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => (this.loading = false));
    return;
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/user/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CONTRIBUINTES REGISTADOS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <p-tabView>
          <p-tabPanel header="DADOS GERAIS">
            <form autocomplete="off" #updateForm="ngForm" (ngSubmit)="update()">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                  <label>NIF</label>
                  <input pInputText name="nifContribuinte" [(ngModel)]="user.tbContribuinte.nifContribuinte" disabled>
                </div>

                <div class="p-field p-col-12 p-md-9">
                  <label>Nome</label>
                  <input pInputText name="nomePessoa" [(ngModel)]="user.tbContribuinte.tbPessoa.nomePessoa" disabled>
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label>Endereço</label>
                  <input pInputText name="enderecoPessoa" [(ngModel)]="user.tbContribuinte.tbPessoa.enderecoPessoa">
                </div>

                <div class="p-field p-col-12 p-md-2">
                  <label>Telefone</label>
                  <input pInputText name="telefonePessoa" [(ngModel)]="user.tbContribuinte.tbPessoa.telefonePessoa">
                </div>

                <div class="p-field p-col-12 p-md-2">
                  <label>BI</label>
                  <input pInputText name="identidade" [(ngModel)]="user.tbContribuinte.tbPessoa.identidade">
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label>E-mail</label>
                  <input pInputText name="emailUsuario" [(ngModel)]="user.emailUsuario">
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Perfil</label>
                  <input pInputText name="descPerfil" [(ngModel)]="user.tbPerfil.descPerfil" disabled>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Status de Registo</label>
                  <p-toggleButton name="isFirst" [(ngModel)]="!user.isFirst" onLabel="Confirmado"
                                  offLabel="Não Confirmado" disabled>
                  </p-toggleButton>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Data de Registo</label>
                  <p-calendar name="dataCriaUsuario" [readonlyInput]="true" dateFormat="dd/mm/yy" [locale]="locale.pt"
                              [(ngModel)]="user.dataCriaUsuario" [disabled]="true">
                  </p-calendar>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Data de Atualização</label>
                  <p-calendar name="dataAlterUsuario" [readonlyInput]="true" dateFormat="dd/mm/yy" [locale]="locale.pt"
                              [(ngModel)]="user.dataAlterUsuario" [disabled]="true">
                  </p-calendar>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Senha de Recuperação</label>
                  <input pInputText name="senhaUsuarioRecuperacao" [(ngModel)]="user.senhaUsuarioRecuperacao" disabled>
                </div>

                <div class="p-field p-col-12 p-md-3">
                  <label>Status do Utilizador</label>
                  <p-inputSwitch name="statusUsuario" [(ngModel)]="user.statusUsuario" [disabled]="true"
                                 [ngStyle]="{display: 'block'}">
                  </p-inputSwitch>
                </div>

                <div class="p-field p-col-12 text-right">
                  <hr/>
                  <button type="submit" mdbBtn color="success" mdbWavesEffect [disabled]="!updateForm.valid || loading">
                    SALVAR
                  </button>

                  <!-- <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="openDialogChangeEmail()">
                    ALTERAR E-MAIL
                  </button> -->

                  <div class="p-field p-col-12">
                    <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
                    </p-progressBar>
                  </div>
                </div>
              </div>
            </form>
          </p-tabPanel>

          <!--<p-tabPanel header="CERTIFICAÇÃO">-->
          <!--  <h5>EM DESENVOLVIMENTO</h5>-->
          <!--</p-tabPanel>-->

          <!--<p-tabPanel header="FICHEIROS">-->
          <!--  <h5>EM DESENVOLVIMENTO</h5>-->
          <!--  &lt;!&ndash; <app-get-file-document></app-get-file-document> &ndash;&gt;-->
          <!--</p-tabPanel>-->

          <p-tabPanel header="CUMPRIMENTO">
            <app-get-situation *ngIf="taxpayerId" [taxpayerId]="taxpayerId"></app-get-situation>
          </p-tabPanel>

          <p-tabPanel header="ESTATÍSTICAS">
            <div class="p-grid">
              <div class="p-col-12">
                <!-- <div class="alert alert-warning" role="alert">
                  <p class="text-danger">
                    <mdb-icon fas icon="info-circle"></mdb-icon>
                    <strong> As estatísticas encontram-se temporariamente indisponíveis!</strong>
                  </p>
                </div> -->
                <!--<app-statistic-emitter-read-month [emitterNif]="nif"></app-statistic-emitter-read-month>-->
              </div>
              <div class="p-col-6">
                <app-statistic-emitter-read-month [emitterNif]="nif" *ngIf="!!nif">
                </app-statistic-emitter-read-month>
              </div>

              <div class="p-col-6">
                <app-statistic-emitter-read-year [emitterNif]="nif" *ngIf="!!nif">
                </app-statistic-emitter-read-year>
              </div>
            </div>
          </p-tabPanel>

          <p-tabPanel header="VALIDAR UTILIZADOR">
            <div class="p-grid">
              <div id="print-area">
                <kendo-pdf-export #pdf forcePageBreak=".page-break">
                  <div class="page">
                    <p class="text-center p-my-0">
                      REPÚBLICA DEMOCRÁTICA
                      <img src="/assets/img/brasao.jpg" class="brasao" alt="STP">
                      DE SÃO TOMÉ E PRÍNCIPE
                    </p>
                    <p class="text-center p-my-0">MINISTÉRIO DO PLANEAMENTO, FINANÇAS E ECONOMIA AZUL</p>
                    <p class="text-center p-my-0 p-text-bold">DIRECÇÃO DOS IMPOSTOS</p>
                    <p class="text-center p-my-0">(Unidade - Disciplina - Trabalho)</p>

                    <br>
                    <h5 class="h6 term-title" [ngStyle]="{textAlign: 'center'}">
                      Termo de uso e responsabilidade para a utilização do portal e-factura
                    </h5>

                    <p class="term-paragraph sir-taxpayer">Senhor Contribuinte</p>

                    <p class="term-paragraph">
                      No âmbito da criação e da implementação do sistema de facturação electrónica no
                      ordenamento jurídico da República Democrática de S. Tome e Príncipe, foi criado o
                      regime jurídico de e-factura, aprovado pelo Decreto-Lei 14/2019, de 09 de Dezembro,
                      com o objectivo de promover e apoiar o cumprimento voluntário das obrigações fiscais
                      pelos sujeitos passivos.
                    </p>

                    <p class="term-paragraph">
                      É indiscutível que as novas tecnologias de informação e comunicação constituem nos
                      tempos que correm uma ferramenta imprescindível para o Estado e para os
                      contribuintes, na medida em que ajudam o primeiro a reforçar ainda mais o combate à
                      fraude e a evasão fiscal e os segundos no cumprimento voluntário das obrigações
                      fiscais, mormente as vertidas no Regime Jurídico das Facturas e Documentos
                      Equivalentes.
                    </p>

                    <p class="term-paragraph">
                      Assim, impõe-se à Direcção dos Impostos tomar as devidas precauções na
                      transmissão de informações em suas contas oficiais no que concerne, por exemplo, à
                      exactidão do conteúdo, segurança e proteção de todos os dados devidamente
                      prestados.
                    </p>

                    <p class="term-paragraph">
                      De modo que o Portal e-factura seja utilizado de maneira consciente, respeitosa e
                      transparente, as informações prestadas pelos contribuintes estarão sujeitas à
                      moderação e filtragem conforme as regras de uso.
                    </p>

                    <p class="term-paragraph">
                      Neste sentido, com o objectivo de manter um ambiente de cordialidade no espaço
                      virtual da Direcção dos Impostos, poderão ser excluídas todas as informações que
                      forem consideradas, de alguma forma, inapropriadas, e o contribuinte será automaticamente
                      bloqueado, nomeadamente: (i) registo de dados falsos; (ii) utilização
                      de linguagem ofensiva, desrespeitosa, abusiva, ilegal, caluniosa, ameaçadora ou
                      difamatória; (iii) opiniões de conteúdo obsceno ou que incitem à actos julgados
                      discriminatórios e preconceituosos; (iv) apologia à práticas ilícitas.
                    </p>

                    <p class="term-paragraph">
                      Por isso, a Direcção dos Impostos não se responsabilizará por eventuais danos
                      causados pela prestação indevida de informações por parte dos contribuintes, sem
                      prejuízo da aplicação das penalizações previstas no artigo 19.º do Decreto Lei
                      14/2019.
                    </p>
                    <span class="page-break"> </span>

                    <p class="term-paragraph">
                      Todos os contribuintes deverão estar cientes das regras de uso e convivência deste
                      portal, o que não exclui a consulta do Decreto Lei acima referido.
                    </p>

                    <p class="term-paragraph">
                      Assim, ao aceitar este termo de uso e de responsabilidade, estará a assumir como
                      verdadeiras as informações prestadas neste acto, fazendo parte integrante dos
                      registros e arquivos do Portal e-factura.
                    </p>

                    <p class="term-paragraph">
                      Todas as responsabilidades inerentes ao uso dos recursos tecnológicos da Direcção
                      dos Impostos, bem como as implicações legais decorrentes do seu uso indevido, seja
                      qual for a circunstância, recaem sobre o usuário (titular da senha disponibilizada para
                      o acesso ao e-factura) estando todas acções realizadas no seu âmbito sujeitas ao
                      monitoramento e controlo da Direcção dos Impostos.
                    </p>

                    <p class="term-paragraph">
                      Através deste termo assume ainda que está ciente que o Portal e-factura concede
                      contas para acesso à rede de computadores e e-mail para utilização exclusiva do
                      usuário, pelo que não deverá disponibilizar nem facilitar ou permitir o uso das referidas
                      contas a qualquer pessoa, funcionário ou não, ainda que hierarquicamente superior.
                    </p>

                    <p class="p-text-bold">Seus Dados de Utilizador</p>
                    <ul>
                      <li>
                        <span class="p-text-bold">Nome: </span>
                        {{user.tbContribuinte.tbPessoa.nomePessoa}}
                      </li>

                      <li>
                        <span class="p-text-bold">NIF: </span>
                        {{user.nifUsuario}}
                      </li>

                      <li *ngIf="user.statusAssinatura">
                        <span class="p-text-bold">Senha Temporária:</span>
                        {{ user.senhaUsuarioRecuperacao }}
                      </li>

                      <li *ngIf="user.statusAssinatura">
                        <span class="p-text-bold">NIF do Assinante: </span>
                        {{user.nifAssinante}}
                      </li>
                    </ul>
                  </div>
                </kendo-pdf-export>
              </div>

              <div class="p-col-12">
                <form>
                  <br>
                  <div class="p-col-12 text-right">
                    <button type="submit" color="success" mdbBtn mdbWavesEffect (click)="displayValidUser=true"
                            [disabled]="user.statusAssinatura">
                      VALIDAR
                    </button>

                    <button type="submit" color="default" mdbBtn mdbWavesEffect [disabled]="!user.statusAssinatura"
                            (click)="pdf.saveAs('TERMO-' + user.nifUsuario + '.pdf')">
                      EXPORTAR
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header=" Validar Utilizador" [(visible)]="displayValidUser" [modal]="true" [responsive]="true"
          [style]="{width: '600px', minWidth: '200px'}" [minY]="70" [baseZIndex]="10000">
  <form #validUserForm="ngForm" (ngSubmit)="validUserAndPrintTerm()">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12">
        <label>NIF do Assinante</label>
        <input pInputText name="nifAssinante" [(ngModel)]="user.nifAssinante" (input)="onInputNif($event)" required>
      </div>

      <div class="p-field p-col-12">
        <label>Nome do Assinante</label>
        <input pInputText name="nameAssinante" [(ngModel)]="nameAssinante" disabled required>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="cancelValidUser()">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="success" mdbWavesEffect
                [disabled]="!validUserForm.valid || loadingValidUser || !validNif">
          VALIDAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingValidUser" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>
    </div>
  </form>
</p-dialog>

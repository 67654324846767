<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMINISTRADOR
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ROTINAS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body">
        <table mdbTable striped="true" bordered="true" hover="true">
          <thead>
            <tr>
              <th scope="col" [style]="{width: '10%'}"> # </th>
              <th [style]="{width: '80%'}"> <strong>Rotina</strong> </th>
              <th [style]="{width: '10%'}"> </th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol>
              <th scope="row">1</th>
              <td>Corrigir máscaras dos números de documentos</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '6px 0'}"
                  (click)="fixDocumentByMask()" [disabled]="loadingFixDocumentByMask">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingFixDocumentByMask"></i>
                  <span *ngIf="!loadingFixDocumentByMask">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">2</th>
              <td>Enviar e-mails de confirmação de ficheiros</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="sendMailConfirmationFile()" [disabled]="loadingSendMailCommunicationFile">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}"
                    *ngIf="loadingSendMailCommunicationFile"></i>
                  <span *ngIf="!loadingSendMailCommunicationFile">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">3</th>
              <td>Enviar e-mails de comunicação de ficheiros com problemas</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="sendMailCommunicationWithProblem()" [disabled]="loadingSendMailCommunicationWithProblem">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}"
                    *ngIf="loadingSendMailCommunicationWithProblem"></i>
                  <span *ngIf="!loadingSendMailCommunicationWithProblem">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">4</th>
              <td>Enviar e-mails de correção de máscaras</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="sendMailFixMask()" [disabled]="loadingSendMailFixMask">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingSendMailFixMask"></i>
                  <span *ngIf="!loadingSendMailFixMask">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">5</th>
              <td>Validar 1ª fase de análise de ficheiro</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="validFirstPhase()" [disabled]="loadingValidFirstPhase">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingValidFirstPhase"></i>
                  <span *ngIf="!loadingValidFirstPhase">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">6</th>
              <td>Validar 2ª fase de análise de ficheiro</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="validSecondPhase()" [disabled]="loadingValidSecondPhase">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingValidSecondPhase"></i>
                  <span *ngIf="!loadingValidSecondPhase">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <!-- <tr mdbTableCol>
              <th scope="row">7</th>
              <td>Validar 3ª fase de análise de ficheiro</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="validThirdPhase()" [disabled]="loadingValidThirdPhase">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingValidThirdPhase"></i>
                  <span *ngIf="!loadingValidThirdPhase">EXECUTAR</span>
                </button>
              </td>
            </tr> -->

            <tr mdbTableCol>
              <th scope="row">8</th>
              <td>Enviar notificações</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="sendNotifications()" [disabled]="loadingSendNotifications">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingSendNotifications"></i>
                  <span *ngIf="!loadingSendNotifications">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">9</th>
              <td>Comunicar documentos emitidos no portal</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="communicateDocumentOnline()" [disabled]="loadingCommunicateDocumentOnline">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}"
                    *ngIf="loadingCommunicateDocumentOnline"></i>
                  <span *ngIf="!loadingCommunicateDocumentOnline">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">10</th>
              <td>Pegar Documento Gerado Duplicado e setar como editável</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="getDuplicateDocumentAndSetItAsEditable()"
                  [disabled]="loadingGetDuplicateDocumentAndSetItAsEditable">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}"
                    *ngIf="loadingGetDuplicateDocumentAndSetItAsEditable"></i>
                  <span *ngIf="!loadingGetDuplicateDocumentAndSetItAsEditable">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">11</th>
              <td>Transportar séries online</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="transportSerieOnline()" [disabled]="loadingTransportSerieOnline">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loadingTransportSerieOnline"></i>
                  <span *ngIf="!loadingTransportSerieOnline">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">12</th>
              <td>Atualizar valor do documento a ser anulado</td>
              <td>
                <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}"
                  (click)="updateDocumentValueToBeCanceled()" [disabled]="loadingUpdateDocumentValueToBeCanceled">
                  <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}"
                    *ngIf="loadingUpdateDocumentValueToBeCanceled"></i>
                  <span *ngIf="!loadingUpdateDocumentValueToBeCanceled">EXECUTAR</span>
                </button>
              </td>
            </tr>

            <tr mdbTableCol>
              <th scope="row">13</th>
              <app-insert-emission-document-histories></app-insert-emission-document-histories>
            </tr>

            <tr mdbTableCol>
              <th scope="row">13</th>
              <app-update-total-emission-document></app-update-total-emission-document>
            </tr>
          </tbody>
        </table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

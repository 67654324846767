import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { FileDocumentCancellationRequestService } from 'src/app/file-document-cancellation-request/file-document-cancellation-request.service';
import { decrypt } from '../../configs/encryption';
import { FileDocumentEmitterService } from '../file-document-emitter.service';
import { FileDocumentCancellationRequest } from './../../core/models/file-document-cancellation-request';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  display: boolean = false;
  loadingRequestCancellation: boolean = false;
  locale = new Locale();
  fileDocument = new FileDocument();

  request = new FileDocumentCancellationRequest();

  constructor(
    private service: FileDocumentEmitterService,
    private serviceRequest: FileDocumentCancellationRequestService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));
    this.get(decryptedId);
  }

  showDialog() {
    this.display = true;
  }

  get(id: number) {
    this.service.get(id)
      .then(response => {
        this.fileDocument = response;
      })
      .catch(error => this.errorHandler.handle(error));
  }

  requestCancellation() {
    this.loadingRequestCancellation = true;
    this.request.tbArquivoDocumentoGerado.idArquivoDocumentoGerado = this.fileDocument.idArquivoDocumentoGerado;

    delete this.request.tbArquivoDocumentoGerado.step1;
    delete this.request.tbArquivoDocumentoGerado.step2;
    delete this.request.tbArquivoDocumentoGerado.step3;
    delete this.request.tbArquivoDocumentoGerado.tbContribuinte;

    this.serviceRequest.create(this.request)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Solicitação enviada! Receberá uma notificação assim que for processada'
        });
        this.display = false;
      })
      .catch(err => this.errorHandler.handle(err))
      .finally(() => this.loadingRequestCancellation = false);
  }
}

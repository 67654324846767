import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from 'src/app/configs/models/locale';
import { DocumentView } from 'src/app/core/models/document';
import { DocumentService } from '../document.service';
import { decrypt } from '../../configs/encryption';
import { DocumentItemService } from './../../document-item/document-item.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  document = new DocumentView();
  locale = new Locale();

  creditAndDebitNotesIsOpen: boolean;
  creditAndDebitNotesItems = [];

  constructor(
    private service: DocumentService,
    private documentItemService: DocumentItemService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));
    this.get(decryptedId);
  }

  get(id: number) {
    this.service.get(id)
      .then((response) => {
        this.document = response
        this.getCreditAndDebitNotes();
      });
  }

  getCreditAndDebitNotes() {
    this.documentItemService.readByDocument(this.document.numDocumento)
      .then((data) => {
        this.creditAndDebitNotesItems = data;
      })
  }

  displayCreditAndDebitNotes() {
    this.creditAndDebitNotesIsOpen = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { EmissionDocumentEmitterService } from '../emission-document-emitter.service';
import { Authorization } from 'src/app/core/models/authorization';
import { EmissionDocumentDTO } from '../types/emission-document';
import { BlockedTaxpayerInfo } from 'src/app/core/models/taxpayer';

@Component({
  selector: 'app-transport-serie-fast',
  templateUrl: './transport-serie-fast.component.html',
  styleUrls: ['./transport-serie-fast.component.scss']
})
export class TransportSerieFastComponent implements OnInit {
  displaySerieTransported: boolean = false;
  loadingSerieToTransport: boolean = false;
  loadingTransportSerie: boolean = false;

  emissionsDocuments: EmissionDocumentDTO[] = [];
  selectedEmissionsDocuments: EmissionDocumentDTO[] = [];

  transportedSeries: Authorization[];

  blockedTaxpayerInfo: BlockedTaxpayerInfo;
  displayBlockedSerieMessage: boolean = false;

  constructor(
    private service: EmissionDocumentEmitterService,
    private confirmationService: ConfirmationService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.read();
  }

  confirm() {
    this.confirmationService.confirm({
      message: `
      Deseja transportar as séries selecionadas?
      `,
      accept: () => {
        this.sendSerieToTransportAutomatic();
      },
    });
  }

  read() {
    this.loadingSerieToTransport = true;

    this.service.readSerieToTransportAutomatic()
      .then((response) => {
        this.emissionsDocuments = response;

      }).finally(() => this.loadingSerieToTransport = false);
  }

  getSelectedEmissionsDocuments(event: EmissionDocumentDTO[]) {
    this.selectedEmissionsDocuments = event;
  }

  sendSerieToTransportAutomatic() {
    this.loadingTransportSerie = true;

    this.service.sendSerieToTransportAutomatic(this.selectedEmissionsDocuments)
      .then((result) => {
        this.read();
        this.transportedSeries = result;
        this.displaySerieTransported = true;
      })
      .catch((error) => {
        if (error.status === 406) {
          this.blockedTaxpayerInfo = error.error;
          this.displayBlockedSerieMessage = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => this.loadingTransportSerie = false);
  }
}

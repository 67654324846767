import { Injectable } from '@angular/core';
import { ThirdPartyActivity } from '../core/models/third-party-activity';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyActivityService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/atividade`;
  }

  async read(): Promise<any> {
    return this.http
      .get<any>(`${this.API_URL}`)
      .toPromise()
      .then((response: ThirdPartyActivity[]) => {
        this.convertFields(response);
        return response;
      });
  }

  private convertFields(activities: ThirdPartyActivity[]) {
    for (const activity of activities) {
      switch (activity.nome) {
        case 'EMITIR_FATURA_ONLINE':
          activity.label = 'EMITIR FATURAS NO PORTAL E-FACTURA';
          break;

        case 'SOLICITAR_NOVAS_SERIES':
          activity.label = 'SOLICITAR NOVAS SERIES PARA SOFTWARE AUTÓNOMO';
          break;

        case 'COMUNICAR_FICHEIRO':
          activity.label = 'COMUNICAR POR FICHEIROS OS DOCUMENTOS EMITIDOS';
          break;

        case 'COMUNICAR_UNIDADE':
          activity.label = 'COMUNICAR POR UNIDADE OS DOCUMENTOS EMITIDOS';
          break;

        default:
          activity.label = null;
          break;
      }
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CountToModule } from 'angular-count-to';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { StatisticEmitterDetailShortComponent } from './statistic-emitter-detail-short/statistic-emitter-detail-short.component';
import { StatisticEmitterReadMonthComponent } from './statistic-emitter-read-month/statistic-emitter-read-month.component';
import { StatisticEmitterReadYearComponent } from './statistic-emitter-read-year/statistic-emitter-read-year.component';

@NgModule({
  declarations: [
    StatisticEmitterReadMonthComponent,
    StatisticEmitterReadYearComponent,
    StatisticEmitterDetailShortComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MDBBootstrapModule,
    CountToModule,
    AccordionModule,
    CalendarModule,
    PanelModule,
  ],
  exports: [
    StatisticEmitterReadMonthComponent,
    StatisticEmitterReadYearComponent,
    StatisticEmitterDetailShortComponent,
  ],
})
export class StatisticEmitterModule {}

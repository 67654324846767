import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CreateComponent } from './create/create.component';

const routes: Routes = [
  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_FATURA'] }
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmissionTypographyRouter { }

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util/help-center">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CENTRAL DE AJUDA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/occurrence">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SOLICITAÇÕES
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES DA SOLICITAÇÃO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-8">
    <mdb-card>
      <mdb-card-body>
        <form #f="ngForm" (ngSubmit)="update()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label>Assunto</label>
              <input pInputText name="assunto" [(ngModel)]="occurrence.assunto"
                placeholder="Informe o assunto da solicitação" required>
            </div>

            <div class="">
              <div class="container">
                <img src="/w3images/bandmember.jpg" alt="Avatar" style="width:100%;">
                <p>Hello. How are you today?</p>
                <span class="time-right">11:00</span>
              </div>

              <div class="container darker">
                <img src="/w3images/avatar_g2.jpg" alt="Avatar" class="right" style="width:100%;">
                <p>Hey! I'm fine. Thanks for asking!</p>
                <span class="time-left">11:01</span>
              </div>

              <div class="container">
                <img src="/w3images/bandmember.jpg" alt="Avatar" style="width:100%;">
                <p>Sweet! So, what do you wanna do today?</p>
                <span class="time-right">11:02</span>
              </div>

              <div class="container darker">
                <img src="/w3images/avatar_g2.jpg" alt="Avatar" class="right" style="width:100%;">
                <p>Nah, I dunno. Play soccer.. or learn more coding perhaps?</p>
                <span class="time-left">11:05</span>
              </div>
            </div>

            <div class="p-field p-col-12">
              <label>Mensagem</label>
              <p-editor name="detalhe" [(ngModel)]="occurrence.detalhe" [style]="{'height':'150px'}"></p-editor>
            </div>

            <div class="p-field p-col-12 text-right">
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/emitter/occurrence">
                VOLTAR
              </button>

              <button mdbBtn type="submit" color="success" mdbWavesEffect [disabled]="!f.valid || loading">
                ENVIAR
              </button>
            </div>

            <div class="p-field p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="p-col-4">
    <mdb-card>
      <mdb-card-body>
        <table mdbTable striped="true" small="true">
          <tbody>
            <tr>
              <th scope="row"> <strong>Status </strong></th>
              <td colspan="2">
                {{occurrence.status}}
              </td>
            </tr>

            <tr>
              <th scope="row"> <strong>Data da solicitação </strong></th>
              <td colspan="2">
                {{occurrence.dataCria | date:'medium'}}
              </td>
            </tr>

            <tr>
              <th scope="row"> <strong>Data da última resposta </strong></th>
              <td colspan="2">
                {{occurrence.dataResposta | date:'medium'}}
              </td>
            </tr>
          </tbody>
        </table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { StatisticEmitter } from './../../core/models/statistic-emitter';
import { StatisticEmitterDetailShortComponent } from './../statistic-emitter-detail-short/statistic-emitter-detail-short.component';
import { StatisticEmitterFilter, StatisticEmitterService } from './../statistic-emitter.service';

@Component({
  selector: 'app-statistic-emitter-read-year',
  templateUrl: './statistic-emitter-read-year.component.html',
  styleUrls: ['./statistic-emitter-read-year.component.scss']
})
export class StatisticEmitterReadYearComponent implements OnInit {
  @Input() emitterNif: string;
  @ViewChild(StatisticEmitterDetailShortComponent) statisticDetail: StatisticEmitterDetailShortComponent

  loading: boolean = false;
  statistic = new StatisticEmitter();
  filter = new StatisticEmitterFilter();

  now = new Date();
  startDate = new Date(this.now.getFullYear(), 0, 1);
  endDate = new Date(this.now.getFullYear(), 11, 31);

  constructor(
    private statistEmitterService: StatisticEmitterService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.statisticDetail.get({
      emitterNif: this.emitterNif,
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  get() {
    this.statistEmitterService.get(this.filter)
      .then((result) => this.statistic = result)
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }

  arrowLeft() {
    this.prevDate();
    this.statisticDetail.get({
      emitterNif: this.emitterNif,
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  arrowRight() {
    this.nextDate();
    this.statisticDetail.get({
      emitterNif: this.emitterNif,
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  prevDate(): void {
    this.now = new Date(this.now.getFullYear() - 1, 11);

    this.startDate = new Date(this.now.getFullYear(), 0, 1);
    this.endDate = new Date(this.now.getFullYear(), 11, 31);
  }

  nextDate(): void {
    this.now = new Date(this.now.getFullYear() + 1, 0);

    this.startDate = new Date(this.now.getFullYear(), 0, 1);
    this.endDate = new Date(this.now.getFullYear(), 11, 31);
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIROS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RECOLHER FICHEIROS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-info" role="alert">
      <p>
        <mdb-icon fas icon="info-circle"></mdb-icon>

        <strong> Atenção! </strong>

        Esta funcionalidade destina-se somente a terceiro que pretende comunicar ficheiros em nome de outrem. Ou seja,
        contabilista autorizado a efetuar operações em nome do operador económico.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <h6 class="text-danger">
        <mdb-icon fas icon="info-circle"></mdb-icon>
        <strong> INFORMAÇÕES IMPORTANTES!</strong>
      </h6>
      <p> Nesta página estão disponíveis duas funcionalidades.</p>

      <ul class="list-unstyled">
        <li> <strong>Verificação de ficheiro.</strong>
          <ul>
            <li class="text-dark">
              Antes de comunicar o seu ficheiro utilize esta funcionalidade para o validar.
            </li>

            <li>
              A funcionalidade consiste na validação da estrutura do ficheiro e o formato dos dados nele contido.
            </li>

            <li>
              Após a verificação é apresentado um relatório dos possíveis erros encontrados e indicações de correções.
            </li>

            <li>
              Instruções de preenchimento do ficheiro podem ser encontradas
              <a href="https://impostos.financas.gov.st/index.php/servicos/e-factura/ajuda/instrucoes-preenchimento-ficheiro-excel"
                target="_blanc"> <strong> neste link</strong> </a>.
            </li>
          </ul>
        </li>
      </ul>

      <ul class="list-unstyled">
        <li> <strong>Comunicação de ficheiro.</strong>
          <ul>
            <li>
              Esta funcionalidade permite aos emitentes comunicarem os seus documentos através de um ficheiro cuja a
              estrutura é pré-definida pela Direcção dos Impostos.
            </li>

            <li>
              Todas as orientações relativamente aos modelos dos ficheiros podem ser encontradas
              <a href="https://impostos.financas.gov.st/index.php/documentos/category/21-ficheiro-efactura"
                target="_blanc"> <strong> neste link</strong> </a>.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form #form="ngForm" autocomplete="off" (ngSubmit)="enviarFicheiro(fileUpload)">
          <div class="p-fluid p-formgrid p-grid">
            <p class="p-col-12">
              Todos os campos com
              <b class="text-danger">*</b>
              são obrigatórios.
            </p>

            <div class="p-field p-col-12 p-md-3" [style]="{marginBottom: '20px'}">
              <strong>Período de Facturação<span class="text-danger">*</span></strong>
              <p-calendar name="period" [(ngModel)]="queryParams.period" view="month" dateFormat="mm/yy"
                [yearNavigator]="true" yearRange="2019:2022" [locale]="locale.pt"
                placeholder="Informe o período de facturação" [minDate]="minDate" [maxDate]="maxDate" required>
              </p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3" [style]="{marginBottom: '20px'}">
              <strong>Emitente<span class="text-danger">*</span></strong>
              <p-dropdown [options]="emitters" name="emitterNif" [(ngModel)]="queryParams.emitterNif"
                placeholder="Selecione o emitente" [showClear]="true"></p-dropdown>
            </div>

            <div class="p-col-12">
              <strong>Ficheiro <span class="text-danger">*</span></strong>
              <p-fileUpload name="file" accept=".xls, .xlsx, .csv, .json" maxFileSize="100000000" customUpload="true"
                chooseLabel="Adicionar" cancelLabel="Cancelar" (onSelect)="onSelectImage($event.files)"
                [showUploadButton]="false" #fileUpload required>
                <ng-template pTemplate="content">
                </ng-template>
              </p-fileUpload>
            </div>

            <div class="p-col-12">
              <div class="alert bg-white" role="alert" style="border: thin solid #cecece;" *ngIf="showVerifiedFile">

                <h5>Resumo da Verificação</h5>

                <p style="color: #000000;">
                  <strong>Nome do ficheiro:</strong> {{ fileCommunicationError.nomeFile }}
                </p>

                <p style="color: #000000;">
                  <strong>Linhas processadas:</strong>
                  {{ fileCommunicationError.totalLinhas }}
                </p>

                <p style="color: #000000;">
                  <strong>Erros encontrados:</strong>
                  {{ fileCommunicationError.erros.length }}
                </p>

                <ul class="">
                  <li class="col-pink" *ngFor="let erro of fileCommunicationError.erros; index as i">
                    {{erro}}
                  </li>
                </ul>
              </div>
            </div>

            <div class="p-col-12 text-right">
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/emitter/third-party/index">
                VOLTAR
              </button>

              <button type="button" mdbBtn color="info" mdbWavesEffect (click)="verificarFicheiro()"
                pTooltip="Verificar o ficheiro antes de enviar" tooltipPosition="top"
                [disabled]="!form.valid || !uploadedFile">
                VERIFICAR
              </button>

              <button type="submit" mdbBtn color="success" mdbWavesEffect pTooltip="Comunicar o ficheiro"
                tooltipPosition="top" [disabled]="!form.valid || !uploadedFile">
                COMUNICAR
              </button>
            </div>

            <div class="p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

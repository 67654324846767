import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { GetCertificationComponent } from './get-certification/get-certification.component';
import { GetFileDocumentComponent } from './get-file-document/get-file-document.component';
import { GetGreatTaxpayerSituationComponent } from './get-great-taxpayer-situation/get-great-taxpayer-situation.component';
import { GetSituationComponent } from './get-situation/get-situation.component';
import { GetComponent } from './get/get.component';
import { ReadComponent } from './read/read.component';
import { TaxpayerReadNotHaveSerieComponent } from './taxpayer-read-not-have-serie/taxpayer-read-not-have-serie.component';
import { TaxpayerReadWithSerieWithoutDocumentComponent } from './taxpayer-read-with-serie-without-document/taxpayer-read-with-serie-without-document.component';
import { TaxpayerRouter } from './taxpayer-router';

@NgModule({
  declarations: [
    TaxpayerReadNotHaveSerieComponent,
    TaxpayerReadWithSerieWithoutDocumentComponent,
    GetSituationComponent,
    GetCertificationComponent,
    GetFileDocumentComponent,
    GetGreatTaxpayerSituationComponent,
    ReadComponent,
    GetComponent
  ],
  imports: [
    CommonModule,
    TaxpayerRouter,
    FormsModule,
    MDBBootstrapModule,

    LayoutModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DialogModule,
    OverlayPanelModule,
    DropdownModule,
    InputMaskModule,
    CurrencyMaskModule,
    AccordionModule,
    ScrollPanelModule,
    SidebarModule
  ],
  exports: [
    GetSituationComponent,
    GetCertificationComponent,
    GetFileDocumentComponent
  ]
})
export class TaxpayerModule { }

<section id="intro">

    <div class="intro-content">
        <h2>Porquê pedir<br><span>factura</span>?</h2>

        <div class="intro-text-h5">
            <h5>
                Quando exige factura garante que os impostos que pagou são entregues ao Estado.
            </h5>
            <h5>
                É um dever de cidadania aumentar a justiça, contribuindo para o combate à fraude e evasão fiscal. Não é
                justo pagar mais impostos por existirem contribuintes (cidadãos ou empresas) que não cumprem as suas
                obrigações fiscais.
            </h5>
        </div>
        <!--<div>
            <a href="/index#services" class="btn-get-started scrollto" (click)=refreshToke()>Começar</a>
            <a href="/ajuda" class="btn-projects scrollto">Ajuda</a>
        </div>-->
    </div>

    <div id="intro-carousel" class="owl-carousel">
        <!--
          <div class="item" style="background-image: url('/assets/img/intro-carousel/1.jpg');"></div>
          <div class="item" style="background-image: url('/assets/img/intro-carousel/2.jpg');"></div>
        <div class="item" style="background-image: url('/assets/img/intro-carousel/3.jpg');"></div>
        <div class="item" style="background-image: url('/assets/img/intro-carousel/4.jpg');"></div>
        <div class="item" style="background-image: url('/assets/img/intro-carousel/5.jpg');"></div>-->
    </div>

</section>

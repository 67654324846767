import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private keycloakService: KeycloakService) {}

  public async login(): Promise<void> {
    return this.keycloakService.login();
  }

  public async logout(): Promise<void> {
    localStorage.clear();
    return this.keycloakService.logout(window.location.origin);
  }

  public async loadUserProfile(): Promise<Keycloak.KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public redirectToProfile(): Keycloak.KeycloakPromise<void, void> {
    return this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public redirectToLogin(): void {
    this.keycloakService.login();
  }

  public getRules(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public getGroups(): string[] {
    return this.getLoggedUser()?.groups;
  }
  
  public getLoggedUser(): Keycloak.KeycloakTokenParsed | undefined {
    try {
      const userDetails =
        this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (error) {
      return undefined;
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  public hasPermission(rule: string): boolean {
    return this.keycloakService.getUserRoles().includes(rule);
  }

  public hasGroup(group: string) {
    return this.getGroups().includes(group);
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/occurrence/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SOLICITAÇÕES
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form #occurrenceForm="ngForm" (ngSubmit)="save()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-6">
              <div class="p-field p-col-12">
                <label>Assunto</label>
                <input pInputText name="assunto" [(ngModel)]="occurrence.assunto"
                  placeholder="Informe o assunto da solicitação" required>
              </div>

              <div class="p-field p-col-12">
                <label>Mensagem</label>
                <p-editor name="detalhe" [(ngModel)]="occurrence.detalhe" [style]="{'height':'150px'}"></p-editor>
              </div>

              <table mdbTable striped="true" small="true">
                <tbody>
                  <tr>
                    <th scope="row" [style]="{width: '18%'}"> <strong>Status </strong></th>
                    <td colspan="2">
                      <div class="badges">
                        <span [class]="['p-tag', occurrence.statusColor]">
                          {{ occurrence.status }}
                        </span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row"> <strong>Enviado em</strong></th>
                    <td colspan="2">
                      {{occurrence.dataCria | date}}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row"> <strong>Respondido em </strong></th>
                    <td colspan="2">
                      {{occurrence.dataResposta | date}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="p-col-6">
              <div class="p-field p-col-12">
                <label>Resposta</label>
                <p-editor name="resposta" [(ngModel)]="occurrence.resposta" [style]="{'height':'150px'}"></p-editor>
              </div>
            </div>

            <div class="p-field p-col-12 text-right">
              <hr>

              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/occurrence/read">
                VOLTAR
              </button>

              <button mdbBtn type="submit" color="success" mdbWavesEffect [disabled]="!occurrenceForm.valid || loading">
                SALVAR
              </button>

              <button mdbBtn type="button" color="success" mdbWavesEffect [disabled]="!occurrenceForm.valid || loading"
                (click)="answer()">
                SALVAR E ENVIAR
              </button>
            </div>

            <div class="p-field p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}"> </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import {
  DocumentEmitterService,
  DocumentEmitterFilter,
} from './../../document-emitter/document-emitter.service';

@Component({
  selector: 'app-emitter',
  templateUrl: './emitter.component.html',
  styleUrls: ['./emitter.component.scss'],
})
export class EmitterComponent implements OnInit {
  filter = new DocumentEmitterFilter();
  total = 0;
  showDocumentsWithIssues: boolean = false;
  nif; // = this.auth.jwtPayload.nif;

  displayTransportSerie: boolean = false;

  constructor(
    // private documentEmitterService: DocumentEmitterService,
    public auth: AuthService
  ) // private errorHandler: ErrorHandlerService,
  {}

  ngOnInit(): void {
    // this.read();
  }

  // read() {
  //   this.documentEmitterService.readIssues(this.filter)
  //     .then((response) => {
  //       this.total = response.total;

  //       if (this.total) {
  //         this.showDocumentsWithIssues = true;
  //       }
  //     })
  //     .catch((error) => this.errorHandler.handle(error))
  // }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMINISTRADOR
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/user-admin/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          UTILIZADORES
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" #updateForm="ngForm" (ngSubmit)="update()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label>NIF</label>
              <input pInputText name="nifContribuinte" [(ngModel)]="user.tbContribuinte.nifContribuinte" disabled>
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome</label>
              <input pInputText name="nomePessoa" [(ngModel)]="user.tbContribuinte.tbPessoa.nomePessoa">
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Endereço</label>
              <input pInputText name="enderecoPessoa" [(ngModel)]="user.tbContribuinte.tbPessoa.enderecoPessoa">
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Telefone</label>
              <input pInputText name="telefonePessoa" [(ngModel)]="user.tbContribuinte.tbPessoa.telefonePessoa">
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>BI</label>
              <input pInputText name="identidade" [(ngModel)]="user.tbContribuinte.tbPessoa.identidade">
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>E-mail</label>
              <input pInputText name="emailUsuario" [(ngModel)]="user.emailUsuario">
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Perfil</label>
              <p-dropdown name="idPerfil" [options]="profiles" placeholder="Selecione"
                [(ngModel)]="user.tbPerfil.idPerfil">
              </p-dropdown>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Status de Registo</label>
              <p-toggleButton name="isFirst" [(ngModel)]="!user.isFirst" onLabel="Confirmado" offLabel="Não Confirmado"
                disabled>
              </p-toggleButton>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Registo</label>
              <p-calendar name="dataCriaUsuario" [readonlyInput]="true" dateFormat="dd/mm/yy" [locale]="locale.pt"
                [(ngModel)]="user.dataCriaUsuario" [disabled]="true">
              </p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Atualização</label>
              <p-calendar name="dataAlterUsuario" [readonlyInput]="true" dateFormat="dd/mm/yy" [locale]="locale.pt"
                [(ngModel)]="user.dataAlterUsuario" [disabled]="true">
              </p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Senha de Recuperação</label>
              <input pInputText name="senhaUsuarioRecuperacao" [(ngModel)]="user.senhaUsuarioRecuperacao" disabled>
            </div>

            <div class="p-field p-col-12 text-right">
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/user-admin/read">
                VOLTAR
              </button>

              <button type="submit" mdbBtn color="success" mdbWavesEffect [disabled]="!updateForm.valid || loading">
                SALVAR
              </button>

              <!--
                <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="display=true">
                ATUALIZAR PERFIL
              </button>
              -->

              <div class="p-field p-col-12">
                <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
                </p-progressBar>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Alterar perfil" [(visible)]="display" [style]="{width: '30vw'}" [baseZIndex]="10000"
  [contentStyle]="{'overflow':'visible'}">
  <form #formProfile="ngForm" autocomplete="off">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Perfil</label>
        <p-dropdown class="p-inputtext-sm" name="idPerfil" [options]="profiles" placeholder="Selecione"
          [(ngModel)]="user.tbPerfil.idPerfil">
        </p-dropdown>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="display=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="info" mdbWavesEffect [disabled]="!formProfile.valid || loadingProfile">
          ENVIAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingProfile" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

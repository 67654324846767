import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { RoutinesService } from './../routines.service';

@Component({
  selector: 'app-fix-period-passing-standard-day',
  templateUrl: './fix-period-passing-standard-day.component.html',
  styleUrls: ['./fix-period-passing-standard-day.component.scss']
})
export class FixPeriodPassingStandardDayComponent implements OnInit {
  loading: boolean = false;
  display: boolean = false;
  routine: string = 'Corrigir a data período faturação dos ficheiro para dia padrão (5)';

  constructor(
    private routinesService: RoutinesService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
  }

  run() {
    this.loading = true;
    this.routinesService.fixPeriodPassingStandardDay()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  confirm() {
    this.confirmationService.confirm({
      message: `${this.routine}?`,
      accept: () => {
        this.run();
      }
    });
  }
}

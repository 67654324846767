import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService, ConfirmationService } from 'primeng/api';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Document, DocumentView } from './../../core/models/document';
import { DocumentEmitterFilter, DocumentEmitterService } from './../document-emitter.service';

@Component({
  selector: 'app-read-issues',
  templateUrl: './read-issues.component.html',
  styleUrls: ['./read-issues.component.scss']
})
export class ReadIssuesComponent implements OnInit {
  locale = new Locale();
  loading: boolean = true;
  display: boolean = false;
  validNif: boolean = true;
  documents = [];
  total = 0;
  filter = new DocumentEmitterFilter();
  document = new Document();
  nomeConsumidor: string;

  constructor(
    private documentEmitterService: DocumentEmitterService,
    private confirmation: ConfirmationService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.filter.editable = true;
    this.documentEmitterService.readIssues(this.filter)
      .then((response) => {
        this.total = response.total;
        this.documents = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  showDialog(document: DocumentView) {
    this.document.idDocumentoGerado = document.idDocumentoGerado;
    this.document.numeroSequencial = document.numeroSequencial;
    this.display = true;
  }

  delete(document: DocumentView) {
    this.confirmation.confirm({
      message: `Tem certeza que pretende eliminar o documento ${document.numDocumento}?`,
      accept: () => {
        this.confirmDelete(document);
      }
    });
  }

  confirmDelete(document: DocumentView) {
    this.documentEmitterService.delete(document.idDocumentoGerado)
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Document eliminado com sucesso!'
      }));
  }

  save() {
    this.documentEmitterService.updateNumber(this.document)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Dados alterados com sucesso'
        });
        this.read();
        this.display = false;
      })
      .catch(error => this.errorHandler.handle(error));
  }
}

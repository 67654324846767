<div class="row clearfix">
  <mdb-card class="card-login">
    <mdb-card-body>
      <blockquote class="blockquote bq-info text-center">
        <div class="col-md-12 text-center">
          <mdb-icon class="icon-message-lg text-info" fas icon="info-circle"></mdb-icon>
        </div>

        <p class="bq-title">Só mais um passo!</p>

        <ul class="list-unstyled">
          <li>
            Os seus dados foram recebidos com sucesso. Foi-lhe enviado um e-mail de confirmação de registo
            contendo as novas orientações, pelo que já pode fechar esta ABA do seu navegador.
          </li>

          <hr>

          <li>
            Caso o e-mail não esteja na sua de caixa de entrada verifique a sua caixa de spam e informe
            que o e-mail é confiável para que receba os próximos na sua caixa de entrada.
          </li>
        </ul>

        <!-- <hr> -->

        <!-- <footer class="blockquote-footer mb-3"><a routerLink="/login">Aceder ao sistema</a></footer> -->

      </blockquote>
    </mdb-card-body>
  </mdb-card>
</div>

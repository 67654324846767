import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from '../auth/app-http';
import { EmissionDocument } from '../core/models/emission-document';
import { environment } from './../../environments/environment';
import { EmissionDocumentDTO } from './types/emission-document';
import { Authorization } from 'src/app/core/models/authorization';

export class EmissionDocumentEmitterFilter {
  page = 0;
  rows = 15;

  typeDocument: any;
  serieNumber: string;
  description: string;
  applicantName: string;
  applicantNif: string;
  dataInicio: Date;
  dataFim: Date;
  year: any;

  emitterNif: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmissionDocumentEmitterService {
  API: string;

  constructor(private http: AppHttp) {
    this.API = `${environment.apiUrl}/pedidoEmissaoDocumento`;
  }

  async read(filter: EmissionDocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.applicantNif) {
      params = params.append('nifRequerente', filter.applicantNif);
    }
    if (filter.applicantName) {
      params = params.append('nomeRequerente', filter.applicantName);
    }
    if (filter.serieNumber) {
      params = params.append(
        'numSerieDocumento',
        filter.serieNumber.toString()
      );
    }
    if (filter.description) {
      params = params.append('descricao', filter.description);
    }
    if (filter.typeDocument) {
      params = params.append(
        'idTipoDocumentoEmissao',
        filter.typeDocument.code.toString()
      );
    }
    if (filter.dataInicio) {
      params = params.append(
        'dtCriaPedidoEmissaoDocumentoDe',
        moment(filter.dataInicio).format('YYYY-MM-DD')
      );
    }
    if (filter.dataFim) {
      params = params.append(
        'dtCriaPedidoEmissaoDocumentoAte',
        moment(filter.dataFim).format('YYYY-MM-DD')
      );
    }
    if (filter.year) {
      params = params.append(
        'anoEmissaoDocumento',
        filter.year.code.toString()
      );
    } 
    // else {
    //   const currentYear = new Date().getFullYear();
    //   params = params.append('anoEmissaoDocumento', currentYear.toString());
    // }
    /*else {
     var date = new Date();
     var year = date.getFullYear();
     params = params.append('anoEmissaoDocumento', year.toString());
   }*/

    const response = await this.http
      .get<any>(`${this.API}/porEmissor`, { params })
      .toPromise();
    const changedEmissionDocuments = response.content as EmissionDocument[];
    this.convertFields(changedEmissionDocuments);

    return {
      body: changedEmissionDocuments,
      total: response.totalElements,
    };
  }

  async readForThird(filter: EmissionDocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif.toString());
    }

    if (filter.applicantNif) {
      params = params.append('nifRequerente', filter.applicantNif);
    }
    if (filter.applicantName) {
      params = params.append('nomeRequerente', filter.applicantName);
    }
    if (filter.serieNumber) {
      params = params.append(
        'numSerieDocumento',
        filter.serieNumber.toString()
      );
    }
    if (filter.description) {
      params = params.append('descricao', filter.description);
    }
    if (filter.typeDocument) {
      params = params.append(
        'idTipoDocumentoEmissao',
        filter.typeDocument.code.toString()
      );
    }
    if (filter.dataInicio) {
      params = params.append(
        'dtCriaPedidoEmissaoDocumentoDe',
        moment(filter.dataInicio).format('YYYY-MM-DD')
      );
    }
    if (filter.dataFim) {
      params = params.append(
        'dtCriaPedidoEmissaoDocumentoAte',
        moment(filter.dataFim).format('YYYY-MM-DD')
      );
    }
    if (filter.year) {
      params = params.append(
        'anoEmissaoDocumento',
        filter.year.code.toString()
      );
    }

    const response = await this.http
      .get<any>(`${this.API}/terceiro`, { params })
      .toPromise();
    return {
      body: <EmissionDocument[]>response.content,
      total: response.totalElements,
    };
  }

  async update(ed: EmissionDocument) {
    const response = await this.http
      .put(`${this.API}/${ed.idPedidoEmissaoDocumento}`, ed)
      .toPromise();
    const changedEmissionDocument = response as EmissionDocument;
    this.convertFields([changedEmissionDocument]);
    return changedEmissionDocument;
  }

  async get(id: number): Promise<EmissionDocument> {
    const response = await this.http.get(`${this.API}/${id}`).toPromise();
    const emissionDocument = response as EmissionDocument;
    this.convertFields([emissionDocument]);
    return emissionDocument;
  }

  async addQuantity(ed: EmissionDocument) {
    const response = await this.http
      .put(`${this.API}/adicionarQuantidade/${ed.idPedidoEmissaoDocumento}`, ed)
      .toPromise();
    const changedEmissionDocument = response as EmissionDocument;
    this.convertFields([changedEmissionDocument]);
    return changedEmissionDocument;
  }

  async readNotTransported(
    filter: EmissionDocumentEmitterFilter
  ): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.applicantNif) {
      params = params.append('nifEmissor', filter.applicantNif);
    }
    if (filter.applicantName) {
      params = params.append('nomeEmissor', filter.applicantName);
    }
    if (filter.serieNumber) {
      params = params.append(
        'numSerieDocumento',
        filter.serieNumber.toString()
      );
    }
    if (filter.description) {
      params = params.append('descricao', filter.description.toString());
    }
    if (filter.typeDocument) {
      params = params.append(
        'idTipoDocumentoEmissao',
        filter.typeDocument.code.toString()
      );
    }
    if (filter.dataInicio) {
      params = params.append(
        'dtCriaPedidoEmissaoDocumentoDe',
        moment(filter.dataInicio).format('YYYY-MM-DD')
      );
    }
    if (filter.dataFim) {
      params = params.append(
        'dtCriaPedidoEmissaoDocumentoAte',
        moment(filter.dataFim).format('YYYY-MM-DD')
      );
    }
    if (filter.year) {
      params = params.append('anoEmissaoDocumento', filter.year);
    }
    // else {
    //   var year = 2020;
    //   params = params.append('anoEmissaoDocumento', year.toString());
    // }

    const response = await this.http
      .get<any>(`${this.API}/porEmissor/serieNaoTransportada`, { params })
      .toPromise();
    return {
      body: <EmissionDocument[]>response.content,
      total: response.totalElements,
    };
  }

  async readSerieToTransportAutomatic(): Promise<any[]> {
    const response = await this.http
      .get<any[]>(`${this.API}/porEmissor/serieNaoTransportadaV2`)
      .toPromise();

    return response;
  }

  async sendSerieToTransportAutomatic(
    emissionsDocuments: EmissionDocumentDTO[]
  ): Promise<Authorization[]> {
    return this.http
      .put<Authorization[]>(
        `${environment.apiUrl}/autorizacaoEmissao/transitarAno`,
        emissionsDocuments
      )
      .toPromise();
  }

  async getYearsToTransport(): Promise<any> {
    return this.http
      .get(`${this.API}/anosQueSeriesPodemSerTransferidas`)
      .toPromise();
  }

  private convertFields(eds: EmissionDocument[]) {
    for (const ed of eds) {
      if (ed.dtCriaPedidoEmissaoDocumento) {
        ed.dtCriaPedidoEmissaoDocumento = moment(
          ed.dtCriaPedidoEmissaoDocumento,
          'YYYY-MM-DD'
        ).toDate();
      }
      if (ed.dtAlterPedidoEmissaoDocumento) {
        ed.dtAlterPedidoEmissaoDocumento = moment(
          ed.dtAlterPedidoEmissaoDocumento,
          'YYYY-MM-DD'
        ).toDate();
      }

      ed.descLocalTributacao =
        ed.localTributacao === 'S' ? 'São Tomé' : 'Príncipe';

      ed.colorLocalTributacao = ed.localTributacao === 'S' ? 'info' : 'success';
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {DocumentConsumerRegistration, SimpleDocumentConsumerRegistrationForm } from 'src/app/core/models/document-consumer-registration';
import { ExemptionReason } from 'src/app/core/models/exemption-reason';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() showModal = true;
  @Output() onClose = new EventEmitter<void>();
  @Output() onAccept = new EventEmitter<DocumentConsumerRegistration>();
  @Output() onRefuse = new EventEmitter<DocumentConsumerRegistration>();
  displayExemption = false;
  @Input() exemptionReasons: ExemptionReason[];
  @Input() documentForm =  new SimpleDocumentConsumerRegistrationForm(this.fb).builder();
  @Input() canValidate = false;

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  toggleModal() {
    this.onClose.emit();
  }

  refuseDocument() {
    this.onRefuse.emit(this.documentForm.getRawValue());
  }
  
  acceptDocument() {
    this.onAccept.emit(this.documentForm.getRawValue());
  }

  showDisplayExemption() {
    this.displayExemption = true;
  }

  onExemptionSelect(event) {
    this.documentForm.get('tbMotivoIsencao').patchValue({
      idMotivoIsencao: event.data.idMotivoIsencao,
      codigoMotivoIsencao: event.data.codigoMotivoIsencao,
      desMotivoIsencao: event.data.desMotivoIsencao,
    });
    this.displayExemption = false;
  }
}

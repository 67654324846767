import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocumentDetailFilter, FileDocumentDetailService } from '../file-document-detail.service';

@Component({
  selector: 'app-read-file-document-detail',
  templateUrl: './read-file-document-detail.component.html',
  styleUrls: ['./read-file-document-detail.component.scss']
})
export class ReadFileDocumentDetailComponent implements OnInit {
  locale = new Locale();
  fileDocuments = [];
  loading = true;

  total = 0;
  filter = new FileDocumentDetailFilter();

  constructor(
    private service: FileDocumentDetailService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.filter.rows = 100;
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;

    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }
}

import { Component, OnInit } from '@angular/core';
import el from 'date-fns/esm/locale/el/index.js';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileCommunicationError } from 'src/app/core/models/file-communication';
import { TaxPayer } from 'src/app/core/models/taxpayer';
import { ThirdPartyEmitterService } from '../third-party-emitter.service';
import { FileDocumentEmitterService } from './../../file-document-emitter/file-document-emitter.service';

export class QueryParams {
  period: Date;
  emitterNif: string;
}

@Component({
  selector: 'app-create-file-document',
  templateUrl: './create-file-document.component.html',
  styleUrls: ['./create-file-document.component.scss']
})
export class CreateFileDocumentComponent implements OnInit {
  loading: boolean = false;
  showVerifiedFile: boolean = false;
  locale = new Locale();
  uploadedFile: any;
  minDate = new Date(2019, 0, 1);
  maxDate = new Date();

  ACTIVITY_ID = 3;

  fileCommunicationError = new FileCommunicationError();
  queryParams = new QueryParams();

  emitters = [];

  constructor(
    private communicationFileEmitterService: FileDocumentEmitterService,
    private thirdPartyEmitterService: ThirdPartyEmitterService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.thirdPartyEmitterService.getEmittersByActivity(this.ACTIVITY_ID)
      .then((emitters: TaxPayer[]) =>
        this.emitters = emitters.map((el: TaxPayer) => ({
          value: el.tbPessoa.nifPessoa,
          label: `${el.tbPessoa.nifPessoa} - ${el.tbPessoa.nomePessoa}`,
        })))
  }

  enviarFicheiro(fileUpload: any) {
    this.loading = true;
    this.showVerifiedFile = false;
    const formData = new FormData();

    formData.append('file', this.uploadedFile);
    this.queryParams.period.setDate(5);

    this.communicationFileEmitterService.enviarFicheiroTerceiro(formData, this.queryParams)
      .then(() => {
        fileUpload.clear();
        this.messageService.add({ severity: 'success', detail: 'Ficheiro enviado com sucesso' });
      })
      .catch(response => {
        if (response.status === 401) {
          this.messageService.add({
            key: 'lg',
            severity: 'error',
            life: 20000,
            detail: 'Este contribuinte não está autorizado à emitir documentos, consequentemente não pode comunicar documentos!'
          });
        } else {
          this.messageService.add({
            key: 'lg',
            severity: 'error',
            life: 20000,
            detail: 'Encontramos problemas ao processar o seu novo ficheiro, por favor verifique se o ficheiro está corretamente preenchido. Se o problema persistir, entre em contacto com a DI.'
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  verificarFicheiro() {
    this.loading = true;
    this.showVerifiedFile = false;
    const formData = new FormData();

    formData.append('file', this.uploadedFile);
    this.queryParams.period.setDate(5);

    this.communicationFileEmitterService.verificarFicheiroTerceiro(formData, this.queryParams)
      .then(response => {
        this.fileCommunicationError = response;
        this.showVerifiedFile = true;
      })
      .catch(response => {
        if (response.status === 400) {
          this.fileCommunicationError = response.error;
          this.showVerifiedFile = true;
        } else {
          this.errorHandler.handle(response.error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onSelectImage(evt: any) {
    this.uploadedFile = evt[0];
  }
}

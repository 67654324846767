
export class ExemptionReason {
    idMotivoIsencao: number = 1;
    codigoMotivoIsencao: number;
    desMotivoIsencao: string;
    isento: boolean;

    /*tbUsuarioByAlterIdUsuario = new Utilizador();
    tbUsuarioByCriaIdUsuario = new Utilizador();*/

    dtCria: Date;
    dtAlter: Date;

    tbTipoImposto?: {
        idTipoImposto: number,
        descricaoTipoImposto: string,
        siglaTipoImposto: string
    }
}

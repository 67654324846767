<td> {{routine}} </td>
<td>
  <button type="button" mdbBtn color="info" size="sm" block="true" [style]="{padding: '4px 0'}" (click)="display=true"
    [disabled]="loading">
    <i class="pi pi-spin pi-spinner" [style]="{fontSize: '1rem'}" *ngIf="loading"></i>
    <span *ngIf="!loading">EXECUTAR</span>
  </button>
</td>

<p-dialog header="Informar Período" [(visible)]="display" [modal]="true" [responsive]="true"
  [style]="{width: '500px', minWidth: '200px'}" [minY]="70" [baseZIndex]="10000">
  <form #formRoutine="ngForm" autocomplete="off" (ngSubmit)="run()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <p> {{routine}} </p>
      </div>
      <div class="p-field p-col-12">
        <label>Série</label>
        <input pInputText name="period" [(ngModel)]="period" disabled>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="display=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="success" mdbWavesEffect [disabled]="!formRoutine.valid || loading">
          VALIDAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>
    </div>
  </form>
</p-dialog>

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { Emission } from './../core/models/emission';

export class EmissionTypographyFilter {
  page = 0;
  rows = 15;
  nifConsumidor: string;
  nifEmissor: string;
  dtCriaPedidoEmissaoFaturaDe: Date;
  dtCriaPedidoEmissaoFaturaAte: Date;
}

@Injectable({
  providedIn: 'root'
})
export class EmissionTypographyService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/pedidosEmissaoFatura`;
  }

  async read(filter: EmissionTypographyFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    const response = await this.http.get<any>(`${this.API_URL}/porEmissor`, { params })
      .toPromise();
    return {
      body: <Emission[]>response.content,
      total: response.totalElements
    };
  }

  async get(id: number): Promise<Emission> {
    const response = await this.http.get<Emission>(`${this.API_URL}/${id}`)
      .toPromise();
    const emission = response as Emission;
    this.convertFields([emission]);
    return emission;
  }

  creat(emission: any): Promise<any> {
    return this.http.post(`${this.API_URL}/tipografia`, emission)
      .toPromise()
  }

  private convertFields(emissions: Emission[]) {
    for (const emission of emissions) {
      emission.dataPedidoEmissaoFatura = moment(emission.dataPedidoEmissaoFatura,
        'YYYY-MM-DD').toDate();
      emission.dtAlterPedidoEmissaoFatura = moment(emission.dtAlterPedidoEmissaoFatura,
        'YYYY-MM-DD').toDate();
      emission.dtCriaPedidoEmissaoFatura = moment(emission.dtCriaPedidoEmissaoFatura,
        'YYYY-MM-DD').toDate();
    }
  }
}

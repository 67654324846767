import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { CreatePortalComponent } from './create-portal/create-portal.component';
import { CreateComponent } from './create/create.component';
import { EmissionEmitterRouter } from './emission-emitter-router';
import { NewComponent } from './new/new.component';
import { CreateForThirdComponent } from './create-for-third/create-for-third.component';

@NgModule({
  declarations: [CreateComponent, NewComponent, CreatePortalComponent, CreateForThirdComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmissionEmitterRouter,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    SidebarModule
  ]
})
export class EmissionEmitterModule { }

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ExemptionReasonService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/motivoIsencao`;
  }

  getAll(): Promise<any> {
    return this.http.get(this.API_URL).toPromise();
  }

  getByTaxIdAndDocumentTypeSigla({ taxId, documentTypeSigla }): Promise<any> {
    let params = new HttpParams();

    if (taxId) params = params.append('idTaxa', taxId.toString());

    if (documentTypeSigla)
      params = params.append('tipoDocumento', documentTypeSigla);

    return this.http.get(`${this.API_URL}/filtro`, { params }).toPromise();
  }
}

import {
  UntypedFormBuilder,
  UntypedFormArray,
  FormControl,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DocumentOnlineForm } from './../../core/models/document-online';
import { TaxpayerWebService } from './../../core/models/taxpayer-webservice';
import { DocumentOnlineItemForm } from './../../core/models/document-online-item';

@Component({
  selector: 'app-view-dialog',
  templateUrl: './view-dialog.component.html',
  styleUrls: ['./view-dialog.component.scss'],
})
export class ViewDialogComponent implements OnInit {
  display = false;
  printDocument: boolean = false;

  document = new DocumentOnlineForm(this.fb).builder();
  documentItemForm = new DocumentOnlineItemForm(this.fb);

  consumer = new TaxpayerWebService();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  get items(): UntypedFormArray {
    return this.document.get(
      'tbItensDocumentosGeradosOnline'
    ) as UntypedFormArray;
  }

  get documentData(): FormControl {
    return this.document.get('dtEmissaoDocumentoOnline') as FormControl;
  }

  showTaxSigla(): string {
    const IVAStartDate: Date = new Date(1, 6, 2023);
    const documentDate: Date = this.documentData.value;

    return documentDate >= IVAStartDate ? 'IVA' : 'I.SC';
  }

  addItem() {
    this.items.push(this.documentItemForm.builder());
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from '../auth/app-http';
import { EmissionDocument } from '../core/models/emission-document';
import { environment } from './../../environments/environment';
import {EmissionDocumentDTO} from '../emission-document-emitter/types/emission-document';

export class EmissionDocumentFilter {
  page = 0;
  rows = 15;

  idTipoDocumentoEmissao: number;
  typeCertificationId: number;
  serieNumber: string;
  description: string;
  applicantName: string;
  applicantNif: string;
  emitterName: string;
  emitterNif: string;

  // dataInicio: Date;
  // dataFim: Date;

  createdAt: Date[];

  year: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmissionDocumentService {
  API: string;

  constructor(private http: AppHttp) {
    this.API = `${environment.apiUrl}/pedidoEmissaoDocumento`;
  }

  read(filter: EmissionDocumentFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.applicantNif) {
      params = params.append('nifRequerente', filter.applicantNif);
    }
    if (filter.applicantName) {
      params = params.append('nomeRequerente', filter.applicantName);
    }
    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif);
    }
    if (filter.emitterName) {
      params = params.append('nomeEmissor', filter.emitterName);
    }
    if (filter.serieNumber) {
      params = params.append('numSerieDocumento', filter.serieNumber.toString());
    }
    if (filter.description) {
      params = params.append('descricao', filter.description);
    }
    if (filter.idTipoDocumentoEmissao) {
      params = params.append('idTipoDocumentoEmissao', filter.idTipoDocumentoEmissao.toString());
    }
    if (filter.typeCertificationId) {
      params = params.append('idTipoCertificacao', filter.typeCertificationId.toString());
    }

    if (filter.createdAt) {
      if (!!filter.createdAt[0])
        params = params.append('dtCriaPedidoEmissaoDocumentoDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (!!filter.createdAt[1])
        params = params.append('dtCriaPedidoEmissaoDocumentoAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }

    // if (filter.dataInicio) {
    //   params = params.append('dtCriaPedidoEmissaoDocumentoDe', moment(filter.dataInicio).format('YYYY-MM-DD'));
    // }
    // if (filter.dataFim) {
    //   params = params.append('dtCriaPedidoEmissaoDocumentoAte', moment(filter.dataFim).format('YYYY-MM-DD'));
    // }

    if (filter.year) {
      params = params.append('anoEmissaoDocumento', filter.year);
    } /*else {
      var date = new Date();
      var year = date.getFullYear();
      params = params.append('anoEmissaoDocumento', year.toString());
    }*/

    return this.http.get<any>(`${this.API}`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <EmissionDocument[]>response.content,
          total: response.totalElements
        }
      });
  }

  get(id: number): Promise<EmissionDocument> {
    return this.http.get(`${this.API}/${id}`)
      .toPromise()
      .then((response) => {
        const emissionDocument = response as EmissionDocument;
        this.convertFields([emissionDocument]);
        return emissionDocument;
      });
  }

  changeEmitter({ emissionDocumentId, emitterNif }) {
    let params = new HttpParams();
    params = params.append('idPedidoEmissaoDocumento', emissionDocumentId.toString());
    params = params.append('nifContribuinte', emitterNif.toString());

    return this.http.put(`${this.API}/atualizarEmitenteDaSerie`, {}, { params })
      .toPromise();
  }

  async unblockSerie(emissionDocumentId: number) {
    return this.http.patch<EmissionDocumentDTO>(`${this.API}/debloquearSerie/${emissionDocumentId}`, null)
      .toPromise();
  }

  private convertFields(eds: EmissionDocument[]) {
    for (const ed of eds) {
      if (ed.dtCriaPedidoEmissaoDocumento) {
        ed.dtCriaPedidoEmissaoDocumento = moment(ed.dtCriaPedidoEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
      if (ed.dtAlterPedidoEmissaoDocumento) {
        ed.dtAlterPedidoEmissaoDocumento = moment(ed.dtAlterPedidoEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DatabaseManagerFilter, DatabaseManagerService } from '../database-manager.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  databases = [];
  loading = true;
  total = 0;
  filter = new DatabaseManagerFilter();

  constructor(
    private service: DatabaseManagerService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.databases = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  export() {
    this.loading = true;
    this.service.export()
      .then(() => {
        this.read();
        this.messageService.add({
          severity: 'success',
          detail: 'BD exportada com sucesso!'
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS RECOLHIDOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <p-tabView>
        <p-tabPanel header="GERAL">
          <mdb-card-header class="mdb-card-header">
            <form autocomplete="off" (ngSubmit)="read()">
              <div class="p-col-12">
                <div class="p-fluid p-formgrid p-grid">
    
                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                      <input pInputText class="p-inputtext-sm" name="numDocumento" [(ngModel)]="filter.numDocumento">
                      <label for="float-input">Número Documento</label>
                    </span>
                  </div>
    
                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                      <input pInputText class="p-inputtext-sm" name="nifConsumidor" [(ngModel)]="filter.nifConsumidor">
                      <label for="float-input">NIF Adquirente</label>
                    </span>
                  </div>
    
                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                      <input pInputText class="p-inputtext-sm" name="numSerieDocumento"
                        [(ngModel)]="filter.numSerieDocumento">
                      <label for="float-input">Número Série</label>
                    </span>
                  </div>
    
                  <div class="p-field p-col-12 p-md-1">
                    <span class="p-float-label">
                      <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoDe"
                        [(ngModel)]="filter.dtEmissaoDocumentoDe" dateFormat="dd/mm/yy" [locale]="locale.pt">
                      </p-calendar>
                      <label for="float-input">Data Inicial</label>
                    </span>
                  </div>
    
                  <div class="p-field p-col-12 p-md-1">
                    <span class="p-float-label">
                      <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoAte"
                        [(ngModel)]="filter.dtEmissaoDocumentoAte" dateFormat="dd/mm/yy" [locale]="locale.pt">
                      </p-calendar>
                      <label for="float-input">Data Final</label>
                    </span>
                  </div>
    
                  <div class="p-field p-col-12 p-md-2">
                    <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                      Filtrar
                    </button>
                  </div>
    
                </div>
              </div>
            </form>
          </mdb-card-header>
    
          <mdb-card-body class="mdb-card-body">
            <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="documents"
              [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
              (onLazyLoad)="onChangePage($event)">
              <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                  Total de {{ total | number:'1.0-0'}} documentos comunicados.
                </div>
              </ng-template>
    
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width: '10%'}">Nº Documento</th>
                  <th>NIF Adquirente</th>
                  <th [style]="{width: '40%'}">Nome do Adquirente</th>
                  <th>Data Emissão</th>
                  <th>Base Tributável</th>
                  <th>Imposto</th>
                  <th>Total</th>
                  <th [style]="{width:'3em'}"></th>
                </tr>
              </ng-template>
    
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td>
                    <span class="p-column-title">Nº Documento</span>
                    {{rowData.numDocumento}}
                  </td>
    
                  <td>
                    <span class="p-column-title">NIf Emitente</span>
                    {{rowData.tbContribuinteNifConsumidor}}
                  </td>
    
                  <td>
                    <span class="p-column-title">Nome Emitente</span>
                    {{rowData.tbPessoaNomeConsumidor}}
                  </td>
    
                  <td>
                    <span class="p-column-title">Data Emissão</span>
                    {{ rowData.dtEmissaoDocumento | date:'dd/MM/yyyy' }}
                  </td>
    
                  <td>
                    <span class="p-column-title number"> Base Tributável </span>
                    <strong>{{ rowData.totalValorBaseTributavel | number:'0.1-6':'pt' }}</strong>
                  </td>
    
                  <td>
                    <span class="p-column-title number"> Imposto </span>
                    <strong>{{ rowData.totalImposto | number:'0.1-6':'pt' }}</strong>
                  </td>
    
                  <td>
                    <span class="p-column-title number"> Total </span>
                    <strong>{{ rowData.totalValor | number:'0.1-2':'pt' }}</strong>
                  </td>
    
                  <td [style]="{padding: '1px 4px'}">
                    <button type="button" mdbBtn color="info" size="sm" block="true" pTooltip="Ver mais informações"
                      tooltipPosition="left" [style]="{padding: '4px 0'}"
                      [routerLink]="['/emitter/document/read', encrypt(rowData.idDocumentoGerado)]">
                      <mdb-icon fas icon="search"></mdb-icon>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </mdb-card-body>
        </p-tabPanel>

        <p-tabPanel header="DOCUMENTOS EMITIDOS PARA O ESTADO">
          <app-read-state></app-read-state>
        </p-tabPanel>
      </p-tabView>

    </mdb-card>
  </div>

</div>

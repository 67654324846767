<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR DOCUMENTOS EMITIDAS NO PORTAL
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="numDocumentoOnline"
                    [(ngModel)]="filter.numDocumentoOnline">
                  <label for="float-input">Número do Documento</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifEmissorOnline"
                    [(ngModel)]="filter.nifEmissorOnline">
                  <label for="float-input">NIF do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nifConsumidorOnline"
                    [(ngModel)]="filter.nifConsumidorOnline">
                  <label for="float-input">NIF do Adquirente</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="numSerieDocumentoOnline"
                    [(ngModel)]="filter.numSerieDocumentoOnline">
                  <label for="float-input">Número Série</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoOnlineDe"
                    [(ngModel)]="filter.dtEmissaoDocumentoOnlineDe" dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoOnlineAte"
                    [(ngModel)]="filter.dtEmissaoDocumentoOnlineAte" dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body>
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="invoices"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} documentos emitidos no portal.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '11%'}">Documento</th>
              <th [style]="{width: '9%'}">Número</th>
              <th [style]="{width: '25%'}">Emitente</th>
              <th [style]="{width: '6%'}">NIF Emitente</th>
              <th [style]="{width: '25%'}">Adquirente</th>
              <th [style]="{width: '6%'}">NIF Adquirente</th>
              <th [style]="{width: '6%'}">Data</th>
              <th [style]="{width: '8%'}">Total</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Documento</span>
                {{ rowData.tbTipoDocumentoDescricao }}
              </td>

              <td>
                <span class="p-column-title">Número</span>
                {{ rowData.numeroDocumento }}
              </td>

              <td>
                <span class="p-column-title">Emitente</span>
                {{ rowData.tbPessoaNomeEmissor }}
              </td>

              <td>
                <span class="p-column-title">NIF Emitente</span>
                {{ rowData.tbContribuinteNifEmissor }}
              </td>

              <td>
                <span class="p-column-title">Adquirente</span>
                {{ rowData.tbPessoaNomeConsumidor }}
              </td>

              <td>
                <span class="p-column-title">NIF Adquirente</span>
                {{ rowData.tbContribuinteNifConsumidor }}
              </td>

              <td>
                <span class="p-column-title">Data</span>
                {{ rowData.dtEmissaoDocumento | date:'dd/MM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title"> Valor Total </span>
                <strong>{{ rowData.totalValor | number:'0.1-2':'pt' }}</strong>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informaões"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                  [routerLink]="['/document-online/read', rowData.idDocumentoGeradoOnline]">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

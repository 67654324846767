import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Notification } from './../../core/models/notification';
import {
  NotificationUserFilter,
  NotificationUserService,
} from './../notification-user.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  locale = new Locale();
  total = 0;
  filter = new NotificationUserFilter();
  notification = new Notification();
  notifications: Notification[];
  notificationId: number;

  constructor(
    private service: NotificationUserService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    // if (!this.auth.isAccessTokenInvalid()) {
    //   this.read();
    //   this.notificationId = this.route.snapshot.params.id;
    //   if (this.notificationId) {
    //     this.get(this.notificationId);
    //   }
    // }
  }

  read(page = 0) {
    this.filter.page = page;
    this.service
      .alert()
      .then((response) => {
        this.total = response.total;
        this.notifications = response.body;
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  getNotification(notification: Notification) {
    this.router.navigate([
      '/notifications/by-user/read',
      notification.idNotificacao,
    ]);
    this.notificationId = notification.idNotificacao;
    this.get(notification.idNotificacao);
  }

  get(id) {
    this.service.get(id).then((response) => {
      this.notification = response;
    });
  }
}

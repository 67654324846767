import { Component, OnInit } from '@angular/core';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { Authorization } from 'src/app/core/models/authorization';
import {
  EmissionDocument,
  EmissionDocumentView,
} from 'src/app/core/models/emission-document';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { TypeDocumentService } from './../../type-document/type-document.service';
import {
  EmissionDocumentEmitterFilter,
  EmissionDocumentEmitterService,
} from './../emission-document-emitter.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class ReadComponent implements OnInit {
  loading = true;
  loadingIncrement = false;
  visibleSidebarFilter = false;

  total = 0;
  filter = new EmissionDocumentEmitterFilter();
  locale = new Locale();
  typesDocument = [];

  emissionDocument = new EmissionDocument();
  emissionDocumentView = new EmissionDocumentView();
  authorization = new Authorization();

  years = [
    { name: '2024', code: '2024' },
    { name: '2023', code: '2023' },
    { name: '2022', code: '2022' },
    { name: '2021', code: '2021' },
    { name: '2020', code: '2020' },
    { name: '2019', code: '2019' },
  ];

  emissionDocuments = [];
  displayEdit: boolean = false;
  displayDetails: boolean = false;
  displayIncrement: boolean = false;

  serieToShow: string;

  displayEditLocalInvoice: boolean = false;
  loadingEditLocalInvoice: boolean = false;

  constructor(
    private service: EmissionDocumentEmitterService,
    private tipoDocumentoService: TypeDocumentService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private confirmation: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.readTypeDocument();
  }

  async readTypeDocument() {
    try {
      const response = await this.tipoDocumentoService.read();
      this.typesDocument = response.map(
        (t: {
          descTipoDocumentoEmissao: string;
          idTipoDocumentoEmissao: number;
        }) => ({
          name: t.descTipoDocumentoEmissao,
          code: t.idTipoDocumentoEmissao,
        })
      );
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service
      .read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  update() {
    this.loading = true;
    this.service
      .update(this.emissionDocument)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Informações alteradas!',
        });
        this.read();
        this.displayEdit = false;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  showDialogEdit(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;

    this.emissionDocument.idPedidoEmissaoDocumento =
      this.emissionDocumentView.idPedidoEmissaoDocumento;
    this.emissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocumentView.descricao;
    this.emissionDocument.mascara = this.emissionDocumentView.mascara;
    this.emissionDocument.numInicialSequencial =
      this.emissionDocumentView.numInicialSequencial;

    this.displayEdit = true;
  }

  showDialogDetails(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;
    this.displayDetails = true;
  }

  setDataToEmissionDocumentView(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;

    this.emissionDocument.idPedidoEmissaoDocumento =
      this.emissionDocumentView.idPedidoEmissaoDocumento;
    this.emissionDocument.qtDocumentosSolicitado =
      this.emissionDocumentView.totalSolicitado;
    this.serieToShow = `${this.emissionDocumentView.siglaSerie}${this.emissionDocumentView.serie}${this.emissionDocumentView.ano}`;
    this.emissionDocument.localTributacao =
      this.emissionDocumentView.localTributacao;
  }

  showDialogIncrement(ed: EmissionDocumentView) {
    this.setDataToEmissionDocumentView(ed);

    this.displayIncrement = true;
  }

  incrementDocInSerie() {
    this.loadingIncrement = true;

    this.service
      .addQuantity(this.emissionDocument)
      .then(() => {
        this.read();
        this.messageService.add({
          severity: 'success',
          detail: 'Documentos adicionados com sucesso!',
        });
        this.displayIncrement = false;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loadingIncrement = false));
  }

  confirmIncrementDocInSerie() {
    this.confirmation.confirm({
      message: `Deseja adicionar mais ${this.emissionDocument.qtDocumentosSolicitado} documentos à esta série?`,
      accept: () => {
        this.incrementDocInSerie();
      },
    });
  }

  resetFormFilter(form: NgForm) {
    form.reset();
  }

  showDialogUpdateLocalInvoice(ed: EmissionDocumentView) {
    this.setDataToEmissionDocumentView(ed);

    this.displayEditLocalInvoice = true;
  }

  handleEditLocalInvoice() {
    this.loadingEditLocalInvoice = true;

    this.emissionDocument.localTributacao =
      this.emissionDocument.localTributacao === 'S' ? 'P' : 'S';

    this.service
      .update(this.emissionDocument)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Informações alteradas!',
        });
        this.read();
        this.displayEditLocalInvoice = false;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loadingEditLocalInvoice = false));
  }

  getDocumentStatus(data) {
    return data.statusSerie === 'BLOQUEADO';
  }

  getDocumentStatusLabel(data) {
    return data.statusSerie;
  }
}

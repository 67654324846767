<section id="contact" class="wow fadeInUp">
    <div class="container">
        <div class="section-header">
            <h2>Contacte-nos</h2>
        </div>

        <div class="row contact-info">

            <div class="col-md-4">
                <div class="contact-address">
                    <i class="ion-ios-location-outline"></i>
                    <h3>Endereço</h3>
                    <address>Praça da Independência</address>
                </div>
            </div>

            <div class="col-md-4">
                <div class="contact-phone">
                    <i class="ion-ios-telephone-outline"></i>
                    <h3>Número de Telefone</h3>
                    <p><a href="javascript">+239 2221547</a></p>
                </div>
            </div>

            <div class="col-md-4">
                <div class="contact-email">
                    <i class="ion-ios-email-outline"></i>
                    <h3>Email</h3>
                    <p><a href="javascript:;">impostos@mf.gov.st</a></p>
                </div>
            </div>

        </div>
    </div>

    <div class="container mb-4">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.4370850726629!2d6.72957793991509!3d0.33935076839574374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10766a60d2870955%3A0x102fbab4ad24ae3e!2zRGlyZWPDp8OjbyBkYXMgRmluYW7Dp2FzLCBTw6NvIFRvbcOp!5e0!3m2!1spt-PT!2sst!4v1568288207747!5m2!1spt-PT!2sst"
            width="100%" height="380" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>

</section>
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PDFExportModule} from '@progress/kendo-angular-pdf-export';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {PasswordModule} from 'primeng/password';
import {ProgressBarModule} from 'primeng/progressbar';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TooltipModule} from 'primeng/tooltip';
import {LayoutModule} from '../layout/layout.module';
import {ProfileModule} from '../profile/profile.module';
import {SharedModule} from '../shared/shared.module';
import {StatisticEmitterModule} from '../statistic-emitter/statistic-emitter.module';
import {TaxpayerModule} from './../taxpayer/taxpayer.module';
import {CreateUserMailConfirmComponent} from './create-user-mail-confirm/create-user-mail-confirm.component';
import {CreateUserSmsComponent} from './create-user-sms/create-user-sms.component';
import {CreateUserComponent} from './create-user/create-user.component';
import {RecorverPassword1Component} from './recorver-password1/recorver-password1.component';
import {RecorverPassword2Component} from './recorver-password2/recorver-password2.component';
import {RecorverPassword3Component} from './recorver-password3/recorver-password3.component';
import {UserGetComponent} from './user-get/user-get.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserReadComponent} from './user-read/user-read.component';
import {UserRouter} from './user-router';
import {ValidateEmailToUpdateComponent} from './validate-email-to-update/validate-email-to-update.component';

@NgModule({
  declarations: [
    CreateUserComponent,
    RecorverPassword1Component,
    CreateUserSmsComponent,
    RecorverPassword2Component,
    RecorverPassword3Component,
    UserReadComponent,
    UserGetComponent,
    UserProfileComponent,
    CreateUserMailConfirmComponent,
    ValidateEmailToUpdateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    UserRouter,
    SharedModule,
    LayoutModule,
    ProfileModule,

    TaxpayerModule,
    StatisticEmitterModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ProgressBarModule,
    MessageModule,
    MessagesModule,
    ToggleButtonModule,
    PasswordModule,
    DropdownModule,
    TabViewModule,
    DialogModule,
    PDFExportModule,
    InputSwitchModule,
  ],
})
export class UserModule {
}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CONTRIBUINTES QUE SOLICITARAM SÉRIES MAS NUNCA COMUNICARAM DOCUMENTOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="nif" [(ngModel)]="filter.nif">
                  <label>NIF</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="gamasNif" name="gammaNif" [showClear]="true"
                    [(ngModel)]="filter.gammaNif" placeholder="Gama de NIF">
                  </p-dropdown>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table [value]="taxpayers" [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows"
          [lazy]="true" [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} contribuintes.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '40%'}">Contribuinte</th>
              <th>NIF</th>
              <th [style]="{width: '20%'}">Endereço</th>
              <th>Telefone</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Nome da Empresa</span>
                {{rowData.nomePessoa}}
              </td>

              <td>
                <span class="p-column-title">NIF</span>
                {{rowData.nifContribuinte}}
              </td>

              <td>
                <span class="p-column-title">Endereço</span>
                {{rowData.enderecoPessoa}}
              </td>

              <td>
                <span class="p-column-title">Telefone</span>
                {{ rowData.telefonePessoa }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

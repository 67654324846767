<a class="notification-icon" href="javascript:;" (click)="op.toggle($event)">
  <i class="fas fa-bell"></i>
  <span *ngIf="total > 0"> {{total}} </span>
</a>

<p-overlayPanel #op [style]="{width: '360px'}" appendTo="body">
  <ng-template pTemplate>
    <p-scrollPanel [style]="{width: '100%', maxHeight: '400px'}">
      <div [style]="{lineHeight: '1.5'}">

        <div class="list-group list-group-flush" *ngFor="let notification of notifications">
          <a href="javascript:;" class="list-group-item list-group-item-action flex-column align-items-start"
            (click)="getNotification(notification)">
            <div class="d-flex w-100 justify-content-between">
              <p><strong> {{ notification.tbTipoNotificacaoDescTipoNotificacao }} </strong></p>
              <small class="text-muted"> {{ notification.dtCriaNotificacao | date:'dd/MM/yyyy' }} </small>
            </div>
            <p [innerHTML]="notification.topicoNotificacao"> {{ notification.topicoNotificacao }} </p>
          </a>
        </div>

        <div class="notification-footer">
          <a routerLink="/notifications/by-user/read">
            Ver todas as notificações
          </a>
        </div>

      </div>
    </p-scrollPanel>
  </ng-template>
</p-overlayPanel>

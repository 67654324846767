import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { DocumentEmitterModule } from './../document-emitter/document-emitter.module';
import { DocumentRouter } from './document-router';
import { GetComponent } from './get/get.component';
import { ReadDocumentByFileComponent } from './read-document-by-file/read-document-by-file.component';
import { ReadComponent } from './read/read.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ReadStateComponent } from './read-state/read-state.component';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { GetStateComponent } from './get-state/get-state.component';

@NgModule({
  declarations: [
    ReadComponent,
    GetComponent,
    ReadDocumentByFileComponent,
    ReadStateComponent,
    GetStateComponent
  ],
  imports: [
    CommonModule,
    DocumentRouter,

    FormsModule,
    MDBBootstrapModule,
    LayoutModule,
    DocumentEmitterModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    FileUploadModule,
    DialogModule,
    OverlayPanelModule,
    CurrencyMaskModule,
    SidebarModule,
    ScrollPanelModule,
    TriStateCheckboxModule,
    InputNumberModule,
    DropdownModule,
    TabViewModule,
    TagModule
  ],
  exports: [
    ReadDocumentByFileComponent
  ]
})
export class DocumentModule { }

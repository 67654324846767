import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';
import { StatisticEmitter } from './../core/models/statistic-emitter';

export class StatisticEmitterFilter {
  emitterNif: string;
  period: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class StatisticEmitterService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/estatistica/emitente`;
  }

  async get(filter: StatisticEmitterFilter): Promise<StatisticEmitter> {
    let params = new HttpParams();

    if (filter.emitterNif)
      params = params.append('nifContribuinte', filter.emitterNif);

    if (filter.period) {
      if (!!filter.period[0])
        params = params.append('dataInicial', moment(filter.period[0]).format('YYYY-MM-DD'));
      if (!!filter.period[1])
        params = params.append('dataFinal', moment(filter.period[1]).format('YYYY-MM-DD'));
    }

    const response = await this.http.get<StatisticEmitter>(
      this.API_URL, { params }
    ).toPromise();

    const statistic = response as StatisticEmitter;
    this.convertStatisticEmitterFields([statistic]);
    return statistic;
  }

  private convertStatisticEmitterFields(statistics: StatisticEmitter[]) {
    for (const statistic of statistics) {
      statistic.ttCanceled = statistic.ttValoresNotaCredito + statistic.ttValoresNotaDebito;
    }
  }
}

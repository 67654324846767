import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { CurrentAccountRouter } from './current-account-router';
import { GetComponent } from './get/get.component';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [ReadComponent, GetComponent],
  imports: [
    CommonModule,
    CurrentAccountRouter,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    FileUploadModule
  ]
})
export class CurrentAccountModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from '../shared/shared.module';
import { DatabaseManagerRouter } from './database-manager-router';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [
    ReadComponent
  ],
  imports: [
    CommonModule,
    DatabaseManagerRouter,
    MDBBootstrapModule,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ProgressBarModule,
    MessageModule,
    MessagesModule,
    PasswordModule,
    DialogModule,
    ButtonModule
  ]
})
export class DatabaseManagerModule { }

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CENTRAL DE AJUDA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/tutorial">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-info-circle text-info"></i></div>
        <h4 class="title"> Tutoriais </h4>
        <p class="description">
          Instruções para transportar as suas séries para o ano 2021, corrigir os seus
          documentos comunicados incorretamente e gerir os ficheiros comunicados.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/occurrence">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fas fa-question-circle text-warning"></i> </div>
        <h4 class="title"> Solicitações </h4>
        <p class="description">
          Agora o contribuinte disponibiliza-se de uma área onde pode entrar em contacto com a equipa do
          e-factura a fim de obter ajuda e esclarecimentos.
        </p>
      </div>
    </a>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypeCertificationService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/tipoCertificacao`;
  }

  read(): Promise<any> {
    return this.http.get<any>(this.API_URL)
      .toPromise();
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { DocumentView } from '../core/models/document';
import { AppHttp } from './../auth/app-http';
import { DocumentConsumer } from './../core/models/document-consumer';

export class DocumentConsumerFilter {
  page = 0;
  rows = 15;

  nifEmissor: string;
  nifConsumidor: string;
  numDocumento: string;
  numSerieDocumento: string;
  dtEmissaoDocumentoDe: Date;
  dtEmissaoDocumentoAte: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentConsumerService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGerado/porConsumidor`;
  }

  read(filter: DocumentConsumerFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise()
      .then((response) => {
        return {
          body: <Document[]>response.content,
          total: response.totalElements
        }
      })
  }

  get(id): Promise<DocumentView> {
    return this.http.get<DocumentView>(`${this.API_URL}/${id}`)
      .toPromise()
      .then(response => {
        const documentView = response as DocumentView;
        this.convertFields([documentView]);
        return documentView;
      })
  }

  private convertFields(documents: DocumentView[]) {
    for (const document of documents) {
      if (document.dtAlter) {
        document.dtAlter = moment(document.dtAlter,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtCria) {
        document.dtCria = moment(document.dtCria,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtEmissaoDocumento) {
        document.dtEmissaoDocumento = moment(document.dtEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
    }
  }

}

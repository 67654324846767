import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { DocumentConsumerRegConsumerRouter } from './document-consumer-reg-consumer-router';
import { ReadComponent } from './read/read.component';
import { GetComponent } from './get/get.component';
import { CreateComponent } from './create/create.component';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [ReadComponent, GetComponent, CreateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DocumentConsumerRegConsumerRouter,

    SharedModule,
    MDBBootstrapModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DialogModule,
    OverlayPanelModule,
    DropdownModule,
    InputMaskModule,
    CurrencyMaskModule,
    InputNumberModule,
    TabViewModule,
    FileUploadModule
  ]
})
export class DocumentConsumerRegConsumerModule { }

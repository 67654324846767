import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SharedModule } from '../shared/shared.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ChangeEmailComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    FormsModule,
    SharedModule,

    InputTextModule,
    ProgressBarModule
  ],
  exports: [
    ChangeEmailComponent
  ]
})
export class ProfileModule { }

import { Locale } from './../../configs/models/locale';
import { Occurrence } from './../../core/models/occurrence';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { MessageService } from 'primeng/api';
import { OccurrenceService } from './../../occurrence/occurrence.service';
import { OccurrenceEmitterService } from './../occurrence-emitter.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
  loading: boolean = false;
  locale = new Locale();
  occurrence = new Occurrence();

  constructor(
    private occurrenceEmitterService: OccurrenceEmitterService,
    private occurrenceService: OccurrenceService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  create() {
    this.loading = true;
    this.occurrenceService.create(this.occurrence)
      .then((result) => {
        this.router.navigate(['/emitter/occurrence/show', result.id])
        this.messageService.add({
          severity: 'success',
          detail: 'Solicitação enviada com sucesso',
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }
}

<table mdbTable striped="true" small="true">
  <tbody>
    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Linhas encontradas </strong>
      </th>
      <td colspan="2" [style]="{width: '10%'}">
        {{fileDocument.totalLinhaAntes}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalLinhaDepois}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Linhas processadas </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Documentos encontrados </strong>
      </th>
      <td colspan="2" [style]="{width: '10%'}">
        {{fileDocument.amountDocumentsFound}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.amountDocuments}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Documentos processados </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Facturas encontradas </strong>
      </th>
      <td colspan="2">
        {{fileDocument.quantidadeFaturaAntes}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.quantidadeFaturaDepois}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Facturas processadas </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Notas de Crédito encontradas</strong>
      </th>
      <td colspan="2">
        {{fileDocument.quantidadeNotaCreditoAntes}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.quantidadeNotaCreditoDepois}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Notas de Crédito processadas </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Notas de Débito encontradas</strong>
      </th>
      <td colspan="2">
        {{fileDocument.quantidadeNotaDebitoAntes}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.quantidadeNotaDebitoDepois}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Notas de Débito processadas </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Base tributável das facturas encontradas</strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalBaseTributavelFacturaAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalBaseTributavelFacturaDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Base Tributável das facturas processadas</strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>ISC das facturas encontrado</strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalImpostoFacturaAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalImpostoFacturaDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>ISC das facturas processado </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Valor total das facturas encontrado </strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalValorFacturaAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalValorFacturaDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Valor total das facturas processado </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Base tributável NC encontrada</strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalBaseTributavelNotaCreditoAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalBaseTributavelNotaCreditoDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Base tributável NC processada </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>ISC NC encontrado </strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalImpostoNotaCreditoAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalImpostoNotaCreditoDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>ISC NC processado </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Valor total NC encontrado </strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalValorNotaCreditoAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalValorNotaCreditoDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Valor total NC processado </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Base tributável ND encontrado </strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalBaseTributavelNotaDebitoAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalBaseTributavelNotaDebitoDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Base tributável ND processado </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>ISC ND encontrado </strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalImpostoNotaDebitoAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalImpostoNotaDebitoDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>ISC ND processado </strong>
      </th>
    </tr>

    <tr>
      <th scope="row" [style]="{width: '40%', textAlign: 'right'}">
        <strong>Valor total ND encontrado </strong>
      </th>
      <td colspan="2">
        {{fileDocument.totalValorNotaDebitoAntes | currency:'STN'}}
      </td>
      <td colspan="2" [style]="{width: '10%', textAlign: 'right'}">
        {{fileDocument.totalValorNotaDebitoDepois | currency:'STN'}}
      </td>
      <th scope="row" [style]="{width: '40%', textAlign: 'left'}">
        <strong>Valor total ND processado </strong>
      </th>
    </tr>
  </tbody>
</table>

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { TaxPayer } from 'src/app/core/models/taxpayer';
import { TaxpayerFilter, TaxpayerService } from './../taxpayer.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  loading: boolean = true;
  visibleSidebarFilter: boolean = false;

  taxpayers: TaxPayer[] = [];
  total = 0;

  filter = new TaxpayerFilter();
  locale = new Locale();

  ranges = [
    { name: 'EMPRESAS PRIVADAS - 517', code: 517 },
    { name: 'EMPRESAS PUBLICAS - 522', code: 522 },
    { name: 'PESSOAS SINGULARES - 109', code: 109 },
    { name: 'PESSOAS SINGULARES - 135', code: 135 },
    { name: 'EMBAIXADAS - 310', code: 310 },
    { name: 'COOPERATIVAS - 538', code: 538 },
    { name: 'ONGs - 212', code: 212 },
  ];

  categories = [
    { name: 'GRANDES CONTRIBUINTE', code: 'GRANDE_CONTRIBUINTE' },
    { name: 'MÉDIOS CONTRIBUINTE', code: 'MEDIO_CONTRIBUINTE' },
    { name: 'PEQUENO CONTRIBUINTE', code: 'PEQUENO_CONTRIBUINTE' }
  ];

  constructor(
    private service: TaxpayerService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.taxpayers = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  resetFormFilter(form): void {
    form.reset();
  }
}

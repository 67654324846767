import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DocumentOnlineItemOrigin } from 'src/app/core/models/document-online-item-origin';
import { ExemptionReason } from 'src/app/core/models/exemption-reason';
import { TaxRate } from 'src/app/core/models/tax-rate';
import { DocumentOnlineEmitterService } from '../document-online-emitter.service';
import { Mathematic } from './../../configs/models/math';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { DocumentOnline } from './../../core/models/document-online';
import { TypeDocument } from './../../core/models/type-document';
import { ExemptionReasonService } from './../../exemption-reason/exemption-reason.service';
import { TaxRateService } from './../../tax-rate/tax-rate.service';
import { TypeDocumentService } from './../../type-document/type-document.service';
import { buildCurrentDate } from 'src/app/shared/date';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  loading = false;
  displayExemption: boolean = false;
  displayTaxRate: boolean = false;

  documentOnline: UntypedFormGroup;
  typesDocument: TypeDocument[];

  itemIndex: number;
  exemptionReasons: ExemptionReason[];
  taxRates: TaxRate[];
  selectedTaxRate: TaxRate;
  selectedExemptionReason: ExemptionReason;
  currentDocumentDate = buildCurrentDate(new Date());
  iscTax = false;
  showFrameworkModal = false;
  isGenericTaxIvaRegimeError = false;
  taxTypeToShow = 'IVA';
  setDefaultDate = false;

  constructor(
    private documentOnlineEmitterService: DocumentOnlineEmitterService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private exemptionReasonService: ExemptionReasonService,
    private taxRateService: TaxRateService,
    private route: ActivatedRoute,
    private typeDocumentService: TypeDocumentService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.documentFormBuild();

    const id: number = this.route.snapshot.params.id;
    this.getById(id);

    this.dropdownTypesDocument();

    this.getTaxRates();
    const IvaStartDate: Date = new Date(2023, 5, 1);

    this.documentOnline.get('dtEmissaoDocumentoOnline').valueChanges.subscribe((value) => {
      const icsTax = value < IvaStartDate;
      if (this.setDefaultDate) {
        if ((icsTax && !this.iscTax) || (!icsTax && this.iscTax)) {
          this.clearTaxesRegime();
        }
      }
      this.setDefaultDate = true;
      this.currentDocumentDate = buildCurrentDate(value);
      this.iscTax = icsTax;
      this.taxTypeToShow = icsTax ? 'ISC' : 'IVA';
      this.taxRates = [];
      this.getTaxRates();
    });
  }

  clearTaxesRegime(): void {
    this.selectedTaxRate = null;
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbTaxaAplicavel.descTaxaAplicavel').reset();
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  clearTaxesRegimeCode(): void {
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  showReceiptFields(): boolean {
    return this.documentOnline.get('tbContribuinteConsumidor.tbPessoa.nifPessoa').valid &&
      this.documentOnline.get('dtEmissaoDocumentoOnline').valid;
  }

  dropdownTypesDocument() {
    this.typeDocumentService.listarPorNif().then((response) => {
      this.typesDocument = response.map((r) => ({
        label: r.descTipoDocumentoEmissao,
        value: {
          idTipoDocumentoEmissao: r.idTipoDocumentoEmissao,
          descTipoDocumentoEmissao: r.descTipoDocumentoEmissao,
          siglaTipoDocumentoEmissao: r.siglaTipoDocumentoEmissao,
        },
      }));
    });
  }

  get items(): UntypedFormArray {
    return this.documentOnline.get(
      'tbItensDocumentosGeradosOnline'
    ) as UntypedFormArray;
  }

  addItem() {
    this.items.push(this.documentItemFormBuild());
  }

  async removeItem(i: number) {
    if (this.items.controls.length > 1) {
      this.items.removeAt(i);
      this.calcTax();
    }
  }

  getById(id: number) {
    this.documentOnlineEmitterService
      .getById(id)
      .then((result: DocumentOnline) => {
        const arrayItems = this.documentOnline.get(
          'tbItensDocumentosGeradosOnline'
        ) as UntypedFormArray;

        result.tbItensDocumentosGeradosOnline.forEach((item) => {
          if (!item.documentosOrigemOnline) {
            item.documentosOrigemOnline = new DocumentOnlineItemOrigin();
            item.documentosOrigemOnline.numDocumentoOrigemOnline = null;
          }
          arrayItems.push(this.documentItemFormBuild());
        });

        this.documentOnline.setValue(result);

        result.tbItensDocumentosGeradosOnline.forEach((item, index) => {
          this.itemIndex = index;
          this.onTaxRateSelect({data: item.tbTaxaAplicavel}, false);
        });
      });
  }

  calcItemTax(index: number) {
    let math = new Mathematic();

    // const itemAmount = this.items.at(index).get('valorBaseTributavelItens').value;
    const itemQuantity = this.items.at(index).get('quantItens').value;
    const itemUnitaryValue = this.items.at(index).get('valorUnitItens').value;
    const itemTaxRate = this.items
      .at(index)
      .get('tbTaxaAplicavel.valorTaxaAplicavel').value;

    const itemAmount = itemQuantity * itemUnitaryValue;
    const itemTaxValue = math.round((itemAmount * itemTaxRate) / 100, 2);

    this.items.at(index).patchValue({
      valorBaseTributavelItens: itemAmount,
      valorImposto: itemTaxValue,
      totalLinhaAposImpostoItens: itemAmount + itemTaxValue,
    });

    // this.items.at(index).get('valorImposto').patchValue(itemTaxValue);

    const itemTax = this.items.at(index).get('valorImposto').value;

    this.items
      .at(index)
      .get('totalLinhaAposImpostoItens')
      .patchValue(math.round(itemAmount + itemTax, 2));

    this.calcTax();
  }

  calcTax() {
    var m = new Mathematic();
    var amount = 0;
    var tax = 0;
    var total = 0;

    for (var i = 0; i < this.items.length; i++) {
      amount += this.items.at(i).get('valorBaseTributavelItens').value;
      tax += this.items.at(i).get('valorImposto').value;
      total += this.items.at(i).get('totalLinhaAposImpostoItens').value;
    }

    this.documentOnline
      .get('totalValorBaseTributavelOnline')
      .patchValue(m.round(amount, 6));
    this.documentOnline.get('totalImpostoOnline').patchValue(m.round(tax, 6));
    this.documentOnline.get('totalValorOnline').patchValue(m.round(total, 6));
  }

  documentFormBuild() {
    this.documentOnline = this.formBuilder.group({
      dtEmissaoDocumentoOnline: new UntypedFormControl(),
      idDocumentoGeradoOnline: new UntypedFormControl(),
      nifConsumidorOnline: new UntypedFormControl(),
      nifUsuarioAlter: new UntypedFormControl(),
      nifUsuarioCria: new UntypedFormControl(),
      numDocumentoOnline: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      numeroSequencial: new UntypedFormControl({
        disabled: true,
      }),
      observacaoDocumentoGeradoOnline: new UntypedFormControl(),
      totalImpostoOnline: new UntypedFormControl(
        {
          value: null,
          disabled: true,
        },
        Validators.compose([Validators.required])
      ),
      totalValorBaseTributavelOnline: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      totalValorOnline: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      btComunicado: new UntypedFormControl({ value: null }),
      btCorrecaoJuntarSerie: new UntypedFormControl({ value: null }),
      btCorrecaoMascara: new UntypedFormControl({ value: null }),
      btIdCriadoContribuinte: new UntypedFormControl({ value: null }),
      dtAlterOnline: new UntypedFormControl({ value: null }),
      dtCriaOnline: new UntypedFormControl({ value: null }),
      tbAutorizacaoEmissao: this.formBuilder.group({
        anoAutorizacaoEmissao: new UntypedFormControl({ value: null }),
        dtAlter: new UntypedFormControl({ value: null }),
        dtCria: new UntypedFormControl({ value: null }),
        idAutorizacaoEmissao: new UntypedFormControl({ value: null }),
        numAutorizacaoEmissao: new UntypedFormControl({ value: null }),
        numSerieDocumento: new UntypedFormControl({ value: null }),
        siglaDocAutorizacaoEmissao: new UntypedFormControl({
          value: null,
          disabled: true,
        }),
        statusTransfTbEmpresa: new UntypedFormControl({ value: null }),
        temComunicacao: new UntypedFormControl({ value: null }),
        transitarAno: new UntypedFormControl({ value: null }),
        localTributacao: new UntypedFormControl({ value: null }),
      }),
      tbContribuinteConsumidor: this.formBuilder.group({
        idContribuinte: new UntypedFormControl({ value: null }),
        nifContribuinte: new UntypedFormControl({ value: null }),
        alterNifUsuario: new UntypedFormControl({ value: null }),
        categoria: new UntypedFormControl({ value: null }),
        criaNifUsuario: new UntypedFormControl({ value: null }),
        dtAlterContribuinte: new UntypedFormControl({ value: null }),
        dtCriaContribuinte: new UntypedFormControl({ value: null }),
        estatisticaEmitente: new UntypedFormControl({ value: null }),

        tbGamaNif: this.formBuilder.group({
          descGamaNif: new UntypedFormControl({ value: null }),
          idGamaNif: new UntypedFormControl({ value: null }),
          numGamaNif: new UntypedFormControl({ value: null }),
          dtAlterGamaNif: new UntypedFormControl({ value: null }),
          dtCriaGamaNif: new UntypedFormControl({ value: null }),
          terSeriesNaoOnline: new UntypedFormControl({ value: null }),
        }),
        tbPessoa: this.formBuilder.group({
          idPessoa: new UntypedFormControl({ value: null }),
          nomePessoa: new UntypedFormControl({
            value: null,
            disabled: true,
          }),
          sobreNomePessoa: new UntypedFormControl({ value: null }),
          nifPessoa: new UntypedFormControl(
            {
              value: null,
            },
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(9),
            ])
          ),
          enderecoPessoa: new UntypedFormControl({ value: null }),
          identidade: new UntypedFormControl({ value: null }),
          telefonePessoa: new UntypedFormControl({ value: null }),
          btStatus: new UntypedFormControl({ value: null }),
          dtAlterPessoa: new UntypedFormControl({ value: null }),
          dtCriaPessoa: new UntypedFormControl({ value: null }),
        }),
      }),
      tbItensDocumentosGeradosOnline: this.formBuilder.array([]),
    });
  }

  documentItemFormBuild(): UntypedFormGroup {
    return this.formBuilder.group({
      idItensDocumentoGeradoOnline: new UntypedFormControl(),
      descItens: new UntypedFormControl(
        null,
        Validators.compose([Validators.required, Validators.min(0.05)])
      ),
      quantItens: new UntypedFormControl(
        1,
        Validators.compose([Validators.required, Validators.min(0.25)])
      ),
      valorUnitItens: new UntypedFormControl(
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      valorBaseTributavelItens: new UntypedFormControl(
        {
          value: 0,
          disabled: false,
        },
        Validators.required
      ),
      valorImposto: new UntypedFormControl(
        {
          value: 0,
          disabled: true,
        },
        Validators.required
      ),
      totalLinhaAposImpostoItens: new UntypedFormControl(
        {
          value: 0,
          disabled: true,
        },
        Validators.required
      ),
      documentosOrigemOnline: this.formBuilder.group({
        numDocumentoOrigemOnline: new UntypedFormControl(null),
      }),
      tbTaxaAplicavel: this.formBuilder.group({
        idTaxaAplicavel: new UntypedFormControl({ value: null }),
        descTaxaAplicavel: new UntypedFormControl(null, Validators.required),
        valorTaxaAplicavel: new UntypedFormControl(null, Validators.required),
        dtAlterTaxaAplicavel: new UntypedFormControl(null),
        dtCriaTaxaAplicavel: new UntypedFormControl(null),
        status: new UntypedFormControl(null),
        codigoEnquadramento: new UntypedFormControl(null),
        tbTipoImposto: this.formBuilder.group({
          descricaoTipoImposto: new UntypedFormControl(null),
          dtAlterTipoImposto: new UntypedFormControl(null),
          dtCriaTipoImposto: new UntypedFormControl(null),
          idTipoImposto: new UntypedFormControl(null),
          siglaTipoImposto: new UntypedFormControl(null),
        }),
      }),
      tbMotivoIsencao: this.formBuilder.group({
        idMotivoIsencao: new UntypedFormControl(null),
        codigoMotivoIsencao: new UntypedFormControl(null, Validators.required),
        desMotivoIsencao: new UntypedFormControl(null),
        codigoMotivoIsencaoCompra: new UntypedFormControl(null),
        artigo: new UntypedFormControl(null),
        dtAlter: new UntypedFormControl(null),
        dtCria: new UntypedFormControl(null),
        isento: new UntypedFormControl(null),
        idTaxaAplicavel: new UntypedFormControl(null),
        tipoDocumento: new UntypedFormControl(null),
        tbTipoImposto: this.formBuilder.group({
          descricaoTipoImposto: new UntypedFormControl(null),
          dtAlterTipoImposto: new UntypedFormControl(null),
          dtCriaTipoImposto: new UntypedFormControl(null),
          idTipoImposto: new UntypedFormControl(null),
          siglaTipoImposto: new UntypedFormControl(null),
        }),
      }),
    });
  }

  update() {
    this.loading = true;
    this.documentOnlineEmitterService
      .update(this.documentOnline.getRawValue())
      .then((result) =>
        this.messageService.add({
          severity: 'success',
          detail: 'Informações salvas com sucesso',
        })
      )
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => (this.loading = false));
  }

  showDisplayExemption(index) {
    this.itemIndex = index;
    this.displayExemption = true;
  }

  showTaxRate(index) {
    this.itemIndex = index;
    this.displayTaxRate = true;
  }

  onExemptionSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbMotivoIsencao: {
        idMotivoIsencao: event.data.idMotivoIsencao,
        codigoMotivoIsencao: event.data.codigoMotivoIsencao,
        desMotivoIsencao: event.data.desMotivoIsencao,
      },
    });
    this.displayExemption = false;
  }

  onTaxRateSelect(event, clearRegime = true) {
    this.items.at(this.itemIndex).patchValue({
      tbTaxaAplicavel: {
        idTaxaAplicavel: event.data.idTaxaAplicavel,
        descTaxaAplicavel: event.data.descTaxaAplicavel,
        valorTaxaAplicavel: event.data.valorTaxaAplicavel,
      },
    });
    if (clearRegime) {
      this.clearTaxesRegimeCode();
    }
    this.getExemptionReasons(event.data);
    this.calcItemTax(this.itemIndex);
    this.displayTaxRate = false;
  }

  async getExemptionReasons(taxRate: TaxRate) {
    const isento = taxRate.valorTaxaAplicavel === 0 ? true : false;

    const dataFilter = {
      taxId: taxRate.idTaxaAplicavel,
      documentTypeSigla:
        this.documentOnline.get('tbAutorizacaoEmissao.siglaDocAutorizacaoEmissao').value,
    };

    try {
      const response =
        await this.exemptionReasonService.getByTaxIdAndDocumentTypeSigla(
          dataFilter
        );
      if (this.iscTax) {
        this.exemptionReasons = response;
      } else {
        this.exemptionReasons = response.filter(
          (e) => e.isento === isento && e.tbTipoImposto.idTipoImposto === 1
        );
      }
      
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  async getTaxRates() {
    const response = await this.taxRateService.readActives(this.currentDocumentDate);
    this.taxRates = response;
    if (!this.iscTax) {
      try {
        const userNif = this.authService.getLoggedUser().preferred_username;
        const taxTypeResult: any = await this.taxRateService.getIvaTaxRegime(userNif);
        let allTaxRates: TaxRate[] = [];
        if (taxTypeResult.errors && taxTypeResult.errors.length > 0) {
          this.isGenericTaxIvaRegimeError = taxTypeResult.errors.some((error) => {
            return error.code !== 'U-404';
          });
          this.showFrameworkModal = true;
        } else {
          try {
            allTaxRates = response.filter((tax: any) => {
              return taxTypeResult && taxTypeResult.data.some((taxType) => {
                return taxType.codigo === Number(tax.codigoEnquadramento);
              });
            });
          } catch (err) {}
          this.taxRates = allTaxRates;
        }
      } catch (e) {
        this.taxRates = response;
      }
    }
    else {
      this.taxRates = response;
    }
  }
}

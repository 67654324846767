import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileCommunicationError } from 'src/app/core/models/file-communication';
import { FileDocument } from 'src/app/core/models/file-document';
import { FileDocumentEmitterService, FileDocumentEmitterFilter } from '../file-document-emitter.service';
import { ReadTodayComponent } from '../read-today/read-today.component';

export class QueryParams {
  dtPeriodoFaturacao: Date;
  emitterNif: string;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  @ViewChild(ReadTodayComponent) readToday: ReadTodayComponent;
  loading = false;
  loadingTable = true;
  loadingUpdate = false;
  loadingFixeFile = false;
  locale = new Locale();
  fileDocuments = [];
  enviado = false;
  uploadedFile: any;
  showVerifiedFile = false;

  fileCommunicationError = new FileCommunicationError();
  fileDocument = new QueryParams();

  displayBasic: boolean;
  fileDocumentEdit = new FileDocument();
  uploadedFileEdit: any;

  filter = new FileDocumentEmitterFilter();
  total = 0;

  minDate = new Date(2019, 0, 1);
  maxDate = new Date();

  constructor(
    private communicationFileEmitterService: FileDocumentEmitterService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  enviarFicheiro(fileUpload) {
    this.loading = true;
    this.showVerifiedFile = false;
    const formData = new FormData();

    formData.append('file', this.uploadedFile);
    // formData.append('dtPeriodoFaturacao', JSON.stringify(this.fileDocument));
    this.fileDocument.dtPeriodoFaturacao.setDate(5);
    this.communicationFileEmitterService.enviarFicheiro(formData, this.fileDocument)
      .then(() => {
        fileUpload.clear();
        this.readToday.read();
        this.messageService.add({ severity: 'success', detail: 'Ficheiro enviado com sucesso' });
      })
      .catch(response => {
        if (response.status === 401) {
          this.messageService.add({
            key: 'lg',
            severity: 'error',
            life: 20000,
            detail: 'O contribuinte não está autorizado à emitir documentos, consequentemente não pode comunicar documentos!'
          });
        } else {
          this.messageService.add({
            key: 'lg',
            severity: 'error',
            life: 20000,
            detail: 'Encontramos problemas ao processar o seu novo ficheiro, por favor verifique se o ficheiro está corretamente preenchido. Se o problema persistir, entre em contacto com a DI.'
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  verificarFicheiro() {
    this.loading = true;
    this.showVerifiedFile = false;
    const formData = new FormData();

    formData.append('file', this.uploadedFile);

    this.fileDocument.dtPeriodoFaturacao.setDate(5);
    this.communicationFileEmitterService.verificarFicheiro(formData, this.fileDocument)
      .then(response => {
        this.fileCommunicationError = response;
        this.showVerifiedFile = true;
      })
      .catch(response => {
        if (response.status === 400) {
          this.fileCommunicationError = response.error;
          this.showVerifiedFile = true;
        } else {
          this.errorHandler.handle(response.error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onSelectImage(evt: any) {
    this.uploadedFile = evt[0];
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RANKING DOS MAIORES CONTRIBUINTES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header text-center p-pt-2">
        <div class="p-grid">
          <div class="p-col-5 p-d-flex p-jc-end">
            <mdb-icon fas icon="chevron-circle-left" size="2x" (click)="arrowLeft()"> </mdb-icon>
          </div>
          <div class="p-col-2 p-d-flex p-jc-center">
            <h4>{{now | date:"y" }}</h4>
          </div>
          <div class="p-col-5 p-d-flex p-jc-start">
            <mdb-icon fas icon="chevron-circle-right" size="2x" (click)="arrowRight()"> </mdb-icon>
          </div>
        </div>
      </mdb-card-header>

      <mdb-card-body>
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="ranking"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} documentos recolhidos.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '80%'}">Contribuinte</th>
              <th [style]="{width: '20%'}">Base tributável</th>
              <th [style]="{width: '20%'}">ISC</th>
              <th [style]="{width: '20%'}">Total das faturas</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Contribuinte</span>
                {{rowData.tbPessoa.nomePessoa}}
              </td>

              <td>
                <span class="p-column-title">Base tributável</span>
                <strong>
                  {{rowData.estatisticaEmitente.ttValoresBaseTributavelFaturasEmitidas | currency:'STN'}}
                </strong>
              </td>

              <td>
                <span class="p-column-title">ISC</span>
                <strong>
                  {{rowData.estatisticaEmitente.ttValoresImpostoSCFaturasEmitidas | currency:'STN'}}
                </strong>
              </td>

              <td>
                <span class="p-column-title">Total das faturas</span>
                <strong>
                  {{rowData.estatisticaEmitente.ttValoresFaturasEmitidas | currency:'STN'}}
                </strong>
              </td>
            </tr>
          </ng-template>s
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

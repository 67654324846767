<mdb-card-header class="mdb-card-header text-right">
  <div class="p-d-flex p-ai-top p-jc-between">
    <div class="">
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        (click)="exportExcelOrPdf('xls')"
        class="p-button-success m-2 btn-export"
        pTooltip="Exportar em XLS"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-pdf"
        (click)="exportExcelOrPdf('pdf')"
        class="p-button-warning m-2 btn-export"
        pTooltip="Exportar em PDF"
      ></button>
    </div>

    <button
      type="button"
      mdbBtn
      size="md"
      color="light"
      mdbWavesEffect
      (click)="visibleSidebarFilter = true"
    >
      <mdb-icon fas icon="search"></mdb-icon>
      Filtrar
    </button>
  </div>
</mdb-card-header>

<mdb-card-body class="mdb-card-body">
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="documents"
    [loading]="loading"
    [paginator]="true"
    [rows]="filter.rows"
    [lazy]="true"
    [totalRecords]="total"
    (onLazyLoad)="onChangePage($event)"
  >
    <ng-template pTemplate="caption">
      <p class="m-0 p-0">
        Total de {{ total | number : "1.0-0" }} registos.
      </p>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th [style]="{ width: '10%' }">Tipo Documento</th>
        <th [style]="{ width: '10%' }">Nº Documento</th>
        <th [style]="{ width: '25%' }">Emitente</th>
        <th [style]="{ width: '25%' }">Adquirente</th>
        <th [style]="{ width: '6%' }">Data Emissão</th>
        <th [style]="{ width: '10%' }">Data Registo</th>
        <th [style]="{ width: '10%' }">Valor Total</th>
        <th>Estado</th>
        <th [style]="{ width: '3%' }"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <span class="p-column-title">Tipo Documento</span>
          {{ rowData.descTipoFatura }}
        </td>
        <td>
          <span class="p-column-title">Nº Documento</span>
          {{ rowData.numero }}
        </td>
        <td>
          <span class="p-column-title">Emitente</span>
          <span> {{ rowData.nifFornecedor }} </span>
          -
          {{ rowData.nomeFornecedor }}
        </td>
        <td>
          <span class="p-column-title">NIF Adquirente</span>
          <span> {{ rowData.nifConsumidor }} </span>
          -
          {{ rowData.nomeConsumidor }}
        </td>
        <td>
          <span class="p-column-title">Data Emissão</span>
          {{ rowData.dataEmissao | date : "dd/MM/yyyy" }}
        </td>
        <td>
          <span class="p-column-title">Data Registo</span>
          {{ rowData.dataCria | date : "dd/MM/yyyy" }}
        </td>
        <td>
          <span class="p-column-title"> Valor Total </span>
          <strong>{{
            rowData.total | number : "0.1-2" : "pt"
          }}</strong>
        </td>
        <td>
          <span class="p-column-title">Estado</span>
          <p-tag [style]="{'min-width': '74px'}" [severity]="getDocumentStatus(rowData)" [value]="getDocumentStatusLabel(rowData)"></p-tag>
        </td>
        <td [style]="{ padding: '1px 4px' }">
          <button
            type="button"
            mdbBtn
            color="info"
            size="sm"
            block="true"
            pTooltip="Ver mais informações"
            tooltipPosition="left"
            [style]="{ padding: '4px 0' }"
            [routerLink]="[
              '/document/read-state',
              encrypt(rowData.id)
            ]"
          >
            <mdb-icon fas icon="search"></mdb-icon>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</mdb-card-body>
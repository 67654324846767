import { ErrorHandlerService } from './../../core/error-handler.service';
import { StatisticFilter, StatisticService } from './../statistic.service';
import { Statistic } from './../../core/models/statistic';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-detail-short',
  templateUrl: './statistic-detail-short.component.html',
  styleUrls: ['./statistic-detail-short.component.scss']
})
export class StatisticDetailShortComponent implements OnInit {
  statistic = new Statistic();
  filter = new StatisticFilter();

  constructor(
    private service: StatisticService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  get({ startDate, endDate }): void {
    this.filter.period = [startDate, endDate];

    this.service.get(this.filter)
      .then(response => this.statistic = response)
      .catch(error => this.errorHandler.handle(error))
  }
}

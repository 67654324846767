import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { UserFilter, UserService } from '../user.service';
import { encrypt } from '../../configs/encryption';

@Component({
  selector: 'app-user-read',
  templateUrl: './user-read.component.html',
  styleUrls: ['./user-read.component.scss']
})
export class UserReadComponent implements OnInit {
  locale = new Locale();
  users = [];
  loading = true;
  total = 0;
  filter = new UserFilter();

  ranges = [
    { name: 'EMPRESAS PRIVADAS - 517', code: 517 },
    { name: 'EMPRESAS PUBLICAS - 522', code: 522 },
    { name: 'PESSOAS SINGULARES - 109', code: 109 },
    { name: 'PESSOAS SINGULARES - 135', code: 135 },
    { name: 'EMBAIXADAS - 310', code: 310 },
    { name: 'COOPERATIVAS - 538', code: 538 },
    { name: 'ONG´s - 212', code: 212 },
  ];

  categories = [
    { name: 'GRANDE_CONTRIBUINTE', code: 'GRANDE_CONTRIBUINTE' },
    { name: 'MEDIO_CONTRIBUINTE', code: 'MEDIO_CONTRIBUINTE' },
    { name: 'PEQUENO_CONTRIBUINTE', code: 'PEQUENO_CONTRIBUINTE' }
  ];

  constructor(
    private service: UserService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.users = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  encrypt(info: string) {
    return encrypt(info)
  }
}

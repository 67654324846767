import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { EmissionDocumentView } from 'src/app/core/models/emission-document';
import { EmissionDocumentTypographyFilter, EmissionDocumentTypographyService } from '../emission-document-typography.service';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { BlockedTaxpayerInfo } from 'src/app/core/models/taxpayer';

export class EmissionDocumentSaved {
  idPedidoEmissaoDocumento: number;
  ano: 20
  dataCertificacao: Date;
  dataRequisicao: Date;
  endereco: string;
  nifEmitente: string;
  nifRequerente: string;
  nifSolicitante: string;
  nomeEmitente: string;
  numSerie: string;
  numeroAutorizacao: string;
  numeroCertificacao: string;
  numeroSequencialFinal: number;
  numeroSequencialInicial: number;
  quantidadeSolicitada: number;
  sigla: string;
  telefone: string;
  totalJaSolicitado: number;
  totalSolicitado: number;
}

@Component({
  selector: 'app-increment',
  templateUrl: './increment.component.html',
  styleUrls: ['./increment.component.scss']
})
export class IncrementComponent implements OnInit {
  loading: boolean = true;
  loadingIncrement: boolean = false;
  display: boolean = false;
  displayInstruction: boolean = false;

  interval: string;
  nifSolicitante: string;
  nomeSolicitante: string;
  validNif: boolean = true;

  histories = [];

  emissionDocument = new EmissionDocumentView();
  filter = new EmissionDocumentTypographyFilter();
  total = 0;

  edSaved = new EmissionDocumentSaved();

  displayBlockedSerieMessage: boolean = false;
  blockedSerieMessage: string = '';

  blockedTaxpayerInfo: BlockedTaxpayerInfo;

  constructor(
    private service: EmissionDocumentTypographyService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private taxpayerService: TaxpayerService
  ) { }

  ngOnInit(): void {
  }

  onValidNif(event) {
    const nif = event.target.value;
    this.taxpayerService.validNif(nif)
      .then(result => {
        const { isValid, name } = result;
        this.validNif = isValid;
        this.nomeSolicitante = name;
      })
  }

  readHistory(page = 0) {
    this.filter.page = page;
    this.filter.emissionDocumentId = this.emissionDocument.idPedidoEmissaoDocumento;
    this.service.readHistory(this.filter)
      .then(result => {
        this.histories = result.body;
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.readHistory(page);
  }

  getSerieDetail() {
    this.service.readIncrement(this.filter)
      .then(response => {
        if (response.body.length) {
          const [emissionDocument] = response.body;
          this.emissionDocument = emissionDocument;
          this.calcInterval();
          this.readHistory();
        } else {
          this.messageService.add({
            severity: 'error',
            detail: 'Serie inválida ou não foi adquirida numa tipografia.',
          })
        }
      })
      .catch(error => this.errorHandler.handle(error))
  }

  calcInterval() {
    const last = Number(this.emissionDocument.totalJaSolicitado) + Number(this.emissionDocument.totalSolicitado);
    this.interval = `${this.emissionDocument.totalJaSolicitado + 1} - ${last}`;
  }

  incrementDocInSerie(form: NgForm) {
    this.loadingIncrement = true;

    const {
      idPedidoEmissaoDocumento,
      numInicialSequencial,
      totalSolicitado,
    } = this.emissionDocument;

    this.service.increment({
      idPedidoEmissaoDocumento,
      numInicialSequencial,
      nifSolicitante: this.nifSolicitante,
      qtDocumentosSolicitado: totalSolicitado
    })
      .then((result) => {
        this.messageService.add({
          severity: 'success',
          detail: 'Documentos adicionados com sucesso!'
        });
        this.edSaved = result;
        this.readHistory();
        this.display = true;
        form.reset();
      })
      .catch(error => {
        if (error.status === 400) {
          const [errorMessage] = error.error;
          this.blockedSerieMessage = errorMessage.mensagemUsuario;
          this.displayBlockedSerieMessage = true;
          return;
        }
        if (error.status === 406) {
          this.blockedTaxpayerInfo = error.error;
          this.displayBlockedSerieMessage = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => this.loadingIncrement = false);
  }

  showDialog() {
    this.display = true;
  }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import {
  Certification,
  CreateCertificationSoftwareDTO,
} from 'src/app/core/models/certification';
import { CertificationEmitterService } from '../certification-emitter.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

interface CreateCertificationSoftwareResultDTO {
  descTipoCertificacao: string;
  dtCertificacao: string;
  dtCriaPedidoCertificacao: string;
  estadoPedidoCertificacao: boolean;
  idPedidoCertificacao: number;
  nifContribuinte: string;
  nomePessoa: string;
  numeroCertificacao: string;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  loading = false;
  displayMessage = false;
  form: FormGroup;
  certificationResult: CreateCertificationSoftwareResultDTO;

  alreadyCertified = false;

  createCertificationSoftwareDTO: CreateCertificationSoftwareDTO;

  idiomas = [
    { label: 'Português', value: 'Português' },
    { label: 'Inglês', value: 'Inglês' },
    { label: 'Francês', value: 'Francês' },
  ];

  msgs2: Message[];

  constructor(
    private fb: FormBuilder,
    private certificacaoService: CertificationEmitterService,
    private errorHandler: ErrorHandlerService,
    public location: Location
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  createForm() {
    this.form = this.fb.group({
      nomeSoftware: ['', Validators.required],
      versaoSoftware: ['', Validators.required],
      fornecedorSoftware: ['', Validators.required],
      idiomaSoftware: ['', Validators.required],
    });
  }

  validateForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((formGroup) => {
          this.validateForm(formGroup as FormGroup);
        });
      }
    });
  }

  addMessages() {
    this.msgs2 = [
      {
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Pedido de certificação efectuado com sucesso.',
      },
    ];
  }

  addErrorMessage() {
    this.msgs2 = [
      {
        severity: 'error',
        summary: 'Contribuinte já certificado',
        detail: 'Já possui um número de certificação. Reveja os dados abaixo',
      },
    ];
  }

  clearMessages() {
    this.msgs2 = [];
  }

  adicionarCertificacaoSoftware() {
    if (this.form.invalid) {
      this.validateForm(this.form);
      return;
    }

    this.loading = true;

    this.createCertificationSoftwareDTO = this.form.value;

    this.certificacaoService
      .create(this.form.value)
      .then((response) => {
        this.certificationResult = response.body;
        this.displayMessage = true;
        this.addMessages();
      })
      .catch((error) => {
        if (error.status === 409) {
          this.alreadyCertified = true;
          this.certificationResult = error.error;
          this.addErrorMessage();
          this.displayMessage = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => (this.loading = false));
  }
}

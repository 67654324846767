import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-recorver-password2',
  templateUrl: './recorver-password2.component.html',
  styleUrls: ['./recorver-password2.component.scss']
})
export class RecorverPassword2Component implements OnInit {
  user = new User();
  loading = false;

  constructor(
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
  ) {

  }

  ngOnInit(): void {
    this.title.setTitle("Criar nova senha");
  }

  save(form: NgForm) {
    this.user.nifUsuario = this.route.snapshot.params['nif'];
    this.loading = true;
    this.userService.recoverPasswordStep2(this.user)
      .then(response => {
        this.router.navigate(['/solicitar-senha/nova-senha-confirmada']);
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

}

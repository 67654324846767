import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';
import { Statistic } from './../core/models/statistic';

export class StatisticFilter {
  page = 0;
  rows = 15;
  rowsShort = 10;

  emitterNif: string;
  period: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class StatisticService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/estatistica`;
  }

  // get(): Promise<any> {
  //   return this.http.get<any>(`${this.API_URL}/total`)
  //     .toPromise();
  // }

  async getGeral(): Promise<any> {
    const response = await this.http.get<any>(`${this.API_URL}/geral`)
      .toPromise();
    const statistic = response as Statistic;
    this.convertFields([statistic]);
    return statistic;
  }

  getByMonth(month: Date): Promise<any> {
    let params = new HttpParams();
    params = params.append('mes', moment(month).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.API_URL}/mes`, { params })
      .toPromise();
  }

  private convertFields(statistics: Statistic[]) {
    for (const statistic of statistics) {
      statistic.ttCanceled = statistic.ttValoresNotaCredito + statistic.ttValoresNotaDebito;
    }
  }

  async get(filter: StatisticFilter): Promise<Statistic> {
    let params = new HttpParams();

    if (filter.emitterNif)
      params = params.append('nifContribuinte', filter.emitterNif);

    if (filter.period) {
      if (!!filter.period[0])
        params = params.append('dataInicial', moment(filter.period[0]).format('YYYY-MM-DD'));
      if (!!filter.period[1])
        params = params.append('dataFinal', moment(filter.period[1]).format('YYYY-MM-DD'));
    }

    const response = await this.http.get<Statistic>(
      this.API_URL, { params }
    ).toPromise();

    const statistic = response as Statistic;
    this.convertFields([statistic]);
    return statistic;
  }

  rankingShort(filter: StatisticFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rowsShort.toString());

    if (filter.period) {
      if (!!filter.period[0])
        params = params.append('dataInicial', moment(filter.period[0]).format('YYYY-MM-DD'));
      if (!!filter.period[1])
        params = params.append('dataFinal', moment(filter.period[1]).format('YYYY-MM-DD'));
    }

    return this.http.get<any>(`${this.API_URL}/list`, { params })
      .toPromise();
  }

  ranking(filter: StatisticFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.period) {
      if (!!filter.period[0])
        params = params.append('dataInicial', moment(filter.period[0]).format('YYYY-MM-DD'));
      if (!!filter.period[1])
        params = params.append('dataFinal', moment(filter.period[1]).format('YYYY-MM-DD'));
    }

    return this.http.get<any>(`${this.API_URL}/list`, { params })
      .toPromise();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { FixDocumentByMaskComponent } from './fix-document-by-mask/fix-document-by-mask.component';
import { RoutinesRouter } from './routines-router';
import { RoutineLoadFileThirdFaseTempComponent } from './routine-load-file-third-fase-temp/routine-load-file-third-fase-temp.component';
import { FixPeriodPassingFirstDateOnFileComponent } from './fix-period-passing-first-date-on-file/fix-period-passing-first-date-on-file.component';
import { FixPeriodPassingStandardDayComponent } from './fix-period-passing-standard-day/fix-period-passing-standard-day.component';
import { RevalidateFileFirstPhaseComponent } from './revalidate-file-first-phase/revalidate-file-first-phase.component';
import { RevalidateFileSecondPhaseComponent } from './revalidate-file-second-phase/revalidate-file-second-phase.component';
import { InsertEmissionDocumentHistoriesComponent } from './insert-emission-document-histories/insert-emission-document-histories.component';
import { UpdateTotalEmissionDocumentComponent } from './update-total-emission-document/update-total-emission-document.component';

@NgModule({
  declarations: [
    FixDocumentByMaskComponent,
    RoutineLoadFileThirdFaseTempComponent,
    FixPeriodPassingFirstDateOnFileComponent,
    FixPeriodPassingStandardDayComponent,
    RevalidateFileFirstPhaseComponent,
    RevalidateFileSecondPhaseComponent,
    InsertEmissionDocumentHistoriesComponent,
    UpdateTotalEmissionDocumentComponent
  ],
  imports: [
    CommonModule,

    RoutinesRouter,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    DialogModule,
  ],
  exports: [
    RoutineLoadFileThirdFaseTempComponent,
    InsertEmissionDocumentHistoriesComponent
  ]
})
export class RoutinesModule { }

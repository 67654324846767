<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/certification/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CERTIFICAÇÃO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/certification/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          MEUS PEDIDOS DE CERTIFICAÇÃO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form>
          <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12 p-md-3">
              <label>Data do Pedido</label>
              <p-calendar name="dtCriaPedidoCertificacao" [(ngModel)]="certification.dtCriaPedidoCertificacao"
                dateFormat="dd/mm/yy" disabled>
              </p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="first">Estado</label>
              <p-toggleButton name="certification.estadoPedidoCertificacao"
                [(ngModel)]="certification.estadoPedidoCertificacao" onLabel="CERTIFICADO" offLabel="NÃO CERTIFICADO"
                disabled></p-toggleButton>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Número da Certificação</label>
              <input pInputText type="text" name="numeroCertificacao" [(ngModel)]="certification.numeroCertificacao"
                disabled>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data da Certificação</label>
              <p-calendar name="dtCertificacao" [(ngModel)]="certification.dtCertificacao" dateFormat="dd/mm/yy"
                [readonlyInput]="true" disabled>
              </p-calendar>
            </div>

            <div class="p-field p-col-12">
              <label>Tipo de Certificação</label>
              <input pInputText type="text" name="descTipoCertificacao"
                [(ngModel)]="certification.descTipoCertificacao" disabled>
            </div>

            <div class="p-field p-col-12 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/emitter/certification/read">
                VOLTAR
              </button>
            </div>

          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { CreateForThirdComponent } from './create-for-third/create-for-third.component';
import { CreatePortalComponent } from './create-portal/create-portal.component';
import { CreateComponent } from './create/create.component';
import { NewComponent } from './new/new.component';

const routes: Routes = [
  {
    path: 'new',
    component: NewComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_CADASTRAR_EMPRESA_PEDIDO_EMISSAO_FATURA'] }
  },

  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_CADASTRAR_EMPRESA_PEDIDO_EMISSAO_FATURA'] }
  },

  {
    path: 'create-portal',
    component: CreatePortalComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_CADASTRAR_EMPRESA_PEDIDO_EMISSAO_FATURA'] }
  },

  {
    path: 'create-for-third',
    component: CreateForThirdComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_CADASTRAR_EMPRESA_PEDIDO_EMISSAO_FATURA'] }
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmissionEmitterRouter { }

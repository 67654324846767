import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TaxPayer } from 'src/app/core/models/taxpayer';
import { TaxpayerService } from 'src/app/taxpayer/taxpayer.service';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { TaxpayerFilter } from './../taxpayer.service';

@Component({
  selector: 'app-taxpayer-read-not-have-serie',
  templateUrl: './taxpayer-read-not-have-serie.component.html',
  styleUrls: ['./taxpayer-read-not-have-serie.component.scss']
})
export class TaxpayerReadNotHaveSerieComponent implements OnInit {
  locale = new Locale();
  loading = true;
  taxpayers: TaxPayer[];
  total = 0;
  filter = new TaxpayerFilter();

  gamasNif = [
    { label: 'EMPRESAS PRIVADAS - 517', value: 517 },
    { label: 'EMPRESAS PUBLICAS - 522', value: 522 },
    { label: 'PESSOAS SINGULARES - 109', value: 109 },
    { label: 'PESSOAS SINGULARES - 135', value: 135 },
    { label: 'EMBAIXADAS - 310', value: 310 },
    { label: 'COOPERATIVAS - 538', value: 538 },
    { label: 'ONG´s - 212', value: 212 },
  ]

  constructor(
    private taxRateService: TaxpayerService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.taxRateService.readNotHaveSerie(this.filter)
      .then((response) => {
        this.total = response.total;
        this.taxpayers = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.read(page);
  }

}

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
  [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
  (onLazyLoad)="onChangePage($event)">
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">
      Total de {{ total | number:'1.0-0'}} ficheiros recolhidos.
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th [style]="{width: '50%'}">Ficheiro</th>
      <th [style]="{width: '6%'}">Dt Registo</th>
      <th [style]="{width: '6%'}">Período</th>
      <th [style]="{width: '5%'}">1ª análise</th>
      <th [style]="{width: '5%'}">2ª análise</th>
      <th [style]="{width: '5%'}">3ª análise</th>
      <th [style]="{width: '6%'}">Status de análise</th>
      <th [style]="{width:'3em'}"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <span class="p-column-title">Ficheiro</span>
        {{ rowData.nomeArquivoDocumentoGerado }}
      </td>

      <td>
        <span class="p-column-title">Dt Registo</span>
        {{ rowData.dtCria | date:'dd/MM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title">Período</span>
        {{ rowData.dtPeriodoFaturacao | date:'MMM/yyyy' }}
      </td>

      <td>
        <span class="p-column-title">1ª análise</span>
        <div *ngIf="rowData.step1 === null; else elseNull">
          <mdb-icon fas icon="sync" class="mr-2 gray-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>

        <ng-template #elseNull>
          <div *ngIf="rowData.step1; else elseBlock">
            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
            </mdb-icon>
          </div>
          <ng-template #elseBlock>
            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
          </ng-template>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">2ª análise</span>
        <div *ngIf="rowData.step2 === null; else elseNull2">
          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>

        <ng-template #elseNull2>
          <div *ngIf="rowData.step2; else elseBlock2">
            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
            </mdb-icon>
          </div>
          <ng-template #elseBlock2>
            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
          </ng-template>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">3ª análise</span>
        <div *ngIf="rowData.step3 === null; else elseNull3">
          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
          </mdb-icon>
        </div>

        <ng-template #elseNull3>
          <div *ngIf="rowData.step3; else elseBlock3">
            <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
            </mdb-icon>
          </div>
          <ng-template #elseBlock3>
            <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
          </ng-template>
        </ng-template>
      </td>

      <td>
        <span class="p-column-title">Status de análise</span>
        {{ rowData.fileAnalise }}
      </td>

      <td [style]="{padding: '1px 4px'}">
        <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left" pTooltip="Transferir o ficheiro"
          class="btn-block" [style]="{padding: '4px 0'}" (click)="getFile(rowData)">
          <i class="fa fa-download"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

import { RoutinesService } from './../routines.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-routine-load-file-third-fase-temp',
  templateUrl: './routine-load-file-third-fase-temp.component.html',
  styleUrls: ['./routine-load-file-third-fase-temp.component.scss']
})
export class RoutineLoadFileThirdFaseTempComponent implements OnInit {
  loading: boolean = false;
  display: boolean = false;

  period: Date;
  day = 5;

  routine: string = 'Rodar 3ª fase de análise para ficheiros temporários'

  constructor(
    private routinesService: RoutinesService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  run() {
    this.loading = true;
    this.period.setDate(this.day);
    this.routinesService.loadFileThirdFaseTempByPeriod(this.period)
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Statistic } from './../../core/models/statistic';
import { StatisticDetailShortComponent } from './../statistic-detail-short/statistic-detail-short.component';
import { StatisticFilter, StatisticService } from './../statistic.service';

@Component({
  selector: 'app-statistic-read-year',
  templateUrl: './statistic-read-year.component.html',
  styleUrls: ['./statistic-read-year.component.scss']
})
export class StatisticReadYearComponent implements OnInit {
  @Input() emitterNif: string;
  @ViewChild(StatisticDetailShortComponent) statisticDetail: StatisticDetailShortComponent

  loading: boolean = false;
  statistic = new Statistic();
  filter = new StatisticFilter();

  now = new Date();
  startDate = new Date(this.now.getFullYear(), 0, 1);
  endDate = new Date(this.now.getFullYear(), 11, 31);

  constructor(
    private statistService: StatisticService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.statisticDetail.get({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  get() {
    this.statistService.get(this.filter)
      .then((result) => this.statistic = result)
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }

  arrowLeft() {
    this.prevDate();
    this.statisticDetail.get({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  arrowRight() {
    this.nextDate();
    this.statisticDetail.get({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  prevDate(): void {
    this.now = new Date(this.now.getFullYear() - 1, 11);

    this.startDate = new Date(this.now.getFullYear(), 0, 1);
    this.endDate = new Date(this.now.getFullYear(), 11, 31);
  }

  nextDate(): void {
    this.now = new Date(this.now.getFullYear() + 1, 0);

    this.startDate = new Date(this.now.getFullYear(), 0, 1);
    this.endDate = new Date(this.now.getFullYear(), 11, 31);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { EmissionForm } from 'src/app/core/models/emission';
import { EmissionDocumentForm } from 'src/app/core/models/emission-document';
import { TypeDocument } from 'src/app/core/models/type-document';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { EmissionEmitterService } from './../../emission-emitter/emission-emitter.service';
import { TypeDocumentService } from './../../type-document/type-document.service';

@Component({
  selector: 'app-create-portal',
  templateUrl: './create-portal.component.html',
  styleUrls: ['./create-portal.component.scss'],
})
export class CreatePortalComponent implements OnInit {
  loading: boolean = false;

  emissionForm = new EmissionForm(this.fb).builder();
  emissionDocumentForm = new EmissionDocumentForm(this.fb);

  typesDocument: TypeDocument[];

  constructor(
    private fb: UntypedFormBuilder,
    private emissionEmitterService: EmissionEmitterService,
    private auth: AuthService,
    private typeDocumentService: TypeDocumentService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.addItem();

    this.getTypeDocument();

    this.emissionForm.patchValue({
      // tbPedidoCertificacao: {
      //   tbContribuinte: {
      //     idContribuinte: this.auth.jwtPayload.idCont
      //   },
      // },
      // tbContribuinte: {
      //   nifContribuinte: this.auth.jwtPayload.nif
      // },
      // nifSolicitante: this.auth.jwtPayload.nif
    });
  }

  get items(): UntypedFormArray {
    return this.emissionForm.get('pedidoEmissaoDocumentos') as UntypedFormArray;
  }

  addItem() {
    this.items.push(this.emissionDocumentForm.builder());
  }

  removeItem(i: number) {
    this.items.removeAt(i);
  }

  getTypeDocument() {
    return this.typeDocumentService
      .read()
      .then((tiposDocumento) => {
        this.typesDocument = tiposDocumento.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  save() {
    this.loading = true;
    this.emissionForm.removeControl('mascara');
    this.emissionEmitterService
      .creatForPortal(this.emissionForm.value)
      .then((response) => {
        this.messageService.add({
          severity: 'success',
          detail: 'Pedido efetuado com sucesso!',
          life: 10000,
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }
}

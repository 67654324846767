<div class="p-grid">

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          NOTIFICAÇÕES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-4">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-dropdown [options]="readsStatus" name="btLida" [showClear]="true" [(ngModel)]="filter.btLida"
                    placeholder="Status" (onChange)="read()">
                  </p-dropdown>
                </span>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body>
        <p-scrollPanel [style]="{width: '100%', height: '400px'}">
          <div [style]="{lineHeight: '1.5'}">
            <div class="list-group list-group-flush" *ngFor="let notification of notifications">

              <a (click)="getNotification(notification)" [class.active]="notification.idNotificacao == notificationId"
                class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <p><strong> {{ notification.tbTipoNotificacaoDescTipoNotificacao }} </strong></p>
                  <small class="text-muted"> {{ notification.dtCriaNotificacao | date:'dd/MM/yyyy' }} </small>
                </div>
                <p [innerHTML]="notification.topicoNotificacao"> </p>
              </a>

            </div>
          </div>
        </p-scrollPanel>
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="p-col-8">
    <mdb-card>
      <mdb-card-body>
        <article>
          <div class="article-header">
            <h5> {{ notification.tbTipoNotificacaoDescTipoNotificacao }} </h5>
          </div>
          <hr>

          <div class="article-body">
            <div class="description" [innerHTML]="notification.descricaoNotificacao">
            </div>

            <!--<p *ngIf="!isRegularized">
              <strong>Prazo de regularização: </strong> {{notification.dtPrazoPegularizacao | date:'dd/MM/yyyy'}}
            </p>

            <div class="p-col">
              <p-inputSwitch [(ngModel)]="notification.btLida"></p-inputSwitch>
            </div>-->
          </div>
        </article>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Message } from 'primeng//api';
import { MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { User } from 'src/app/core/models/user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateUserComponent implements OnInit {

  loading = false;
  user = new User();
  msgs: Message[] = [];

  constructor(
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private router: Router,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Novo Utilizador");
  }

  create() {
    this.messageService.clear();
    this.loading = true;
    this.userService.create(this.user)
      .then(() => {
        this.router.navigate(['/user/new/message'])
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { ReadComponent } from './read/read.component';
import { GetComponent } from './get/get.component';
import { CreateComponent } from './create/create.component';

const routes: Routes = [
  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'read/:id',
    component: GetComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_CONSUMIDOR_DOCUMENTO_GERADO'] }
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class DocumentConsumerRegConsumerRouter { }

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';
import { TaxPayer } from '../core/models/taxpayer';
import { TaxpayerWebService } from '../core/models/taxpayer-webservice';
import {Certification} from "../core/models/certification";

export class TaxpayerFilter {
  page = 0;
  rows = 15;

  nif: string;
  gammaNif: any;
  category: any;

  personName: string;
  userEmail: string;
  createdAt: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class TaxpayerService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/contribuinte`;
  }

  async read(filter: TaxpayerFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nif)
      params = params.append('nif', filter.nif);

    if (filter.personName)
      params = params.append('nomePessoa', filter.personName);

    if (filter.userEmail)
      params = params.append('emailUsuario', filter.userEmail);

    if (filter.gammaNif)
      params = params.append('gama', filter.gammaNif.code.toString());

    if (filter.category)
      params = params.append('categoria', filter.category.code.toString());

    if (filter.createdAt) {
      if (!!filter.createdAt[0])
        params = params.append('dtCriaDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (!!filter.createdAt[1])
        params = params.append('dtCriaAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }

    const response = await this.http
      .get<any>(this.API_URL, { params })
      .toPromise();

    return {
      body: <TaxPayer[]>response.content,
      total: response.totalElements
    };
  }

  readAll(): Promise<any> {
    return this.http.get<any>(`${this.API_URL}/grande`)
      .toPromise();
  }

  async readNotHaveSerie(filter: TaxpayerFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nif)
      params = params.append('nifEmpresa', filter.nif);

    if (filter.gammaNif)
      params = params.append('idGamaNif', filter.gammaNif.toString());

    const response = await this.http.get<any>(`${this.API_URL}/semSerie`, { params })
      .toPromise();
    return {
      body: <TaxPayer[]>response.content,
      total: response.totalElements
    };
  }

  async readWithSerieWithoutDocument(filter: TaxpayerFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nif)
      params = params.append('nifEmpresa', filter.nif);

    if (filter.gammaNif)
      params = params.append('idGamaNif', filter.gammaNif.toString());

    const response = await this.http.get<any>(`${this.API_URL}/comSerieSemDocumento`, { params })
      .toPromise();
    return {
      body: <TaxPayer[]>response.content,
      total: response.totalElements
    };
  }

  async getByNIf_(nif): Promise<TaxPayer> {
    const response = await this.http.get(`${this.API_URL}/getContribuintePorNif/${nif}`)
      .toPromise();
    const taxPayer = response as TaxPayer;
    this.convertFields([taxPayer]);
    return taxPayer;
  }

  async getByNifInWebService(nif): Promise<TaxpayerWebService> {
    const response = await this.http.get(`${this.API_URL}/getContribuinteCadastroPorNif/${nif}`)
      .toPromise();
    const taxPayer = response as TaxpayerWebService;
    this.convertFieldsWebService([taxPayer]);
    return taxPayer;
  }

  getSituation(id: number): Promise<any> {
    let params = new HttpParams();
    params = params.append('id', id.toString());

    return this.http.get<any>(`${this.API_URL}/grande`, { params })
      .toPromise();
  }

  private convertFields(taxPayers: TaxPayer[]) {
    for (const taxPayer of taxPayers) {
      taxPayer.dtCriaContribuinte = moment(taxPayer.dtCriaContribuinte,
        'YYYY-MM-DD').toDate();
      taxPayer.dtAlterContribuinte = moment(taxPayer.dtAlterContribuinte,
        'YYYY-MM-DD').toDate();
    }
  }

  async validNif(nif: string) {
    if (nif.length !== 9) {
      return {
        isValid: false,
        name: null
      }
    }

    try {
      const response = await this.getByNifInWebService(nif);
      return {
        isValid: true,
        name: response.contriNome
      }
    } catch (error) {
      return {
        isValid: null,
        name: null,
        error: new Error(error)
      }
    }
  }

  private convertFieldsWebService(taxPayers: TaxpayerWebService[]) {
    for (const taxPayer of taxPayers) {
      taxPayer.contriDatanascimento = moment(taxPayer.contriDatanascimento,
        'YYYY-MM-DD').toDate();
      taxPayer.contriDatareg = moment(taxPayer.contriDatareg,
        'YYYY-MM-DD').toDate();
      taxPayer.contriDatafim = moment(taxPayer.contriDatafim,
        'YYYY-MM-DD').toDate();
      taxPayer.contriImportDatavalidade = moment(taxPayer.contriImportDatavalidade,
        'YYYY-MM-DD').toDate();
    }
  }
}

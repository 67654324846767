import { CurrentAccount } from './../core/models/current-account';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';

export class CurrentAccountFilter {
  page = 0;
  rows = 15;

  emitterNif: string;
}

@Injectable({
  providedIn: 'root'
})
export class CurrentAccountService {
  API_URL: string;

  constructor(
    private http: AppHttp
  ) {
    this.API_URL = `${environment.apiUrl}/contaCorrente`;
  }

  read(filter: CurrentAccountFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    return this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise()
      .then(response => {
        return {
          content: response.content,
          total: response.totalElements
        };
      });
  }

  getById(id: number): Promise<CurrentAccount> {
    return this.http.get(`${this.API_URL}/${id}`)
      .toPromise()
      .then(response => response as CurrentAccount)
  }
}

import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { FileDocumentEmitterFilter, FileDocumentEmitterService } from '../file-document-emitter.service';

@Component({
  selector: 'app-read-today',
  templateUrl: './read-today.component.html',
  styleUrls: ['./read-today.component.scss']
})
export class ReadTodayComponent implements OnInit {
  loading: boolean = true;
  loadingChangePeriod: boolean = false;
  loadingChangeFile: boolean = false;
  loadingCancelFile: boolean = false;

  displayPeriod: boolean = false;
  displayFile: boolean = false;
  displayCancelFile: boolean = false;

  locale = new Locale();
  filter = new FileDocumentEmitterFilter();
  fileDocument = new FileDocument();

  total = 0;
  fileDocuments = [];

  maxDate = new Date();
  minDate = new Date("2019-01-01");

  uploadedFileEdit: any;

  constructor(
    private service: FileDocumentEmitterService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  showChangePeriodDialog(fileDocument: FileDocument) {
    this.fileDocument = fileDocument;
    this.displayPeriod = true;
  }

  showChangeFileDialog(fileDocument: FileDocument) {
    this.fileDocument = fileDocument;
    this.displayFile = true;
  }

  showCancelFile(fileDocument: FileDocument) {
    this.fileDocument = fileDocument;
    this.displayCancelFile = true;
  }

  getFile(file: FileDocument) {
    this.service.getFile(file)
      .then(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
      })
  }

  updatePeriod() {
    this.loadingChangePeriod = true;

    this.fileDocument.dtPeriodoFaturacao.setDate(5);
    this.service.updatePeriod(this.fileDocument)
      .then(() => {
        this.read();
        this.displayPeriod = false;

        this.messageService.add({
          severity: 'success',
          detail: 'Informação alterada com sucesso'
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => {
        this.loadingChangePeriod = false;
      });
  }

  fixFile() {
    this.loadingChangeFile = true;
    const formData = new FormData();

    formData.append('file', this.uploadedFileEdit);
    formData.append('jsondata', JSON.stringify(this.fileDocument.idArquivoDocumentoGerado));

    this.service.fixFile(formData)
      .then(() => {
        this.displayFile = false;
        this.read();

        this.messageService.add({
          severity: 'success',
          detail: 'Ficheiro corrigido enviado com sucesso'
        });
      })
      .catch(() => {
        this.messageService.add({
          key: 'lg',
          life: 20000,
          severity: 'error',
          detail: 'Encontramos problemas ao processar o seu novo ficheiro, por favor verifique se o ficheiro está corretamente preenchido. Se o problema persistir, entre em contacto com a DI.'
        });
      })
      .finally(() => {
        this.loadingChangeFile = false;
      });
  }

  onSelectFileEdit(evt: any) {
    this.uploadedFileEdit = evt[0];
  }

  cancelFile() {
    this.loadingCancelFile = true;
    this.service.cancelFile(this.fileDocument.idArquivoDocumentoGerado)
      .then(() => {
        this.read();
        this.displayCancelFile = false;

        this.messageService.add({
          severity: 'success',
          detail: 'A comunicação do ficheiro foi cancelada'
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => {
        this.loadingCancelFile = false;
      });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { ControlDocumentRouter } from './control-document-router';
import { CreateComponent } from './create/create.component';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [
    ReadComponent,
    CreateComponent
  ],
  imports: [
    CommonModule,
    ControlDocumentRouter,

    LayoutModule,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ProgressBarModule,
    DropdownModule,
    InputTextareaModule
  ]
})
export class ControlDocumentModule { }

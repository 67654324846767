import { Component, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { encrypt } from '../../configs/encryption';
import { FileDocumentAdminFilter, FileDocumentAdminService } from '../file-document-admin.service';
import { FileDocumentEmitterService } from './../../file-document-emitter/file-document-emitter.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  loading = true;
  loadingChangePeriod: boolean = false;
  loadingCopyToDocumentTable: boolean = false;
  displayPeriod: boolean = false;

  locale = new Locale();

  fileDocuments = [];
  total = 0;
  filter = new FileDocumentAdminFilter();
  fileDocument = new FileDocument();

  maxDate = new Date();
  minDate = new Date("2019-01-01");

  statusFiles = [
    { code: true, name: 'ATIVO' },
    { code: false, name: 'INATIVO' },
  ]

  statusAnalises = [
    { code: 'POR_ANALISAR', name: 'POR ANALISAR' },
    { code: 'ANALISE_PRIMEIRA_FASE', name: 'ANALISE 1ª FASE' },
    { code: 'ANALISE_SEGUNDA_FASE', name: 'ANALISE 2ª FASE' },
    // { code: 'ANALISE_TERCEIRA_FASE', name: 'ANALISE 3ª FASE' },
    { code: 'EM_ESPERA', name: 'EM ESPERA' },
    { code: 'PROCESSANDO', name: 'PROCESSANDO' },
    { code: 'REJEITADO', name: 'REJEITADO' },
    { code: 'CONCLUIDO', name: 'CONCLUÍDO' },
  ]

  constructor(
    private service: FileDocumentAdminService,
    private fileDocumentEmitterService: FileDocumentEmitterService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  getFile(file: FileDocument) {
    this.service.getFile(file)
      .then(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() => this.messageService.add({
        key: 'lg',
        severity: 'error',
        life: 20000,
        detail: 'Não foi possível transferir o ficheiro! Tente mais tarde.'
      }))
  }

  confirm(fileDocument: any) {
    const status = !fileDocument.estadoPedidoCertificacao;
    const action = status ? 'Ativar' : 'Desativar';
    this.confirmation.confirm({
      message: `${action} o arquivo?`,
      accept: () => {
        this.valid(fileDocument);
      }
    });
  }

  valid(fileDocument: any): void {
    this.loading = true;

    const newStatus = !fileDocument.status;
    this.service.valid(fileDocument.idArquivoDocumentoGerado, newStatus)
      .then(() => {
        const action = newStatus ? 'ativado' : 'desativado';
        fileDocument.status = newStatus;
        this.messageService.add({
          severity: 'success',
          detail: `Ficheiro ${action} com sucesso!`
        })
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  showChangePeriodDialog(fileDocument: FileDocument) {
    this.fileDocument = fileDocument;
    this.displayPeriod = true;
  }

  updatePeriod() {
    this.loadingChangePeriod = true;

    this.fileDocument.dtPeriodoFaturacao.setDate(5);
    this.fileDocumentEmitterService.updatePeriod(this.fileDocument)
      .then(() => {
        this.read();
        this.displayPeriod = false;

        this.messageService.add({
          severity: 'success',
          detail: 'Informação alterada com sucesso'
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => {
        this.loadingChangePeriod = false;
      });
  }

  encrypt(info: string) {
    return encrypt(info);
  }

  copyToDocumentTable({ idArquivoDocumentoGerado: id }) {
    this.loadingCopyToDocumentTable = true;
    this.service.copyInfoToDocumentTable(id)
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loadingCopyToDocumentTable = false);
  }
}

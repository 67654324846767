import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Authorization } from 'src/app/core/models/authorization';
import {
  EmissionDocument,
  EmissionDocumentView,
} from 'src/app/core/models/emission-document';
import { TypeCertificationService } from 'src/app/type-certification/type-certification.service';
import { TypeDocumentService } from 'src/app/type-document/type-document.service';
import {
  EmissionDocumentFilter,
  EmissionDocumentService,
} from '../../emission-document/emission-document.service';
import {EmissionDocumentDTO} from '../../emission-document-emitter/types/emission-document';
import {ConfirmationService} from 'primeng/api';
import { EmissionDocumentEmitterService } from 'src/app/emission-document-emitter/emission-document-emitter.service';
import { TaxpayerService } from 'src/app/taxpayer/taxpayer.service';
import { AuthorizationService } from 'src/app/authorization/authorization.service';

class TransportSerieInput {
  serieId: number;
  currentSerie: string;
  nextYear: number;
  documentAmount: number;
  description: string;
  requesterNif: string;
  requesterName: string;
}

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class ReadComponent implements OnInit {
  loading = true;
  loadingTransport = false;
  visibleSidebarFilter = false;
  total = 0;
  filter = new EmissionDocumentFilter();
  locale = new Locale();
  tiposDocumento = [];
  typesCertification = [];

  emissionDocument = new EmissionDocument();
  emissionDocumentView = new EmissionDocumentView();
  authorization = new Authorization();

  years = [
    { label: '2024', value: '2024' },
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
  ];

  emissionDocuments = [];
  clonedDocuments: { [s: string]: EmissionDocument } = {};
  displayEdit = false;
  displayDetails = false;
  displayTransportDialog = false;

  serieToTransport: string;
  yearsToTransport: { label: string; value: number }[] = [];
  yearToTransport: string;

  transportSerieInput = new TransportSerieInput();
  validNifSol: boolean = true;

  constructor(
    private service: EmissionDocumentService,
    private tipoDocumentoService: TypeDocumentService,
    private typeCertificationService: TypeCertificationService,
    private confirmationService: ConfirmationService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private emissionDocumentEmitterService: EmissionDocumentEmitterService,
    private taxPayerService: TaxpayerService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    this.readTypeDocument();
    this.readTypeCertification();
    this.getYearsToTransport();
  }

  readTypeDocument() {
    return this.tipoDocumentoService
      .read()
      .then((response) => {
        this.tiposDocumento = response.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  getYearsToTransport(): void {
    this.emissionDocumentEmitterService
      .getYearsToTransport()
      .then((results) => {
        const years = results.map((result: any) => ({
          value: result,
          label: result.toString(),
        }));

        this.yearsToTransport = years;
      })
      .catch((err) => this.errorHandler.handle(err));
  }

  readTypeCertification() {
    return this.typeCertificationService
      .read()
      .then((response) => {
        this.typesCertification = response.map((t) => ({
          label: t.siglaTipoCertificacao,
          value: t.idTipoCertificacao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service
      .read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  showDialogDetails(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;
    this.displayDetails = true;
  }

  showTransportDialog(ed: EmissionDocumentView) {
    this.transportSerieInput.serieId = ed.idAutorizacaoEmissao;
    this.transportSerieInput.currentSerie = `${ed.siglaSerie}${ed.serie}${ed.ano}`;
    this.transportSerieInput.documentAmount = ed.totalSolicitado;
    this.transportSerieInput.description = ed.descricao;

    const currentYear = Number(`20${ed.ano}`);

    this.yearsToTransport = this.yearsToTransport.filter((year) => year.value > currentYear);
    this.displayTransportDialog = true;
  }

  closeTransportDialog(form: NgForm) {
    form.reset();
    this.transportSerieInput = new TransportSerieInput();
    this.displayTransportDialog = false;
  }

  resetFormFilter(form) {
    form.reset();
  }

  getDocumentStatus(data) {
    return data.statusSerie === 'BLOQUEADO';
  }

  getDocumentStatusLabel(data) {
    return data.statusSerie;
  }

  onInputNifSol(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.transportSerieInput.requesterName = response.contriNome;
          this.validNifSol = true;
        })
        .catch(() => {
          this.validNifSol = false;
          this.transportSerieInput.requesterName = null;
        });
    } else {
      this.validNifSol = false;
      this.transportSerieInput.requesterName = null;
    }
  }

  unblockSerieConfirm(ed: EmissionDocumentDTO) {
    if (this.getDocumentStatus(ed)) {
      const serie = `${ed.siglaSerie}${ed.serie}${ed.ano}`;
      this.confirmationService.confirm({
        message: `Desbloquear a série ${serie}?`,
        accept: () => {
          this.unblockSerie(ed.idPedidoEmissaoDocumento)
            .then(() => ed.statusSerie = 'DESBLOQUEADO');
        }
      });
    }
  }

  unblockSerie(emissionDocumentId: number){
    return this.service.unblockSerie(emissionDocumentId)
      .then(() => this.messageService.add({
        severity: 'success',
        detail: '´Série Desbloqueada com sucesso!'
      }))
      .catch((erro) => this.errorHandler.handle(erro));
  }

  transport(form: NgForm) {
    this.loadingTransport = true;

    const newEmissionDocument = new EmissionDocument();
    
    newEmissionDocument.qtDocumentosSolicitado = this.transportSerieInput.documentAmount,
    newEmissionDocument.qtTotalDocumentosJaSolicitado = this.transportSerieInput.documentAmount,
    newEmissionDocument.mascara = 6,
    newEmissionDocument.descPedidoEmissaoDocumento = this.transportSerieInput.description,
  
    this.authorizationService.transport({
      currentEmissionDocumentId: this.transportSerieInput.serieId,
      requesterNif: this.transportSerieInput.requesterNif,
      applicantNif: null,
      newEmissionDocument,
      isTypography: false,
    }, this.transportSerieInput.nextYear)
    .then((response) => {
      this.messageService.add({
        severity: 'success',
        detail: `Transporte efectuado com sucesso com sucesso! Nova serie: 
        ${response.siglaDocAutorizacaoEmissao}${response.numSerieDocumento}${response.anoAutorizacaoEmissao}`,
        life: 100000
      });
      this.read();
      this.displayTransportDialog = false;
    })
    .catch((erro) => this.errorHandler.handle(erro))
    .finally(() => {
      this.loadingTransport = false;
    });
  }
}

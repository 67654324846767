import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-create-user-mail-confirm',
  templateUrl: './create-user-mail-confirm.component.html',
  styleUrls: ['./create-user-mail-confirm.component.scss']
})
export class CreateUserMailConfirmComponent implements OnInit {

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const { nif, codeConfirmation } = this.route.snapshot.params;

    this.userService.validMail({ nif, codeConfirmation })
      .then((result) => result)
  }
}

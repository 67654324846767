import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { User } from 'src/app/core/models/user';
import { TaxpayerService } from 'src/app/taxpayer/taxpayer.service';
import { decrypt } from '../../configs/encryption';
import { UserService } from '../user.service';
import { ProfileService } from './../../profile/profile.service';
@Component({
  selector: 'app-user-get',
  templateUrl: './user-get.component.html',
  styleUrls: ['./user-get.component.scss']
})
export class UserGetComponent implements OnInit {
  loading: boolean = false;
  loadingValidUser: boolean = false;
  displayValidUser: boolean = false;

  displayDialogChangeEmail: boolean = false;

  profiles = [];
  locale = new Locale();
  user = new User();
  printArea: any;

  nif: string;
  taxpayerId: number;

  nameAssinante: string;
  validNif: boolean = false;

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private taxPayerService: TaxpayerService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.dropdownProfile();
    const userId = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(userId));
    this.get(decryptedId);
  }

  get(id: number) {
    this.userService.get(id)
      .then(response => {
        this.user = response;
        this.taxpayerId = this.user.tbContribuinte.idContribuinte;
        this.nif = this.user.tbContribuinte.nifContribuinte;
      })
      .catch(error => this.errorHandler.handle(error));
  }

  update() {
    this.loading = true;
    this.userService.update(this.user)
      .then(response => {
        this.user = response;
        this.messageService.add({
          severity: 'success',
          detail: 'Informações alteradas com sucesso.'
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  dropdownProfile() {
    this.profileService.dropdown()
      .then(response => {
        this.profiles = response.map(p => ({
          label: p.descPerfil, value: p.idPerfil
        }))
      }).catch(error => this.errorHandler.handle(error));
  }

  validUserAndPrintTerm() {
    this.loadingValidUser = true;
    delete this.user.statusColor;
    this.userService.validUserAndPrintTerm(this.user)
      .then(response => {
        this.user = response;
        this.messageService.add({
          severity: 'success',
          detail: 'Informações processadas com sucesso!'
        });
        this.displayValidUser = false;
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loadingValidUser = false);
  }

  cancelValidUser(): void {
    this.displayValidUser = false;
    this.user.nifAssinante = '';
  }

  openDialogChangeEmail() {
    this.displayDialogChangeEmail = true;
  }

  closeDialogChangeEmail() {
    this.displayDialogChangeEmail = false;
  }

  onInputNif(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService.getByNifInWebService(nif)
        .then((response) => {
          this.nameAssinante = response.contriNome;
          this.validNif = true;
        })
        .catch(() => {
          this.nameAssinante = null;
          this.validNif = false;
        });
    } else {
      this.nameAssinante = null;
      this.validNif = false;
    }
  }
}

<p-dialog header="NOTA DE BLOQUEIO DE CONTAS DE UTILIZADORES NO PORTAL E-FACTURA" [(visible)]="display"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true" (onHide)="closeDialog()">

  <div class="block-note alert alert-light" role="alert">
    <p>
      A direcção dos Impostos procedeu ao bloqueio de todas as contas do portal e-Factura de São Tomé e Príncipe sob a
      recomendação da Agência Nacional de Protecção de Dados Pessoais de todas as contas que não foram validadas junto à
      Direcção dos Impostos.
    </p>

    <p>
      Todos os contribuintes registados no sistema e-factura e que se encontram bloqueados, deverão dirigir-se ao Guichê
      de Atendimento da Direcção dos Impostos a fim de confirmar o seu registo e efectuar o desbloqueio da mesma.
    </p>

    <br>

    <p>
      O procedimento para a confirmação do registo será o seguinte:
    </p>

    <ul>
      <li>
        <p class="subtitle">
          <strong>
            Pessoas individuais com NIF da gama 109, 114 ou 135
          </strong>
        </p>

        <p>
          Deverão dirigir-se ao guiché do atendimento da Direcção dos Impostos, acompanhados do seu NIF e do seu
          documento de identificação (BI ou Passaporte);
        </p>
      </li>

      <li>
        <p class="subtitle">
          <strong>
            Todas as outras gamas do NIF:
          </strong>
        </p>

        <p>
          A pessoa indigitada pela entidade deverá dirigir-se ao guiché do atendimento da Direcção dos Impostos
          fazendo-se acompanhar de uma declaração emitida por aquela entidade, devidamente assinada e carimbada,
          outorgando autorização à pessoa, devidamente identificada no documento, dando os poderes para junto à Direcção
          solicitar a confirmação da conta.
        </p>

        <p>
          A pessoa deverá fazer-se acompanhar do NIF da empresa em causa, do seu NIF pessoal e de um documento de
          identificação (BI ou Passaporte).
        </p>
      </li>
    </ul>

    <p>
      <strong>Nota:</strong>
      Todas as contas novas no sistema passarão pelo mesmo procedimento.
    </p>

    <p>
      O termo “gama” corresponde aos três primeiros algarismos que compõem o NIF.
    </p>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="info" mdbWavesEffect (click)="closeDialog()">
      TOMEI CONHECIMENTO
    </button>
  </ng-template>
</p-dialog>

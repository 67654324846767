<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <!-- <div class="p-col-12">
    <app-statistic-all-count></app-statistic-all-count>
  </div> -->

  <!-- <p translate="helloWorld"></p> -->
  <!-- {{ "greeting" | translate }} -->

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/adquirente">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-user blue-text"></i></div>
        <h4 class="title">Adquirente</h4>
        <p class="description">
          Na área do adquirente ou consumidor poderá verificar e registar as
          suas facturas e consultar alertas pendentes, bem como aceder às outras
          funcionalidades.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitente">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-shopping-bag green-text"></i></div>
        <h4 class="title">Emitente</h4>
        <p class="description">
          Na área do emitente poderá consultar facturas, enviar facturas
          emitidas através do cadastro ou pelo carregamento de arquivo e
          verificar alertas pendentes.
        </p>
      </div>
    </a>
  </div>
</div>

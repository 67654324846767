import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AuthValidFirstUser } from '../auth/auth.valid-first-user';
import { CreateUserMailConfirmComponent } from './create-user-mail-confirm/create-user-mail-confirm.component';
import { CreateUserSmsComponent } from './create-user-sms/create-user-sms.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { RecorverPassword1Component } from './recorver-password1/recorver-password1.component';
import { RecorverPassword2Component } from './recorver-password2/recorver-password2.component';
import { RecorverPassword3Component } from './recorver-password3/recorver-password3.component';
import { UserGetComponent } from './user-get/user-get.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserReadComponent } from './user-read/user-read.component';
import { ValidateEmailToUpdateComponent } from './validate-email-to-update/validate-email-to-update.component';

const routes: Routes = [
  {
    path: 'new',
    component: CreateUserComponent,
  },
  {
    path: 'read',
    component: UserReadComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },
  {
    path: 'read/:id',
    component: UserGetComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard, AuthValidFirstUser],
  },
  {
    path: 'new/message',
    component: CreateUserSmsComponent,
  },
  {
    path: 'new/mail-confirm/:nif/:codeConfirmation',
    component: CreateUserMailConfirmComponent,
  },
  {
    path: 'solicitar-senha',
    component: RecorverPassword1Component,
  },
  {
    path: 'solicitar-senha/nova-senha/:nif',
    component: RecorverPassword2Component,
  },
  {
    path: 'solicitar-senha/nova-senha-confirmada',
    component: RecorverPassword3Component,
  },
  {
    path: 'validate-email-to-update/:userId',
    component: ValidateEmailToUpdateComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class UserRouter {
}

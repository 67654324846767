import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { BlockedTaxpayerInfo } from 'src/app/core/models/taxpayer';
import { AuthService } from './../../auth/auth.service';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Emission, EmissionForm } from './../../core/models/emission';
import { EmissionDocumentForm } from './../../core/models/emission-document';
import { TypeDocument } from './../../core/models/type-document';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { TypeDocumentService } from './../../type-document/type-document.service';
import { EmissionTypographyService } from './../emission-typography.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  loading: boolean = false;
  validNif: boolean = true;
  validNifSol: boolean = true;
  display: boolean = false;

  displayBlockedSerieMessage: boolean = false;

  emission = new Emission();

  emissionForm = new EmissionForm(this.fb).builder();
  emissionDocumentForm = new EmissionDocumentForm(this.fb);

  typesDocument: TypeDocument[];

  blockedTaxpayerInfo: BlockedTaxpayerInfo;

  constructor(
    private fb: UntypedFormBuilder,
    private emissionTypographyService: EmissionTypographyService,
    private typeDocumentService: TypeDocumentService,
    private errorHandler: ErrorHandlerService,
    private taxPayerService: TaxpayerService
  ) {}

  ngOnInit(): void {
    this.addItem();

    this.getTypeDocument();
  }

  get items(): UntypedFormArray {
    return this.emissionForm.get('pedidoEmissaoDocumentos') as UntypedFormArray;
  }

  addItem() {
    this.items.push(this.emissionDocumentForm.builder());
  }

  removeItem(i: number) {
    this.items.removeAt(i);
  }

  getTypeDocument() {
    return this.typeDocumentService
      .read()
      .then((tiposDocumento) => {
        this.typesDocument = tiposDocumento.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  onInputNif(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.emissionForm.patchValue({
            tbContribuinte: { nomeContribuinte: response.contriNome },
          });
          this.validNif = true;
        })
        .catch(() => {
          this.validNif = false;
          this.emissionForm.patchValue({
            tbContribuinte: { nomeContribuinte: null },
          });
        });
    } else {
      this.validNif = false;
      this.emissionForm.patchValue({
        tbContribuinte: { nomeContribuinte: null },
      });
    }
  }

  onInputNifSol(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.emissionForm.patchValue({
            nomeSolicitante: response.contriNome,
          });
          this.validNifSol = true;
        })
        .catch(() => {
          this.validNifSol = false;
          this.emissionForm.patchValue({ nomeSolicitante: null });
        });
    } else {
      this.validNifSol = false;
      this.emissionForm.patchValue({ nomeSolicitante: null });
    }
  }

  save() {
    this.loading = true;

    this.emissionForm.value.pedidoEmissaoDocumentos.forEach((pedido) => {
      pedido.localTributacao = pedido.localTributacao ? 'P' : 'S';
    });

    this.emissionTypographyService
      .creat(this.emissionForm.value)
      .then((response) => {
        this.emission = response;
        this.display = true;
      })
      .catch((error) => {
        if (error.status === 406) {
          this.blockedTaxpayerInfo = error.error;
          this.displayBlockedSerieMessage = true;
          return;
        }
        this.errorHandler.handle(error);
      })
      .finally(() => (this.loading = false));
  }
}

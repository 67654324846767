<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/document-online">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SISTEMA DE FACTURAÇÃO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR DOCUMENTOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-dropdown inputId="dropdown" class="p-inputtext-sm" [autoDisplayFirst]="false"
                    [options]="typesDocument" name="typeDocument" [(ngModel)]="filter.typeDocument" optionLabel="name"
                    [showClear]="true">
                  </p-dropdown>
                  <label for="dropdown">Tipo Documento</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="consumerNif" [(ngModel)]="filter.consumerNif">
                  <label for="float-input">NIF Adquirente</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="documentNumber" [(ngModel)]="filter.documentNumber">
                  <label for="float-input">Número Documento</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="serieNumber" [(ngModel)]="filter.serieNumber">
                  <label for="float-input">Número Série</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="startDate" [(ngModel)]="filter.startDate"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="endDate" [(ngModel)]="filter.endDate" dateFormat="dd/mm/yy"
                    [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body>
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="invoices"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} documentos emitidos.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>Documento</th>
              <th [style]="{width: '12%'}">Número</th>
              <th [style]="{width: '35%'}">Adquirente</th>
              <th>Data</th>
              <th>Sub-total</th>
              <th>Imposto</th>
              <th>Total</th>
              <th>Valor Anulado</th>
              <th>Valor Final</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Documento</span>
                {{ rowData.tbTipoDocumentoDescricao }}
              </td>

              <td>
                <span class="p-column-title">Número</span>
                {{ rowData.numeroDocumento }}
              </td>

              <td>
                <span class="p-column-title">Adquirente</span>
                {{ rowData.tbContribuinteNifConsumidor + ' - ' +rowData.tbPessoaNomeConsumidor }}
              </td>

              <td>
                <span class="p-column-title">Data</span>
                {{ rowData.dtEmissaoDocumento | date:'dd/MM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title"> Valor </span>
                <strong> {{ rowData.totalValorBaseTributavel | number:'0.1-2':'pt' }} </strong>
              </td>

              <td>
                <span class="p-column-title"> Imposto </span>
                <strong> {{ rowData.totalImposto | number:'0.1-2':'pt' }} </strong>
              </td>

              <td>
                <span class="p-column-title"> Valor Total </span>
                <strong> {{ rowData.totalValor | number:'0.1-2':'pt' }} </strong>
              </td>

              <td>
                <span class="p-column-title"> Valor Anulado </span>
                <strong [ngClass]="rowData.cancellationSeverity"
                  *ngIf="rowData.tbAutorizacaoEmissaoSigla !== 'NC'; else elseBlock">
                  {{ rowData.totalValorAnulado | number:'0.1-2':'pt' }}
                </strong>
                <ng-template #elseBlock> - </ng-template>
              </td>

              <td>
                <span class="p-column-title"> Valor Final </span>
                <strong *ngIf="rowData.tbAutorizacaoEmissaoSigla !== 'NC'; else elseBlock1">
                  {{ rowData.valorDisponivelAnular | number:'0.1-2':'pt' }}
                </strong>
                <ng-template #elseBlock1> - </ng-template>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais informaões"
                  tooltipPosition="left" class="btn-block" [style]="{padding: '4px 0'}"
                  (click)="routerLink(rowData.idDocumentoGeradoOnline)">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

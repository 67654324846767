import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { OccurrenceRouter } from './occurrence-router';
import { ReadComponent } from './read/read.component';
import { UpdateComponent } from './update/update.component';

@NgModule({
  declarations: [ReadComponent, UpdateComponent],
  imports: [
    CommonModule,
    OccurrenceRouter,

    FormsModule,
    MDBBootstrapModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    ProgressBarModule,
    EditorModule,
    CalendarModule,
    DialogModule
  ]
})
export class OccurrenceModule { }

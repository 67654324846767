import { Component, OnInit } from '@angular/core';
import { isAfter } from 'date-fns';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { ControlDocument } from 'src/app/core/models/control-document';
import { TypeDocumentService } from 'src/app/type-document/type-document.service';
import {
  AuthorizationEmitterFilter,
  AuthorizationEmitterService,
} from './../../authorization-emitter/authorization-emitter.service';
import { ControlDocumentService } from './../../control-document/control-document.service';

@Component({
  selector: 'app-read-control',
  templateUrl: './read-control.component.html',
  styleUrls: ['./read-control.component.scss'],
})
export class ReadControlComponent implements OnInit {
  loading: boolean = true;
  loadingDeclare: boolean = true;

  authorizations = [];
  selectedAuthorizations = [];
  typesDocument = [];
  series = [];

  total = 0;

  controlDocument = new ControlDocument();
  locale = new Locale();
  filter = new AuthorizationEmitterFilter();

  constructor(
    private authorizationEmitterService: AuthorizationEmitterService,
    private authorizationService: AuthorizationService,
    private typeDocumentService: TypeDocumentService,
    private controlDocumentService: ControlDocumentService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.readTypeDocument();
  }

  readTypeDocument() {
    return this.typeDocumentService
      .read()
      .then((response) => {
        this.typesDocument = response
          .filter((t) => t.siglaTipoDocumentoEmissao !== "NC" && t.siglaTipoDocumentoEmissao !== "ND")
          .map((t) => ({
            label: t.descTipoDocumentoEmissao,
            value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  dropdownSeries(typeDocumentId: number) {
    
    if (!typeDocumentId) {
      this.series = [];
      return;
    }

    this.authorizationService
      .listarSeriePorNifIdTipoDocumento(typeDocumentId)
      .then((response) => {
        this.series = response.map((s) => ({
          label: `${s.siglaDocAutorizacaoEmissao}${s.numSerieDocumento}${s.anoAutorizacaoEmissao}`,
          value: s.idAutorizacaoEmissao,
        }));
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.authorizationEmitterService
      .readControl(this.filter)
      .then((response) => {
        this.total = response.total;
        this.authorizations = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.read(page);
  }

  declare(period, controlDocument) {
    this.loadingDeclare = true;

    var { month, year: yearAbbreviate } = period;
    month -= 1;
    const year = Number(`20${yearAbbreviate}`);

    const date = new Date(year, month, 2);

    this.controlDocument.tbAutorizacaoEmissao.idAutorizacaoEmissao =
      controlDocument.idAutorizacaoEmissao;
    this.controlDocument.periodo = date;

    this.controlDocumentService
      .create(this.controlDocument)
      .then((response) => {
        this.controlDocument = response;
        this.messageService.add({
          severity: 'success',
          detail: 'Comunicação efetuada com sucesso.',
        });
        this.read();
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loadingDeclare = false));
  }

  confirmDeclare(period, controlDocument) {
    var { month } = period;
    month -= 1;

    const serie = `${controlDocument.siglaDocAutorizacaoEmissao}${controlDocument.numSerieDocumento}${controlDocument.anoAutorizacaoEmissao}`;
    this.confirmation.confirm({
      message: `Deseja declarar que não teve facturação durante o mê de ${this.getFullMonth(
        month
      )} para a série ${serie}?`,
      accept: () => {
        this.declare(period, controlDocument);
      },
    });
  }

  isCurrentDateBefore({ month, year }) {
    const date = new Date();

    const day = 30;

    const dateToCompare = new Date(Number(`20${year}`), month - 1, day);

    const res = isAfter(date, dateToCompare);

    return res;
  }

  getFullMonth(monthNumber: number) {
    var month = new Array();
    month[0] = 'Janeiro';
    month[1] = 'Fevereiro';
    month[2] = 'Março';
    month[3] = 'Abril';
    month[4] = 'Maio';
    month[5] = 'Junho';
    month[6] = 'Julho';
    month[7] = 'Agosto';
    month[8] = 'Setembro';
    month[9] = 'Outubro';
    month[10] = 'Novembro';
    month[11] = 'Dezembro';

    var fullMonth = month[monthNumber];
    return fullMonth;
  }

  declareMany() {
    // this.loadingDeclare = true;
    // const controlDocuments = this.selectedAuthorizations.map((element) => {
    // })
  }
  /*showName(dict) {
    const name = Object.keys(dict)[0];

    return name.replace(/[^0-9]/g, '');
  }*/
}

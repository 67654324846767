import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { Emission } from '../core/models/emission';
import {
  EmissionDocument,
  EmissionDocumentView
} from './../core/models/emission-document';
import { EmissionDocumentHistory } from './get/get.component';

export class EmissionDocumentTypographyFilter {
  page = 0;
  rows = 15;

  emissionDocumentId: number;

  idTipoDocumentoEmissao: number;
  serieNumber: string;
  description: string;
  applicantName: string;
  applicantNif: string;
  emitterNif: string;
  emitterName: string;
  dataInicio: Date;
  dataFim: Date;
  year: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmissionDocumentTypographyService {
  API: string;

  constructor(private http: AppHttp) {
    this.API = `${environment.apiUrl}/pedidoEmissaoDocumento`;
  }

  async read(filter: EmissionDocumentTypographyFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif);
    }
    if (filter.emitterName) {
      params = params.append('nomeEmissor', filter.emitterName);
    }
    if (filter.serieNumber) {
      params = params.append('numSerieDocumento', filter.serieNumber.toString());
    }
    if (filter.description) {
      params = params.append('descricao', filter.description);
    }
    if (filter.idTipoDocumentoEmissao) {
      params = params.append('idTipoDocumentoEmissao', filter.idTipoDocumentoEmissao.toString());
    }
    if (filter.dataInicio) {
      params = params.append('dtCriaPedidoEmissaoDocumentoDe', moment(filter.dataInicio).format('YYYY-MM-DD'));
    }
    if (filter.dataFim) {
      params = params.append('dtCriaPedidoEmissaoDocumentoAte', moment(filter.dataFim).format('YYYY-MM-DD'));
    }
    if (filter.year) {
      params = params.append('anoEmissaoDocumento', filter.year);
    } /*else {
      var date = new Date();
      var year = date.getFullYear();
      params = params.append('anoEmissaoDocumento', year.toString());
    }*/

    const response = await this.http.get<any>(`${this.API}/porGrafica`, { params })
      .toPromise();
    return {
      body: <EmissionDocument[]>response.content,
      total: response.totalElements
    };
  }

  async readIncrement(filter: EmissionDocumentTypographyFilter) {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emitterNif) {
      params = params.append('nifEmissor', filter.emitterNif);
    }
    if (filter.serieNumber) {
      params = params.append('numSerieDocumento', filter.serieNumber.toString());
    }

    const response = await this.http.get<any>(`${this.API}/porGrafica/geral`, { params })
      .toPromise();
    return {
      body: <EmissionDocumentView[]>response.content,
      total: response.totalElements
    };
  }

  async readHistory(filter: EmissionDocumentTypographyFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.emissionDocumentId) {
      params = params.append('idPedidoEmissaoDocumento', filter.emissionDocumentId.toString());
    }

    const response = await this.http.get<any>(`${environment.apiUrl}/pedidoEmissaoDocumentoHistorico`, { params })
      .toPromise();
    return {
      body: response.content,
      total: response.totalElements
    };
  }

  async updateDescription(ed: EmissionDocument) {
    const response = await this.http.put(
      `${this.API}/adicionarQuantidadeInicialSerie/${ed.idPedidoEmissaoDocumento}`,
      ed
    ).toPromise();

    const changedEmissionDocument = response as EmissionDocument;
    this.convertFields([changedEmissionDocument]);
    return changedEmissionDocument;
  }

  async updateHistory(history: EmissionDocumentHistory): Promise<any> {
    return this.http.put(`${environment.apiUrl}/pedidoEmissaoDocumentoHistorico`, history)
      .toPromise();
  }

  async getYearsToTransport(): Promise<any> {
    return this.http.get(`${this.API}/anosQueSeriesPodemSerTransferidas`)
      .toPromise();
  }

  async increment(ed): Promise<any> {
    return this.http.put(`${this.API}/adicionarQuantidade/${ed.idPedidoEmissaoDocumento}`, ed)
      .toPromise();
  }

  async readNotTransported(filter: EmissionDocumentTypographyFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.applicantNif) {
      params = params.append('nifEmissor', filter.applicantNif);
    }
    if (filter.applicantName) {
      params = params.append('nomeEmissor', filter.applicantName);
    }
    if (filter.serieNumber) {
      params = params.append('numSerieDocumento', filter.serieNumber.toString());
    }
    if (filter.description) {
      params = params.append('descricao', filter.description.toString());
    }
    if (filter.idTipoDocumentoEmissao) {
      params = params.append('idTipoDocumentoEmissao', filter.idTipoDocumentoEmissao.toString());
    }
    if (filter.dataInicio) {
      params = params.append('dtCriaPedidoEmissaoDocumentoDe', moment(filter.dataInicio).format('YYYY-MM-DD'));
    }
    if (filter.dataFim) {
      params = params.append('dtCriaPedidoEmissaoDocumentoAte', moment(filter.dataFim).format('YYYY-MM-DD'));
    }
    if (filter.year) {
      params = params.append('anoEmissaoDocumento', filter.year);
    } /*else {
      var year = 2020;
      params = params.append('anoEmissaoDocumento', year.toString());
    }*/

    const response = await this.http.get<any>(`${this.API}/porGrafica/serieNaoTransportada`, { params })
      .toPromise();
    return {
      body: <EmissionDocument[]>response.content,
      total: response.totalElements
    };
  }

  private convertFields(eds: EmissionDocument[]) {
    for (const ed of eds) {
      if (ed.dtCriaPedidoEmissaoDocumento) {
        ed.dtCriaPedidoEmissaoDocumento = moment(ed.dtCriaPedidoEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
      if (ed.dtAlterPedidoEmissaoDocumento) {
        ed.dtAlterPedidoEmissaoDocumento = moment(ed.dtAlterPedidoEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
    }
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/document/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS RECOLHIDOS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" (ngSubmit)="onUpdate()" #form="ngForm">
          <div class="p-fluid p-formgrid p-grid">
            <!--
              <div class="p-field p-col-12 p-md-4">
              <label>NIF do Emitente</label>
              <input pInputText type="text" name="document.tbContribuinteNifEmissor"
                [(ngModel)]="document.tbContribuinteNifEmissor" disabled>
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label>Nome do Emitente</label>
              <input pInputText type="text" name="document.tbPessoaNomeEmissor"
                [(ngModel)]="document.tbPessoaNomeEmissor" disabled>
            </div>
             -->

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">NIF do Adquirente</label>
              <input
                pInputText
                name="nifConsumidor"
                [(ngModel)]="document.nifConsumidor"
                (input)="onInputNif($event)"
              />
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label class="p-text-bold">Nome do Adquirente</label>
              <input
                pInputText
                name="nomeConsumidor"
                [(ngModel)]="document.nomeConsumidor"
                disabled
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Tipo de Documento</label>
              <input
                pInputText
                type="text"
                name="descTipoDocumento"
                [(ngModel)]="document.descTipoDocumento"
                disabled
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Número da Documento</label>
              <input
                pInputText
                type="text"
                name="numDocumento"
                [(ngModel)]="document.numDocumento"
                disabled
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Data de Emissão</label>
              <p-calendar
                name="dtEmissaoDocumento"
                [readonlyInput]="true"
                dateFormat="dd/mm/yy"
                [(ngModel)]="document.dtEmissaoDocumento"
                #dtEmissaoDocumento="ngModel"
                (onSelect)="showTaxSigla($event)"
              ></p-calendar>

              <app-message
                [control]="dtEmissaoDocumento"
                error="required"
                text="Informe a data de emissão"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label class="p-text-bold">Tipo comunicação</label>
              <input
                pInputText
                type="text"
                name="origem"
                [(ngModel)]="document.origem"
                disabled
              />
            </div>

            <div class="p-col-12">
              <hr />

              <div>
                <div class="p-grid p-mt-2">
                  <label
                    class="p-col-fixed p-text-bold"
                    [ngStyle]="{ width: '250px' }"
                    *ngIf="displayFieldForCreditNote"
                  >
                    Documento a anular<b class="red-text">*</b>
                  </label>

                  <label
                    class="p-col-fixed p-text-bold"
                    [ngStyle]="{ width: '450px' }"
                  >
                    Descrição (opcional)
                  </label>

                  <label class="p-col p-text-bold">
                    Subtotal<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Taxa ({{ siglaTipoImpostoToShow }})<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Código i/s<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    {{ siglaTipoImpostoToShow }}<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Total<b class="red-text">*</b>
                  </label>

                  <label class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                  </label>
                </div>

                <div
                  class="p-grid"
                  *ngFor="
                    let item of document.tbItensDocumentoGerados;
                    let i = index;
                    trackBy: trackByIndex
                  "
                >
                  <!-- <div class="p-field p-col-fixed" formGroupName="tbDocumentoOrigems"
                        *ngIf="displayFieldForCreditNote" [ngStyle]="{width:'250px'}">
                        <input pInputText class="p-inputtext-sm" name="tbDocumentoOrigems"
                          (focus)="showDisplayOrigin(i)" formControlName="numDocumentoOrigem"
                          placeholder="Documento origem">
                      </div> -->

                  <div
                    class="p-field p-col-fixed"
                    [ngStyle]="{ width: '450px' }"
                  >
                    <input
                      pInputText
                      class="p-inputtext-sm"
                      name="descItens"
                      [(ngModel)]="
                        document.tbItensDocumentoGerados[i].descItens
                      "
                      [ngModelOptions]="{ standalone: true }"
                      maxlength="100"
                      placeholder="Descrição (máximo 100 caracteres)"
                    />
                  </div>

                  <div class="p-field p-col">
                    <p-inputNumber
                      mode="decimal"
                      [minFractionDigits]="2"
                      name="valorBaseTributavelItens"
                      [(ngModel)]="
                        document.tbItensDocumentoGerados[i]
                          .valorBaseTributavelItens
                      "
                      [ngModelOptions]="{ standalone: true }"
                      class="p-inputtext-sm"
                      placeholder="Montante do item"
                      #valorBaseTributavelItens="ngModel"
                      (onBlur)="calcItemTax(i)"
                    >
                    </p-inputNumber>

                    <app-message
                      [control]="valorBaseTributavelItens"
                      error="required"
                      text="Informe o montante"
                    >
                    </app-message>
                  </div>

                  <div class="p-field p-col">
                    <input
                      pInputText
                      class="p-inputtext-sm"
                      name="descTaxaAplicavel"
                      [(ngModel)]="
                        document.tbItensDocumentoGerados[i].tbTaxaAplicavel
                          .descTaxaAplicavel
                      "
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Taxa"
                      (focus)="showTaxRate(i)"
                    />
                  </div>

                  <div class="p-field p-col">
                    <input
                      pInputText
                      class="p-inputtext-sm"
                      name="tbMotivoIsencao"
                      [(ngModel)]="
                        document.tbItensDocumentoGerados[i].tbMotivoIsencao
                          .codigoMotivoIsencao
                      "
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Motivo isenção/sujeição"
                      (focus)="showDisplayExemption(i)"
                    />
                  </div>

                  <div class="p-field p-col">
                    <p-inputNumber
                      class="p-inputtext-sm"
                      mode="decimal"
                      [minFractionDigits]="2"
                      name="valorImposto"
                      [(ngModel)]="
                        document.tbItensDocumentoGerados[i].valorImposto
                      "
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Imposto"
                    >
                    </p-inputNumber>
                  </div>

                  <div class="p-field p-col">
                    <p-inputNumber
                      class="p-inputtext-sm"
                      mode="decimal"
                      [minFractionDigits]="2"
                      name="valorItens"
                      [(ngModel)]="
                        document.tbItensDocumentoGerados[i].valorItens
                      "
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Total do item"
                    >
                    </p-inputNumber>
                  </div>

                  <div class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                    <button
                      type="button"
                      mdbBtn
                      color="danger"
                      size="sm"
                      pTooltip="Remover item"
                      tooltipPosition="left"
                      (click)="removeItem(i)"
                    >
                      <mdb-icon fas icon="trash"></mdb-icon>
                    </button>
                  </div>
                </div>
              </div>

              <button
                type="button"
                mdbBtn
                color="info"
                size="sm"
                pTooltip="Adicionar linha"
                tooltipPosition="right"
                [style]="{ margin: '5px 0 15px 0' }"
                (click)="addItem()"
              >
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Base Tributável</label>
              <input
                pInputText
                name="baseTributavel"
                [(ngModel)]="document.totalValorBaseTributavel"
                currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                disabled
              />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Imposto</label>
              <input
                pInputText
                name="imposto"
                [(ngModel)]="document.totalImposto"
                currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                disabled
              />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Valor Total</label>
              <input
                pInputText
                name="valorTotal"
                [(ngModel)]="document.totalValor"
                currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                disabled
              />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="text-danger p-text-bold"> Valor Anulado </label>
              <input
                pInputText
                name="canceledAmount"
                [(ngModel)]="document.canceledAmount"
                currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                disabled
              />
            </div>

            <div class="p-col-12 p-p-0">
              <hr />
              <div class="p-grid">
                <div class="p-col-6 text-left">
                  <button
                    type="button"
                    mdbBtn
                    color="light"
                    mdbWavesEffect
                    routerLink="/emitter/document/read"
                  >
                    VOLTAR
                  </button>

                  <button
                    type="submit"
                    mdbBtn
                    color="warning"
                    mdbWavesEffect
                    [disabled]="form.invalid || loading"
                  >
                    SALVAR ALTERAÇÕES
                  </button>
                </div>

                <div class="p-col-6 text-right">
                  <button
                    type="button"
                    mdbBtn
                    color="secondary"
                    mdbWavesEffect
                    (click)="displayCreditAndDebitNotes()"
                  >
                    NOTAS DE CRÉDITO E DÉBITO
                    <span
                      class="badge bg-danger ms-2"
                      *ngIf="creditAndDebitNotesItems.length > 0"
                    >
                      {{ creditAndDebitNotesItems.length }}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Selecione a taxa"
  modal="true"
  [(visible)]="displayTaxRate"
  [style]="{ width: '50vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="taxRates"
    selectionMode="single"
    [(selection)]="selectedTaxRate"
    (onRowSelect)="onTaxRateSelect($event)"
    [rows]="10"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{ width: '60%' }">Imposto</th>
        <th [style]="{ width: '20%' }">Sigla</th>
        <th [style]="{ width: '20%' }">Taxa</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.descricaoTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.siglaTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Taxa</span>
          {{ rowData.descTaxaAplicavel }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayTaxRate = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o documento de origem"
  [(visible)]="displayOrigin"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <form autocomplete="off" (ngSubmit)="readOriginsByEmitter()">
    <div class="p-col-12">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-5">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="numDocumento"
              [(ngModel)]="filter.numDocumento"
            />
            <label for="float-input">Número Documento</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-2">
          <button
            type="submit"
            mdbBtn
            color="pink"
            size="sm"
            class="btn-block btn-filter"
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  </form>

  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="documentsOrigin"
    selectionMode="single"
    [(selection)]="selectedOrigin"
    (onRowSelect)="onOriginSelect($event)"
    [paginator]="true"
    [rows]="filter.minRows"
    [totalRecords]="totalDocumentsOrigin"
    [lazy]="true"
    (onLazyLoad)="onChangePage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Número Documento</th>
        <th>Data</th>
        <th>Montante</th>
        <th>{{ siglaTipoImpostoToShow }}</th>
        <th>Total</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>{{ rowData.numDocumento }}</td>
        <td>{{ rowData.dtEmissaoDocumento | date : "dd/MM/yyyy" }}</td>
        <td>{{ rowData.totalValorBaseTributavel }}</td>
        <td>{{ rowData.totalImposto }}</td>
        <td>{{ rowData.totalValor }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayOrigin = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o código de isenção/sujeição"
  [(visible)]="displayExemption"
  [style]="{ width: '70vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="exemptionReasons"
    selectionMode="single"
    [(selection)]="selectedExemptionReason"
    (onRowSelect)="onExemptionSelect($event)"
    [paginator]="true"
    [rows]="5"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Código</th>
        <th [style]="{ width: '40%' }">Descrição</th>
        <th [style]="{ width: '40%' }">Artigo</th>
        <th>Situação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Código</span>
          {{ rowData.codigoMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Descrição</span>
          {{ rowData.desMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Artigo</span>
          {{ rowData.artigo }}
        </td>
        <td>
          <span class="p-column-title">Situação</span>
          {{ rowData.isento ? "Isenção" : "Sujeição" }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayExemption = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-sidebar
  [(visible)]="creditAndDebitNotesIsOpen"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '80vw' }"
>
  <h4>Notas de Crédito e Débito</h4>

  <app-get-credit-and-debit-items-by-invoice
    [creditAndDebitNotesItems]="creditAndDebitNotesItems"
  >
  </app-get-credit-and-debit-items-by-invoice>
</p-sidebar>

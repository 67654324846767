import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Authorization } from './../../core/models/authorization';
import {
  EmissionDocument,
  EmissionDocumentView,
} from './../../core/models/emission-document';
import { TypeDocumentService } from './../../type-document/type-document.service';
import {
  EmissionDocumentTypographyFilter,
  EmissionDocumentTypographyService,
} from './../emission-document-typography.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class ReadComponent implements OnInit {
  loading: boolean = true;
  validNif: boolean = true;
  displayEdit: boolean = false;
  displayDetails: boolean = false;

  total = 0;

  locale = new Locale();
  filter = new EmissionDocumentTypographyFilter();
  emissionDocumentView = new EmissionDocumentView();
  emissionDocument = new EmissionDocument();

  tiposDocumento = [];
  emissionDocuments = [];
  years = [
    { label: '2024', value: '2024' },
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
  ];

  constructor(
    private service: EmissionDocumentTypographyService,
    private tipoDocumentoService: TypeDocumentService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.readTypeDocument();
  }

  readTypeDocument() {
    return this.tipoDocumentoService
      .read()
      .then((response) => {
        this.tiposDocumento = response.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service
      .read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  updateDescription() {
    this.loading = true;

    this.service
      .updateDescription(this.emissionDocument)
      .then(() => {
        this.read();
        this.messageService.add({
          severity: 'success',
          detail: 'Informações alteradas!',
        });
        this.displayEdit = false;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  showDialogEdit(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;

    this.emissionDocument.idPedidoEmissaoDocumento =
      this.emissionDocumentView.idPedidoEmissaoDocumento;
    this.emissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocumentView.descricao;
    this.emissionDocument.mascara = this.emissionDocumentView.mascara;

    this.displayEdit = true;
  }

  showDialogDetails(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;
    this.displayDetails = true;
  }
}

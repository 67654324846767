import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AuthModule } from '../auth/auth.module';
import { AuthService } from '../auth/auth.service';
import { AuthorizationUtilModule } from '../authorization-util/authorization-util.module';
import { AuthorizationModule } from '../authorization/authorization.module';
import { AuthorizationService } from '../authorization/authorization.service';
import { CertificationEmitterModule } from '../certification-emitter/certification-emitter.module';
import { CertificationEmitterService } from '../certification-emitter/certification-emitter.service';
import { CertificationTypographyModule } from '../certification-typography/certification-typography.module';
import { CertificationTypographyService } from '../certification-typography/certification-typography.service';
import { CertificationModule } from '../certification/certification.module';
import { CertificationService } from '../certification/certification.service';
import { DocumentOnlineEmitterModule } from '../document-online-emitter/document-online-emitter.module';
import { DocumentModule } from '../document/document.module';
import { EmissionDocumentEmitterModule } from '../emission-document-emitter/emission-document-emitter.module';
import { EmissionDocumentEmitterService } from '../emission-document-emitter/emission-document-emitter.service';
import { EmissionDocumentTypographyModule } from '../emission-document-typography/emission-document-typography.module';
import { EmissionDocumentTypographyService } from '../emission-document-typography/emission-document-typography.service';
import { EmissionDocumentModule } from '../emission-document/emission-document.module';
import { EmissionDocumentService } from '../emission-document/emission-document.service';
import { EmissionEmitterModule } from '../emission-emitter/emission-emitter.module';
import { EmissionEmitterService } from '../emission-emitter/emission-emitter.service';
import { EmissionTypographyModule } from '../emission-typography/emission-typography.module';
import { EmissionTypographyService } from '../emission-typography/emission-typography.service';
import { ExemptionReasonModule } from '../exemption-reason/exemption-reason.module';
import { ExemptionReasonService } from '../exemption-reason/exemption-reason.service';
import { FileDocumentEmitterModule } from '../file-document-emitter/file-document-emitter.module';
import { FileDocumentEmitterService } from '../file-document-emitter/file-document-emitter.service';
import { NotificationUserService } from '../notification-user/notification-user.service';
import { NotificationService } from '../notification/notification.service';
import { PersonModule } from '../person/person.module';
import { PersonService } from '../person/person.service';
import { StartModule } from '../start/start.module';
import { StatisticModule } from '../statistic/statistic.module';
import { StatisticService } from '../statistic/statistic.service';
import { TaxRateModule } from '../tax-rate/tax-rate.module';
import { TaxRateService } from '../tax-rate/tax-rate.service';
import { TaxpayerModule } from '../taxpayer/taxpayer.module';
import { TaxpayerService } from '../taxpayer/taxpayer.service';
import { TutorialModule } from '../tutorial/tutorial.module';
import { TypeDocumentModule } from '../type-document/type-document.module';
import { TypeDocumentService } from '../type-document/type-document.service';
import { TypeTaxModule } from '../type-tax/type-tax.module';
import { TypeTaxService } from '../type-tax/type-tax.service';
import { UserModule } from '../user/user.module';
import { UserService } from '../user/user.service';
import { AuthorizationEmitterModule } from './../authorization-emitter/authorization-emitter.module';
import { AuthorizationEmitterService } from './../authorization-emitter/authorization-emitter.service';
import { AuthorizationUtilService } from './../authorization-util/authorization-util.service';
import { CertificationUtilModule } from './../certification-util/certification-util.module';
import { CertificationUtilService } from './../certification-util/certification-util.service';
import { ControlDocumentModule } from './../control-document/control-document.module';
import { ControlDocumentService } from './../control-document/control-document.service';
import { CurrentAccountService } from './../current-account/current-accoun.service';
import { CurrentAccountModule } from './../current-account/current-account.module';
import { DatabaseManagerModule } from './../database-manager/database-manager.module';
import { DatabaseManagerService } from './../database-manager/database-manager.service';
import { DocumentConsumerRegConsumerModule } from './../document-consumer-reg-consumer/document-consumer-reg-consumer.module';
import { DocumentConsumerRegConsumerService } from './../document-consumer-reg-consumer/document-consumer-reg-consumer.service';
import { DocumentConsumerRegEmitterModule } from './../document-consumer-reg-emitter/document-consumer-reg-emitter.module';
import { DocumentConsumerRegEmitterService } from './../document-consumer-reg-emitter/document-consumer-reg-emitter.service';
import { DocumentConsumerRegModule } from './../document-consumer-reg/document-consumer-reg.module';
import { DocumentConsumerRegService } from './../document-consumer-reg/document-consumer-reg.service';
import { DocumentConsumerModule } from './../document-consumer/document-consumer.module';
import { DocumentConsumerService } from './../document-consumer/document-consumer.service';
import { DocumentEmitterModule } from './../document-emitter/document-emitter.module';
import { DocumentEmitterService } from './../document-emitter/document-emitter.service';
import { DocumentItemModule } from './../document-item/document-item.module';
import { DocumentItemService } from './../document-item/document-item.service';
import { DocumentOnlineEmitterService } from './../document-online-emitter/document-online-emitter.service';
import { DocumentOnlineModule } from './../document-online/document-online.module';
import { DocumentOnlineService } from './../document-online/document-online.service';
import { DocumentTempModule } from './../document-temp/document-temp.module';
import { DocumentTempService } from './../document-temp/document-temp.service';
import { DocumentService } from './../document/document.service';
import { FileDocumentAdminModule } from './../file-document-admin/file-document-admin.module';
import { FileDocumentAdminService } from './../file-document-admin/file-document-admin.service';
import { FileDocumentAnalysisReportModule } from './../file-document-analysis-report/file-document-analysis-report.module';
import { FileDocumentAnalysisReportService } from './../file-document-analysis-report/file-document-analysis-report.service';
import { FileDocumentCancellationRequestModule } from './../file-document-cancellation-request/file-document-cancellation-request.module';
import { FileDocumentCancellationRequestService } from './../file-document-cancellation-request/file-document-cancellation-request.service';
import { FileDocumentDetailModule } from './../file-document-detail/file-document-detail.module';
import { FileDocumentDetailService } from './../file-document-detail/file-document-detail.service';
import { FileDocumentModule } from './../file-document/file-document.module';
import { FileDocumentService } from './../file-document/file-document.service';
import { HelpCenterModule } from './../help-center/help-center.module';
import { MailModule } from './../mail/mail.module';
import { MailService } from './../mail/mail.service';
import { OccurrenceEmitterModule } from './../occurrence-emitter/occurrence-emitter.module';
import { OccurrenceEmitterService } from './../occurrence-emitter/occurrence-emitter.service';
import { OccurrenceModule } from './../occurrence/occurrence.module';
import { OccurrenceService } from './../occurrence/occurrence.service';
import { ProfileModule } from './../profile/profile.module';
import { ProfileService } from './../profile/profile.service';
import { ReportRoutinesAnalysisFileModule } from './../report-routines-analysis-file/report-routines-analysis-file.module';
import { ReportRoutinesAnalysisFileService } from './../report-routines-analysis-file/report-routines-analysis-file.service';
import { RoutinesModule } from './../routines/routines.module';
import { RoutinesService } from './../routines/routines.service';
import { StatisticEmitterModule } from './../statistic-emitter/statistic-emitter.module';
import { StatisticEmitterService } from './../statistic-emitter/statistic-emitter.service';
import { ThirdPartyActivityModule } from './../third-party-activity/third-party-activity.module';
import { ThirdPartyActivityService } from './../third-party-activity/third-party-activity.service';
import { ThirdPartyConsumerModule } from './../third-party-consumer/third-party-consumer.module';
import { ThirdPartyConsumerService } from './../third-party-consumer/third-party-consumer.service';
import { ThirdPartyEmitterModule } from './../third-party-emitter/third-party-emitter.module';
import { ThirdPartyEmitterService } from './../third-party-emitter/third-party-emitter.service';
import { ThirdPartyModule } from './../third-party/third-party.module';
import { ThirdPartyService } from './../third-party/third-party.service';
import { TypeCertificationModule } from './../type-certification/type-certification.module';
import { TypeCertificationService } from './../type-certification/type-certification.service';
import { UserAdminModule } from './../user-admin/user-admin.module';
import { UserAdminService } from './../user-admin/user-admin.service';
import { ErrorHandlerService } from './error-handler.service';
import { AuthKeycloakService } from '../auth/services/auth-keycloak.service';

moment.locale('pt');

registerLocaleData(localePt);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    HttpClientModule,
    ToastModule,
    ConfirmDialogModule,

    AuthModule,
    UserModule,
    StartModule,
    TypeDocumentModule,
    TypeTaxModule,
    PersonModule,
    TaxpayerModule,
    TaxRateModule,
    ExemptionReasonModule,
    CertificationModule,
    CertificationEmitterModule,
    CertificationTypographyModule,
    EmissionEmitterModule,
    EmissionTypographyModule,
    EmissionDocumentModule,
    EmissionDocumentTypographyModule,
    AuthorizationModule,
    StatisticModule,
    StatisticEmitterModule,

    DocumentModule,
    DocumentConsumerModule,
    DocumentEmitterModule,
    DocumentOnlineEmitterModule,
    ControlDocumentModule,
    FileDocumentEmitterModule,
    DocumentConsumerRegModule,
    DocumentConsumerRegEmitterModule,
    DocumentConsumerRegConsumerModule,
    UserAdminModule,
    ProfileModule,
    RoutinesModule,
    AuthorizationEmitterModule,
    EmissionDocumentEmitterModule,
    AuthorizationUtilModule,
    DocumentOnlineModule,
    CertificationUtilModule,
    TutorialModule,
    MailModule,
    TypeCertificationModule,
    FileDocumentModule,
    FileDocumentAdminModule,
    CurrentAccountModule,
    DocumentTempModule,
    FileDocumentDetailModule,
    ReportRoutinesAnalysisFileModule,
    OccurrenceModule,
    OccurrenceEmitterModule,
    HelpCenterModule,
    FileDocumentAnalysisReportModule,
    DatabaseManagerModule,
    FileDocumentCancellationRequestModule,
    ThirdPartyModule,
    ThirdPartyActivityModule,
    ThirdPartyEmitterModule,
    ThirdPartyConsumerModule,
    DocumentItemModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [ToastModule, ConfirmDialogModule],
  providers: [
    Title,
    { provide: LOCALE_ID, useValue: 'pt' },

    // { provide: HTTP_INTERCEPTORS, useClass: HttpClientConfig, multi: true },

    ErrorHandlerService,
    JwtHelperService,
    MessageService,
    ConfirmationService,

    AuthService,
    AuthKeycloakService,
    PersonService,
    UserService,
    TaxpayerService,
    TaxRateService,
    ExemptionReasonService,
    TypeDocumentService,
    TypeTaxService,
    CertificationService,
    CertificationEmitterService,
    CertificationTypographyService,
    EmissionEmitterService,
    EmissionTypographyService,
    EmissionDocumentService,
    EmissionDocumentTypographyService,
    AuthorizationService,
    StatisticService,
    StatisticEmitterService,
    NotificationService,
    NotificationUserService,
    DocumentConsumerService,
    DocumentEmitterService,
    ControlDocumentService,
    DocumentService,
    DocumentOnlineEmitterService,
    FileDocumentEmitterService,
    DocumentConsumerRegService,
    DocumentConsumerRegEmitterService,
    DocumentConsumerRegConsumerService,
    UserAdminService,
    ProfileService,
    RoutinesService,
    AuthorizationEmitterService,
    EmissionDocumentEmitterService,
    AuthorizationUtilService,
    DocumentOnlineService,
    CertificationUtilService,
    MailService,
    TypeCertificationService,
    FileDocumentService,
    FileDocumentAdminService,
    CurrentAccountService,
    DocumentTempService,
    FileDocumentDetailService,
    ReportRoutinesAnalysisFileService,
    OccurrenceEmitterService,
    OccurrenceService,
    FileDocumentAnalysisReportService,
    DatabaseManagerService,
    FileDocumentCancellationRequestService,
    ThirdPartyService,
    ThirdPartyActivityService,
    ThirdPartyEmitterService,
    ThirdPartyConsumerService,
    DocumentItemService,
    TranslateService,
  ],
})
export class CoreModule {}

import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { RoutinesService } from '../routines.service';

@Component({
  selector: 'app-fix-document-by-mask',
  templateUrl: './fix-document-by-mask.component.html',
  styleUrls: ['./fix-document-by-mask.component.scss']
})
export class FixDocumentByMaskComponent implements OnInit {
  loadingFixDocumentByMask: boolean = false;
  loadingSendMailCommunicationFile: boolean = false;
  loadingSendMailCommunicationWithProblem: boolean = false;
  loadingSendMailFixMask: boolean = false;
  loadingValidFirstPhase: boolean = false;
  loadingValidSecondPhase: boolean = false;
  loadingValidThirdPhase: boolean = false;
  loadingSendNotifications: boolean = false;
  loadingCommunicateDocumentOnline: boolean = false;
  loadingGetDuplicateDocumentAndSetItAsEditable: boolean = false;
  loadingTransportSerieOnline: boolean = false;
  loadingUpdateDocumentValueToBeCanceled: boolean = false;

  constructor(
    private routinesService: RoutinesService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  fixDocumentByMask() {
    this.loadingFixDocumentByMask = true;
    this.routinesService.fixDocumentByMask()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingFixDocumentByMask = false);
  }

  sendMailConfirmationFile() {
    this.loadingSendMailCommunicationFile = true;
    this.routinesService.sendMailConfirmationFile()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingSendMailCommunicationFile = false);
  }

  sendMailCommunicationWithProblem() {
    this.loadingSendMailCommunicationWithProblem = true;
    this.routinesService.sendMailCommunicationWithProblem()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingSendMailCommunicationWithProblem = false);
  }

  sendMailFixMask() {
    this.loadingSendMailFixMask = true;
    this.routinesService.sendMailFixMask()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingSendMailFixMask = false);
  }

  validFirstPhase() {
    this.loadingValidFirstPhase = true;
    this.routinesService.validFirstPhase()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingValidFirstPhase = false);
  }

  validSecondPhase() {
    this.loadingValidSecondPhase = true;
    this.routinesService.validSecondPhase()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingValidSecondPhase = false);
  }

  validThirdPhase() {
    this.loadingValidThirdPhase = true;
    this.routinesService.validThirdPhase()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingValidThirdPhase = false);
  }

  sendNotifications() {
    this.loadingSendNotifications = true;

    this.routinesService.sendNotifications()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingSendNotifications = false);
  }

  communicateDocumentOnline() {
    this.loadingCommunicateDocumentOnline = true;

    this.routinesService.communicateDocumentOnline()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingCommunicateDocumentOnline = false);
  }

  getDuplicateDocumentAndSetItAsEditable() {
    this.loadingGetDuplicateDocumentAndSetItAsEditable = true;

    this.routinesService.getDuplicateDocumentAndSetItAsEditable()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingGetDuplicateDocumentAndSetItAsEditable = false);
  }

  transportSerieOnline() {
    this.loadingTransportSerieOnline = true;

    this.routinesService.transportSerieOnline()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingTransportSerieOnline = false);
  }

  updateDocumentValueToBeCanceled() {
    this.loadingUpdateDocumentValueToBeCanceled = true;

    this.routinesService.updateDocumentValueToBeCanceled()
      .then(() => this.messageService.add({
        severity: 'success',
        detail: 'Processado com sucesso'
      }))
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loadingUpdateDocumentValueToBeCanceled = false);
  }
}

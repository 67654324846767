<mdb-card>
  <mdb-card-header class="mdb-card-header">
    <div class="p-col-12">
      <div class="alert alert-light" role="alert">
        <p>
          <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true"></mdb-icon>
          - Ficheiro em processamento.
        </p>
        <p>
          <mdb-icon fas icon="times" class="mr-3 red-text" size="lg" aria-hidden="true"></mdb-icon>
          - O ficheiro foi processado sem êxito durante a análise.
        </p>
        <p>
          <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true"></mdb-icon>
          - O ficheiro foi processado com êxito durante a análise.
        </p>
      </div>
    </div>
  </mdb-card-header>

  <mdb-card-body class="mdb-card-body">
    <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="fileDocuments"
      [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
      [totalRecords]="total" (onLazyLoad)="onChangePage($event)">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          Total de {{ total | number:'1.0-0'}} ficheiros recolhidos.
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: '50%'}">Ficheiro</th>
          <th [style]="{width: '6%'}">Dt Registo</th>
          <th [style]="{width: '6%'}">Período</th>
          <th [style]="{width: '5%'}">1ª análise</th>
          <th [style]="{width: '5%'}">2ª análise</th>
          <th [style]="{width: '5%'}">3ª análise</th>
          <th [style]="{width: '6%'}">Status de análise</th>
          <th [style]="{width:'3em'}"></th>
          <th [style]="{width:'3em'}"></th>
          <th [style]="{width:'3em'}"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <span class="p-column-title">Ficheiro</span>
            {{ rowData.nomeArquivoDocumentoGerado }}
          </td>

          <td>
            <span class="p-column-title">Dt Registo</span>
            {{ rowData.dtCria | date:'dd/MM/yyyy' }}
          </td>

          <td>
            <span class="p-column-title">Período</span>
            {{ rowData.dtPeriodoFaturacao | date:'MMM/yyyy' }}
          </td>

          <td>
            <span class="p-column-title">1ª análise</span>
            <div *ngIf="rowData.step1 === null; else elseNull">
              <mdb-icon fas icon="sync" class="mr-2 gray-text" size="lg" aria-hidden="true">
              </mdb-icon>
            </div>

            <ng-template #elseNull>
              <div *ngIf="rowData.step1; else elseBlock">
                <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                </mdb-icon>
              </div>
              <ng-template #elseBlock>
                <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
              </ng-template>
            </ng-template>
          </td>

          <td>
            <span class="p-column-title">2ª análise</span>
            <div *ngIf="rowData.step2 === null; else elseNull2">
              <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
              </mdb-icon>
            </div>

            <ng-template #elseNull2>
              <div *ngIf="rowData.step2; else elseBlock2">
                <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                </mdb-icon>
              </div>
              <ng-template #elseBlock2>
                <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
              </ng-template>
            </ng-template>
          </td>

          <td>
            <span class="p-column-title">3ª análise</span>
            <div *ngIf="rowData.step3 === null; else elseNull3">
              <mdb-icon fas icon="sync" class="mr-2 grey-text" size="lg" aria-hidden="true">
              </mdb-icon>
            </div>

            <ng-template #elseNull3>
              <div *ngIf="rowData.step3; else elseBlock3">
                <mdb-icon fas icon="check-double" class="mr-2 teal-text" size="lg" aria-hidden="true">
                </mdb-icon>
              </div>
              <ng-template #elseBlock3>
                <mdb-icon fas icon="times" class="mr-2 red-text" size="lg" aria-hidden="true"></mdb-icon>
              </ng-template>
            </ng-template>
          </td>

          <td>
            <span class="p-column-title">Status de análise</span>
            {{ rowData.fileAnalise }}
          </td>

          <td [style]="{padding: '1px 4px'}">
            <button type="button" mdbBtn color="warning" size="sm" tooltipPosition="left"
              pTooltip="Alterar o período de facturação" class="btn-block" [style]="{padding: '4px 0'}"
              [disabled]="rowData.statusAnaliseArquivo === 'PROCESSANDO' || rowData.statusAnaliseArquivo === 'CONCLUIDO'"
              (click)="showChangePeriodDialog(rowData)">
              <i class="fa fa-calendar-alt"></i>
            </button>
          </td>

          <td [style]="{padding: '1px 4px'}">
            <button type="button" mdbBtn color="red" size="sm" tooltipPosition="left"
              pTooltip="Cancelar a comunicação do ficheiro" class="btn-block" [style]="{padding: '4px 0'}"
              [disabled]="rowData.statusAnaliseArquivo === 'PROCESSANDO' || rowData.statusAnaliseArquivo === 'CONCLUIDO'"
              (click)="showCancelFile(rowData)">
              <i class="fa fa-times-circle"></i>
            </button>
          </td>

          <td [style]="{padding: '1px 4px'}">
            <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left" pTooltip="Transferir o ficheiro"
              class="btn-block" [style]="{padding: '4px 0'}" (click)="getFile(rowData)">
              <i class="fa fa-download"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </mdb-card-body>
</mdb-card>

<p-dialog header="Alterar Período de Facturação" [(visible)]="displayPeriod" [style]="{width: '30vw'}"
  [baseZIndex]="10000" [contentStyle]="{'overflow':'visible'}">
  <form #formPeriod="ngForm" autocomplete="off" (ngSubmit)="updatePeriod()">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12">
        <label>Período de Facturação<b class="font-bold col-pink">*</b></label>
        <p-calendar name="dtPeriodoFaturacao" [(ngModel)]="fileDocument.dtPeriodoFaturacao" view="month"
          dateFormat="mm/yy" [yearNavigator]="true" yearRange="2019:2020" [locale]="locale.pt" [minDate]="minDate"
          [maxDate]="maxDate" placeholder="Informe o período de facturação" required>
        </p-calendar>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayPeriod=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="info" mdbWavesEffect [disabled]="!formPeriod.valid || loadingChangePeriod">
          ENVIAR
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingChangePeriod" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<p-dialog header="Substituir o ficheiro" [(visible)]="displayFile" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <form #formFixFile="ngForm" autocomplete="off" (ngSubmit)="fixFile()">
    <div class="p-fluid p-formgrid p-grid">

      <div class="p-field p-col-12">
        <p-fileUpload name="file" accept=".xls, .xlsx, .csv, .json" maxFileSize="100000000" customUpload="true"
          chooseLabel="Adicionar" cancelLabel="Cancelar" (onSelect)="onSelectFileEdit($event.files)" #fileUpload
          [showUploadButton]="false">
          <ng-template pTemplate="content">
          </ng-template>
        </p-fileUpload>
      </div>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayFile=false">CANCELAR</button>
        <button type="submit" mdbBtn color="info" mdbWavesEffect> ENVIAR</button>
      </div>

      <div class="p-col-12">
        <p-progressBar *ngIf="loadingChangeFile" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<p-dialog header="Anular ficheiro" [(visible)]="displayCancelFile" [style]="{width: '45vw'}" [baseZIndex]="10000"
  [contentStyle]="{'overflow':'visible'}">
  <form #formCancelFile="ngForm" autocomplete="off" (ngSubmit)="cancelFile()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="alert alert-warning p-col" role="alert">
        <p> Esta operação é irreversível e deixará de ter acesso ao ficheiro.</p>
        <p> Tem certeza que quer prosseguir? </p>
      </div>

      <table mdbTable [style]="{width:'100%'}">
        <tr>
          <th>Nome:</th>
          <td> {{fileDocument.nomeArquivoDocumentoGerado}} </td>
        </tr>
        <tr>
          <th>Período:</th>
          <td> {{fileDocument.dtPeriodoFaturacao | date:'MMMM/yyyy'}} </td>
        </tr>
        <tr>
          <th>Status:</th>
          <td> {{fileDocument.statusAnaliseArquivo}} </td>
        </tr>
      </table>

      <div class="p-col-12 text-right">
        <hr>
        <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayCancelFile=false">
          CANCELAR
        </button>

        <button type="submit" mdbBtn color="success" mdbWavesEffect
          [disabled]="!formCancelFile.valid || loadingCancelFile">
          CONFIRMAR ANULAÇÃO
        </button>
      </div>

      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-progressBar *ngIf="loadingCancelFile" mode="indeterminate" [style]="{'height': '3px'}">
        </p-progressBar>
      </div>

    </div>
  </form>
</p-dialog>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/certification/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CERTIFICAÇÃO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PARA O PORTAL E-FACTURA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      <p>
        Esta funcionalidade permite aos comerciantes solicitarem certificação
        para emitirem facturas no portal e-factura da Direcção dos Impostos.
      </p>
      <p>
        Para adquirir a certificação basta aceitar os termos e condições abaixo.
      </p>
    </div>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6" style="margin: auto">
    <mdb-card>
      <mdb-card-body>
        <div class="p-col-12">
          <h5>TERMOS E CONDIÇÕES</h5>
          <p>
            Este Contrato de Licença de Uso do Software, doravante denominado
            Contrato, é um acordo legal entre pessoa física ou jurídica,
            doravante denominada LICENCIADO, e a Direcção dos Impostos,
            doravante denominada LICENCIANTE, para o uso do Software de Emissão
            de Facturas no Portal e-Factura da Direcção dos Impostos,
            disponibilizado neste acto pelo LICENCIANTE no prazo ou limites de
            utilização determinados pelo LICENCIANTE.
          </p>
          <br />
          <p>
            Ao utilizar o SOFTWARE, mesmo que parcialmente, o LICENCIADO estará
            vinculado aos termos exigidos pelo LICENCIANTE, concordando com
            eles, principalmente no que diz respeito à CONSENTIMENTO COM O
            ACESSO, COLECTA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE
            PROTECÇÃO ÀS INFORMAÇÕES do LICENCIADO para a integral execução das
            funcionalidades oferecidas (ou disponibilizadas) pelo SOFTWARE. No
            caso de discordância dos termos aqui apresentados, a utilização do
            SOFTWARE deve ser imediatamente interrompida, sendo que as regras
            estabelecidas no Contrato permanecerão vigentes até a data do
            efectivo pedido de interrupção.
          </p>
        </div>

        <div class="p-col-12">
          <form #certificacaoOnlineForm="ngForm" (ngSubmit)="createForPortal()">
            <div class="p-col-12">
              <label>
                <strong> CONCORDO COM OS TERMOS E CONDIÇÕES </strong>
              </label>
              <br />
              <p-inputSwitch
                name="acceptTerms"
                [(ngModel)]="acceptTerms"
              ></p-inputSwitch>
            </div>
            <br />

            <div class="p-col-12 text-right">
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/emitter/certification/index"
              >
                VOLTAR
              </button>

              <button
                type="submit"
                color="pink"
                mdbBtn
                mdbWavesEffect
                [disabled]="!acceptTerms || loading"
              >
                SOLICITAR
              </button>
            </div>

            <div class="p-col-12 ui-fluid">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              ></p-progressBar>
            </div>
          </form>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  [(visible)]="display"
  [style]="{ width: '60vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
  modal="true"
>
  <p-messages [(value)]="msgs2" [enableService]="false"></p-messages>

  <div class="table-responsive-sm">
    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row" [style]="{ width: '30%' }">
            <strong>Número de certificação</strong>
          </th>
          <td class="text-left">
            {{ certificationResult?.numeroCertificacao }}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Data de certificação</strong>
          </th>
          <td class="text-left">
            {{ certificationResult?.dtCertificacao | date : "dd/MM/yyyy" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <hr />

  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="certificationResult?.autorizacaoEmissaoList"
  >
    <ng-template pTemplate="header">
      <tr class="bg-red">
        <th>Séries</th>
        <th>Documentos Solicitados</th>
        <th>Nº Autorização</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <span class="p-column-title">Série</span>
          {{
            rowData?.siglaTipoDocumentoEmissao +
              "" +
              rowData?.numSerieDocumento +
              "" +
              rowData?.anoAutorizacaoEmissao
          }}
        </td>

        <td>
          <span class="p-column-title">Documentos Solicitados</span>
          {{ rowData?.numSerieDocumento }}
        </td>

        <td>
          <span class="p-column-title">Nº Autorização</span>
          {{ rowData?.numAutorizacaoEmissao }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button
      type="button"
      mdbBtn
      color="light"
      size="md"
      mdbWavesEffect
      (click)="display = false"
    >
      FECHAR
    </button>
  </ng-template>
</p-dialog>

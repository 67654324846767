import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-validate-email-to-update',
  templateUrl: './validate-email-to-update.component.html',
  styleUrls: ['./validate-email-to-update.component.scss'],
})
export class ValidateEmailToUpdateComponent implements AfterViewInit {
  isEmailChanged: boolean = false;
  loading: boolean = false;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.loading = true;
    const userId: number = this.route.snapshot.params['userId'];

    this.profileService
      .validChangeEmail(userId)
      .then(() => (this.isEmailChanged = true))
      .catch(() => (this.isEmailChanged = false))
      .finally(() => (this.loading = false));
  }
}

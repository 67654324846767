import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-recorver-password1',
  templateUrl: './recorver-password1.component.html',
  styleUrls: ['./recorver-password1.component.scss']
})
export class RecorverPassword1Component implements OnInit {

  user = new User();
  loading = false;

  constructor(
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private router: Router,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Solicitar Nova Senha");
  }

  request(form: NgForm) {
    this.loading = true;
    this.userService.recoverPasswordStep1(this.user)
      .then(response => {
        this.router.navigate(['solicitar-senha/nova-senha', this.user.nifUsuario]);
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

}

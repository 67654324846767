import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExemptionReason } from './exemption-reason';

export class DocumentConsumerRegistration {
  idDocumentoGeradoConsumidor: number;
  numDocumento: string;
  nifConsumidor: string;
  nomeConsumidor: string;
  nifEmissor: string;
  nomeEmissor: string;
  dtEmissaoDocumento: Date;
  totalValor: string;
  dtAlter: Date;
  dtCria: Date;
  files: File[];
  numero: string;
  serie: string;
  totalImposto: string;
  totalValorBaseTributavel: string;
  tbMotivoIsencao: ExemptionReason;
  desDocumentoGeradoConsumidor: string;
  cdMotivoIsencao: string;
  status: boolean;
}

export class DocumentConsumerRegistrationForm {

  constructor(private fb: UntypedFormBuilder) { }

  builder(): UntypedFormGroup {
    return this.fb.group({
      idDocumentoGeradoConsumidor: new UntypedFormControl({ value: null, disabled: true }),
      numDocumento: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      nifEmissor: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      nifConsumidor: new UntypedFormControl({ value: null, disabled: true }),
      nomeEmissor: new UntypedFormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      nomeConsumidor: new UntypedFormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      dtEmissaoDocumento: new UntypedFormControl({ value: new Date(), disabled: true }, Validators.required),
      dtAlter: new UntypedFormControl({ value: null, disabled: true }),
      dtCria: new UntypedFormControl({ value: null, disabled: true }),
      totalValor: new UntypedFormControl({ value: 0, disabled: true }, Validators.required),
      numero: new UntypedFormControl(null, Validators.required),
      serie: new UntypedFormControl(null, Validators.required),
      totalValorBaseTributavel: new UntypedFormControl({ value: 0, disabled: true }, [Validators.min(1), Validators.required]),
      totalImposto: new UntypedFormControl({ value: 0, disabled: true }, Validators.required),
      status: new UntypedFormControl(null)
    });
  }
}

export class SimpleDocumentConsumerRegistrationForm {

  constructor(private fb: UntypedFormBuilder) { }

  builder(): UntypedFormGroup {
    return this.fb.group({
      tbMotivoIsencao: this.fb.group({
        idMotivoIsencao: new UntypedFormControl(),
        codigoMotivoIsencao: new UntypedFormControl(null, Validators.required),
        desMotivoIsencao: new UntypedFormControl(),
      }),
      desDocumentoGeradoConsumidor: new UntypedFormControl(null, Validators.required)
    });
  }
}


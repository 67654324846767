import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { CreateComponent } from './create/create.component';
import { GetComponent } from './get/get.component';
import { NewComponent } from './new/new.component';
import { ReadIssuesComponent } from './read-issues/read-issues.component';
import { ReadComponent } from './read/read.component';
import { GetStateComponent } from './get-state/get-state.component';

const routes: Routes = [
  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'new',
    component: NewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read/:id',
    component: GetComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read-state/:id',
    component: GetStateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'read-issues',
    component: ReadIssuesComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DocumentEmitterRouter { }

import { Mail } from 'src/app/core/models/mail';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MailService } from '../mail.service';

@Component({
  selector: 'app-to-all',
  templateUrl: './to-all.component.html',
  styleUrls: ['./to-all.component.scss']
})
export class ToAllComponent implements OnInit {
  mail = new Mail();
  loading = false;

  constructor(
    private mailService: MailService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  send() {
    this.loading = true;

    this.mailService.send(this.mail)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Enviado com sucesso!'
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Authorization } from './../../core/models/authorization';
import { Certification } from './../../core/models/certification';
import { InvoiceView } from './../../core/models/invoice-system';
import { TaxPayer } from './../../core/models/taxpayer';
import { TaxpayerWebService } from './../../core/models/taxpayer-webservice';
import { TypeTax } from './../../core/models/type-tax';
import { DocumentOnlineService } from './../document-online.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TaxRateService } from 'src/app/tax-rate/tax-rate.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  invoice = new InvoiceView();
  tipoImposto = new TypeTax();
  contribuinte = new TaxPayer();
  contribuinteEmitente = new TaxPayer();
  autorizacao = new Authorization();
  certificacao = new Certification();
  consumer = new TaxpayerWebService();

  taxTypeRegimeCode: number;

  constructor(
    private service: DocumentOnlineService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taxRateService: TaxRateService,
  ) { }

  ngOnInit(): void {
    const documentId = this.route.snapshot.params.id;
    this.get(documentId);
  }

  getSpecialRegimeDocumentType() {
    return this.taxTypeRegimeCode === 1116220 ? '2%' : (this.taxTypeRegimeCode === 1116210 ? '7%' : null);
  }

  get(id) {
    this.service.get(id)
      .then(async (response) => {
        try {
          const userNif = this.authService.getLoggedUser().preferred_username;
          const taxTypeResult: any = await this.taxRateService.getIvaTaxRegime(userNif);
          if (taxTypeResult.data && taxTypeResult.data.length > 0) {
            this.taxTypeRegimeCode = taxTypeResult.data[0].codigo;
          }
        } catch (e) {

        }

        this.invoice = response;
      })
      .catch(error => this.errorHandler.handle(error));
  }

}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/document/new">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RECOLHER DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DECLARAÇÃO DE NÃO FACTURAÇÃO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Esta funcionalidade permite aos emitentes declararem que não emitiram documentos durante um determinado mês.
    </div>
  </div>

  <div class="p-col-6">
    <mdb-card>
      <mdb-card-body>
        <form #controlForm="ngForm" enctype="multipart/form-data" (ngSubmit)="create()">
          <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
              <label>Número de série</label>
              <p-dropdown [options]="series" name="serieNumber" placeholder="Selecione" filter="true"
                [(ngModel)]="controlDocument.tbAutorizacaoEmissao.idAutorizacaoEmissao">
              </p-dropdown>
            </div>

            <div class="p-field p-col-12">
              <label>Período de facturação</label>
              <p-calendar name="period" [(ngModel)]="controlDocument.periodo" view="month" dateFormat="mm/yy"
                [yearNavigator]="true" yearRange="2019:2020" [locale]="locale.pt" placeholder="Período Faturação">
              </p-calendar>
            </div>

            <div class="p-field p-col-12">
              <label>Observações(Opcional)</label>
              <textarea pInputTextarea name="observacao" rows="3" [(ngModel)]="controlDocument.observacao"
                maxlength="200"></textarea>
            </div>

            <div class="p-field p-col-12 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect (click)="location.back()">VOLTAR</button>

              <button mdbBtn type="submit" color="success" mdbWavesEffect
                [disabled]="!controlForm.valid || loading">COMUNICAR</button>
            </div>

            <div class="p-field p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>

          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Validar Fatura"
  [(visible)]="showModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [closable]="false"
  [resizable]="false">
  <form autocomplete="off" [formGroup]="documentForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12" formGroupName="tbMotivoIsencao" *ngIf="canValidate">
        <label>Código de Incidência <b class="font-bold pink-text">*</b></label>
        <input
          pInputText
          class="p-inputtext-sm"
          placeholder="Motivo isenção/sujeição"
          formControlName="codigoMotivoIsencao"
          (focus)="showDisplayExemption()"
        />
      </div>
      <div class="p-field p-col-12">
        <label>Observações(Opcional) <b class="font-bold pink-text">*</b></label>
        <textarea pInputTextarea name="observacao" rows="3" formControlName="desDocumentoGeradoConsumidor"
          maxlength="200"></textarea>
      </div>
    </div>
  </form>
    
  <div class="p-col-12 text-right">
    <hr>
    <button
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
      (click)="toggleModal()"
    >
      FECHAR
    </button>

    <button
      type="button"
      mdbBtn
      color="danger"
      mdbWavesEffect
      pTooltip="Recusar fatura"
      tooltipPosition="top"
      (click)="refuseDocument()"
      [disabled]="documentForm.get('desDocumentoGeradoConsumidor').invalid"
    >
      RECUSAR
    </button>

    <button
      type="submit"
      mdbBtn
      color="success"
      mdbWavesEffect
      pTooltip="Aceitar a fatura"
      tooltipPosition="top"
      [disabled]="documentForm.invalid || !canValidate"
      (click)="acceptDocument()"
    >
      ACEITAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o código de isenção/sujeição"
  [(visible)]="displayExemption"
  [style]="{ width: '70vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="exemptionReasons"
    selectionMode="single"
    (onRowSelect)="onExemptionSelect($event)"
    [paginator]="true"
    [rows]="5"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Código</th>
        <th [style]="{ width: '40%' }">Descrição</th>
        <th [style]="{ width: '40%' }">Artigo</th>
        <th>Situação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Código</span>
          {{ rowData.codigoMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Descrição</span>
          {{ rowData.desMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Artigo</span>
          {{ rowData.artigo }}
        </td>
        <td>
          <span class="p-column-title">Situação</span>
          {{ rowData.isento ? "Isenção" : "Sujeição" }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayExemption = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { Notification } from './../core/models/notification';

export class NotificationFilter {
  page = 0;
  rows = 0;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/notificacao`;
  }

  read(filter: NotificationFilter): Promise<any> {
    return this.http.get<any>(`${this.API_URL}`)
      .toPromise()
      .then(response => {
        const content = response.content;
        this.convertFields(content);
        return {
          body: content,
          total: response.totalElements
        }
      });
  }

  get(id): Promise<Notification> {
    return this.http.get<Notification>(`${this.API_URL}/geral/${id}`)
      .toPromise()
      .then(response => {
        const notification = response as Notification;
        this.convertFields([notification]);
        return notification;
      })
  }

  private convertFields(notifications: Notification[]) {
    for (const notification of notifications) {
      if (notification.dtAlterNotificacao)
        notification.dtAlterNotificacao = moment(notification.dtAlterNotificacao,
          'YYYY-MM-DD').toDate();

      if (notification.dtCriaNotificacao)
        notification.dtCriaNotificacao = moment(notification.dtCriaNotificacao,
          'YYYY-MM-DD').toDate();

      if (notification.dtEnviarNotificacao)
        notification.dtEnviarNotificacao = moment(notification.dtEnviarNotificacao,
          'YYYY-MM-DD').toDate();

      if (notification.dtPrazoPegularizacao)
        notification.dtPrazoPegularizacao = moment(notification.dtPrazoPegularizacao,
          'YYYY-MM-DD').toDate();

      if (notification.descricaoNotificacao.length > 50) {
        notification.topicoNotificacao = notification.descricaoNotificacao.substr(0, 100) + '...';
      }
    }
  }

}

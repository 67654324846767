import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { ControlDocument } from 'src/app/core/models/control-document';
import { ControlDocumentService } from '../control-document.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  loading = false;
  locale = new Locale();

  controlDocument = new ControlDocument();

  series = [];

  constructor(
    private controlDocumentService: ControlDocumentService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    public location: Location,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    const nif = null; //= this.auth.jwtPayload.nif;
    this.dropdownSeries(nif);
  }

  dropdownSeries(nif: string) {
    this.authorizationService
      .dropdownByNif(nif)
      .then((response) => (this.series = response))
      .catch((error) => this.errorHandler.handle(error));
  }

  create() {
    this.loading = true;
    this.controlDocumentService
      .create(this.controlDocument)
      .then((response) => {
        this.controlDocument = response;
        this.messageService.add({
          severity: 'success',
          detail: 'Comunicação efetuada com sucesso.',
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }
}

import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { OccurrenceService } from './../occurrence.service';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { Occurrence } from './../../core/models/occurrence';

export enum statuses {
  PENDENTE = 'PENDENTE',
  EM_ANALISE = 'EM_ANALISE',
  FECHADO = 'FECHADO'
};

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  loading: boolean = false;
  locale = new Locale();
  occurrence = new Occurrence();
  id: number = this.route.snapshot.params.id;

  constructor(
    private occurrenceService: OccurrenceService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.get(this.id);
  }

  get(id: number) {
    this.occurrenceService.get(id)
      .then((response) => this.occurrence = response)
      .catch((error => this.errorHandler.handle(error)))
  }

  save() {
    this.loading = true;
    this.occurrence.tbSolicitacaoEstado = statuses.EM_ANALISE;
    this.occurrenceService.update(this.occurrence)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Resposta salva com sucesso.',
        });
        this.get(this.id);
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }

  answer() {
    this.loading = true;
    this.occurrence.tbSolicitacaoEstado = statuses.FECHADO;
    this.occurrenceService.update(this.occurrence)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Resposta salva e enviada para o solicitante.',
        });
        this.get(this.id);
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }
}

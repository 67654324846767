import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { GridSerieToTransportComponent } from './components/grid-serie-to-transport/grid-serie-to-transport.component';
import { GridSerieTransportedComponent } from './components/grid-serie-transported/grid-serie-transported.component';
import { EmissionDocumentEmitterRouter } from './emission-document-emitter-router';
import { ReadForThirdComponent } from './read-for-third/read-for-third.component';
import { ReadComponent } from './read/read.component';
import { TransportSerieFastComponent } from './transport-serie-fast/transport-serie-fast.component';
import { TransportSerieManuallyComponent } from './transport-serie-manually/transport-serie-manually.component';

@NgModule({
  declarations: [
    ReadComponent,
    ReadForThirdComponent,
    GridSerieToTransportComponent,
    GridSerieTransportedComponent,
    TransportSerieFastComponent,
    TransportSerieManuallyComponent,
  ],
  imports: [
    CommonModule,
    EmissionDocumentEmitterRouter,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    DialogModule,
    SidebarModule,
    InputNumberModule,
    ScrollPanelModule,
    TagModule,
  ],
  exports: [GridSerieToTransportComponent, GridSerieTransportedComponent],
})
export class EmissionDocumentEmitterModule {}

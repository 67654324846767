import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { Certification } from './../core/models/certification';

export class CertificationFilter {
  page = 0;
  rows = 15;
  nifEmitter: string;
  initialCertificationDate: Date;
  finalCertificationDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class CertificationService {

  CERTIFICATION_URL: string;

  constructor(
    private http: AppHttp,
    private httpClient: HttpClient
  ) {
    this.CERTIFICATION_URL = `${environment.apiUrl}/pedidosCertificacao`;
  }

  read(filter: CertificationFilter) {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmitter)
      params = params.append('nifRequerente', filter.nifEmitter);

    if (filter.initialCertificationDate)
      params = params.append('dtCriaPedidoCertificacaoDe', moment(filter.initialCertificationDate).format('YYYY-MM-DD'));

    if (filter.finalCertificationDate)
      params = params.append('dtCriaPedidoCertificacaoAte', moment(filter.finalCertificationDate).format('YYYY-MM-DD'));

    return this.http.get<any>(`${this.CERTIFICATION_URL}`, { params })
      .toPromise()
      .then(response => {
        return {
          content: response.content,
          total: response.totalElements
        };
      });
  }

  async getById(id: number): Promise<Certification> {
    const response = await this.http.get(`${this.CERTIFICATION_URL}/${id}`)
      .toPromise();
    const certification = response as Certification;
    CertificationService.convertData([certification]);
    return certification;
  }

  getByTaxpayerId(taxpayerId: number): Promise<Certification> {
    return this.http.get(`${this.CERTIFICATION_URL}/pesquisarPorTipoEIdContribuinte/${taxpayerId}`)
      .toPromise()
      .then(response => {
        const certification = response as Certification;
        CertificationService.convertData([certification]);
        return certification;
      })
  }

  readByEmtter(filtro: CertificationFilter): Promise<any> {
    const params = new URLSearchParams();
    params.set('page', filtro.page.toString());
    params.set('size', filtro.rows.toString());

    return this.http.get<any>(`${this.CERTIFICATION_URL}/porEmissor`, { search: params })
      .toPromise()
      .then(response => {
        return {
          content: response.content,
          total: response.totalElements
        };
      });
  }

  valid(id: number, active: boolean): Promise<void> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json');

    return this.http.put(`${this.CERTIFICATION_URL}/${id}/ativo`, active, { headers })
      .toPromise()
      .then(() => null);
  }

  createByTypography(formData): Observable<any> {
    return this.httpClient.post(`${this.CERTIFICATION_URL}/tipografia`, formData, {
      observe: 'response'
    })
  }

  createByEmitter(certificacao: Certification): Promise<any> {
    return this.http.post(`${this.CERTIFICATION_URL}/empresa`, certificacao, { observe: "response" })
      .toPromise()
  }

  createByEmitterPortal(certificacao: Certification): Promise<any> {
    return this.http.post(`${this.CERTIFICATION_URL}/online`, certificacao)
      .toPromise()
  }

  readTypographyCertified(): Promise<any> {
    return this.http.get<any>(`${this.CERTIFICATION_URL}/tipografiaCertificada`)
      .toPromise()
      .then(response => {
        return {
          content: response.content,
          total: response.totalElements
        };
      });
  }

  private static convertData(certificatios: Certification[]) {
    for (const certification of certificatios) {
      certification.dtCriaPedidoCertificacao = moment(certification.dtCriaPedidoCertificacao,
        'YYYY-MM-DD').toDate();

      if (certification.dtCertificacao) {
        certification.dtCertificacao = moment(certification.dtCertificacao,
          'YYYY-MM-DD').toDate();
      }
    }
  }

}

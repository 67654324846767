import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { TaxRateService } from '../tax-rate.service';

interface TaxRate {
  idTaxaAplicavel: number;
  descTaxaAplicavel: string;
  valorTaxaAplicavel: number;
  tbTipoImposto: {
    idTipoImposto: number;
  };
}

@Component({
  selector: 'app-update-tax-rate',
  templateUrl: './update-tax-rate.component.html',
  styleUrls: ['./update-tax-rate.component.scss'],
})
export class UpdateTaxRateComponent implements OnInit {
  @Output('updateList') updateList = new EventEmitter<void>();
  @Input() taxRate: TaxRate;
  @Input() taxTypes = [];

  display = false;
  loading = false;

  form = new FormGroup({
    descTaxaAplicavel: new FormControl('', [Validators.required]),
    valorTaxaAplicavel: new FormControl(null, [Validators.required]),
    idTipoImposto: new FormControl(null, [Validators.required]),
  });

  constructor(
    private taxRateService: TaxRateService,

    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {}

  showDialog() {
    this.form.patchValue({
      descTaxaAplicavel: this.taxRate.descTaxaAplicavel,
      valorTaxaAplicavel: this.taxRate.valorTaxaAplicavel,
      idTipoImposto: this.taxRate.tbTipoImposto.idTipoImposto,
    });
    this.display = true;
  }

  hideDialog() {
    this.display = false;
  }

  update() {
    this.loading = true;
    const body = this.form.value;
    this.taxRateService
      .update(this.taxRate.idTaxaAplicavel, body)
      .then(() => {
        this.updateList.emit();
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => (this.loading = true));
  }
}

import { Injectable } from '@angular/core';
import { AppHttp } from '../auth/app-http';
import { environment } from 'src/environments/environment';
import { TypeDocument } from '../core/models/type-document';

@Injectable({
  providedIn: 'root'
})
export class TypeDocumentService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/tipoDocumentoEmissao`;
  }

  read(): Promise<any> {
    return this.http.get<any>(this.API_URL)
      .toPromise()
      .then(response => <TypeDocument[]>response.content);
  }

  listarPorNif(): Promise<any> {
    return this.http.get(`${this.API_URL}/lista/nifCliente`)
      .toPromise();
  }

  listarOnlinePorNif(): Promise<any> {
    return this.http.get<any>(`${this.API_URL}/listaOnline/nifCliente`)
      .toPromise()
      .then(response => <TypeDocument[]>response);
  }

  dropdown() {
    return this.listarPorNif()
      .then(response => {
        return response.map(item => ({
          label: item.descTipoDocumentoEmissao,
          value: item.idTipoDocumentoEmissao,
        }));
      });
  }

  dropdownOnline() {
    return this.listarOnlinePorNif()
      .then(response => {
        return response.map(item => ({
          label: item.descTipoDocumentoEmissao,
          value: {
            idTipoDocumentoEmissao: item.idTipoDocumentoEmissao,
            descTipoDocumentoEmissao: item.descTipoDocumentoEmissao,
            siglaTipoDocumentoEmissao: item.siglaTipoDocumentoEmissao
          },
        }));
      });
  }

  dropdownGet() {
    return this.listarPorNif()
      .then(response => {
        return response.map(item => ({
          label: item.descTipoDocumentoEmissao,
          value: item.idTipoDocumentoEmissao,
        }));
      });
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <app-menu-agent></app-menu-agent>
  </div>

  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">

        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/document-consumer/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS REGISTADOS PELO ADQUIRENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>

      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Esta funcionalidade permite aos adquirentes comunicarem as suas facturas e outros documentos
      equivalentes que não foram comunicados pelos seus emitentes.
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" [formGroup]="documentForm">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-d-flex justify-content-end">
              <p-tag [severity]="getDocumentStatus()" [value]="getDocumentStatusLabel()"></p-tag>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>NIF do Emitente</label>
              <input pInputText class="p-inputtext-sm" formControlName="nifEmissor">
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome do Emitente</label>
              <input pInputText class="p-inputtext-sm" formControlName="nomeEmissor">
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>NIF do Adquirente</label>
              <input pInputText class="p-inputtext-sm" formControlName="nifConsumidor">
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome do Adquirente</label>
              <input pInputText class="p-inputtext-sm" formControlName="nomeConsumidor">
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Referência</label>
              <input pInputText class="p-inputtext-sm" formControlName="numDocumento">
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Emissão</label>
              <p-calendar formControlName="dtEmissaoDocumento" [readonlyInput]="true" dateFormat="dd/mm/yy"></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Registo</label>
              <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy"
                formControlName="dtCria"></p-calendar>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Alteração</label>
              <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy"
              formControlName="dtAlter"></p-calendar>
            </div>

            <div class="p-field p p-col-12 p-md-4">
              <label>Montante <b class="font-bold col-pink">*</b></label>
              <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2"
                name="totalValorBaseTributavel" formControlName="totalValorBaseTributavel">
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>IVA</label>
              <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2" name="totalImposto"
                formControlName="totalImposto">
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Valor Total</label>
              <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2" name="totalValor"
              formControlName="totalValor" disabled>
              </p-inputNumber>
            </div>
            <div class="p-field p-col-12">
              <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="files"
                [responsive]="true" [lazy]="true" >
                <ng-template pTemplate="header">
                  <tr>
                    <th [style]="{width: '90%'}">Ficheiros</th>
                    <th [style]="{width: '3em'}"></th>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>
                      <span class="p-column-title">Ficheiro</span>
                      <span>{{ rowData.nomeArquivoDocumentoGerado }}</span>
                    </td>

                    <td [style]="{padding: '1px 4px'}">
                      <button type="button" mdbBtn color="light" size="sm" tooltipPosition="left"
                        pTooltip="Transferir o ficheiro" class="btn-block" [style]="{padding: '4px'}"
                        (click)="downloadFile(rowData)">
                        <i class="fa fa-download"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="p-field p-col-12 ">
              <hr>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Série <b class="font-bold pink-text">*</b></label>
              <input pInputText class="p-inputtext-sm" formControlName="serie">
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Número Sequencial <b class="font-bold pink-text">*</b></label>
              <input pInputText class="p-inputtext-sm" formControlName="numero">
            </div>
            <div class="p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>
            <div class="p-col-12 text-right">
              <hr>
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/document-consumer/read"
              >
                VOLTAR
              </button>

              <button
                type="button"
                mdbBtn
                color="info"
                mdbWavesEffect
                pTooltip="Verificar fatura"
                tooltipPosition="top"
                (click)="verify()"
                [disabled]="documentForm.invalid || loading"
              >
                VERIFICAR
              </button>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
<app-modal
  [canValidate]="canValidate"
  [showModal]="showValidationModal"
  (onClose)="toggleValidationModal(false)"
  (onAccept)="save($event)"
  (onRefuse)="refuse($event)"
  [documentForm]="validationDocumentForm"
></app-modal>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { ChangeEmitterComponent } from './change-emitter/change-emitter.component';
import { GetComponent } from './get/get.component';
import { IncrementComponent } from './increment/increment.component';
import { ReadComponent } from './read/read.component';
import { TransportComponent } from './transport/transport.component';

const routes: Routes = [
  {
    path: 'read',
    component: ReadComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },
  {
    path: 'get/:id',
    component: GetComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },
  {
    path: 'increment',
    component: IncrementComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },
  {
    path: 'transport',
    component: TransportComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  },
  {
    path: 'change-emitter',
    component: ChangeEmitterComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_PESQUISAR_POR_EMISSOR_PEDIDO_EMISSAO_DOCUMENTO'] }
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmissionDocumentTypographyRouter { }

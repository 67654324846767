import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  const options: KeycloakOptions = {
    config: environment.keycloakConfig,
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'check-sso',
      checkLoginIframe: false,
    },
    bearerExcludedUrls: environment.bearerExcludedUrls,
  };

  return () => keycloak.init(options);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { DocumentConsumerRegRouter } from './document-consumer-reg-router';
import { ReadComponent } from './read/read.component';
import { GetComponent } from './get/get.component';
import { LayoutModule } from '../layout/layout.module';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [ReadComponent, GetComponent, ModalComponent],
  imports: [
    CommonModule,
    DocumentConsumerRegRouter,
    FormsModule,
    ReactiveFormsModule,

    LayoutModule,

    SharedModule,
    MDBBootstrapModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DialogModule,
    OverlayPanelModule,
    DropdownModule,
    InputMaskModule,
    CurrencyMaskModule,
    InputNumberModule,
    TabViewModule
  ]
})
export class DocumentConsumerRegModule { }

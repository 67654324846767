import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { LayoutModule } from '../layout/layout.module';
import { GetComponent } from './get/get.component';
import { NotificationRouter } from './notification-router';
import { ReadComponent } from './read/read.component';

@NgModule({
  declarations: [
    ReadComponent,
    GetComponent,
  ],
  imports: [
    CommonModule,
    NotificationRouter,
    MDBBootstrapModule,

    OverlayPanelModule,
    TableModule,
    NotificationRouter,
    SelectButtonModule,
    ScrollPanelModule
  ],
})
export class NotificationModule { }

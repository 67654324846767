import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from './../shared/shared.module';
import { AuthorizationReadTotalSerieForEmitterComponent } from './authorization-read-total-serie-for-emitter/authorization-read-total-serie-for-emitter.component';
import { AuthorizationRouter } from './authorization-router';

@NgModule({
  declarations: [
    AuthorizationReadTotalSerieForEmitterComponent
  ],
  imports: [
    CommonModule,
    AuthorizationRouter,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    LayoutModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    DialogModule
  ],
})
export class AuthorizationModule { }

import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { StatisticEmitterFilter, StatisticEmitterService } from '../statistic-emitter.service';
import { StatisticEmitter } from './../../core/models/statistic-emitter';

@Component({
  selector: 'app-statistic-emitter-detail-short',
  templateUrl: './statistic-emitter-detail-short.component.html',
  styleUrls: ['./statistic-emitter-detail-short.component.scss']
})
export class StatisticEmitterDetailShortComponent implements OnInit {
  statistic = new StatisticEmitter();
  filter = new StatisticEmitterFilter();

  constructor(
    private service: StatisticEmitterService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
  }

  get({ emitterNif, startDate, endDate }): void {
    this.filter.emitterNif = emitterNif;
    this.filter.period = [startDate, endDate];

    this.service.get(this.filter)
      .then(response => this.statistic = response)
      .catch(error => this.errorHandler.handle(error))
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AppHttp } from './../auth/app-http';
import { Notification } from './../core/models/notification';

export class NotificationUserFilter {
  page = 0;
  rows = 50;

  btLida: string = null;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationUserService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/notificacao/porContribuinte`;
  }

  read(filter: NotificationUserFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.btLida !== null) {
      params = params.append('btLida', filter.btLida);
    }

    return this.http.get<any>(`${this.API_URL}`, { params })
      .toPromise()
      .then((response) => {
        const content = response.content;
        this.convertFields(content)
        return {
          body: content,
          total: response.totalElements
        }
      });
  }

  get(id): Promise<Notification> {
    return this.http.get<Notification>(`${this.API_URL}/${id}`)
      .toPromise()
      .then(response => {
        const notification = response as Notification
        this.convertFields([notification]);
        return notification;
      })
  }

  alert() {
    return this.http.get<any>(`${this.API_URL}/naoLida`)
      .toPromise()
      .then(response => {
        const content = response.content;
        this.convertFields(content);
        return {
          body: content,
          total: response.totalElements
        }
      });
  }

  private convertFields(notifications: Notification[]) {
    for (const notification of notifications) {
      if (notification.dtAlterNotificacao)
        notification.dtAlterNotificacao = moment(notification.dtAlterNotificacao,
          'YYYY-MM-DD').toDate();

      if (notification.dtCriaNotificacao)
        notification.dtCriaNotificacao = moment(notification.dtCriaNotificacao,
          'YYYY-MM-DD').toDate();

      if (notification.dtEnviarNotificacao)
        notification.dtEnviarNotificacao = moment(notification.dtEnviarNotificacao,
          'YYYY-MM-DD').toDate();

      if (notification.dtPrazoPegularizacao)
        notification.dtPrazoPegularizacao = moment(notification.dtPrazoPegularizacao,
          'YYYY-MM-DD').toDate();

      if (notification.descricaoNotificacao) {
        if (notification.descricaoNotificacao.length > 50) {
          notification.topicoNotificacao = notification.descricaoNotificacao.substr(0, 50) + '...';
        }
      }
    }
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CERTIFICAÇÃO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Este módulo permite solicitar certificação para emitir facturas bem como consultar os dados da
      sua certificação.
    </div>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" (click)="display=true" href="javascript:;">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-clipboard-check light-blue-text"></i></div>
        <h4 class="title">
          Pedir Certificação
        </h4>
        <p class="description">
          Pedido de certificação para tipografias e agentes com software de facturação.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/certification/read">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-search light-blue-text"></i></div>
        <h4 class="title">
          Meus pedidos de Certificação
        </h4>
        <p class="description">
          Verificação dos detalhes do seu pedido de certificação.
        </p>
      </div>
    </a>
  </div>
</div>

<p-dialog header="Selecione o tipo de certificação que deseja solicitar" [(visible)]="display"
  [style]="{width: '90vw', height: 'auto'}" [baseZIndex]="10000"
  [contentStyle]="{'overflow':'scroll', background: '#eee'}" modal="true" [showHeader]="true">
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-4">
      <a class="a-box" routerLink="/emitter/certification/create">
        <div class="box wow fadeInRight" data-wow-delay="0.2s">
          <div class="icon"><i class="fa fa-certificate teal-text"></i></div>
          <h4 class="title">Para Softwares</h4>
          <p class="description">
            Permite aquisição de certificação para emitentes com Softwares de facturação electrónica.
          </p>
        </div>
      </a>
    </div>

    <div class="p-col-12 p-md-6 p-lg-4">
      <a class="a-box" routerLink="/emitter/certification/create/portal">
        <div class="box wow fadeInRight" data-wow-delay="0.2s">
          <div class="icon"> <i class="fa fa-blog red-text"></i></div>
          <h4 class="title"> Para Portal e-Factura </h4>
          <p class="description">
            Permite adquirir certificação para emitir facturas e documentos equivalentes no portal e-Factura.
          </p>
        </div>
      </a>
    </div>

    <div class="p-col-12 p-md-6 p-lg-4">
      <a class="a-box" routerLink="/typography/certification/create">
        <div class="box wow fadeInLeft" data-wow-delay="0.2s">
          <div class="icon"><i class="fa fa-text-height blue-text"></i></div>
          <h4 class="title">Para Tipografias</h4>
          <p class="description">
            Permite as tipografias adquirirem certificação para imprimir blocos de facturas aos seus clientes.
          </p>
        </div>
      </a>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <hr>
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="display=false">
      CANCELAR
    </button>
  </div>
</p-dialog>

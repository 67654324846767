import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';

export class CertificationUtilFilter {
  page = 0;
  rows = 15;
}

@Injectable({
  providedIn: 'root'
})
export class CertificationUtilService {
  API_URL: string;

  constructor(
    private httpClient: HttpClient,
    private http: AppHttp
  ) {
    this.API_URL = `${environment.apiUrl}/pedidosCertificacao`;
  }

  readCertified(filter: CertificationUtilFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    return this.http.get<any>(`${this.API_URL}/tipografiaCertificada`, { params })
      .toPromise()
      .then(response => {
        return {
          body: response.content,
          total: response.totalElements
        };
      });
  }
}

<p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="creditAndDebitNotesItems"
  [rows]="10">
  <ng-template pTemplate="header">
    <tr>
      <th>Nº Documento</th>
      <th>Descrição</th>
      <th>Subtotal</th>
      <th>Taxa (ISC)</th>
      <th>Código i/s</th>
      <th>ISC</th>
      <th>Total</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData>
    <tr [pSelectableRow]="rowData">
      <td>
        <span class="p-column-title">Nº Documento</span>
        {{rowData.tbDocumentoGerado.numDocumento}}
      </td>
      <td>
        <span class="p-column-title">Descrição</span>
        {{rowData.descItens}}
      </td>
      <td>
        <span class="p-column-title">Subtotal</span>
        {{rowData.taxAplicavelItens}}
      </td>
      <td>
        <span class="p-column-title">Taxa (ISC)</span>
        {{rowData.tbTaxaAplicavel.descTaxaAplicavel}}
      </td>
      <td>
        <span class="p-column-title">Código i/s</span>
        <span [pTooltip]="rowData.tbMotivoIsencao.desMotivoIsencao" tooltipPosition="button">
          {{rowData.tbMotivoIsencao.codigoMotivoIsencao}}
        </span>
      </td>
      <td>
        <span class="p-column-title">ISC</span>
        {{rowData.valorImposto}}
      </td>
      <td>
        <span class="p-column-title">Total</span>
        {{rowData.valorItens}}
      </td>
    </tr>
  </ng-template>
</p-table>

import { ThirdPartyView } from './../../core/models/third-party';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  LazyLoadEvent,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { ThirdPartyActivity } from 'src/app/core/models/third-party-activity';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { ThirdPartyActivityService } from './../../third-party-activity/third-party-activity.service';
import {
  ThirdPartyConsumerFilter,
  ThirdPartyConsumerService,
} from './../third-party-consumer.service';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class ReadComponent implements OnInit {
  locale = new Locale();
  loading: boolean = true;
  visibleSidebarFilter: boolean = false;
  thirdParties = [];
  total = 0;
  filter = new ThirdPartyConsumerFilter();
  activities: ThirdPartyActivity[] = [];

  nif: string; // = this.authService.jwtPayload.nif;

  constructor(
    private thirdPartyConsumerService: ThirdPartyConsumerService,
    private thirdPartyActivityService: ThirdPartyActivityService,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService,
    private confirmation: ConfirmationService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.readActivities();
  }

  readActivities(): void {
    this.thirdPartyActivityService
      .read()
      .then(
        (activities) =>
          (this.activities = activities.map((activity: ThirdPartyActivity) => ({
            value: activity.id,
            label: activity.label,
          })))
      )
      .catch((error) => this.errorHandler.handle(error));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.thirdPartyConsumerService
      .read(this.filter)
      .then((response) => {
        this.total = response.total;
        this.thirdParties = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  resetFormFilter(form: NgForm) {
    form.reset();
    this.read();
  }

  accept(thirdPartyView: ThirdPartyView) {
    let message: string;

    thirdPartyView.aceitoPri
      ? (message = `Deseja ativar essa autorização?`)
      : (message = `Deseja desativar essa autorização?`);

    this.confirmation.confirm({
      message: message,
      reject: () => {
        thirdPartyView.aceitoPri = !thirdPartyView.aceitoPri;
      },
      accept: () => {
        this.thirdPartyConsumerService
          .accept(thirdPartyView)
          .then(() => {
            this.message.add({
              severity: 'success',
              detail: 'Processado com sucesso',
            });
            this.read();
          })
          .catch((error) => {
            this.errorHandler.handle(error);
            thirdPartyView.aceitoPri = !thirdPartyView.aceitoPri;
          });
      },
    });
  }
}

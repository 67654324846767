import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Occurrence } from './../../core/models/occurrence';
import { OccurrenceService } from './../../occurrence/occurrence.service';
import { OccurrenceEmitterService } from './../occurrence-emitter.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  loading: boolean = false;
  locale = new Locale();
  occurrence = new Occurrence();

  id = this.route.snapshot.params.id;

  constructor(
    private occurrenceEmitterService: OccurrenceEmitterService,
    private occurrenceService: OccurrenceService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.occurrenceService.get(this.id)
      .then((result) => this.occurrence = result)
      .catch((err) => this.errorHandler.handle(err))
  }

  update() {
    this.loading = true;
    this.occurrenceService.update(this.occurrence)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Solicitação atualizada com sucesso',
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }
}

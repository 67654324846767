import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { FileDocument } from './../../core/models/file-document';
import {
  FileDocumentEmitterFilter,
  FileDocumentEmitterService
} from './../../file-document-emitter/file-document-emitter.service';

@Component({
  selector: 'app-get-file-document',
  templateUrl: './get-file-document.component.html',
  styleUrls: ['./get-file-document.component.scss']
})
export class GetFileDocumentComponent implements OnInit {
  loading: boolean = true;
  filter = new FileDocumentEmitterFilter();
  total = 0;
  fileDocuments = [];

  constructor(
    private service: FileDocumentEmitterService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  read(page = 0) {
    this.loading = true;
    this.filter.page = page;
    this.service.read(this.filter)
      .then(response => {
        this.total = response.total;
        this.fileDocuments = response.body;
      })
      .catch(erro => this.errorHandler.handle(erro))
      .finally(() => this.loading = false);
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  getFile(file: FileDocument) {
    this.service.getFile(file)
      .then(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
      })
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <strong>FACTURAÇÃO</strong>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-center">
        Base de Facturação
        <mdb-badge pill="true" primary="true" size="lg">
          {{statistic.ttValoresBaseTributavelFaturasEmitidas | currency:'STN'}}
        </mdb-badge>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-center">
        Imposto Sobre Consumo
        <mdb-badge pill="true" info="true">
          {{statistic.ttValoresImpostoSCFaturasEmitidas | currency:'STN'}}
        </mdb-badge>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-center">
        Imposto de Selo
        <mdb-badge pill="true" info="true">
          {{statistic.ttValoresImpotosSeloFaturasEmitidas | currency:'STN'}}
        </mdb-badge>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-center">
        Valor Total
        <mdb-badge pill="true" primary="true">
          {{statistic.ttValoresFaturasEmitidas | currency:'STN'}}
        </mdb-badge>
      </li>
    </ul>
  </div>

  <div class="p-col-12">
    <p-accordion>
      <p-accordionTab header="Mais informações">
        <div class="p-grid">
          <div class="p-col-6">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>NOTA DE CRÉDITO</strong>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Base de Notas de Crédito
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttValoresBaseTributavelNotaCredito | currency:'STN'}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Imposto Sobre Consumo
                <mdb-badge pill="true" info="true">
                  {{statistic.ttValoresIscNotaCretito | currency:'STN'}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Imposto de Selo
                <mdb-badge pill="true" info="true">
                  {{statistic.ttValoresImpostoSeloNotaCredito | currency:'STN'}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Valor Total
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttValoresNotaCredito | currency:'STN'}}
                </mdb-badge>
              </li>
            </ul>
          </div>

          <div class="p-col-6">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>NOTA DE DÉBITO</strong>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Valor Base
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttValoresBaseTributavelNotaDebito | currency:'STN'}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Imposto Sobre Consumo
                <mdb-badge pill="true" info="true">
                  {{statistic.ttValoresIscNotaDebito | currency:'STN'}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Imposto de Selo
                <mdb-badge pill="true" info="true">
                  {{statistic.ttValoresImpostoSeloNotaDebito | currency:'STN'}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Valor Total
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttValoresNotaDebito | currency:'STN'}}
                </mdb-badge>
              </li>
            </ul>
          </div>

          <div class="p-col-6">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>DOCUMENTOS</strong>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Total Comunicados
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttDocumentoGerado | number}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Comunicados Por Ficheiro
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttDocumentoComunicadoFicheiro | number}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Comunicados Por Unidade
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttDocumentoRegistadosUnidade | number}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Emitidos No Portal
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttDocumentoGeradoOnline | number}}
                </mdb-badge>
              </li>

              <li class="list-group-item d-flex justify-content-between align-items-center">
                Série Solicitadas
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttSerie | number}}
                </mdb-badge>
              </li>
            </ul>
          </div>

          <div class="p-col-6">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>FICHEIROS</strong>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Comunicados
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttFicheirosComunicados | number}}
                </mdb-badge>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Na 1ª Fase de Análise
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttFicheirosPrimentoFase | number}}
                </mdb-badge>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Na 2ª Fase de Análise
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttFicheirosSegundaFase | number}}
                </mdb-badge>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Rejeitados
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttFicheirosRejeitados | number}}
                </mdb-badge>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Processados
                <mdb-badge pill="true" primary="true">
                  {{statistic.ttFicheirosConcluidosFase | number}}
                </mdb-badge>
              </li>
            </ul>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMIN
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          RELATÓRIO DAS ROTINAS DE ANÁLISE DOS FICHEIRO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header text-right">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtInicioDe" [(ngModel)]="filter.dtInicioDe"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtInicioAte" [(ngModel)]="filter.dtInicioAte"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtFimDe" [(ngModel)]="filter.dtFimDe"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtFimAte" [(ngModel)]="filter.dtFimAte"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>

            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="reports"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" dataKey="idRelatorioRotinasAnaliseFicheiro" editMode="row"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} resultados encontrados.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>ID</th>
              <th>Fase</th>
              <th>Data inicial</th>
              <th>Data final</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">ID</span>
                {{ rowData.idRelatorioRotinasAnaliseFicheiro }}
              </td>

              <td>
                <span class="p-column-title">Fase</span>
                {{ rowData.faseRelatorio }}
              </td>

              <td>
                <span class="p-column-title">Data inicial</span>
                {{ rowData.dtInicio | date:'medium' }}
              </td>

              <td>
                <span class="p-column-title">Data final</span>
                {{ rowData.dtFim | date:'medium' }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

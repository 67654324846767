import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validate-account-get',
  templateUrl: './validate-account-get.component.html',
  styleUrls: ['./validate-account-get.component.scss']
})
export class ValidateAccountGetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from '../auth/app-http';
import { environment } from './../../environments/environment';
import { Occurrence } from './../core/models/occurrence';

export class OccurrenceFilter {
  page = 0;
  rows = 15;

  requesterUserNif: string;
  recipientUserNif: string;

  subject: string;

  answeredAt: Date[];
  createdAt: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class OccurrenceService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/solicitacao`;
  }

  read(filter: OccurrenceFilter) {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.subject)
      params = params.append('assunto', filter.subject);

    if (filter.answeredAt) {
      if (!!filter.answeredAt[0])
        params = params.append('dtRespostaDe', moment(filter.answeredAt[0]).format('YYYY-MM-DD'));
      if (!!filter.answeredAt[1])
        params = params.append('dtRespostaAte', moment(filter.answeredAt[1]).format('YYYY-MM-DD'));
    }

    if (filter.createdAt) {
      if (!!filter.createdAt[0])
        params = params.append('dtCriaDe', moment(filter.createdAt[0]).format('YYYY-MM-DD'));
      if (!!filter.createdAt[1])
        params = params.append('dtCriaAte', moment(filter.createdAt[1]).format('YYYY-MM-DD'));
    }

    return this.http.get<any>(this.API_URL, { params })
      .toPromise()
      .then(response => {
        this.convertFields(response.content);
        return {
          content: response.content,
          total: response.totalElements
        }
      });
  }

  create({ assunto, detalhe }: Occurrence): Promise<Occurrence> {
    return this.http.post<Occurrence>(this.API_URL, { assunto, detalhe })
      .toPromise()
  }

  get(id: number): Promise<Occurrence> {
    return this.http.get<Occurrence>(`${this.API_URL}/${id}`)
      .toPromise()
      .then((response) => {
        const occurrence = response as Occurrence;
        this.convertFields([occurrence]);
        return occurrence;
      })
  }

  update(occurrence: Occurrence): Promise<Occurrence> {
    const { assunto, detalhe, resposta, tbSolicitacaoEstado, id } = occurrence;
    return this.http.put<Occurrence>(
      this.API_URL,
      { assunto, detalhe, resposta, tbSolicitacaoEstado, id }
    ).toPromise()
  }

  private convertFields(occurrences: Occurrence[]) {
    for (const occurrence of occurrences) {
      // occurrence.dataCria = moment(occurrence.dataCria, 'YYYY-MM-DD HH:mm:ss')
      //   .toDate();

      // occurrence.dataAlter = moment(occurrence.dataAlter, 'YYYY-MM-DD HH:mm:ss')
      //   .toDate();

      // occurrence.dataResposta = moment(occurrence.dataResposta, 'YYYY-MM-DD HH:mm:ss')
      //   .toDate();

      switch (occurrence.tbSolicitacaoEstado) {
        case 'PENDENTE':
          occurrence.status = 'Pendente';
          occurrence.statusColor = 'p-tag-warning'
          break;

        case 'EM_ANALISE':
          occurrence.status = 'Em análise';
          occurrence.statusColor = 'p-tag-info'
          break;

        case 'FECHADO':
          occurrence.status = 'Fechado';
          occurrence.statusColor = 'p-tag-success'
          break;

        default:
          occurrence.status = 'Pendente';
          occurrence.statusColor = 'p-tag-warning';
          break;
      }
    }
  }
}

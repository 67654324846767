import { Injectable } from '@angular/core';
import { AppHttp } from '../auth/app-http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../core/models/user';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

export class UserFilter {
  page = 0;
  rows = 15;

  nifUsuario: string;
  nomePessoa: string;
  emailUsuario: string;
  isFirst: boolean;
  dataCriaUsuarioDe: Date;
  dataCriaUsuarioAte: Date;

  range: any;
  category: any;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  API_URL: string;

  constructor(
    private http: AppHttp,
    private httpClient: HttpClient
  ) {
    this.API_URL = `${environment.apiUrl}/usuarios`;
  }

  async create(utilizador: User): Promise<User> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic YWRtaW5AZWZhdHVyYS5nb3Yuc3Q6YWRtaW4=');
    headers = headers.append('Content-Type', 'application/json');

    const response = await this.httpClient.post<User>(this.API_URL, JSON.stringify(utilizador), { headers })
      .toPromise();
    return response as User;
  }

  async get(id: number): Promise<User> {
    const response = await this.http.get<User>(`${this.API_URL}/${id}`)
      .toPromise();
    const user = response as User;
    this.convertFields([user]);
    return user;
  }

  async recoverPasswordStep1(user: User): Promise<User> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const response = await this.httpClient.put<User>(`${this.API_URL}/recuperarSenha`, user, { headers })
      .toPromise();
    return response;
  }

  async recoverPasswordStep2(user: User): Promise<User> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const response = await this.httpClient.put<User>(`${this.API_URL}/alterarSenhaRecuperacao`, user, { headers })
      .toPromise();
    return response;
  }

  changePassword(user: User): Promise<User> {
    delete user.statusColor;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.put<User>(`${this.API_URL}/alterarSenha/${user.idUsuario}`, user, { headers })
      .toPromise();
  }

  async changeEmail(user: User): Promise<User> {
    delete user.statusColor;

    return await this.http.put<User>(`${this.API_URL}/changeEmail`, user)
      .toPromise();
  }

  async validateEmailChanged({ nif, email }): Promise<void> {
    await this.http.put(`${this.API_URL}/changeEmail`, { nif, email })
      .toPromise();
  }

  async read(filter: UserFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifUsuario)
      params = params.append('nifUsuario', filter.nifUsuario);

    if (filter.nomePessoa)
      params = params.append('nomePessoa', filter.nomePessoa);

    if (filter.emailUsuario)
      params = params.append('emailUsuario', filter.emailUsuario);

    if (filter.range)
      params = params.append('gama', filter.range.code.toString());

    if (filter.category)
      params = params.append('categoria', filter.category.code.toString());

    if (filter.isFirst)
      params = params.append('isFirst', filter.isFirst.toString());

    if (filter.dataCriaUsuarioDe)
      params = params.append('dataCriaUsuarioDe', moment(filter.dataCriaUsuarioDe).format('YYYY-MM-DD'));

    if (filter.dataCriaUsuarioAte)
      params = params.append('dataCriaUsuarioAte', moment(filter.dataCriaUsuarioAte).format('YYYY-MM-DD'));

    const response = await this.http.get<any>(this.API_URL, { params })
      .toPromise();
    this.convertFields(response.content);
    return {
      body: <User[]>response.content,
      total: response.totalElements
    };
  }

  async update(user: User): Promise<User> {
    delete user.statusColor;
    const response = await this.http.put<User>(`${this.API_URL}/${user.idUsuario}`, user)
      .toPromise();
    const user_1 = response as User;
    this.convertFields([user_1]);
    return user_1;
  }

  async validUserAndPrintTerm(user: User): Promise<User> {
    const response = await this.http.put<User>(`${this.API_URL}/imprimirTermoDesbloquearUtilizador`, user)
      .toPromise();
    const user_1 = response as User;
    this.convertFields([user_1]);
    return user_1;
  }

  async validOldPassword({ username, password }): Promise<boolean> {
    const oauthTokenUrl = `${environment.apiUrl}/oauth/token`;

    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', `Basic ${environment.AUTHORIZATION_KEY}`);

    const body = `username=${username}&password=${password}&grant_type=password`;

    try {
      await this.http.post<any>(oauthTokenUrl, body,
        { headers, withCredentials: true })
        .toPromise();
      return true;
    } catch (response) {
      if (response.status === 400) {
        if (response.error.error === 'invalid_grant') {
          return Promise.reject('A senha actual é inválida, por favor reveja sua senha!');
        }
      }
      return await Promise.reject(response);
    }
  }

  async validMail({ nif, codeConfirmation }): Promise<User> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic YWRtaW5AZWZhdHVyYS5nb3Yuc3Q6YWRtaW4=');
    headers = headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    params = params.append('nif', nif);
    params = params.append('codigo', codeConfirmation);

    return this.httpClient.patch<User>(`${this.API_URL}/confirmarEmail`, null, { params, headers })
      .toPromise();
  }

  private convertFields(users: User[]) {
    users.forEach(user => {
      user.dataCriaUsuario = moment(user.dataCriaUsuario, 'YYYY-MM-DD').toDate();
      user.dataAlterUsuario = moment(user.dataAlterUsuario, 'YYYY-MM-DD').toDate();

      user.statusUsuario
        ? user.statusColor = 'text-success'
        : user.statusColor = 'text-danger';
    });
  }
}

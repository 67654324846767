<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          NOTIFICAÇÕES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <!--<p-selectButtons [options]="options" [(ngModel)]="displayOption"></p-selectButtons>-->
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="p-col-4">
    <mdb-card>
      <mdb-card-body>
        <p-scrollPanel [style]="{width: '100%', height: '400px'}">
          <div [style]="{lineHeight: '1.5'}">
            <div class="list-group list-group-flush" *ngFor="let notification of notifications">

              <a (click)="getNotification(notification)"
                class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <p><strong> {{ notification.tbTipoNotificacaoDescTipoNotificacao }} </strong></p>
                  <small class="text-muted"> {{notification.dtCriaNotificacao | date:'dd/MM/yyyy' }} </small>
                </div>
                <p [innerHTML]="notification.topicoNotificacao"> </p>
              </a>

            </div>
          </div>
        </p-scrollPanel>
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="p-col-8">
    <mdb-card>
      <mdb-card-body>
        <article>
          <div class="article-header">
            <h5> </h5>
          </div>
          <hr>

          <div class="article-body">
            <p class="text-center">
              Selecione uma mensagem para ler
            </p>
          </div>
        </article>
      </mdb-card-body>
    </mdb-card>
  </div>

</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmissionDocument } from 'src/app/core/models/emission-document';
import { EmissionDocumentDTO } from '../../types/emission-document';

@Component({
  selector: 'app-grid-serie-to-transport',
  templateUrl: './grid-serie-to-transport.component.html',
  styleUrls: ['./grid-serie-to-transport.component.scss']
})
export class GridSerieToTransportComponent implements OnInit {
  @Input() emissionsDocuments: EmissionDocumentDTO[] = [];
  @Output() getSelectedEmissionsDocuments = new EventEmitter<EmissionDocumentDTO[]>();

  selectedEmissionsDocuments: EmissionDocumentDTO[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  callGetSelectedEmissionsDocuments(): void {
    const selected: EmissionDocumentDTO[] = this.selectedEmissionsDocuments.map((selected) => ({
      ...selected,
      transitarAno: true,
    }))
    this.getSelectedEmissionsDocuments.emit(selected);
  }
}

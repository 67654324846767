<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIROS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PRESTADOR DE SERVIÇOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-info" role="alert">
      <p>
        <mdb-icon fas icon="info-circle"></mdb-icon>

        Esta área destina-se aos <strong>Prestadores de Serviços</strong> do E-factura e permite que os mesmos façam
        gestão das actividades que realizam em nome de outrém.
      </p>
    </div>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/third-party/read">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog cyan-text"></i></div>
        <h4 class="title"> Gerir Operadores Económicos</h4>
        <p class="description">
          Permite gerir os clientes bem como as atividades que pode realizar em nome dos mesmos.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/third-party/create">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog cyan-text"></i></div>
        <h4 class="title"> Vincular Operador Económico </h4>
        <p class="description">
          Permite identificar e vincular novos clientes que podem lhe autorizar realizar atividades em seu nome.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/third-party/create-file-document">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-import cyan-text"></i></div>
        <h4 class="title"> Recolher Ficheiros </h4>
        <p class="description">
          Permite comunicar ficheiros e outros documentos emitidos por outrém.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/file-document/read-for-third-party">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-file-upload cyan-text"></i></div>
        <h4 class="title"> Ficheiros Recolhidos </h4>
        <p class="description">
          Permite consultar os ficheiros recolhidos em nome de outros contribuintes.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/emission/create-for-third">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-medical cyan-text"></i></div>
        <h4 class="title"> Pedir Nova Série Para Software Autónomo </h4>
        <p class="description">
          Permite adquirir novas séries de facturas a serem emitidas aos seus consumidores.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/emitter/emission-document/read-for-third-party">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-list-alt cyan-text"></i></div>
        <h4 class="title"> Verificar Séries </h4>
        <p class="description">
          Permite consultar as séries de documentos solicitados à Direcção dos Impostos.
        </p>
      </div>
    </a>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DocumentItemService } from 'src/app/document-item/document-item.service';
import { TaxRateService } from 'src/app/tax-rate/tax-rate.service';
import { TaxpayerService } from 'src/app/taxpayer/taxpayer.service';
import { decrypt } from '../../configs/encryption';
import { Locale } from './../../configs/models/locale';
import { Mathematic } from './../../configs/models/math';
import { Document } from './../../core/models/document';
import { DocumentoItem } from './../../core/models/document-item';
import { DocumentItemOrigin } from './../../core/models/document-item-origin';
import { ExemptionReason } from './../../core/models/exemption-reason';
import { TaxRate } from './../../core/models/tax-rate';
import { ExemptionReasonService } from './../../exemption-reason/exemption-reason.service';
import {
  DocumentEmitterFilter,
  DocumentEmitterService,
} from './../document-emitter.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss'],
})
export class GetComponent implements OnInit {
  loading: boolean = false;
  document = new Document();
  locale = new Locale();

  itemIndex: number;
  displayTaxRate: boolean = false;
  displayOrigin: boolean = false;
  displayExemption: boolean = false;
  displayFieldForCreditNote: boolean = false;
  validNif: boolean = true;

  filter = new DocumentEmitterFilter();
  totalDocumentsOrigin = 0;
  documentsOrigin = [];

  exemptionReasons: ExemptionReason[];

  selectedTaxRate: TaxRate;
  selectedExemptionReason: ExemptionReason;
  selectedOrigin: DocumentItemOrigin;

  taxRates: TaxRate[];

  creditAndDebitNotesIsOpen: boolean;
  creditAndDebitNotesItems = [];

  siglaTipoImpostoToShow: string;

  constructor(
    private service: DocumentEmitterService,
    private documentItemService: DocumentItemService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private exemptionReasonService: ExemptionReasonService,
    private taxRateService: TaxRateService,
    private taxPayerService: TaxpayerService,
    private route: ActivatedRoute,
    private confirmation: ConfirmationService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));

    this.getTaxRates();
    this.get(decryptedId);
  }

  onInputNif(event: any) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.document.nomeConsumidor = response.contriNome;
          this.validNif = true;
        })
        .catch(() => {
          this.validNif = false;
          this.document.nomeConsumidor = null;
        });
    } else {
      this.validNif = false;
      this.document.nomeConsumidor = null;
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  get items(): DocumentoItem[] {
    return this.document.tbItensDocumentoGerados as DocumentoItem[];
  }

  addItem() {
    this.items.push(new DocumentoItem());
  }

  removeItem(i: number) {
    if (this.items.length > 1) {
      this.items.splice(i, 1);
      this.calcTax();
    }
  }

  calcItemTax(index: number) {
    const itemAmount = this.items[index].valorBaseTributavelItens;
    const itemTaxRate = this.items[index].tbTaxaAplicavel.valorTaxaAplicavel;

    var m = new Mathematic();
    this.items[index].valorImposto = m.round(
      (itemAmount * itemTaxRate) / 100,
      2
    );

    const itemTax = this.items[index].valorImposto;

    this.items[index].valorItens = m.round(itemAmount + itemTax, 2);

    this.calcTax();
  }

  calcTax() {
    var m = new Mathematic();
    var amount = 0;
    var tax = 0;
    var total = 0;

    for (var i = 0; i < this.items.length; i++) {
      amount += this.items[i].valorBaseTributavelItens;
      tax += this.items[i].valorImposto;
      total += this.items[i].valorItens;
    }

    this.document.totalValorBaseTributavel = m.round(amount, 2);
    this.document.totalImposto = m.round(tax, 2);
    this.document.totalValor = m.round(total, 2);
  }

  async getTaxRates() {
    const response = await this.taxRateService.read();
    this.taxRates = response;
  }

  showTaxRate(index) {
    this.itemIndex = index;
    this.displayTaxRate = true;
  }

  showDisplayExemption(index) {
    this.itemIndex = index;
    this.displayExemption = true;
  }

  showDisplayOrigin(index) {
    this.itemIndex = index;
    this.displayOrigin = true;
  }

  onExemptionSelect(event) {
    this.items[this.itemIndex].tbMotivoIsencao = event.data;
    this.displayExemption = false;
  }

  onOriginSelect(event: any) {
    this.service
      .getTotalCreditNote(event.data.idDocumentoGerado)
      .then((response) => {
        this.items[this.itemIndex].tbDocumentoOrigems.numDocumentoOrigem =
          event.data.numDocumento;
        this.items[this.itemIndex].valorBaseTributavelItens =
          response.totalValor - response.totalValorItensNc;
      });
    this.displayOrigin = false;
  }

  readOriginsByEmitter(page = 0) {
    this.filter.page = page;
    this.service
      .readDocumentOriginsByEmitter(this.filter)
      .then((response) => {
        this.totalDocumentsOrigin = response.total;
        this.documentsOrigin = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.readOriginsByEmitter(pagina);
  }

  onTaxRateSelect(event) {
    this.items[this.itemIndex].tbTaxaAplicavel = event.data;

    this.getExemptionReasons(event.data);
    this.calcItemTax(this.itemIndex);
    this.displayTaxRate = false;
  }

  async getExemptionReasons(taxRate: TaxRate) {
    var isento = taxRate.valorTaxaAplicavel === 0 ? true : false;
    try {
      const response = await this.exemptionReasonService.getAll();
      this.exemptionReasons = response.filter(
        (e: ExemptionReason) => e.isento === isento
      );
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  get(id: number) {
    this.service
      .get(id)
      .then((response) => {
        this.document = response;
        this.showTaxSigla(this.document.dtEmissaoDocumento);
        this.getCreditAndDebitNotes();
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  onUpdate() {
    this.confirmation.confirm({
      message: 'Deseja salvar as alterações feitas neste documento?',
      accept: () => this.update(),
    });
  }

  showTaxSigla(documentDate: any) {
    const IvaStartDate: Date = new Date(2023, 5, 1);
    this.siglaTipoImpostoToShow =
      documentDate >= IvaStartDate || !documentDate ? 'IVA' : 'ISC';
  }

  update() {
    this.loading = true;
    this.service
      .update(this.document)
      .then((result) => {
        this.document = result;
        this.messageService.add({
          severity: 'success',
          detail: 'Informações salvas com sucesso',
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  getCreditAndDebitNotes() {
    this.documentItemService
      .readByDocument(this.document.numDocumento)
      .then((data) => {
        this.creditAndDebitNotesItems = data;
      });
  }

  displayCreditAndDebitNotes() {
    this.creditAndDebitNotesIsOpen = true;
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tipografia">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          INCREMENTAR NÚMEROS À SÉRIE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <p>
        <span class="text-danger">
          <mdb-icon fas icon="info-circle"></mdb-icon>
          <strong> IMPORTANTE!</strong>
        </span>
      </p>

      <p>
        Caro utilizador, a funcionalidade de solicitar mais documentos a uma série existente foi atualizada.
      </p>
      <p>
        Agora uma tipografia pode solicitar mais blocos para uma série adquirida noutras tipografias.
      </p>
      <p>
        <a href="javascript:;" (click)="displayInstruction=true">
          Veja as instruções de preenchimento do formulário aqui
        </a>
      </p>
    </div>
  </div>

  <div class="p-col-4">
    <mdb-card>
      <mdb-card-header>
        <h5>Requisitar mais documentos série</h5>
      </mdb-card-header>

      <mdb-card-body>
        <form autocomplete="off" #f="ngForm" (ngSubmit)="incrementDocInSerie(f)">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12">
                <label>Série</label>
                <b class="text-danger"> *</b>
                <div class="p-inputgroup">
                  <input pInputText name="serieNumber" [(ngModel)]="filter.serieNumber" required>
                  <button type="button" pButton pRipple label="Pesquisar" (click)="getSerieDetail()"
                    placeholder="Informe o nº da série"></button>
                </div>
              </div>

              <div class="p-field p-col-12">
                <label>Total de requisições</label>
                <b class="text-danger"> *</b>
                <input pInputText name="totalJaSolicitado" [(ngModel)]="emissionDocument.totalJaSolicitado"
                  placeholder="Total de documentos já solicitados" (input)="calcInterval()" disabled>
              </div>

              <div class="p-field p-col-12">
                <label>Quantidade a requisitar</label>
                <b class="text-danger"> *</b>
                <p-inputNumber name="totalSolicitado" (input)="calcInterval()" required
                  [(ngModel)]="emissionDocument.totalSolicitado" inputId="minmax" inputId="minmax" mode="decimal"
                  [min]="50" [max]="10000" placeholder="A quantidade a ser solicitada">
                </p-inputNumber>
              </div>

              <div class="p-field p-col-12">
                <label>NIF do solicitante</label>
                <b class="text-danger"> *</b>
                <input pInputText name="nifSolicitante" [(ngModel)]="nifSolicitante" (input)="onValidNif($event)"
                  required placeholder="NIF do indivíduo que dirigiu-se a tipografia">
                <div *ngIf="!validNif" class="ui-message ui-message-error">NIF inválido</div>
              </div>

              <div class="p-field p-col-12">
                <label>Nome do solicitante</label>
                <input pInputText name="nomeSolicitante" [(ngModel)]="nomeSolicitante" required disabled
                  placeholder="Nome do indivíduo que dirigiu-se a tipografia">
              </div>

              <div class="p-field p-col-12">
                <label>Emitente</label>
                <input pInputText name="nomeEmissor" [(ngModel)]="emissionDocument.nomeEmissor" disabled
                  placeholder="Proprietário da série">
              </div>

              <div class="p-field p-col-12">
                <label>Intervalo de folhas a imprimir</label>
                <input pInputText name="interval" [(ngModel)]="interval" required disabled
                  placeholder="Intervalo de folhas a imprimir">
              </div>

              <div class="p-col-12 text-right">
                <hr>
                <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/tipografia">
                  VOLTAR
                </button>

                <button type="submit" mdbBtn color="success" mdbWavesEffect [disabled]="!f.valid || loadingIncrement">
                  SALVAR
                </button>
              </div>

              <div class="p-col-12" [style]="{marginTop: '20px'}">
                <p-progressBar *ngIf="loadingIncrement" mode="indeterminate" [style]="{'height': '3px'}">
                </p-progressBar>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="p-col-8">
    <mdb-card>
      <mdb-card-header>
        <h5>Histórico de requisições</h5>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="histories"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" dataKey="idPedidoEmissaoDocumento" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'50%'}">Requerente</th>
              <th [style]="{width:'20%'}">Intervalo de páginas</th>
              <th [style]="{width:'10%'}">Data Requisição</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
            <tr>
              <td> {{ rowData.nifRequerente +' - '+ rowData.nomeRequerente }} </td>
              <td> {{ rowData.numeroSequencialInicial +' - '+ rowData.numeroSequencialFinal }} </td>
              <td> {{ rowData.dataRequisicao | date}} </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog [(visible)]="display" [style]="{width: '60vw'}" [baseZIndex]="10000" [showHeader]="false" modal="true">
  <div class="p-grid">
    <div class="col-md-12 text-center  p-mt-3">
      <mdb-icon class="icon-message-lg text-success text-center" fas icon="check-circle"></mdb-icon>
      <p class="bq-title text-center p-mt-2">
        O seu pedido foi processado com sucesso!
      </p>
      <hr>
    </div>

    <div class="p-col-12">
      <div class="alert alert-warning" role="alert">
        <p>
          As informações de identificação do emitente na tabela abaixo devem constar obrigatoriamente nas folhas a serem
          impressas.
        </p>

        <p>
          Caso o emitente deseje alterar as mesmas informações, o mesmo deve dirigir-se à Direcção dos Impostos.
        </p>
      </div>
    </div>

    <table mdbTable borderless="true" striped="true" bordered="true" small="true">
      <tbody>
        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Emitente</strong>
          </th>
          <td class="text-left">
            {{edSaved.nifEmitente +' - '+ edSaved.nomeEmitente}}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Telefone</strong>
          </th>
          <td class="text-left">
            {{edSaved.telefone}}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Endereço</strong>
          </th>
          <td class="text-left">
            {{edSaved.endereco}}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Série</strong>
          </th>
          <td class="text-left">
            {{
            edSaved.sigla + '' +
            edSaved.numSerie + '' +
            edSaved.ano
            }}
          </td>
        </tr>

        <!-- <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Quantidade solicitada</strong>
          </th>
          <td class="text-left">
            {{edSaved.quantidadeSolicitada}}
          </td>
        </tr> -->

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Intervalo de páginas</strong>
          </th>
          <td class="text-left">
            {{edSaved.numeroSequencialInicial +' - '+ edSaved.numeroSequencialFinal}}
          </td>
        </tr>

        <!-- <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Data requisição</strong>
          </th>
          <td class="text-left">
            {{edSaved.dataRequisicao}}
          </td>
        </tr> -->

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Nº de certificação</strong>
          </th>
          <td class="text-left">
            {{edSaved.numeroCertificacao}}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Data de certificação</strong>
          </th>
          <td class="text-left">
            {{edSaved.dataCertificacao}}
          </td>
        </tr>

        <tr>
          <th scope="row" [style]="{width: '20%'}">
            <strong>Nº de autorização</strong>
          </th>
          <td class="text-left">
            {{edSaved.numeroAutorizacao}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="display=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="displayInstruction" [style]="{width: '60vw'}" [baseZIndex]="10000" [showHeader]="false"
  modal="true">

  <div class="c7">
    <ul class="c5 lst-kix_2njqj3e34avh-0 start">
      <li class="c10 li-bullet-0">
        <span class="c0 c6">INSTRU&Ccedil;&Otilde;ES PARA O PREENCHIMENTO DOS CAMPOS</span>
      </li>
    </ul>
    <ol class="c5 lst-kix_inf30vxoc1r5-0 start" start="1">
      <li class="c3 li-bullet-0"><span class="c0">S&eacute;rie</span>
        <span class="c1">
          : informar o n&uacute;mero de s&eacute;rie a adicionar mais documentos e clicar em pesquisar para obter os
          detalhes da mesma. Na coluna direita &eacute; apresentada um resumo do hist&oacute;rico das
          solicita&ccedil;&otilde;es feitas para a s&eacute;rie informada;</span>
      </li>
      <li class="c3 li-bullet-0"><span class="c0">Total de requisi&ccedil;&otilde;es</span><span class="c1">: neste
          campo &eacute; apresentado o total de documentos j&aacute; solicitados para esta s&eacute;rie;
        </span></li>
      <li class="c3 li-bullet-0">
        <span class="c0">Quantidade a requisitar</span><span class="c1">
          : informa a quantidade de documentos a solicitar;</span>
      </li>
      <li class="c3 li-bullet-0"><span class="c0">NIF do solicitante</span><span class="c1">: informe o NIF do
          indiv&iacute;duo que dirigiu-se a tipografia;</span></li>
      <li class="c3 li-bullet-0">
        <span class="c0">Nome do solicitante</span>
        <span class="c1">: ao inserir o NIF do solicitante correctamente &eacute; apresentado automaticamente o seu nome
          completo;</span>
      </li>
      <li class="c3 li-bullet-0">
        <span class="c0">Intervalo de folhas a imprimir</span>
        <span class="c1">: por fim &eacute; apresentada a previs&atilde;o do intervalo de folhas a serem impressas pela
          tipografia. Aten&ccedil;&atilde;o que o primeiro n&uacute;mero desse intervalo deve ser exatamente a
          sequ&ecirc;ncia do &uacute;ltimo n&uacute;mero j&aacute; impresso. Ex. Se o contribuinte j&aacute; adquiriu um
          bloco de 50 p&aacute;ginas o intervalo correto deve ser 51 - 100 que corresponde ao intervalo de
          p&aacute;ginas do bloco a ser adquirido;
        </span>
      </li>
    </ol>
    <p class="c9"><span class="c0 c11">Nota
      </span><span class="c1">: Caso o n&uacute;mero n&atilde;o esteja correto, o contribuinte dever&aacute; se dirigir
        a gr&aacute;fica que solicitou as folhas anteriores para regularizar a situa&ccedil;&atilde;o.</span>
    </p>
    <ul class="c5 lst-kix_mgjfq0vig4fw-0 start">
      <li class="c4 li-bullet-0">
        <span class="c1">
          Ao validar o pedido &eacute; apresentada uma janela com os detalhes do pedido efectuado. Siga as
          instru&ccedil;&otilde;es apresentadas para imprimir os blocos com as informa&ccedil;&otilde;es correctas.
        </span>
      </li>
    </ul>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="displayInstruction=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayBlockedSerieMessage"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
>
  <div class="alert alert-danger" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Sujeito passivo bloqueado para solicitação de novas séries. Deve comunicar
    as faturas em falta!
    <p class="p-mt-2">Nome: {{ blockedTaxpayerInfo?.nomePessoa }}</p>
    <p>NIF: {{ blockedTaxpayerInfo?.nifContribuinte }}</p>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBlockedSerieMessage = false"
      label="Ok"
      styleClass="p-button-text"
    >
    </p-button>
  </ng-template>
</p-dialog>
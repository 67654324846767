import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from '../auth/app-http';
import { environment } from './../../environments/environment';
import { ReportRoutinesAnalysisFile } from './../core/models/report-routines-analysis-file';

export class ReportRoutinesAnalysisFileFilter {
  page = 0;
  rows = 15;

  faseRelatorio: string;
  dtInicioDe: Date;
  dtInicioAte: Date;
  dtFimAte: Date;
  dtFimDe: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ReportRoutinesAnalysisFileService {
  API: string;

  constructor(private http: AppHttp) {
    this.API = `${environment.apiUrl}/controleRotinasAnaliseFicheiro`;
  }

  read(filter: ReportRoutinesAnalysisFileFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.faseRelatorio) {
      params = params.append('faseRelatorio', filter.faseRelatorio);
    }

    if (filter.dtInicioDe) {
      params = params.append('dtInicioDe', moment(filter.dtInicioDe).format('YYYY-MM-DD'));
    }
    if (filter.dtInicioAte) {
      params = params.append('dtInicioAte', moment(filter.dtInicioAte).format('YYYY-MM-DD'));
    }

    if (filter.dtFimDe) {
      params = params.append('dtFimDe', moment(filter.dtFimDe).format('YYYY-MM-DD'));
    }
    if (filter.dtFimAte) {
      params = params.append('dtFimAte', moment(filter.dtFimAte).format('YYYY-MM-DD'));
    }

    return this.http.get<any>(`${this.API}`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <ReportRoutinesAnalysisFile[]>response.content,
          total: response.totalElements
        }
      });
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tipografia">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EDITAR SOLICITAÇÃO DE SÉRIE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-4">
    <mdb-card>
      <mdb-card-header>
        <h5>Dados da Série</h5>
      </mdb-card-header>

      <mdb-card-body>

      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="p-col-8">
    <mdb-card>
      <mdb-card-header>
        <h5>Histórico de requisição de Páginas</h5>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="histories"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" dataKey="idPedidoEmissaoDocumento" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'55%'}">Gráfica</th>
              <th [style]="{width:'20%'}">Intervalo de páginas</th>
              <th [style]="{width:'20%'}">Data Requisição</th>
              <th [style]="{width:'5%'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
            <tr>
              <td> {{ rowData.nifRequerente +' - '+ rowData.nomeRequerente }} </td>
              <td> {{ rowData.numeroSequencialInicial +' - '+ rowData.numeroSequencialFinal }} </td>
              <td> {{ rowData.dataRequisicao | date}} </td>
              <td [style]="{padding: '1px 4px'}">
                <a mdbBtn block="true" color="warning" size="sm" pTooltip="Editar registo" tooltipPosition="left"
                  [style]="{padding: '4px 0'}" (click)="getHistory(rowData)">
                  <i class="fa fa-edit"></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Editar histórico" [(visible)]="displayDialogEditHistory" [modal]="true" [responsive]="true"
  [style]="{width: '30vw', minWidth: '800px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <form #editHistoryForm="ngForm" (ngSubmit)="updateHistory()">

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Série</label>
        <input pInputText type="text" name="historyFullSerie" [(ngModel)]="historyFullSerie" disabled>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Gráfica</label>
        <input pInputText type="text" name="nomeRequerente" [(ngModel)]="history.nomeRequerente" disabled>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Emitente</label>
        <input pInputText type="text" name="nomeEmitente" [(ngModel)]="history.nomeEmitente" disabled>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Quantidade solicitada</label>
        <p-inputNumber [minFractionDigits]="0" name="quantidadeSolicitada" [(ngModel)]="history.quantidadeSolicitada"
          class="p-inputtext-sm" placeholder="Montante do item" (input)="adjustInterval()" locale="de-DE">
        </p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label>Intervalo de páginas</label>
        <input pInputText type="text" name="historyPageInterval" [(ngModel)]="historyPageInterval" disabled>
      </div>
    </div>

    <div class="p-col text-right">
      <hr>
      <button type="button" mdbBtn color="light" mdbWavesEffect (click)="displayDialogEditHistory=false"
        [disabled]="loading">
        CANCELAR
      </button>

      <button type="submit" mdbBtn color="primary" mdbWavesEffect [disabled]="!editHistoryForm.valid || loading">
        SALVAR
      </button>
    </div>

    <div class="p-col">
      <p-progressBar *ngIf="loadingUpdateHistory" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    </div>
  </form>
</p-dialog>

import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  showModal = true;
  @Input() genericError = false;

  fazendaNonActivityUrl: string = environment.fazendaNonActivityUrl;
  fazendaNonElectronicBoxUrl: string = environment.fazendaNonElectronicBoxUrl;
  toggleModal(){
    this.showModal = !this.showModal;
  }
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Emission, EmissionForm } from 'src/app/core/models/emission';
import { EmissionDocumentForm } from 'src/app/core/models/emission-document';
import { TypeDocument } from 'src/app/core/models/type-document';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { TaxPayer } from './../../core/models/taxpayer';
import { EmissionEmitterService } from './../../emission-emitter/emission-emitter.service';
import { ThirdPartyEmitterService } from './../../third-party-emitter/third-party-emitter.service';
import { TypeDocumentService } from './../../type-document/type-document.service';

@Component({
  selector: 'app-create-for-third',
  templateUrl: './create-for-third.component.html',
  styleUrls: ['./create-for-third.component.scss'],
})
export class CreateForThirdComponent implements OnInit {
  loading: boolean = false;
  display: boolean = false;

  emission = new Emission();

  emissionForm = new EmissionForm(this.fb).builderForThird();
  emissionDocumentForm = new EmissionDocumentForm(this.fb);

  typesDocument: TypeDocument[];

  displayBlockedSerieMessage: boolean = false;
  blockedSerieMessage: string = '';
  ACTIVITY_ID = 2;
  emitters = [];

  constructor(
    private fb: UntypedFormBuilder,
    private emissionEmitterService: EmissionEmitterService,
    private thirdPartyEmitterService: ThirdPartyEmitterService,
    private auth: AuthService,
    private typeDocumentService: TypeDocumentService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.getEmitters();

    this.addItem();

    this.getTypeDocument();

    this.setApplicant();
  }

  getEmitters() {
    this.thirdPartyEmitterService.getEmittersByActivity(this.ACTIVITY_ID).then(
      (emitters: TaxPayer[]) =>
        (this.emitters = emitters.map((el: TaxPayer) => ({
          value: el.tbPessoa.nifPessoa,
          label: `${el.tbPessoa.nifPessoa} - ${el.tbPessoa.nomePessoa}`,
        })))
    );
  }

  get items(): UntypedFormArray {
    return this.emissionForm.get('pedidoEmissaoDocumentos') as UntypedFormArray;
  }

  addItem() {
    this.items.push(this.emissionDocumentForm.builder());
  }

  removeItem(i: number) {
    if (this.items.controls.length > 1) {
      this.items.removeAt(i);
    }
  }

  setApplicant() {
    this.emissionForm.patchValue({
      // tbPedidoCertificacao: {
      //   tbContribuinte: {
      //     idContribuinte: this.auth.jwtPayload.idCont
      //   },
      // },
      // tbContribuinte: {
      //   nifContribuinte: this.auth.jwtPayload.nif
      // },
      // nifSolicitante: this.auth.jwtPayload.nif,
      // btPedidoEmissaoProximoAno: false,
    });
  }

  getTypeDocument() {
    return this.typeDocumentService
      .read()
      .then((tiposDocumento) => {
        this.typesDocument = tiposDocumento.map((t) => ({
          label: t.descTipoDocumentoEmissao,
          value: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  setTotalEmissionDocuments(event, index: number) {
    this.items.at(index).patchValue({
      qtTotalDocumentosJaSolicitado: event.target.value,
    });
  }

  save(emissionForm: UntypedFormGroup) {
    this.loading = true;
    this.emissionForm.removeControl('mascara');

    this.emissionEmitterService
      .creat(this.emissionForm.value)
      .then((response) => {
        this.emission = response;
        this.display = true;

        emissionForm.reset();
        emissionForm = new EmissionForm(this.fb).builder();
        this.setApplicant();

        this.items.controls.forEach((item) => {
          item.patchValue({ mascara: 6 });
        });
      })
      .catch((error) => {
        if (error.status === 400) {
          const [errorMessage] = error.error;
          this.blockedSerieMessage = errorMessage.mensagemUsuario;
          this.displayBlockedSerieMessage = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => (this.loading = false));
    return;
  }
}

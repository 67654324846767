import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AboutComponent } from './about/about.component';
import { AdminComponent } from './admin/admin.component';
import { AgentComponent } from './agent/agent.component';
import { ConsumerComponent } from './consumer/consumer.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentComponent } from './document/document.component';
import { EmitterComponent } from './emitter/emitter.component';
import { FuncionalityUtilComponent } from './funcionality-util/funcionality-util.component';
import { IndexComponent } from './index/index.component';
import { TypographyComponent } from './typography/typography.component';

const routes: Routes = [
  {
    path: 'index',
    component: IndexComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documento',
    component: DocumentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contacto',
    component: ContactComponent,
  },
  {
    path: 'sobre',
    component: AboutComponent,
  },
  {
    path: 'emitente',
    component: EmitterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'adquirente',
    component: ConsumerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tipografia',
    component: TypographyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'util',
    component: FuncionalityUtilComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'agente',
    component: AgentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StartRouter {}

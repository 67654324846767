<section id="topbar" class="d-none d-lg-block">
  <div class="container clearfix">
    <div class="contact-info float-left text-uppercase">
      <a href="javascript:;"
        >Ministério do Planeamento, Finanças e Economia Azul</a
      >
    </div>

    <div
      class="social-links float-right"
      *ngIf="isLoggedIn; else elseIsLoggedIn"
    >
      <a
        href="https://impostos.financas.gov.st/index.php/informacoes/e-factura"
        target="_blanc"
      >
        <i class="fas fa-question-circle"></i> Ajuda
      </a>
      <a
        routerLink="/user/profile"
        class="text-ellipses"
        [title]="user && user.given_name"
      >
        <i class="fas fa-user"></i>
        {{ user && user.given_name }}

        <!-- <span class="last-session">
          Última sessão: {{ auth.lastSession | date : "medium" }}
        </span> -->
      </a>
      <!-- <app-alert class="notification"></app-alert> -->

      <a (click)="logout()"> <i class="fas fa-sign-in-alt"></i> Sair </a>
    </div>

    <ng-template #elseIsLoggedIn>
      <div class="social-links float-right">
        <a
          href="https://impostos.financas.gov.st/index.php/servicos/e-factura"
          target="_blanc"
        >
          <i class="fas fa-question-circle"></i> Ajuda
        </a>

        <a (click)="redirectToLogin()">
          <i class="fa fa-sign-in-alt"></i> Entrar
        </a>

        <a [attr.href]="registerUrl">
          <i class="fas fa-user-plus"></i> Registar
        </a>
      </div>
    </ng-template>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FileDocumentCancellationRequestFilter, FileDocumentCancellationRequestService } from 'src/app/file-document-cancellation-request/file-document-cancellation-request.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  filter = new FileDocumentCancellationRequestFilter();
  totalCancellationRequest: string = '0';

  constructor(
    public auth: AuthService,
    private fileDocumentCancellationRequestService: FileDocumentCancellationRequestService,
  ) { }

  ngOnInit(): void {
    this.getTotalCancellationRequest();
  }

  getTotalCancellationRequest(page = 0) {
    this.filter.page = page;
    this.filter.rows = 1;
    this.filter.status = 'PENDENTE';
    this.fileDocumentCancellationRequestService.read(this.filter)
      .then(response => {
        const total = response.total;

        if (total > 9) {
          this.totalCancellationRequest = `+${9}`;
        } else {
          this.totalCancellationRequest = `${total}`;
        }
      })
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util/help-center">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          CENTRAL DE AJUDA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TUTORIAIS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <span class="text-info">
        <mdb-icon fas icon="info-circle"></mdb-icon>
      </span>
      <p>
        Nesta área são apresentadas instruções para transportar as suas séries para o ano 2021, corrigir os seus
        documentos comunicados incorretamente e gerir os ficheiros comunicados.
      </p>
      <p> Siga os procedimentos abaixo para começar o novo ano com a sua situação e-factura regularizada. </p>
    </div>
  </div>

  <div class="p-col-12 p-md-6 p-lg-4">
    <a class="a-box" (click)="displaySerieNextYear=true">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-export teal-text"></i></div>
        <h4 class="title"> Transportar Séries Para o Ano 2021</h4>
        <p class="description">
          Indique as séries adquiridas em 2020 que pretende transportar para o ano 2021.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-4">
    <a class="a-box" (click)="incorrectlyCommunicatedDocuments=true">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-signature pink-text"></i></div>
        <h4 class="title"> Corrigir Documentos Comunicados Incorretamente </h4>
        <p class="description">
          Corrija informações dos documentos comunicados incorretamente.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6 p-lg-4">
    <a class="a-box" (click)="displayReviewReportedFiles=true">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-upload blue-text"></i></div>
        <h4 class="title"> Rever Ficheiros Comunicados </h4>
        <p class="description">
          Reveja todos os seus ficheiros já comunicados e informe os que deseja anular se necessário.
        </p>
      </div>
    </a>
  </div>
</div>

<p-dialog header="TRANSPORTAR SÉRIES PARA O PRÓXIMO ANO" [(visible)]="displaySerieNextYear" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [modal]="true" [maximizable]="true" [draggable]="false" [resizable]="false">

  <div class="alert alert-info" role="alert">
    <span class="text-info">
      <mdb-icon fas icon="info-circle"></mdb-icon>
    </span>
    <p>
      Este procedimento mostra como transportar as suas séries do ano 2020 para o ano 2021.
    </p>
    <p>
      <strong>OBS:</strong> Transporte apenas as séries que pretende utilizar no ano 2021.
    </p>
  </div>

  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 1: </strong>
        </span>
        Clique em <strong>"Documentos"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imagea6c3806f893fe462.png" alt="image" border="1"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 2: </strong>
        </span>
        Clique em <strong>"Emitente"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/30/image59db25fd5d94d963.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}"> <br>
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 3: </strong>
        </span>
        Clique em <strong>"Verificar Séries"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/30/image24ac957d4650a666.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 4: </strong>
        </span>
        Clique em <strong>"TRANSPORTAR SÉRIES PARA O ANO 2021"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/image54e8c831e6956e3c.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 5: </strong>
        </span>
        Selecione a série que deseja transportar.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/30/image4faf44c5b5b5d37f.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 6: </strong>
        </span>
        Informe a quantidade de documentos e outras informações.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imagefbf1a3760f5ffe57.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <span class="text-info">
        <strong>PASSO 7: </strong>
      </span>
      <p>
        Foi-lhe emitida uma nova série para o ano 2021.
      </p>
      <p>
        Parametrize a nova série e o respetivo nº de autorização no software de facturação autónomo.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/image94635ab405000425.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="info" mdbWavesEffect (click)="displaySerieNextYear=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog header="REVER FICHEIROS COMUNICADOS" [(visible)]="displayReviewReportedFiles" [style]="{width: '50vw'}"
  [baseZIndex]="10000" [modal]="true" [maximizable]="true" [draggable]="false" [resizable]="false">

  <div class="alert alert-info" role="alert">
    <p>
      <span class="text-info">
        <mdb-icon fas icon="info-circle"></mdb-icon>
      </span>
      Veja neste tutorial Como gerir os documentos já comunicados.
    </p>
  </div>

  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 1: </strong>
        </span>
        Clique em <strong>"Documentos"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/image0f4c2014ab3c482c.png" alt="image" border="1"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 2: </strong>
        </span>
        Clique em <strong>"Emitente"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/image4c43987914b5713a.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}"> <br>
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 3: </strong>
        </span>
        Clique em <strong>"Ficheiros Recolhidos"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/image725291642acb63ee.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 4: </strong>
        </span>
        Faça a gestão dos seus ficheiros.
      </p>
      <p> 1 - Pesquisar pelo nome parcial de um ficheiro para encontra-lo. </p>
      <p> 2 - Anular o envio de um determinado ficheiro. </p>
      <p> 3 - Alterar o período de facturação de um determinado ficheiro. </p>
      <p> 4 - Substituir um determinado ficheiro. </p>
      <p> 5 - Efetuar o download de um determinado ficheiro. </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/image9774e801c8e07616.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="info" mdbWavesEffect (click)="displayReviewReportedFiles=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog header="CORRIGIR DOCUMENTOS COMUNICADOS INCORRETAMENTE" [(visible)]="incorrectlyCommunicatedDocuments"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true" [maximizable]="true" [draggable]="false"
  [resizable]="false">

  <div class="alert alert-info" role="alert">
    <p>
      <span class="text-info">
        <mdb-icon fas icon="info-circle"></mdb-icon>
      </span>
      É possível que tenha nos comunicados documentos com problemas. Veja como corrigi-los neste tutorial.
    </p>
  </div>

  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 1: </strong>
        </span>
        Clique em <strong>"Documentos"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imageb91396f73a4da0a7.png" alt="image" border="1"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 2: </strong>
        </span>
        Clique em <strong>"Emitente"</strong>.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imagedf7adf402ffeb9cc.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}"> <br>
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <span class="text-info">
        <strong>PASSO 3: </strong>
      </span>
      <p>
        Se recebeu o aviso indicado na imagem clique no link <strong>"Saiba mais"</strong> para continuar.
      </p>
      <p>
        Caso não tenha recebido o aviso, significa que não possuí documentos comunicados incorretamente. Pode ignorar
        este tutorial.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imagec1c0c37218764c28.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 4: </strong>
        </span>
      </p>
      <p> 4.1 - Na imagem é mostrado um exemplo de um documento comunicado com número sequencial incorreto. </p>
      <p> 4.2 - Clique no ícone de Edição para corrigir o número sequencial. </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imagea6e60c06c339f3cb.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <div class="p-col-12 p-p-0">
    <div class="alert alert-info" role="alert">
      <p>
        <span class="text-info">
          <strong>PASSO 5: </strong>
        </span>
        Informe o número correto e clique em salvar.
      </p>
    </div>
    <img src="https://imgupload.io/images/2020/12/31/imagedd7cf5fe44c30ab3.png" alt="image" border="0"
      [style]="{width: '100%', height: '100%'}">
  </div> <br> <br>
  <hr>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="info" mdbWavesEffect (click)="incorrectlyCommunicatedDocuments=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-table [value]="emissionsDocuments" [(selection)]="selectedEmissionsDocuments" dataKey="idPedidoEmissaoDocumento"
  (onRowSelect)="callGetSelectedEmissionsDocuments()" (onRowUnselect)="callGetSelectedEmissionsDocuments()"
  (onHeaderCheckboxToggle)="callGetSelectedEmissionsDocuments()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th>Tipo</th>
      <th>Série</th>
      <th>Autorização</th>
      <th>Data solicitação</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td>{{rowData.tipoDocumento}}</td>
      <td>{{rowData.siglaSerie+rowData.serie+rowData.ano}}</td>
      <td>{{rowData.numAutorizacaoEmissao}}</td>
      <td>{{rowData.data | date:'dd/MM/yyyy'}}</td>
    </tr>
  </ng-template>
</p-table>

<div class="p-grid" *ngIf="!emissionsDocuments.length">
  <div class="p-col-12 text-center">
    Nenhum registo encontrado.
  </div>
</div>

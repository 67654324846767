import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { decrypt } from '../../configs/encryption';
import { FileDocumentAdminService } from '../file-document-admin.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  loadingAnnulment: boolean = false;
  locale = new Locale();
  fileDocument = new FileDocument();

  constructor(
    private service: FileDocumentAdminService,
    private message: MessageService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    const userId = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(userId));
    this.get(decryptedId);
  }

  get(id) {
    this.service.get(id)
      .then(response => {
        this.fileDocument = response;
      })
      .catch(error => this.errorHandler.handle(error));
  }

  getFile(file: FileDocument) {
    this.service.getFile(file)
      .then(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = file.nomeArquivoDocumentoGerado;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() => this.message.add({
        key: 'lg',
        severity: 'error',
        life: 20000,
        detail: 'Não foi possível transferir o ficheiro! Tente mais tarde.'
      }))
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { ReadTypographyComponent } from './read-typography/read-typography.component';

const routes: Routes = [
  {
    path: 'read-typography-certified',
    component: ReadTypographyComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CertificationUtilRouter { }

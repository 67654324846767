import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { TypeDocument } from 'src/app/core/models/type-document';
import { TaxRateService } from 'src/app/tax-rate/tax-rate.service';
import {
  DocumentEmitterFilter,
  DocumentEmitterService,
} from '../document-emitter.service';
import { AuthService } from './../../auth/auth.service';
import { Locale } from './../../configs/models/locale';
import { Mathematic } from './../../configs/models/math';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Document, DocumentForm } from './../../core/models/document';
import { DocumentItemForm } from './../../core/models/document-item';
import { DocumentItemOrigin } from './../../core/models/document-item-origin';
import { ExemptionReason } from './../../core/models/exemption-reason';
import { TaxRate } from './../../core/models/tax-rate';
import { ExemptionReasonService } from './../../exemption-reason/exemption-reason.service';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { TypeDocumentService } from './../../type-document/type-document.service';
import { buildCurrentDate } from 'src/app/shared/date';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  locale = new Locale();
  loading = false;
  document = new Document();
  documentForm = new DocumentForm(this.fb).builder();
  documentItemForm = new DocumentItemForm(this.fb);
  filter = new DocumentEmitterFilter();
  totalDocumentsOrigin = 0;

  typesDocument: TypeDocument[];
  taxRates: TaxRate[];
  documentsOrigin = [];
  series = [];
  exemptionReasons: ExemptionReason[];

  taxRate = new TaxRate();
  selectedTaxRate: TaxRate;
  selectedExemptionReason: ExemptionReason;
  selectedOrigin: DocumentItemOrigin;

  displayTaxRate: boolean = false;
  displayOrigin: boolean = false;
  displayExemption: boolean = false;
  displayFieldForCreditNote: boolean = false;

  itemIndex: number;

  validNif: boolean = true;

  siglaTipoImpostoToShow: string;
  currentDocumentDate = buildCurrentDate(new Date());
  iscTax = false;

  constructor(
    private fb: UntypedFormBuilder,
    private documentEmitterService: DocumentEmitterService,
    private typeDocumentService: TypeDocumentService,
    private taxRateService: TaxRateService,
    private errorHandler: ErrorHandlerService,
    private exemptionReasonService: ExemptionReasonService,
    private auth: AuthService,
    private taxPayerService: TaxpayerService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.dropdownTypesDocument();
    this.getTaxRates();
    this.showTaxSigla(null);

    const IvaStartDate: Date = new Date(2023, 5, 1);

    this.documentForm.get('dtEmissaoDocumento').valueChanges.subscribe((value) => {
      const icsTax = value < IvaStartDate;
      if ((icsTax && !this.iscTax) || (!icsTax && this.iscTax)) {
        this.clearTaxesRegime();
      }
      this.currentDocumentDate = buildCurrentDate(value);
      this.iscTax = icsTax;
      this.taxRates = [];
      this.getTaxRates();
    });

  }

  clearTaxesRegime(): void {
    this.selectedTaxRate = null;
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbTaxaAplicavel.descTaxaAplicavel').reset();
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  clearTaxesRegimeCode(): void {
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  showReceiptFields(): boolean {
    return this.documentForm.get('tbTipoDocumentoEmissao').valid &&
      this.documentForm.get('numDocumento').valid &&
      this.documentForm.get('dtEmissaoDocumento').valid &&
      this.documentForm.get('tbAutorizacaoEmissao.idAutorizacaoEmissao').valid;
  }

  displayFormCreditNote({ siglaTipoDocumentoEmissao: acronym }) {
    if (acronym === 'NC' || acronym === 'ND') {
      this.displayFieldForCreditNote = true;
    } else {
      this.displayFieldForCreditNote = false;
    }
  }

  get items(): UntypedFormArray {
    return this.documentForm.get('tbItensDocumentoGerados') as UntypedFormArray;
  }

  addItem() {
    this.items.push(this.documentItemForm.builder());
  }

  removeItem(i: number) {
    if (this.items.controls.length > 1) {
      this.items.removeAt(i);
      this.calcTax();
    }
  }

  onInputNif(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.documentForm
            .get('nomeConsumidor')
            .patchValue(response.contriNome);
          this.validNif = true;
        })
        .catch(() => {
          this.validNif = false;
          this.documentForm.get('nomeConsumidor').patchValue(null);
        });
    } else {
      this.validNif = false;
      this.documentForm.get('nomeConsumidor').patchValue(null);
    }
  }

  async getTaxRates() {
    const response = await this.taxRateService.readActives(this.currentDocumentDate);
    this.taxRates = response;
  }

  dropdownTypesDocument() {
    this.typeDocumentService.listarPorNif().then((response) => {
      this.typesDocument = response.map((r) => ({
        label: r.descTipoDocumentoEmissao,
        value: {
          idTipoDocumentoEmissao: r.idTipoDocumentoEmissao,
          descTipoDocumentoEmissao: r.descTipoDocumentoEmissao,
          siglaTipoDocumentoEmissao: (r.siglaTipoDocumentoEmissao !== 'ND' || r.siglaTipoDocumentoEmissao !== 'NC') ?
            'FT' : r.siglaTipoDocumentoEmissao,
        },
      }));
    });
  }

  dropdownSeriesByTypeDocument(typeDocument) {
    this.displayFormCreditNote(typeDocument);

    return this.authorizationService
      .listarSeriePorNifIdTipoDocumento(typeDocument.idTipoDocumentoEmissao)
      .then((response) => {
        this.series = response.map((s) => ({
          label: `${s.siglaDocAutorizacaoEmissao}${s.numSerieDocumento}${s.anoAutorizacaoEmissao}`,
          value: s.idAutorizacaoEmissao,
        }));

        this.documentForm.patchValue({
          ...response,
        });
      });
  }

  get emissionDocumentType(): FormControl {
    return this.documentForm.get('tbTipoDocumentoEmissao') as FormControl;
  }

  async getExemptionReasons(taxRate: TaxRate) {
    var isento = taxRate.valorTaxaAplicavel === 0 ? true : false;

    const dataFilter = {
      taxId: taxRate.idTaxaAplicavel,
      documentTypeSigla:
        this.emissionDocumentType.value?.siglaTipoDocumentoEmissao,
    };

    try {
      const response =
        await this.exemptionReasonService.getByTaxIdAndDocumentTypeSigla(
          dataFilter
        );
      if (this.iscTax) {
        this.exemptionReasons = response;
      } else {
        this.exemptionReasons = response.filter(
          (e) => e.isento === isento && e.tbTipoImposto.idTipoImposto === 1
        );
      }
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  showTaxRate(index) {
    this.itemIndex = index;
    this.displayTaxRate = true;
  }

  showDisplayExemption(index) {
    this.itemIndex = index;
    this.displayExemption = true;
  }

  showDisplayOrigin(index) {
    this.itemIndex = index;
    this.displayOrigin = true;
  }

  onExemptionSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbMotivoIsencao: {
        idMotivoIsencao: event.data.idMotivoIsencao,
        codigoMotivoIsencao: event.data.codigoMotivoIsencao,
        desMotivoIsencao: event.data.desMotivoIsencao,
      },
    });
    this.displayExemption = false;
  }

  onOriginSelect(event) {
    this.documentEmitterService
      .getTotalCreditNote(event.data.idDocumentoGerado)
      .then((response) => {
        this.items.at(this.itemIndex).patchValue({
          tbDocumentoOrigems: {
            numDocumentoOrigem: event.data.numDocumento,
          },
          // valorBaseTributavelItens: (response.totalValorBaseTributavel - response.valorDisponivelAnular)
        });
      });
    this.displayOrigin = false;
  }

  readOriginsByEmitter(page = 0) {
    this.filter.page = page;
    this.documentEmitterService
      .readDocumentOriginsByEmitter(this.filter)
      .then((response) => {
        this.totalDocumentsOrigin = response.total;
        this.documentsOrigin = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.readOriginsByEmitter(pagina);
  }

  onTaxRateSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbTaxaAplicavel: {
        idTaxaAplicavel: event.data.idTaxaAplicavel,
        descTaxaAplicavel: event.data.descTaxaAplicavel,
        valorTaxaAplicavel: event.data.valorTaxaAplicavel,
      },
    });
    this.clearTaxesRegimeCode();
    this.getExemptionReasons(event.data);
    this.calcItemTax(this.itemIndex);
    this.displayTaxRate = false;
  }

  calcItemTax(index: number) {
    const itemAmount = this.items
      .at(index)
      .get('valorBaseTributavelItens').value;
    const itemTaxRate = this.items.at(index).get('tbTaxaAplicavel')
      .value.valorTaxaAplicavel;

    var m = new Mathematic();
    this.items.at(index).patchValue({
      valorImposto: m.round((itemAmount * itemTaxRate) / 100, 2),
    });

    const itemTax = this.items.at(index).get('valorImposto').value;

    this.items.at(index).patchValue({
      valorItens: m.round(itemAmount + itemTax, 2),
    });

    this.calcTax();
  }

  calcTax() {
    var m = new Mathematic();
    var amount = 0;
    var tax = 0;
    var total = 0;

    for (var i = 0; i < this.items.length; i++) {
      amount += this.items.at(i).get('valorBaseTributavelItens').value;
      tax += this.items.at(i).get('valorImposto').value;
      total += this.items.at(i).get('valorItens').value;
    }

    this.documentForm
      .get('totalValorBaseTributavel')
      .patchValue(m.round(amount, 2));
    this.documentForm.get('totalImposto').patchValue(m.round(tax, 2));
    this.documentForm.get('totalValor').patchValue(m.round(total, 2));
  }

  showTaxSigla(documentDate: any) {
    const IvaStartDate: Date = new Date(2023, 5, 1);
    this.siglaTipoImpostoToShow =
      documentDate >= IvaStartDate || !documentDate ? 'IVA' : 'ISC';
  }

  save() {
    this.loading = true;

    /*var sendForm: FormGroup = this.fb.group(this.documentForm.controls);

    for (let i = 0; i < this.items.length; i++) {
      sendForm.controls.tbItensDocumentoGerados['controls'][i].removeControl('totalOrigin');

      if (!['NC', 'ND'].includes(sendForm.get('tbTipoDocumentoEmissao').value.siglaTipoDocumentoEmissao)) {
        sendForm.controls.tbItensDocumentoGerados['controls'][i].removeControl('tbDocumentoOrigems');
      }
    }

    sendForm.removeControl('tbTipoDocumentoEmissao');
    sendForm.removeControl('nomeConsumidor');
    sendForm.removeControl('nifEmissor');
    sendForm.removeControl('nomeEmissor');*/

    this.documentEmitterService
      .create(this.documentForm.getRawValue())
      .then((response) => {
        this.messageService.add({
          severity: 'success',
          detail: 'Documento salvo com sucesso.',
        });
        this.documentForm.reset();
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }
}

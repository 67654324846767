<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/admin">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADMIN
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS TEMPORÁRIOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="nifEmissor" [(ngModel)]="filter.nifEmissor">
                  <label for="float-input">NIF Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="nifConsumidor" [(ngModel)]="filter.nifConsumidor">
                  <label for="float-input">NIF Adquirente</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="numDocumento" [(ngModel)]="filter.numDocumento">
                  <label for="float-input">Número Documento</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <input class="p-inputtext-sm" pInputText name="numSerieDocumento"
                    [(ngModel)]="filter.numSerieDocumento">
                  <label for="float-input">Número Série</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoDe"
                    [(ngModel)]="filter.dtEmissaoDocumentoDe" dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Inicial</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoAte"
                    [(ngModel)]="filter.dtEmissaoDocumentoAte" dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data Final</label>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-1">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="documents"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} documentos recolhidos.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '4rem'}">ID</th>
              <th [style]="{width: '10%'}">Tipo Documento</th>
              <th [style]="{width: '10%'}">Nº Documento</th>
              <th [style]="{width: '6%'}">NIF Emitente</th>
              <th [style]="{width: '25%'}">Nome Emitente</th>
              <th [style]="{width: '6%'}">NIF Adquirente</th>
              <th [style]="{width: '25%'}">Nome Adquirente</th>
              <th [style]="{width: '6%'}">Data Emissão</th>
              <th [style]="{width: '8%'}">Valor Total</th>
              <th [style]="{width: '4rem'}">ID Ficheiro</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">ID</span>
                {{rowData.idDocumentoGerado}}
              </td>

              <td>
                <span class="p-column-title">Tipo Documento</span>
                {{rowData.tbTipoDocumentoDescricao}}
              </td>

              <td>
                <span class="p-column-title">Nº Documento</span>
                {{rowData.numDocumento}}
              </td>

              <td>
                <span class="p-column-title">NIf Emitente</span>
                {{rowData.tbContribuinteNifEmissor}}
              </td>

              <td>
                <span class="p-column-title">Nome Emitente</span>
                {{rowData.tbPessoaNomeEmissor}}
              </td>

              <td>
                <span class="p-column-title">NIF Adquirente</span>
                {{ rowData.tbContribuinteNifConsumidor }}
              </td>

              <td>
                <span class="p-column-title">Nome Adquirente</span>
                {{ rowData.tbPessoaNomeConsumidor }}
              </td>

              <td>
                <span class="p-column-title">Data Emissão</span>
                {{ rowData.dtEmissaoDocumento | date:'dd/MM/yyyy' }}
              </td>

              <td>
                <span class="p-column-title"> Valor Total </span>
                <strong>{{ rowData.totalValor | number:'0.1-2':'pt' }}</strong>
              </td>

              <td>
                <span class="p-column-title"> ID Ficheiro </span>
                <strong>{{ rowData.idArquivoDocumentoGerado }}</strong>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

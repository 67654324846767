import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { EmissionDocumentTypographyRouter } from './emission-document-typography-router';
import { IncrementComponent } from './increment/increment.component';
import { ReadComponent } from './read/read.component';
import { TransportComponent } from './transport/transport.component';
import { ChangeEmitterComponent } from './change-emitter/change-emitter.component';
import { GetComponent } from './get/get.component';

@NgModule({
  declarations: [ReadComponent, IncrementComponent, TransportComponent, ChangeEmitterComponent, GetComponent],
  imports: [
    CommonModule,
    EmissionDocumentTypographyRouter,

    FormsModule,
    MDBBootstrapModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    DropdownModule,
    DialogModule,
    InputNumberModule
  ]
})
export class EmissionDocumentTypographyModule { }

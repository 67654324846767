import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttp } from '../auth/app-http';
import { User } from '../core/models/user';
import { environment } from './../../environments/environment';

export class UserAdminFilter {
  page = 0;
  rows = 10;

  nifUsuario: string;
  nomeUsuario: string;
  emailUsuario: string;
  dataCriaUsuarioDe: Date;
  dataCriaUsuarioAte: Date;

  isFirst: boolean;
  statusAssinatura: boolean;

  // filtros não implementados
  statusCadastro: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {

  API_URL: string;

  constructor(
    private http: AppHttp,
  ) {
    this.API_URL = `${environment.apiUrl}/usuarios`;
  }

  read(filter: UserAdminFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifUsuario)
      params = params.append('nifUsuario', filter.nifUsuario.toString());

    if (filter.nomeUsuario)
      params = params.append('nomePessoa', filter.nomeUsuario.toString());

    if (filter.emailUsuario)
      params = params.append('emailUsuario', filter.emailUsuario.toString());

    if (filter.isFirst)
      params = params.append('isFirst', filter.isFirst.toString());

    if (filter.dataCriaUsuarioDe)
      params = params.append('dataCriaUsuarioDe', moment(filter.dataCriaUsuarioDe).format('YYYY-MM-DD'));

    if (filter.dataCriaUsuarioAte)
      params = params.append('dataCriaUsuarioAte', moment(filter.dataCriaUsuarioAte).format('YYYY-MM-DD'));

    if (filter.statusAssinatura)
      params = params.append('statusAssinatura', filter.statusAssinatura.toString());

    return this.http.get<any>(this.API_URL, { params })
      .toPromise()
      .then(response => {
        this.convertFields(response.content);
        return {
          body: <User[]>response.content,
          total: response.totalElements
        }
      })
  }

  get(id): Promise<User> {
    return this.http.get<User>(`${this.API_URL}/${id}`)
      .toPromise()
      .then(response => {
        const user = response as User;
        this.convertFields([user]);
        return user;
      })
  }

  update(user: User): Promise<User> {
    /*delete user.dataAlterUsuario;
    delete user.dataCriaUsuario;
    delete user.isFirst;
    delete user.senhaUsuario;
    delete user.senhaUsuarioConfirmacao;
    delete user.senhaUsuarioRecuperacao;
    delete user.statusUsuario;
    delete user.tbContribuinte.dtAlterContribuinte;
    delete user.tbContribuinte.dtCriaContribuinte;
    delete user.tbContribuinte.tbPessoa.btStatus;
    delete user.tbContribuinte.tbPessoa.dtAlterPessoa;
    delete user.tbContribuinte.tbPessoa.dtCriaPessoa;
    delete user.tbPerfil.dataAlterPerfil;
    delete user.tbPerfil.dataCriaPerfil;
    delete user.tbPerfil.permissoes;*/

    return this.http.put<User>(`${this.API_URL}/${user.idUsuario}`, user)
      .toPromise()
      .then(response => {
        const user = response as User;
        this.convertFields([user]);
        return user;
      })
  }

  private convertFields(users: User[]) {
    users.forEach(user => {
      user.dataCriaUsuario = moment(user.dataCriaUsuario, 'YYYY-MM-DD').toDate();
      user.dataAlterUsuario = moment(user.dataAlterUsuario, 'YYYY-MM-DD').toDate();
    });
  }

}

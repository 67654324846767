<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TERCEIROS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/third-party/index">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          PRESTADOR DE SERVIÇOS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          GERIR OPERADORES ECONÓMICOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <button type="button" mdbBtn size="md" color="light" mdbWavesEffect (click)="visibleSidebarFilter=true">
          <mdb-icon fas icon="search"></mdb-icon>
          Filtrar
        </button>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="thirdParties"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="total"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} linhas.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'30%'}">Operador Económico</th>
              <th [style]="{width:'30%'}">Atividade</th>
              <th [style]="{width:'10%'}">Data de Registo</th>
              <th [style]="{width:'10%'}">Contri. / Terce.</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Contribuinte</span>
                {{rowData.nifContriPri + ' - ' + rowData.nomeContriPri}}
              </td>

              <!-- <td>
                <span class="p-column-title">Terceiro</span>
                {{rowData.nifContriTer + ' - ' + rowData.nomeContriTer}}
              </td> -->

              <td>
                <span class="p-column-title">Actividade</span>
                {{rowData.labelActivity}}
              </td>

              <td>
                <span class="p-column-title">Data de Registo</span>
                {{ rowData.dataCria | date }}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <p-inputSwitch [(ngModel)]="rowData.aceitoPri" class="p-mr-2"
                  [disabled]="!canTaxpayerAccept(rowData.nifContriPri)" (onChange)="acceptForTaxpayer(rowData)">
                </p-inputSwitch>

                <p-inputSwitch [(ngModel)]="rowData.aceitoTer" [disabled]="!canThirdPartAccept(rowData.nifContriTer)"
                  (onChange)="acceptForThirdPart(rowData)">
                </p-inputSwitch>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-sidebar [(visible)]="visibleSidebarFilter" position="right" [baseZIndex]="10000" [style]="{width: '450px'}">
  <h2 style="font-weight:normal">Filtros</h2>
  <form #formFilter="ngForm" autocomplete="off" (ngSubmit)="read()">
    <div class="p-fluid">

      <div class="p-grid p-col-12 p-pb-2">
        <div class="p-col-6">
          <button type="button" mdbBtn color="red" size="md" block="true" (click)="resetFormFilter(formFilter)">
            Limpar
          </button>
        </div>

        <div class="p-col-6">
          <button type="submit" mdbBtn color="info" size="md" block="true">
            Filtrar
          </button>
        </div>
        <hr>
      </div>

      <p-scrollPanel [style]="{width: '100%', height: '80vh'}">
        <div class="p-field p-mt-5">
          <span class="p-float-label p-input-icon-right">
            <i class="pi pi-user"></i>
            <input pInputText class="p-inputtext-sm" name="nifContriTer" [(ngModel)]="filter.nifContriTer">
            <label for="float-input">NIF do Terceiro</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label p-input-icon-right">
            <i class="pi pi-user"></i>
            <input pInputText class="p-inputtext-sm" name="nomeContriTer" [(ngModel)]="filter.nomeContriTer">
            <label for="float-input">Nome do Terceiro</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown class="p-inputtext-sm" [options]="activities" name="idAtividade" [showClear]="true"
              [autoDisplayFirst]="false" [(ngModel)]="filter.idAtividade" placeholder="Atividade">
            </p-dropdown>
          </span>
        </div>
      </p-scrollPanel>
    </div>
  </form>
</p-sidebar>

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';

@Injectable({
  providedIn: 'root'
})
export class FileDocumentAnalysisReportService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/relatorioAnaliseFicheiro`
  }

  readByFIleDocument(fileId: number): Promise<any> {
    let params = new HttpParams();
    params = params.append('idFicheiro', fileId.toString());

    return this.http.get<any>(`${this.API_URL}/porFicheiro`, { params })
      .toPromise()
  }
}

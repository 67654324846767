import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HelpCenterRouter } from './help-center-router';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HelpCenterRouter,
    MDBBootstrapModule,
  ]
})
export class HelpCenterModule { }

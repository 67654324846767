<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tipografia">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          INCREMENTAR NÚMEROS À SÉRIE
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <span class="text-info">
        <mdb-icon fas icon="info-circle"></mdb-icon>
      </span>

      Esta funcionalidade permite corrigir o emitente ou proprietário de uma determinada série que por engano foi
      atribuída a outro contribuinte.
    </div>
  </div>

  <div class="p-col-4">
    <mdb-card>
      <mdb-card-header>
        <h5>Corrigir Proprietário da Série</h5>
      </mdb-card-header>

      <mdb-card-body>
        <form autocomplete="off" #f="ngForm" (ngSubmit)="fixEmitter(f)">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">

              <div class="p-field p-col-12">
                <label>Série</label>
                <b class="text-danger"> *</b>
                <div class="p-inputgroup">
                  <input pInputText name="serieNumber" [(ngModel)]="filter.serieNumber"
                    placeholder="Informe o número da série" required>

                  <button type="button" pButton pRipple label="Pesquisar" (click)="getSerieDetail()"></button>
                </div>
              </div>

              <div class="p-field p-col-12">
                <label>Atual Emitente</label>
                <input pInputText name="nomeEmissor" [(ngModel)]="emissionDocument.nomeEmissor" disabled required
                  placeholder="Atual emitente ou proprietário">
              </div>

              <div class="p-field p-col-12">
                <label>NIF do novo emitente</label>
                <b class="text-danger"> *</b>
                <input pInputText (input)="onValidNif($event)" name="newEmitterNif" [(ngModel)]="newEmitterNif" required
                  placeholder="NIF do novo emitente ou proprietário">
                <div *ngIf="!validNif" class="ui-message ui-message-error">NIF inválido</div>
              </div>

              <div class="p-field p-col-12">
                <label>Nome do novo emitente</label>
                <input pInputText name="newEmitterName" [(ngModel)]="newEmitterName" required disabled
                  placeholder="Nome do novo emitente ou proprietário">
              </div>

              <div class="p-col-12 text-right">
                <hr>
                <button type="button" mdbBtn color="light" mdbWavesEffect routerLink="/tipografia">
                  VOLTAR
                </button>

                <button type="submit" mdbBtn color="success" mdbWavesEffect
                  [disabled]="!f.valid || !validNif || loading">
                  SALVAR
                </button>
              </div>

              <div class="p-col-12" [style]="{marginTop: '20px'}">
                <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
                </p-progressBar>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR SÉRIES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <button
          type="button"
          mdbBtn
          size="md"
          color="light"
          mdbWavesEffect
          (click)="visibleSidebarFilter = true"
        >
          <mdb-icon fas icon="search"></mdb-icon>
          Filtrar
        </button>

        <!-- <button type="button" mdbBtn size="md" color="primary" mdbWavesEffect
          routerLink="/emitter/emission-document/transport">
          TRANSPORTAR SÉRIE PARA O ANO 2021
        </button> -->
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table
          styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
          [value]="emissionDocuments"
          [loading]="loading"
          [responsive]="true"
          [paginator]="true"
          [rows]="filter.rows"
          [lazy]="true"
          [totalRecords]="total"
          dataKey="idPedidoEmissaoDocumento"
          editMode="row"
          (onLazyLoad)="onChangePage($event)"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number : "1.0-0" }} séries solicitadas.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{ width: '10%' }">Documento</th>
              <th [style]="{ width: '9%' }">Série</th>
              <th [style]="{ width: '9%' }">Local de Facturação</th>
              <th [style]="{ width: '10%' }">Nº Autorização</th>
              <th [style]="{ width: '5%' }">Última Solicit.</th>
              <th [style]="{ width: '5%' }">Total Solicitado</th>
              <th [style]="{ width: '6%' }">Dt. Solicit.</th>
              <th [style]="{ width: '15%' }">Descrição</th>
              <th [style]="{ width: '15%' }">Tipo de Certificação</th>
              <th [style]="{width:'8.5%'}">Estado</th>
              <th [style]="{ width: '2.5%' }"></th>
              <th [style]="{ width: '2.5%' }"></th>
              <th [style]="{ width: '2.5%' }"></th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-rowData
            let-editing="editing"
            let-ri="rowIndex"
          >
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">Tipo de Documento</span>
                {{ rowData.tipoDocumento }}
              </td>
              <td>
                <span class="p-column-title">Série</span>
                {{ rowData.siglaSerie + "" + rowData.serie + "" + rowData.ano }}
              </td>

              <td>
                <span class="p-column-title">Local de Facturação</span>
                <a (click)="showDialogUpdateLocalInvoice(rowData)">
                  <p-tag
                    [severity]="rowData.colorLocalTributacao"
                    [value]="rowData.descLocalTributacao"
                  ></p-tag>
                </a>
              </td>

              <td>
                <span class="p-column-title">Nº Autorização</span>
                {{ rowData.numAutorizacaoEmissao }}
              </td>

              <td>
                <span class="p-column-title">Última Solicit.</span>
                {{ rowData.totalSolicitado }}
              </td>

              <td>
                <span class="p-column-title">Total Solicitado</span>
                {{ rowData.totalJaSolicitado }}
              </td>

              <td>
                <span class="p-column-title">Dt. Solicit.</span>
                {{ rowData.data | date : "dd/MM/yyyy" }}
              </td>

              <td>
                <span class="p-column-title">Descrição</span>
                {{ rowData.descricao }}
              </td>

              <td>
                <span class="p-column-title">Requerente</span>
                {{ rowData.tipoCertificacao }}
              </td>
              <td>
                <span class="p-column-title">Estado</span>
                <p-tag [severity]="getDocumentStatus(rowData) ? 'danger' : 'success'" [value]="getDocumentStatusLabel(rowData)"></p-tag>
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <button
                  mdbBtn
                  block="true"
                  color="info"
                  size="sm"
                  pTooltip="Mais detalhes"
                  tooltipPosition="left"
                  (click)="showDialogDetails(rowData)"
                  [style]="{ padding: '4px 0' }"
                >
                  <i class="fa fa-search"></i>
                </button>
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <button
                  mdbBtn
                  block="true"
                  color="warning"
                  size="sm"
                  pTooltip="Editar"
                  tooltipPosition="left"
                  (click)="showDialogEdit(rowData)"
                  [style]="{ padding: '4px 0' }"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <button
                  mdbBtn
                  block="true"
                  color="primary"
                  size="sm"
                  pTooltip="Adicionar números a série"
                  tooltipPosition="left"
                  (click)="showDialogIncrement(rowData)"
                  [style]="{ padding: '4px 0' }"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Atualizar Série"
  [(visible)]="displayEdit"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <form #adicionarSequenciaInicialForm="ngForm" (ngSubmit)="update()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>Adicione uma descrição</label>
        <input
          pInputText
          type="text"
          name="descPedidoEmissaoDocumento"
          [(ngModel)]="emissionDocument.descPedidoEmissaoDocumento"
        />
      </div>

      <div class="p-field p-col-12">
        <label>Nº do primeiro documento</label>
        <p-inputNumber
          name="numInicialSequencial"
          [(ngModel)]="emissionDocument.numInicialSequencial"
          mode="decimal"
          [min]="0"
          [max]="100"
        >
        </p-inputNumber>
      </div>

      <!-- <div class="p-field p-col-12">
        <label>Qtd de dígitos do número da factura</label>
        <input pInputText type="text" name="mascaraDocumento" [(ngModel)]="emissionDocument.mascara">
      </div> -->
    </div>

    <div class="p-col-12 text-right">
      <hr />
      <button
        type="submit"
        mdbBtn
        size="sm"
        color="green"
        [disabled]="!adicionarSequenciaInicialForm.valid || loading"
      >
        SALVAR
      </button>

      <button
        type="button"
        mdbBtn
        size="sm"
        color="light"
        (click)="displayEdit = false"
      >
        FECHAR
      </button>
    </div>

    <div class="p-col-12">
      <p-progressBar
        *ngIf="loading"
        mode="indeterminate"
        [style]="{ height: '3px' }"
      ></p-progressBar>
    </div>
  </form>
</p-dialog>

<p-dialog
  header="Detalhes"
  [(visible)]="displayDetails"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '40vw', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="table-responsive-sm">
    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row"><strong>Tipo Documento </strong></th>
          <td>{{ emissionDocumentView.tipoDocumento }}</td>
        </tr>

        <tr>
          <th scope="row"><strong>Nº de Série </strong></th>
          <td>
            {{
              emissionDocumentView.siglaSerie +
                "" +
                emissionDocumentView.serie +
                "" +
                emissionDocumentView.ano
            }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Nº de Autorização </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.numAutorizacaoEmissao }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Data de Autorização </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.data | date : "dd/MM/yyyy" }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Descrição </strong></th>
          <td colspan="2">{{ emissionDocumentView.descricao }}</td>
        </tr>

        <tr>
          <th scope="row"><strong>Total Solicitado </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.totalJaSolicitado }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Total Última Solicitação </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.totalSolicitado }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Nº da Primeira Factura </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.numInicialSequencial }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Máscara </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.mascara }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Emitente </strong></th>
          <td colspan="2">
            {{
              emissionDocumentView.nifEmissor +
                " - " +
                emissionDocumentView.nomeEmissor
            }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Requerente </strong></th>
          <td colspan="2">
            {{
              emissionDocumentView.nifRequerente +
                " - " +
                emissionDocumentView.nomeRequerente
            }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Tipo de Certificação </strong></th>
          <td colspan="2">
            {{ emissionDocumentView.tipoCertificacao }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="p-col text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
      (click)="displayDetails = false"
      [disabled]="loading"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Incrementar Série"
  [(visible)]="displayIncrement"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '500px', minWidth: '200px' }"
  [minY]="70"
  [baseZIndex]="10000"
>
  <form
    #formIncrement="ngForm"
    autocomplete="off"
    (ngSubmit)="incrementDocInSerie()"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>Série</label>
        <input
          pInputText
          name="numSerieDocumento"
          [(ngModel)]="serieToShow"
          disabled
        />
      </div>

      <div class="p-field p-col-12">
        <label>Quantidade a incrementar</label>
        <input
          pInputText
          name="qtDocumentosSolicitado"
          [(ngModel)]="emissionDocument.qtDocumentosSolicitado"
        />
      </div>

      <div class="p-col-12 text-right">
        <hr />
        <button
          type="button"
          mdbBtn
          color="light"
          mdbWavesEffect
          (click)="displayIncrement = false"
        >
          CANCELAR
        </button>

        <button
          type="submit"
          mdbBtn
          color="success"
          mdbWavesEffect
          [disabled]="!formIncrement.valid || loadingIncrement"
        >
          VALIDAR
        </button>
      </div>

      <div class="p-col-12" [style]="{ marginTop: '20px' }">
        <p-progressBar
          *ngIf="loadingIncrement"
          mode="indeterminate"
          [style]="{ height: '3px' }"
        >
        </p-progressBar>
      </div>
    </div>
  </form>
</p-dialog>

<p-sidebar
  [(visible)]="visibleSidebarFilter"
  position="right"
  [baseZIndex]="10000"
  [style]="{ width: '450px' }"
>
  <h2 style="font-weight: normal">Filtros</h2>
  <form #formFilter="ngForm" autocomplete="off" (ngSubmit)="read()">
    <div class="p-fluid">
      <div class="p-grid p-col-12 p-pb-2">
        <div class="p-col-6">
          <button
            type="button"
            mdbBtn
            color="red"
            size="md"
            block="true"
            (click)="resetFormFilter(formFilter)"
          >
            Limpar
          </button>
        </div>

        <div class="p-col-6">
          <button type="submit" mdbBtn color="info" size="md" block="true">
            Filtrar
          </button>
        </div>
        <hr />
      </div>

      <p-scrollPanel [style]="{ width: '100%', height: '80vh' }">
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              inputId="dropdown"
              class="p-inputtext-sm"
              [autoDisplayFirst]="false"
              [options]="typesDocument"
              name="typeDocument"
              [(ngModel)]="filter.typeDocument"
              optionLabel="name"
              [showClear]="true"
            >
            </p-dropdown>
            <label for="dropdown">Tipo Documento</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="serieNumber"
              [(ngModel)]="filter.serieNumber"
            />
            <label for="float-input">Número de Série (ex.FR0000120)</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="description"
              [(ngModel)]="filter.description"
            />
            <label for="float-input">Descrição</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              inputId="dropdownYear"
              class="p-inputtext-sm"
              [autoDisplayFirst]="false"
              [options]="years"
              name="year"
              [(ngModel)]="filter.year"
              optionLabel="name"
              [showClear]="true"
            >
            </p-dropdown>
            <label for="dropdownYear">Ano</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="applicantNif"
              [(ngModel)]="filter.applicantNif"
            />
            <label for="float-input">NIF do Requerente</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="applicantName"
              [(ngModel)]="filter.applicantName"
            />
            <label for="float-input">Nome do Requerente</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-calendar
              class="p-inputtext-sm"
              name="dtEmissaoDocumentoDe"
              [(ngModel)]="filter.dataInicio"
              dateFormat="dd/mm/yy"
              [locale]="locale.pt"
            >
            </p-calendar>
            <label for="float-input">Data inicial</label>
          </span>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <p-calendar
              class="p-inputtext-sm"
              name="dtEmissaoDocumentoAte"
              [(ngModel)]="filter.dataFim"
              dateFormat="dd/mm/yy"
              [locale]="locale.pt"
            >
            </p-calendar>
            <label for="float-input">Data final</label>
          </span>
        </div>
      </p-scrollPanel>
    </div>
  </form>
</p-sidebar>

<p-dialog
  header="AlterarTipo de Documento"
  [(visible)]="displayEditLocalInvoice"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '40vw', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="table-responsive-sm">
    <table mdbTable striped="true" small="true">
      <tbody>
        <tr>
          <th scope="row"><strong>Nº de Série </strong></th>
          <td>
            {{
              emissionDocumentView.siglaSerie +
                "" +
                emissionDocumentView.serie +
                "" +
                emissionDocumentView.ano
            }}
          </td>
        </tr>

        <tr>
          <th scope="row"><strong>Local de Emissão Atual </strong></th>
          <td>
            <p-tag
              [severity]="emissionDocumentView.colorLocalTributacao"
              [value]="emissionDocumentView.descLocalTributacao"
            ></p-tag>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="alert alert-warning p-col-12" role="alert">
    <p>
      Deseja alterar o local de emissão para
      {{
        emissionDocumentView.localTributacao === "S" ? "Príncipe" : "São Tomé"
      }}?
    </p>
  </div>

  <div class="p-col text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="success"
      mdbWavesEffect
      [disabled]="loadingEditLocalInvoice"
      (click)="handleEditLocalInvoice()"
    >
      SIM
    </button>

    <button
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
      (click)="displayEditLocalInvoice = false"
      [disabled]="loading"
    >
      NÃO
    </button>
  </div>
  <div class="p-col-12">
    <p-progressBar
      *ngIf="loadingEditLocalInvoice"
      mode="indeterminate"
      [style]="{ height: '3px' }"
    ></p-progressBar>
  </div>
</p-dialog>

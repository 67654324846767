import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AppHttp } from './../auth/app-http';
import { Emission } from './../core/models/emission';

export class EmissionEmitterFilter {
  page = 0;
  rows = 15;
  nifConsumidor: string;
  nifEmissor: string;
  dtCriaPedidoEmissaoFaturaDe: Date;
  dtCriaPedidoEmissaoFaturaAte: Date;
}

@Injectable({
  providedIn: 'root'
})
export class EmissionEmitterService {
  apiUrl: string;

  constructor(private http: AppHttp) {
    this.apiUrl = `${environment.apiUrl}/pedidosEmissaoFatura`;
  }

  async read(filter: EmissionEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    const response = await this.http.get<any>(`${this.apiUrl}/porConsumidor`, { params })
      .toPromise();
    return {
      body: <Emission[]>response.content,
      total: response.totalElements
    };
  }

  async get(id: number): Promise<Emission> {
    const response = await this.http.get<Emission>(`${this.apiUrl}/${id}`)
      .toPromise();
    const emission = response as Emission;
    this.convertFields([emission]);
    return emission;
  }

  creat(emission: any): Promise<any> {
    let params = new HttpParams();

    const emitterNif = emission.emitterNif;

    if (emitterNif)
      params = params.append('nifEmitente', emitterNif);

    delete emission.emitterNif;

    return this.http.post(`${this.apiUrl}/empresa`, emission, { params })
      .toPromise()
  }

  creatForPortal(emission: Emission): Promise<any> {
    return this.http.post(`${this.apiUrl}/online`, emission)
      .toPromise()
  }

  private convertFields(emissions: Emission[]) {
    for (const emission of emissions) {
      emission.dataPedidoEmissaoFatura = moment(emission.dataPedidoEmissaoFatura,
        'YYYY-MM-DD').toDate();

      emission.dtAlterPedidoEmissaoFatura = moment(emission.dtAlterPedidoEmissaoFatura,
        'YYYY-MM-DD').toDate();

      emission.dtCriaPedidoEmissaoFatura = moment(emission.dtCriaPedidoEmissaoFatura,
        'YYYY-MM-DD').toDate();
    }
  }
}

<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/emitter/document-online">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-file-invoice purple-text"></i></div>
        <h4 class="title"> Sistema de Facturação </h4>
        <p class="description">
          Emissão e consulta de facturas à partir do portal.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/util/certification/read-typography-certified">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-check-circle purple-text"></i></div>
        <h4 class="title"> Tipografias Certificadas </h4>
        <p class="description">
          Consulta de tipografias certificadas para imprimir blocos de facturas.
        </p>
      </div>
    </a>
  </div>

  <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/util/authorization/check">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-search purple-text"></i></div>
        <h4 class="title"> Verificar Séries </h4>
        <p class="description">
          Permite verificar séries de um determinado emitente.
        </p>
      </div>
    </a>
  </div>

  <!-- <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/util/authorization/check">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fa fa-info-circle text-info"></i></div>
        <h4 class="title"> Tutoriais </h4>
        <p class="description">
          Instruções para transportar as suas séries para o ano 2021, corrigir os seus
          documentos comunicados incorretamente e gerir os ficheiros comunicados.
        </p>
      </div>
    </a>
  </div> -->

  <!-- <div class="p-col-12 p-md-6">
    <a class="a-box" routerLink="/util/help-center">
      <div class="box wow fadeInRight" data-wow-delay="0.2s">
        <div class="icon"> <i class="fas fa-question-circle purple-text"></i> </div>
        <h4 class="title"> Central de ajuda </h4>
        <p class="description">
          Agora o contribuinte disponibiliza-se de uma área onde pode entrar em contacto com a equipa do
          e-factura a fim de obter ajuda e esclarecimentos.
        </p>
      </div>
    </a>
  </div> -->

  <div class="p-col-12 p-md-6 p-lg-6">
    <a class="a-box" routerLink="/third-party/index">
      <div class="box wow fadeInLeft" data-wow-delay="0.2s">
        <div class="icon"><i class="fa fa-users-cog purple-text"></i></div>
        <h4 class="title"> Terceiros </h4>
        <p class="description">
          Permite ao utilizador gerir as suas informações de terceiros e operar em nome de outrém.
        </p>
      </div>
    </a>
  </div>
</div>

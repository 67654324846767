import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { User } from 'src/app/core/models/user';
import { ProfileService } from 'src/app/profile/profile.service';
import { UserAdminService } from './../user-admin.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  locale = new Locale();
  user = new User();
  profiles = [];
  loading: boolean = false;
  loadingProfile: boolean = false;
  display: boolean = false;

  constructor(
    private userService: UserAdminService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    const userId = this.route.snapshot.params.id;
    this.dropdownProfile();
    this.get(userId);
  }

  get(id) {
    this.userService.get(id)
      .then(response => {
        this.user = response;
      })
      .catch(error => this.errorHandler.handle(error));
  }

  update() {
    this.loading = true;
    this.userService.update(this.user)
      .then(response => {
        this.user = response;
        this.messageService.add({
          severity: 'success',
          detail: 'Informações alteradas com sucesso.'
        });
      })
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false);
  }

  dropdownProfile() {
    this.profileService.dropdown()
      .then(response => {
        this.profiles = response.map(p => ({
          label: p.descPerfil, value: p.idPerfil
        }))
      }).catch(error => this.errorHandler.handle(error));
  }
}

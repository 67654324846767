import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { jsPDF } from "jspdf";
import { TaxpayerService } from '../taxpayer.service';
import { ErrorHandlerService } from './../../core/error-handler.service';

@Component({
  selector: 'app-get-great-taxpayer-situation',
  templateUrl: './get-great-taxpayer-situation.component.html',
  styleUrls: ['./get-great-taxpayer-situation.component.scss']
})
export class GetGreatTaxpayerSituationComponent implements OnInit {
  loadingFilter: boolean = false;
  displayFilter: boolean = false;
  nif: string;

  data = {
    name: null,
    nif: null,
    isRegistered: null,
    registeredAt: null,
    series: [{
      id: null,
      serie: null,
      date: null,
      lastMonthCommunicated: null,
      monthsOverdue: [
        { number: null, label: null }
      ],
    }],
  };

  taxpayers = [
    { id: 3, nif: '109868361' },
  ];

  taxpayerId = this.taxpayers[0].id;

  constructor(
    private service: TaxpayerService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.read();
  }

  read() {
    this.service.readAll()
      .then((result) => {
        this.taxpayers = result.map((taxpayer) => ({
          id: taxpayer.id,
          nif: taxpayer.nif
        }));

        this.taxpayerId = this.taxpayers[0].id;
        this.getSituation(this.taxpayerId);
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  getSituation(id: number) {
    this.service.getSituation(id)
      .then(response => {
        const taxpayerInfo = {
          id: response.id,
          nif: response.nif,
          name: response.nomeContribuinte,
          isRegistered: response.registoPortal,
          registeredAt: response.registoPortalData,
        }

        const series = response.grandesContribuintesDtoInfos.map((grand) => ({
          id: grand.idSerie,
          serie: grand.seriesSolicitada,
          date: grand.seriesSolicitadaData,
          lastMonthCommunicated: grand.ultimoMesComunicado,
          monthsOverdue: grand.mesesAtraso.map(month => ({
            ...grand,
            number: month,
            label: new Date(2021, (month - 1), 10).toLocaleString('default', { month: 'long' })
          }))
        }));

        this.data = {
          ...taxpayerInfo,
          series
        };
      })
      .catch(error => this.errorHandler.handle(error));
  }

  arrowLeft() {
    let index = this.taxpayers.findIndex(
      (item) => item.id === this.taxpayerId
    );

    if (index > 0) {
      index--;
      this.taxpayerId = this.taxpayers[index].id;
      this.getSituation(this.taxpayerId);
    }
  }

  arrowRight() {
    let index = this.taxpayers.findIndex(
      (item) => item.id === this.taxpayerId
    );

    if (index < this.taxpayers.length - 1) {
      index++;
      this.taxpayerId = this.taxpayers[index].id;
      this.getSituation(this.taxpayerId);
    }
  }

  disableArrowLeft() {
    let index = this.taxpayers.findIndex(
      (item) => item.id === this.taxpayerId
    );

    if (index <= 0) {
      return true;
    }
    return false;
  }

  disableArrowRight() {
    let index = this.taxpayers.findIndex(
      (item) => item.id === this.taxpayerId
    );

    if (index >= this.taxpayers.length - 1) {
      return true;
    }
    return false;
  }

  async filter() {
    this.loadingFilter = true;

    const [taxpayer] = await this.taxpayers.filter((item) => item.nif === this.nif);

    if (!taxpayer) {
      this.messageService.add({
        severity: 'error',
        detail: 'NIF inexistente ou não pertence ao grupo de grandes contribuintes',
        life: 10000
      });
      this.loadingFilter = false;
      this.displayFilter = false;
      return;
    }

    await this.getSituation(taxpayer.id);
    this.nif = null;
    this.loadingFilter = false;
    this.displayFilter = false;
  }

  exportReport() {
    const report = new jsPDF();

    report.setFontSize(12);
    report.text('SITUAÇÃO DE CONTRIBUINTES NO E-FACTURA', 10, 10);

    report.setFontSize(12);
    report.text('Grandes Contribuintes', 10, 20);

    // report.text("This is centred text.", 105, 80, null, null, "center");
    // report.text("And a little bit more underneath it.", 105, 90, null, null, "center");

    report.setFontSize(11);
    report.text(`Contribuinte: ${this.data.name}`, 10, 30);
    report.text(`NIF: ${this.data.nif}`, 10, 35);
    report.text(`Data de registo: ${this.data.registeredAt}`, 10, 40);
    report.text(`Séries adquiridas nas gráficas: INSERIR EM TABELA`, 10, 45);
    report.text(`Último mês comunicado`, 10, 50);
    report.text(`Resumo de comunicação`, 10, 55);

    report.table(10, 60, this.generateData(10), this.headers, { autoSize: true });

    report.save('report.pdf');
  }

  generateData = function (amount) {
    var result = [];
    var data = {
      id: null,
      coin: "100",
      game_group: "GameGroup",
      game_name: "XPTO2",
      game_version: "25",
      machine: "20485861",
      vlt: "0"
    };
    for (var i = 0; i < amount; i += 1) {
      data.id = (i + 1).toString();
      result.push(Object.assign({}, data));
    }
    return result;
  };

  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0
      });
    }
    return result;
  }

  headers = this.createHeaders([
    "id",
    "coin",
    "game_group",
    "game_name",
    "game_version",
    "machine",
    "vlt"
  ]);
}

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DocumentConsumerItem, DocumentConsumerItemForm } from './document-consumer-item';
import { TypeDocument } from './type-document';

export class DocumentConsumer {
  idDocumentoGeradoConsumidor: number;
  numDocumento: string;
  nifConsumidor: string;
  nomeConsumidor: string;
  nifEmissor: string;
  nomeEmissor: string;
  dtEmissaoDocumento: Date;
  totalValor: string;
  totalImposto: string;
  totalValorBaseTributavel: string;

  dtAlter: Date;
  dtCria: Date;
  criaIdUsuario: string;
  alterIdUsuario: string;

  tbTipoDocumentoEmissao = new TypeDocument();
  tbItensDocumentoGeradoConsumidor: DocumentConsumerItem[];
  files: File[];
}

export class DocumentConsumerForm {

  constructor(private fb: UntypedFormBuilder) { }

  builder(): UntypedFormGroup {
    return this.fb.group({
      numDocumento: new UntypedFormControl(null, Validators.required),
      nifEmissor: new UntypedFormControl(null, Validators.required),
      nifConsumidor: new UntypedFormControl(null),
      nomeEmissor: new UntypedFormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      dtEmissaoDocumento: new UntypedFormControl(new Date(), Validators.required),
      totalValorBaseTributavel: new UntypedFormControl(0, [Validators.min(1), Validators.required]),
      totalImposto: new UntypedFormControl(0, Validators.required),
      totalValor: new UntypedFormControl({ value: 0, disabled: true }, Validators.required),
      files: new UntypedFormControl(null, Validators.required),
    });
  }

}

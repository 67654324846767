import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmissionDocumentTypographyService } from '../emission-document-typography.service';
import { EmissionDocumentTypographyFilter } from './../emission-document-typography.service';
import { LazyLoadEvent } from 'primeng/api';
import { ErrorHandlerService } from './../../core/error-handler.service';

export class EmissionDocumentHistory {
  ano: number;
  dataCertificacao: Date;
  dataRequisicao: Date;
  endereco: string;
  idPedidoEmissaoDocumento: number;
  nifEmitente: string;
  nifRequerente: string;
  nifSolicitante: string;
  nomeEmitente: string;
  nomeRequerente: string;
  numSerie: string;
  numeroAutorizacao: string;
  numeroCertificacao: string;
  numeroSequencialFinal: number;
  numeroSequencialInicial: number;
  quantidadeSolicitada: number;
  sigla: string;
  telefone: string;
  totalJaSolicitado: number;
  totalSolicitado: number;
}

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {
  loading: boolean = true;
  displayDialogEditHistory: boolean = false;
  loadingUpdateHistory: boolean = false;

  histories = [];
  history = new EmissionDocumentHistory();

  filter = new EmissionDocumentTypographyFilter();
  total = 0;
  emissionDocumentId: number = this.route.snapshot.params.id;

  historyFullSerie: string;
  historyPageInterval: string;

  constructor(
    private service: EmissionDocumentTypographyService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

  }

  readHistory(page = 0): void {
    this.filter.page = page;
    this.filter.emissionDocumentId = this.emissionDocumentId;

    this.service.readHistory(this.filter)
      .then((result) => this.histories = result.body)
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loading = false)
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.readHistory(page);
  }

  getHistory(history: EmissionDocumentHistory) {
    this.history = history;
    this.historyFullSerie = `${this.history.sigla}${this.history.numSerie}${this.history.ano}`;
    this.historyPageInterval = `${this.history.numeroSequencialInicial} - ${this.history.numeroSequencialFinal}`;

    this.displayDialogEditHistory = true;
  }

  adjustInterval() {
    this.history.numeroSequencialFinal = Number(this.history.numeroSequencialInicial) + Number(this.history.quantidadeSolicitada);
    this.historyPageInterval = `${this.history.numeroSequencialInicial} - ${this.history.numeroSequencialFinal}`;
  }

  updateHistory() {
    this.service.updateHistory(this.history)
      .then((result) => result)
      .catch(error => this.errorHandler.handle(error))
      .finally(() => this.loadingUpdateHistory = false);
  }
}

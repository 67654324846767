<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/agente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          AGENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SOLICITAÇÕES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-10">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-3">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="subject" [(ngModel)]="filter.subject">
                  <label for="float-input">Assunto</label>
                </span>
              </div>

              <div class="p-field p-col-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="requesterUserNif"
                    [(ngModel)]="filter.requesterUserNif">
                  <label for="float-input">NIF solicitante</label>
                </span>
              </div>

              <div class="p-field p-col-2">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" selectionMode="range" [readonlyInput]="true" inputId="range"
                    [locale]="locale.pt" dateFormat="dd/mm/yy" name="answeredAt" [(ngModel)]="filter.answeredAt">
                  </p-calendar>
                  <label for="float-input">Data resposta</label>
                </span>
              </div>

              <div class="p-field p-col-2">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" selectionMode="range" [readonlyInput]="true" inputId="range"
                    [locale]="locale.pt" dateFormat="dd/mm/yy" name="createdAt" [(ngModel)]="filter.createdAt">
                  </p-calendar>
                  <label for="float-input">Data solicitação</label>
                </span>
              </div>

              <div class="p-field p-col">
                <button type="submit" mdbBtn pRipple color="pink" size="sm" class="btn-filter">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="occurrences"
          [loading]="loading" [paginator]="true" [rows]="filter.rows" [lazy]="true" [totalRecords]="totalRecords"
          (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ totalRecords | number:'1.0-0'}} pedidos de certificação.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'20%'}">Solicitante</th>
              <th [style]="{width:'20%'}">Técnico</th>
              <th [style]="{width:'25%'}">Assunto</th>
              <th>Data solicitação</th>
              <th>Data resposta</th>
              <th>Estado</th>
              <th [style]="{width:'3em'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Solicitante</span>
                {{ rowData.usuarioSolicitante.tbContribuinte.tbPessoa.nomePessoa }}
              </td>
              <td>
                <span class="p-column-title">Técnico</span>
                {{ rowData.usuarioResponde?.tbContribuinte.tbPessoa.nomePessoa }}
              </td>
              <td>
                <span class="p-column-title">Assunto</span>
                {{ rowData.assunto }}
              </td>
              <td>
                <span class="p-column-title">Recebido em</span>
                {{ rowData.dataCria | date }}
              </td>
              <td>
                <span class="p-column-title">Respondido em</span>
                {{ rowData.dataResposta | date }}
              </td>
              <td>
                <span class="p-column-title">Estado</span>
                <div class="badges">
                  <span [class]="['p-tag', rowData.statusColor]">
                    {{ rowData.status }}
                  </span>
                </div>
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" pTooltip="Ver mais detalhes" tooltipPosition="left"
                  class="btn-block" [style]="{padding: '4px 0'}" [routerLink]="['/occurrence/read', rowData.id]">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
